import React from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ContentCopyRounded } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '../button';
import { useAppSelector } from 'store/hooks';
import { THEME_MODE } from 'utilities';
import { useAlert } from 'hooks';

type Props = {
	text: string;
};

const InfoList = ({ text }: Props) => {
	const theme = useTheme();
	const alert = useAlert();
	const { mode } = useAppSelector((store) => store.theme);
	const handleCopy = () => {
		alert({ message: `${text} copied successfully!`, type: 'info' });
	};

	return (
		<Box
			sx={{
				padding: '10px 16px',
				border: `1px solid ${
					mode === THEME_MODE.dark ? grey[500] : theme.palette.primary.main
				}`,
				borderRadius: '5px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<Typography>{text}</Typography>
			<CopyToClipboard text={text} onCopy={() => handleCopy()}>
				<Button
					sx={{
						fontWeight: '600',
						fontSize: '13px',
						color:
							mode === THEME_MODE.dark ? grey[500] : theme.palette.primary.main,
					}}
					startIcon={<ContentCopyRounded />}
				>
					Copy
				</Button>
			</CopyToClipboard>
		</Box>
	);
};

export default InfoList;
