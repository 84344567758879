import React, { ChangeEvent, useEffect, useState } from 'react';
import {
	Box,
	Typography,
	useTheme,
	// InputAdornment,
} from '@mui/material';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { grey } from '@mui/material/colors';
import TextInput from '../form-components/text-input';
import Button from '../button';
import { purchaseVoucher, voucherProducts, eVoucherProviders } from 'api';
import {
	QUERY_KEYS,
	IProvider,
	Product,
	formatNumberToCurrency,
	THEME_MODE,
	LIGHT_GRAY,
	capitalize,
} from 'utilities';
import { setTransactionBreakdown } from 'store/transaction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
	useModalAlert,
	useHandleError,
	useVerifyPin,
	useVerifyCoupon,
	useAlert,
} from 'hooks';
import CopyToClipboard from 'components/copy-to-clipboard';
import CustomSelect from 'components/form-components/custom-select';

const SELECT_PROVIDER = 'Select provider';
const SELECT_PACKAGE = 'Select package';

interface IEVoucherPin {
	pin?: {
		number: string;
		serial: string;
		instructions: string;
	};
}

const EVoucherPin = ({ pin }: IEVoucherPin) => {
	const theme = useTheme();
	const mode = useAppSelector((store) => store.theme.mode);
	const isDark = mode === THEME_MODE.dark;
	if (pin) {
		return (
			<Box
				sx={{
					display: 'grid',
					gap: '4px',
				}}
			>
				<Typography>E-Voucher Details</Typography>
				{Object.keys(pin).map((pinKey) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							gap: '15px',
							backgroundColor: isDark
								? theme.palette.background.paper
								: LIGHT_GRAY,
							padding: '6px 10px',
						}}
						key={pinKey}
					>
						<Typography>{capitalize(pinKey)}</Typography>
						<Box
							sx={{
								display: 'flex',
								gap: '4px',
								alignItems: 'center',
							}}
						>
							<Typography>{pin[pinKey] || `No available ${pinKey}`}</Typography>
							{pinKey === 'number' && <CopyToClipboard text={pin[pinKey]} />}
						</Box>
					</Box>
				))}
			</Box>
		);
	}

	return null;
};

const EVoucherForm = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const handleError = useHandleError();
	const modal = useModalAlert();
	const verifyPin = useVerifyPin();
	const alert = useAlert();
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	// Product State

	const [isLoadingDataProducts, setLoadingDataProducts] =
		useState<boolean>(false);

	const [products, setProducts] = useState<Product[] | null>(null);

	const [selectedProduct, setSelectedProduct] = useState<null | Product>(null);

	const isProductPriceRanged = selectedProduct?.priceType === 'range';

	const { coupon, clearCoupon, verifyCoupon, isVerifyingCoupon } =
		useVerifyCoupon();

	useEffect(
		() => {
			if (coupon) {
				dispatch(
					setTransactionBreakdown({
						coupon,
					})
				);
			}
		},
		// eslint-disable-next-line
		[coupon]
	);

	const validationSchema = yup.object().shape({
		operatorId: yup
			.string()
			.notOneOf([SELECT_PROVIDER], SELECT_PROVIDER)
			.required('Network provider is required'),
		productId: yup
			.string()
			.notOneOf([SELECT_PACKAGE], SELECT_PACKAGE)
			.required('Network package is required'),
		amount: yup
			.number()
			.positive('Amount cannot be negative')
			.required('Amount is required'),
	});

	const initialValues = {
		product: '',
		productId: SELECT_PACKAGE,
		operatorId: SELECT_PROVIDER,
		phone_number: '',
		amount: '',
		discount_code: '',
	};

	const { isLoading: isPurchasingVoucher, mutate: mutatePurchaseData } =
		useMutation(purchaseVoucher, {
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						modal({
							title: 'E-Voucher Purchase',
							message: response.message,
							hasCloseButton: true,
							type: 'error',
							primaryButtonText: 'Close',
							onClickPrimaryButton: () => modal(null),
						});
					}
				}
				if (data && data.success) {
					queryClient.invalidateQueries([QUERY_KEYS.UserWallet]);
					dispatch(setTransactionBreakdown(null));

					const payload = data.payload;

					resetForm();
					modal({
						title: 'E-Voucher Purchase',
						message: `${payload?.operator} of ${
							payload?.product
						} for ${formatNumberToCurrency(payload.amount)} is successful!`,
						type: 'success',
						hasCloseButton: true,
						children: <EVoucherPin pin={payload.pin} />,
						primaryButtonText: 'Purchase again',
						secondaryButtonText: 'Close',
						onClickPrimaryButton: () => {
							setSelectedProduct(null);
							modal(null);
						},
						onClickSecondaryButton: () => {
							setSelectedProduct(null);
							modal(null);
						},
					});
				}
			},
		});

	const {
		errors,
		values,
		touched,
		handleSubmit,
		setFieldValue,
		resetForm,
		setFieldError,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			const data = {
				productId: Number(values.productId),
				operatorId: Number(values.operatorId),
				product: values.product,
				amount: values.amount,
			};

			let amount = Number(data.amount) || 0;

			const price = selectedProduct?.price;

			if (price && isProductPriceRanged) {
				const minAmount = Number(price.min.operator);
				const maxAmount = Number(price.max.operator);

				if (amount < minAmount) {
					setFieldError(
						'amount',
						`Amount must be more than ${formatNumberToCurrency(
							Number(minAmount)
						)}`
					);
					return;
				}
				if (amount > Number(maxAmount)) {
					setFieldError(
						'amount',
						`Amount must be less than ${formatNumberToCurrency(
							Number(maxAmount)
						)}`
					);
					return;
				}
			}

			// if (values.discount_code) {
			// 	if (!coupon) {
			// 		return alert({ message: 'Apply discount code', type: 'info' });
			// 	}

			// 	if (coupon && coupon.name !== values.discount_code) {
			// 		return alert({ message: 'Invalid coupon code', type: 'error' });
			// 	}

			// 	data.discount_code = values.discount_code;
			// 	amount = calculateDiscountedAmount({ coupon, amount });
			// }

			// mutatePurchaseAirtime(data);

			const callback = () => {
				verifyPin(null);
				mutatePurchaseData({ ...data, amount: Number(data.amount) });
			};
			verifyPin({
				title: 'E-Voucher Purchase',
				message: `Verify transaction pin to confirm the purchase of ${formatNumberToCurrency(
					amount
				)} worth of ${data.product} e-voucher`,
				callback,
			});

			// if (checkKycLevel()) {
			// }
		},
	});

	const { amount, discount_code, operatorId, productId } = values;

	const onChangeCode = (code: string) => {
		clearCoupon();
		setFieldValue('discount_code', code);
	};

	const handleApplyCode = () => {
		if (discount_code) {
			verifyCoupon(discount_code);
		}

		alert({ message: 'Enter discount code', type: 'info' });
	};

	const { data: dataEVoucherProviders, isLoading: isLoadingEVoucherProviders } =
		useQuery(['e-voucher-providers'], eVoucherProviders);

	// Airtime products
	const getVouchersProducts = async (providerId: string) => {
		try {
			setLoadingDataProducts(true);
			const response = await voucherProducts(providerId);
			if (response.payload) setProducts(response.payload);
		} catch (error) {
			const response = handleError({ error });
			if (response?.message) {
				alert({
					message: response.message,
					type: 'error',
				});
			}
		} finally {
			setLoadingDataProducts(false);
		}
	};

	// Handlee Select Provider
	const handleSelectProvider = async (operatorId: string) => {
		if (
			dataEVoucherProviders &&
			dataEVoucherProviders.payload &&
			Array.isArray(dataEVoucherProviders.payload)
		) {
			const provider = dataEVoucherProviders.payload.find(
				(provider: IProvider) => provider.id === operatorId
			);
			if (provider) {
				setFieldValue('operatorId', operatorId);
			}
		}
		setFieldValue('productId', SELECT_PACKAGE);
		setFieldValue('amount', '');
		dispatch(
			setTransactionBreakdown({
				serviceCost: 0,
			})
		);
		await getVouchersProducts(operatorId); // Call products
	};

	const handleSelectProduct = (productId: string): void => {
		if (products) {
			const foundProduct = products.find((product) => product.id === productId);
			if (foundProduct) {
				setSelectedProduct(foundProduct);
				setFieldValue('productId', foundProduct.id);
				setFieldValue('product', foundProduct.name);
				if (foundProduct.priceType === 'fixed') {
					const amount = foundProduct.price.user;
					setFieldValue('amount', amount);
					dispatch(
						setTransactionBreakdown({
							serviceCost: Number(amount),
						})
					);
				} else {
					setFieldValue('amount', '');
					dispatch(
						setTransactionBreakdown({
							serviceCost: 0,
						})
					);
				}
			}
		}
	};

	return (
		<Box style={styles.form as any} component={'form'}>
			<CustomSelect
				isLoading={isLoadingEVoucherProviders}
				disabled={isLoadingEVoucherProviders}
				labelText={SELECT_PROVIDER}
				placeholder={SELECT_PROVIDER}
				error={touched.operatorId && Boolean(errors.operatorId)}
				helpertext={touched.operatorId && errors.operatorId}
				options={
					dataEVoucherProviders &&
					dataEVoucherProviders.payload &&
					Array.isArray(dataEVoucherProviders.payload)
						? [
								{ label: SELECT_PROVIDER, value: '' },
								...dataEVoucherProviders.payload.map((data) => ({
									label: data.name,
									value: data.id,
								})),
						  ]
						: [{ label: SELECT_PROVIDER, value: '1' }]
				}
				value={operatorId}
				onChange={(value) => handleSelectProvider(value)}
			/>

			<CustomSelect
				disabled={!operatorId || isLoadingDataProducts}
				labelText={SELECT_PACKAGE}
				isLoading={isLoadingDataProducts}
				placeholder={SELECT_PACKAGE}
				error={touched.productId && Boolean(errors.productId)}
				helpertext={touched.productId && errors.productId}
				options={
					products
						? [
								{ label: SELECT_PACKAGE, value: '' },
								...products.map((data) => ({
									label: data.name,
									value: data.id,
								})),
						  ]
						: [{ label: SELECT_PROVIDER, value: '' }]
				}
				value={productId}
				onChange={(value) => handleSelectProduct(value)}
			/>

			{isProductPriceRanged && (
				<Box>
					<TextInput
						labelText='Amount'
						fullWidth
						placeholder={'Type in amount'}
						error={Boolean(touched.amount && errors.amount)}
						helperText={touched.amount && errors.amount}
						value={amount}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							const amount = e.target.value;
							setFieldValue('amount', amount);
							dispatch(
								setTransactionBreakdown({
									serviceCost: Number(amount),
								})
							);
						}}
					/>
				</Box>
			)}

			{/* <Box>
				<Typography style={styles.label} component={'label'} variant={'body1'}>
					Discount code [optional]
				</Typography>
				<TextInput
					disabled={isVerifyingCoupon}
					value={discount_code}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						onChangeCode(e.target.value)
					}
					fullWidth
					placeholder={'Type in discount code here'}
					InputProps={{
						endAdornment: (
							<InputAdornment position='start'>
								<Button
									loading={isVerifyingCoupon}
									onClick={handleApplyCode}
									disableRipple
									style={styles.applyBtn}
								>
									Apply
								</Button>
							</InputAdornment>
						),
					}}
				/>
			</Box> */}

			<Button
				loading={isPurchasingVoucher}
				onClick={(e: React.FormEvent<HTMLButtonElement>) => {
					e.preventDefault();
					handleSubmit();
				}}
				size={'large'}
				style={styles.btn}
			>
				Purchase
			</Button>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default EVoucherForm;
