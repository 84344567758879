import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { AuthLayout, ResetPasswordPinForm, Seo } from 'components';
import { resetPassword } from 'api';
import { useMutation } from 'react-query';
import { useHandleError, useModal } from 'hooks';
import { LINKS } from 'utilities';

const ResetPassword = () => {
	const handleError = useHandleError();
	const modal = useModal();
	const navigate = useNavigate();
	const { search, state } = useLocation();
	const parseUrl: Record<string, any> = queryString.parse(search);

	const ref = parseUrl.ref || state.ref;
	const access = parseUrl.access || state.access;

	const { isLoading: isResettingPassword, mutate: mutateResetPassword } =
		useMutation(resetPassword, {
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						modal.display({
							title: 'Reset Password Message',
							message: res.message,
							onClickPrimaryButton: () => modal.close(),
							type: 'error',
							primaryButtonText: 'Close',
						});
					}
				}

				if (data && data.success) {
					modal.display({
						title: 'Reset Password Message',
						message: 'Password has been reset successfully. Log in',
						primaryButtonText: 'Log in',
						onClickPrimaryButton: () => {
							modal.close();
							navigate(LINKS.Login);
						},
						type: 'success',
					});
				}
			},
		});

	const handleResetPassword = (value: string) => {
		mutateResetPassword({
			data: {
				newPassword: value,
				otp: parseInt(access),
			},
			id: ref as string,
		});
	};

	return (
		<AuthLayout>
			<Seo title={'Reset password'} />

			<ResetPasswordPinForm
				isPasswordForm
				title={'Reset password'}
				description={'Enter a new password to reset you password'}
				placeholder={'Type in your new password'}
				label={'New password'}
				btnText={'Update password'}
				onSubmit={(value: string) => handleResetPassword(value)}
				handleGoBack={() => navigate(-1)}
				isLoading={isResettingPassword}
			/>
		</AuthLayout>
	);
};

export default ResetPassword;
