import React from 'react';

type Props = {
	color?: string;
	size?: number;
};

const NoteIcon = ({ size, color }: Props) => {
	return (
		<svg
			width={size || '24'}
			height={size || '24'}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_3346_19704)'>
				<path
					d='M12.9663 4.17969H5.89062V6.86877H12.9663V4.17969Z'
					fill={'#DCE3E7'}
				/>
				<path
					d='M12.9667 7.54145H5.89103C5.51973 7.54145 5.21875 7.24047 5.21875 6.86917V4.18009C5.21875 3.8088 5.51973 3.50781 5.89103 3.50781H12.9667C13.338 3.50781 13.639 3.8088 13.639 4.18009V6.86917C13.639 7.24042 13.3379 7.54145 12.9667 7.54145ZM6.56331 6.19689H12.2944V4.85233H6.56331V6.19689Z'
					fill={color || '#28536B'}
				/>
				<path
					d='M18.1038 10.6562H5.89062V13.3453H18.1038V10.6562Z'
					fill={'#DCE3E7'}
				/>
				<path
					d='M18.104 14.018H5.89103C5.51973 14.018 5.21875 13.717 5.21875 13.3457V10.6567C5.21875 10.2854 5.51973 9.98438 5.89103 9.98438H18.104C18.4753 9.98438 18.7763 10.2854 18.7763 10.6567V13.3457C18.7763 13.7171 18.4752 14.018 18.104 14.018ZM6.56331 12.6735H17.4318V11.3289H6.56331V12.6735Z'
					fill={color || '#28536B'}
				/>
				<path
					d='M18.104 20.4946H5.89103C5.51973 20.4946 5.21875 20.1936 5.21875 19.8223V17.1332C5.21875 16.7619 5.51973 16.4609 5.89103 16.4609H18.104C18.4753 16.4609 18.7763 16.7619 18.7763 17.1332C18.7763 17.5045 18.4753 17.8055 18.104 17.8055H6.56331V19.1501H18.104C18.4753 19.1501 18.7763 19.451 18.7763 19.8223C18.7763 20.1936 18.4752 20.4946 18.104 20.4946Z'
					fill={color || '#28536B'}
				/>
				<path
					d='M15.8355 0.671875H15.6562V5.66908H21.1942L15.8355 0.671875Z'
					fill={'#DCE3E7'}
				/>
				<path
					d='M21.3102 9.57994C20.9389 9.57994 20.6379 9.88093 20.6379 10.2522V22.6555H3.36014V1.34461H14.9794V5.66953C14.9794 6.04083 15.2804 6.34181 15.6517 6.34181H21.1897C21.4659 6.34181 21.714 6.17288 21.8152 5.91591C21.9165 5.65894 21.8502 5.3662 21.6482 5.17781L16.2895 0.180609C16.2844 0.175828 16.2788 0.171656 16.2736 0.167109C16.2656 0.160172 16.2578 0.153281 16.2495 0.146719C16.2413 0.140109 16.2328 0.133969 16.2242 0.127828C16.2153 0.121359 16.2064 0.114984 16.1971 0.108938C16.1889 0.103641 16.1806 0.0987188 16.1721 0.09375C16.1619 0.0877032 16.1517 0.0817969 16.1413 0.0762657C16.1334 0.0721875 16.1253 0.0685313 16.1173 0.0646875C16.106 0.0592969 16.0946 0.0539531 16.0828 0.0492188C16.075 0.0460781 16.067 0.0434531 16.0591 0.0405469C16.0469 0.0361875 16.0348 0.0317344 16.0224 0.0280313C16.0139 0.0255 16.0052 0.0235781 15.9966 0.021375C15.9845 0.0183281 15.9726 0.0150469 15.9603 0.0127031C15.9493 0.0105469 15.9382 0.0091875 15.9271 0.00759375C15.9169 0.00609375 15.9069 0.00421875 15.8966 0.00328125C15.8749 0.00121875 15.853 0 15.831 0H15.6517H2.68791C2.31661 4.6875e-05 2.01562 0.301031 2.01562 0.672328V23.3278C2.01562 23.6991 2.31661 24.0001 2.68791 24.0001H21.3103C21.6816 24.0001 21.9826 23.6991 21.9826 23.3278V10.2522C21.9825 9.88093 21.6815 9.57994 21.3102 9.57994ZM16.324 4.99725V2.0513L19.4831 4.99725H16.324Z'
					fill={color || '#28536B'}
				/>
			</g>
			<defs>
				<clipPath id='clip0_3346_19704'>
					<rect width='24' height='24' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default NoteIcon;
