import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotificationSettings, INotification } from 'utilities';

export type NotificationState = {
	settings: Partial<INotificationSettings> | null;
	alert: INotification | null;
	notifications: INotification[] | null;
	isLoadingNotifications: boolean;
};

// Define the initial state using that type
const initialState: NotificationState = {
	alert: null,
	notifications: null,
	isLoadingNotifications: true,
	settings: {
		transfer: false,
		topup: false,
		push_notification: false,
		newsletter: false,
	},
};

export const notificationSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		setNotificationAlert: (
			state,
			action: PayloadAction<INotification | null>
		) => {
			state.alert = action.payload;
			state.isLoadingNotifications = false;
		},
		setNotificationSettings: (
			state,
			action: PayloadAction<Partial<INotificationSettings> | null>
		) => {
			state.settings = action.payload;
		},
		setNotifications: (state, action: PayloadAction<INotification[]>) => {
			state.notifications = action.payload;
			state.isLoadingNotifications = false;
		},
	},
});

export const {
	setNotificationAlert,
	setNotificationSettings,
	setNotifications,
} = notificationSlice.actions;

export default notificationSlice.reducer;
