import React from 'react';
import {
	Box,
	Typography,
	Checkbox as MuiCheckBox,
	CheckboxProps,
} from '@mui/material';
import { red } from '@mui/material/colors';

interface Props extends CheckboxProps {
	label?: any;
	labelStyle?: any;
	error?: boolean;
	helpertext?: string | false | undefined;
}

const CheckBox = (props: Props) => {
	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
				<MuiCheckBox {...props} />
				{props.label && (
					<Typography style={props.labelStyle} variant={'body1'}>
						{props.label}
					</Typography>
				)}
			</Box>
			{props.helpertext && (
				<Typography
					sx={{
						color: red[900],
						fontSize: '12px',
					}}
					variant={'body2'}
				>
					{props.helpertext}
				</Typography>
			)}
		</>
	);
};

export default CheckBox;
