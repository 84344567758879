import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import queryString from 'query-string';
import { LINKS, STORAGE_KEYS, Storage } from 'utilities';

const Initial = () => {
	const navigate = useNavigate();
	const { search } = useLocation();
	const parseUrl = queryString.parse(search);

	useEffect(
		() => {
			const token = Storage.getItem(STORAGE_KEYS.UserToken);
			if (token) {
				if (parseUrl && parseUrl._redirect) {
					navigate(parseUrl._redirect as string);
				} else {
					navigate(LINKS.Dashboard);
				}
			} else {
				navigate(LINKS.Login, { state: parseUrl });
			}
		},
		// eslint-disable-next-line
		[parseUrl, navigate]
	);

	return null;
};

export default Initial;
