import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import TextInput from '../form-components/text-input';
import Button from '../button';
import { LINKS, PHONE_REX, UseEPin, formatNumberToCurrency } from 'utilities';
import { useHandleError, useModalAlert, useVerifyPin } from 'hooks';
import { useEPin } from 'api';

interface Props {
	pin: string;
}

const LoadDataEPinForm = ({ pin }: Props) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const handleError = useHandleError();

	const verifyPin = useVerifyPin();
	const modal = useModalAlert();
	const styles = useStyles(theme);

	const validationSchema = yup.object().shape({
		number: yup
			.string()
			.required('Enter phone number')
			.matches(PHONE_REX, 'Invalid phone number'),
	});

	const initialValues: { number: string } = {
		number: '',
	};

	const { isLoading, mutate } = useMutation(useEPin, {
		onSettled: (data, error) => {
			if (error) {
				const response = handleError({ error });
				if (response?.message) {
					modal({
						title: 'Load Data E-Pin',
						message: response?.message as string,
						type: 'error',
						onClickPrimaryButton: () => modal(null),
						primaryButtonText: 'Close',
					});
				}
			}
			if (data && data.success) {
				const { amount, number } = data?.payload;
				modal({
					title: 'Load E-Pin Data',
					message: `E-Pin Data subscription for ${formatNumberToCurrency(
						amount
					)} to ${number} is successful!`,
					primaryButtonText: 'Close',
					type: 'success',
					onClickPrimaryButton: () => {
						modal(null);
						resetForm();
						navigate(LINKS.Dashboard);
					},
				});
			}
		},
	});

	const { values, handleSubmit, touched, errors, handleChange, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: ({ number }) => {
				const data = {
					pin,
					user_data: {
						number,
					},
				} as UseEPin;

				verifyPin({
					title: 'Load Data EPin',
					message: 'Verify transaction pin to load data e-pin',
					callback: () => {
						verifyPin(null);
						mutate(data);
					},
				});
			},
		});

	return (
		<>
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Phone number
					</Typography>
					<TextInput
						fullWidth
						error={touched.number && errors.number ? true : false}
						helperText={touched.number && errors.number}
						placeholder={'Type in your phone number here'}
						value={values.number}
						onChange={handleChange('number')}
					/>
				</Box>

				<Button
					loading={isLoading}
					style={styles.btn}
					size={'large'}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Load E-pin
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default LoadDataEPinForm;
