import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import {
	Table,
	Box,
	useTheme,
	TableBody,
	TableHead,
	TableRow,
} from '@mui/material';

import { SquareRounded } from '@mui/icons-material';
import { useQuery } from 'react-query';
import {
	LIGHT_GRAY,
	SUCCESS_COLOR,
	PRIMARY_COLOR,
	QUERY_KEYS,
	IUser,
	extractUserName,
	MAX_RECORDS,
	LINKS,
	IReferral,
	ErrorBoundaryGuard,
} from 'utilities';
import Pagination from '../pagination';
import { useAppSelector } from 'store/hooks';
import Empty from '../empty/table-empty';
import Loader from '../loader/table-loader';
import { useAlert, useHandleError } from 'hooks';
import { referrals } from 'api';
import { StyledTableRow, StyledTableCell } from './styled-components';

const ReferralTable = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const handleError = useHandleError();
	const alert = useAlert();
	const { token, user } = useAppSelector((store) => store.auth);
	const mode = useAppSelector((store) => store.theme.mode);
	const navigate = useNavigate();
	const [count, setCount] = useState<number>(1);
	const [page, setPage] = useState<number>(1);
	const [total, setTotal] = useState<number>(0);
	const location = useLocation();
	const query = queryString.parse(location.search);

	useEffect(() => {
		if (query && query.page) {
			setPage(parseInt(query.page as string));
		}
	}, [query, query.page]);

	const { isLoading, data } = useQuery(
		[QUERY_KEYS.Referrals, user?.id],
		() =>
			referrals({
				limit: MAX_RECORDS,
				skip: (page - 1) * MAX_RECORDS,
				populate: 'user',
			}),
		{
			enabled: !!token,
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						alert({ message: response.message, type: 'error' });
					}
				}

				if (data && data.success) {
					const total = data.metadata?.total as number;
					setTotal(total);
					const count = Math.ceil(total / MAX_RECORDS);
					setCount(count);
				}
			},
		}
	);

	const handlePageChange = (page: number) => {
		if (page !== 1) {
			setPage(page);
			navigate(`${LINKS.REFERRAL_HISTORY}?page=${page}`);
		} else {
			navigate(LINKS.REFERRAL_HISTORY);
			setPage(page);
		}
	};

	const userName = (user: IUser) => {
		const name = `${user?.firstname} ${user?.lastname}`;
		if (name) return name;
		return user?.username;
	};

	return (
		<Box sx={{ overflow: 'auto' }}>
			<>
				<Box
					style={styles.dFlex}
					sx={{ justifyContent: 'flex-end', marginBottom: theme.spacing(3) }}
				>
					<Box style={styles.dFlex}>
						<Box style={styles.dFlex}>
							<SquareRounded sx={{ color: SUCCESS_COLOR }} />= Active
						</Box>
						<Box sx={{ fontSize: '24px' }}>/</Box>
						<Box style={styles.dFlex}>
							<SquareRounded sx={{ color: PRIMARY_COLOR }} />= Inactive
						</Box>
					</Box>
				</Box>
				<Table sx={{ overflow: 'auto' }} stickyHeader>
					<TableHead
						sx={{
							'& tr': {
								backgroundColor: LIGHT_GRAY,
							},
						}}
					>
						<TableRow>
							<StyledTableCell mode={mode}>User name</StyledTableCell>
							<StyledTableCell mode={mode}>Email</StyledTableCell>
							<StyledTableCell mode={mode}>Status</StyledTableCell>
						</TableRow>
					</TableHead>
					<ErrorBoundaryGuard>
						<TableBody>
							{isLoading ? (
								<Loader colSpan={4} />
							) : (
								data && (
									<>
										{data.payload.length > 0 ? (
											data.payload.map((referral: IReferral) => (
												<StyledTableRow mode={mode} key={referral.id}>
													<StyledTableCell mode={mode}>
														{extractUserName(referral.user)}
													</StyledTableCell>
													<StyledTableCell mode={mode}>
														{referral.user.email}
													</StyledTableCell>
													<StyledTableCell mode={mode}>
														{referral.user.verified ? (
															<SquareRounded sx={{ color: SUCCESS_COLOR }} />
														) : (
															<SquareRounded sx={{ color: PRIMARY_COLOR }} />
														)}
													</StyledTableCell>
												</StyledTableRow>
											))
										) : (
											<Empty colSpan={4} text={'No Referral'} />
										)}
									</>
								)
							)}
						</TableBody>
					</ErrorBoundaryGuard>
				</Table>
				{total > MAX_RECORDS && (
					<Pagination
						size={'large'}
						variant={'outlined'}
						shape={'rounded'}
						page={page}
						count={count}
						onChange={(e, number) => handlePageChange(number)}
					/>
				)}
			</>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	filterWrapper: {
		display: 'flex',
		gap: '10px',
		alignItems: 'center',
	},
	title: {
		fontWeight: '600',
	},
	dFlex: {
		display: 'flex',
		alignItems: 'center',
		gap: '5px',
	},
});

export default ReferralTable;
