import React, { useEffect } from 'react';
import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { grey } from '@mui/material/colors';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';
import TextInput from '../form-components/text-input';
import Select from '../form-components/select';
import {
	RELATED_TRANSACTION,
	TRANSACTION_SERVICE,
	PRIORITY,
	TICKET_TYPE,
	ITicket,
	LINKS,
} from 'utilities';
import Button from '../button';
import { useHandleError, useModalAlert } from 'hooks';
import { createTicket } from 'api';

const SELECT_PRIORITY = 'Select priority';

const SupportTicketForm = () => {
	const theme = useTheme();
	const handleError = useHandleError();
	const modal = useModalAlert();
	const navigate = useNavigate();
	const styles = useStyles(theme);
	const { search } = useLocation();

	const urlQuery = queryString.parse(search);

	const validationSchema = yup.object().shape({
		message: yup.string().required('Enter dispute message'),
		subject: yup.string().required('Enter dispute subject'),
		priority: yup
			.string()
			.notOneOf([SELECT_PRIORITY], SELECT_PRIORITY)
			.required('Select dispute priority'),
	});

	// Cable Payment Mutation
	const { isLoading: isCreatingDispute, mutate: mutateCreateDispute } =
		useMutation(createTicket, {
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						modal({
							message: res.message,
							title:
								urlQuery._type === TICKET_TYPE.DISPUTE
									? 'Create Dispute'
									: 'Raise Complain',
							primaryButtonText: 'Close',
							type: 'error',
							onClickPrimaryButton: () => modal(null),
						});
					}
				}

				if (data && data.success) {
					resetForm();
					modal({
						message: data.message,
						title:
							urlQuery._type === TICKET_TYPE.DISPUTE
								? 'Create Dispute'
								: 'Raise Complain',
						primaryButtonText: 'Go to Dashboard',
						type: 'success',
						onClickPrimaryButton: () => {
							modal(null);
							navigate(LINKS.Dashboard);
						},
					});
				}
			},
		});

	// Switch between transaction type
	const switchTransactionType = (type: string) => {
		switch (type) {
			case TRANSACTION_SERVICE.DATA_SUBSCRIPTION:
				return RELATED_TRANSACTION.DataSubscription;
			case TRANSACTION_SERVICE.AIRTIME_CONVERSION:
				return RELATED_TRANSACTION.AirtimeConvert;
			case TRANSACTION_SERVICE.CABLE ||
				TRANSACTION_SERVICE.EDUCATION ||
				TRANSACTION_SERVICE.ELECTRICITY:
				return RELATED_TRANSACTION.BillPayment;

			default:
				return '';
		}
	};

	const initialValues: ITicket = {
		priority: SELECT_PRIORITY,
		subject: '',
		message: '',
		type: '',
	};

	const {
		values,
		handleSubmit,
		errors,
		touched,
		handleChange,
		setFieldValue,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			const type = values.type;
			const related_transaction = urlQuery._transaction as string;
			const transaction_type = urlQuery._transaction_type;

			if (!related_transaction && !type && !transaction_type) {
				modal({
					message: `Something went wrong, unable to create dispute`,
					title: 'Create Dispute',
					primaryButtonText: 'Go to Dashboard',
					type: 'error',
					onClickPrimaryButton: () => {
						modal(null);
						navigate(LINKS.Dashboard);
					},
				});

				return;
			}

			const related_transaction_type = switchTransactionType(
				urlQuery._transaction_type as string
			);

			let data = values;

			if (type === TICKET_TYPE.DISPUTE) {
				data = { ...data, type, related_transaction, related_transaction_type };
				return mutateCreateDispute(data);
			}

			mutateCreateDispute(data);
		},
	});

	useEffect(
		() => {
			if (urlQuery) {
				if (urlQuery._transaction_type) {
					const type_slice_rest = urlQuery._transaction_type
						.toString()
						.toLowerCase()
						.slice(1);
					setFieldValue(
						'subject',
						`${urlQuery._transaction_type
							.toString()
							.charAt(0)}${type_slice_rest} Dispute`
					);
				}

				if (urlQuery._type) {
					setFieldValue('type', urlQuery._type);
				}
			}
		},
		// eslint-disable-next-line
		[]
	);

	const { priority, subject, message } = values;

	return (
		<>
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Priority
					</Typography>
					<Select
						fullWidth
						error={touched.priority && errors.priority ? true : false}
						helpertext={touched.priority && errors.priority}
						value={priority}
						onChange={handleChange('priority') as never}
					>
						<MenuItem disabled value={SELECT_PRIORITY}>
							{SELECT_PRIORITY}
						</MenuItem>
						{Object.values(PRIORITY).map((value) => (
							<MenuItem key={value} value={value}>
								{value}
							</MenuItem>
						))}
					</Select>
				</Box>

				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Subject
					</Typography>
					<TextInput
						fullWidth
						error={touched.subject && errors.subject ? true : false}
						helperText={touched.subject && errors.subject}
						placeholder={'Type in subject'}
						value={subject}
						onChange={handleChange('subject')}
					/>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Message
					</Typography>
					<TextInput
						fullWidth
						multiline
						error={touched.message && errors.message ? true : false}
						helperText={touched.message && errors.message}
						placeholder={'Type in your message'}
						rows={4}
						value={message}
						onChange={handleChange('message')}
					/>
				</Box>

				<Button
					loading={isCreatingDispute}
					size={'large'}
					type={'submit'}
					style={styles.btn}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Submit
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
		maxWidth: '480px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
});

export default SupportTicketForm;
