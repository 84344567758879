import React from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import { Layout, BankAccountForm, Seo } from 'components';

const AddBank = () => {
	const theme = useTheme();
	const styles = useStyles(theme);

	return (
		<Layout>
			<Seo title={'Add Bank'} />
			<Box style={styles.container}>
				<Box>
					<Typography style={styles.title} variant={'h5'}>
						Add new bank account
					</Typography>
					<Typography variant={'body1'}>
						Enter the details of the bank account you want to add
					</Typography>
				</Box>
				<BankAccountForm />
			</Box>
		</Layout>
	);
};

const useStyles = (theme: any) => ({
	container: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '30px',
		maxWidth: '600px',
	},
	title: {
		fontWeight: '600',
		marginBottom: '20px',
	},
});

export default AddBank;
