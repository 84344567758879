import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import queryString from 'query-string';
import {
	Layout,
	TwoColGrid,
	WalletWithBreakdown,
	GenerateBillTab,
	GenerateEducationPinForm,
	GenerateCablePinForm,
	GeneratePowerPinForm,
	InternetSubscriptionForm,
	Seo,
	BackButton,
} from 'components';
import { BILL_TAB, LINKS } from 'utilities';

const GenerateBills = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const { tab } = queryString.parse(location.search);
	const [currentTab, setCurrentTab] = useState<number>(0);
	const title =
		currentTab === 1
			? 'Generate power bill'
			: currentTab === 2
			? 'Generate education bill'
			: currentTab === 3
			? 'Generate internet subscription bill'
			: 'Generate cable payment bill';

	const handleChangeTab = (e: any, value: number) => {
		switch (value) {
			case 1:
				return navigate(`${LINKS.GenerateBill}?tab=${BILL_TAB.PowerBill}`);

			case 2:
				return navigate(`${LINKS.GenerateBill}?tab=${BILL_TAB.Education}`);
			case 3:
				return navigate(
					`${LINKS.GenerateBill}?tab=${BILL_TAB.InternetSubscription}`
				);

			default:
				navigate(`${LINKS.GenerateBill}`);
		}
		setCurrentTab(value);
	};

	useEffect(() => {
		switch (tab) {
			case BILL_TAB.PowerBill:
				return setCurrentTab(1);

			case BILL_TAB.Education:
				return setCurrentTab(2);

			case BILL_TAB.InternetSubscription:
				return setCurrentTab(3);

			default:
				setCurrentTab(0);
		}
	}, [tab]);

	return (
		<Layout>
			<Seo title={title} />
			<BackButton name={'E-Pin'} sx={{ marginBottom: ['1.5rem'] }} />
			<GenerateBillTab currentTab={currentTab} handleChange={handleChangeTab} />
			<TwoColGrid
				rightAside={<WalletWithBreakdown isStickySidebar top={'10rem'} />}
			>
				<Box hidden={currentTab !== 0}>
					<GenerateCablePinForm enableRequest={currentTab === 0} />
				</Box>
				<Box hidden={currentTab !== 1}>
					<GeneratePowerPinForm enableRequest={currentTab === 1} />
				</Box>
				<Box hidden={currentTab !== 2}>
					<GenerateEducationPinForm enableRequest={currentTab === 2} />
				</Box>
				<Box hidden={currentTab !== 3}>
					<InternetSubscriptionForm
						isGenerateBill
						enableRequest={currentTab === 3}
					/>
				</Box>
			</TwoColGrid>
		</Layout>
	);
};

export default GenerateBills;
