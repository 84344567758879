import React from 'react';
import { Box } from '@mui/material';
import UserImageWithAction from '../user-image-with-action';
import ProfileForm from '../forms/profile-form';

const Profile = () => {
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: '1fr',
				gap: (theme) => theme.spacing(4),
				maxWidth: '600px',
			}}
		>
			<UserImageWithAction />
			<ProfileForm />
		</Box>
	);
};

export default Profile;
