import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import GenerateEpinItem from './generate-epin-item';
import { grey } from '@mui/material/colors';
import { LINKS, THEME_MODE } from 'utilities';
import { useAppSelector } from 'store/hooks';
import PurchaseDataIcon from 'components/icons/purchase-data';
import PurchaseAirtimeIcon from 'components/icons/purchase-airtime';
import NoteIcon from 'components/icons/note-icon';
import H5Typography from 'components/typography/h5-typography';

const GenerateEPin = () => {
	const theme = useTheme();
	const { mode } = useAppSelector((store) => store.theme);
	const styles = useStyles(theme, mode);
	return (
		<Box>
			<H5Typography name='Generate E-Pin' />

			<Box
				sx={{
					display: 'grid',
					gap: '8px',
					gridTemplateColumns: {
						xs: '1fr',
						md: 'repeat(3, 1fr)',
					},
				}}
			>
				<GenerateEpinItem
					logo={PurchaseAirtimeIcon}
					title={'Generate Airtime e-pin'}
					description={`Create Airtime ePINs in seconds for instant connectivity.`}
					link={LINKS.GenerateAirtime}
				/>
				<GenerateEpinItem
					imageWidth={'25px'}
					logo={PurchaseDataIcon}
					title={'Generate Data e-pin'}
					description={`Instantly create data ePINs to keep your digital world connected.`}
					link={LINKS.GenerateData}
				/>
				<GenerateEpinItem
					imageWidth={'20px'}
					logo={NoteIcon}
					title={'Generate e-pin for bill payment'}
					description={`Generate ePINs for bill payments with ease and convenience.
						`}
					link={LINKS.GenerateBill}
				/>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any, mode: string) => ({
	title: {
		fontWeight: '600',
		marginBottom: theme.spacing(4),
		color: mode === THEME_MODE.dark ? grey[300] : 'initial',
	},
});

export default GenerateEPin;
