import React from 'react';
import {
	AppBar,
	Toolbar,
	Container,
	useTheme,
	Box,
	Button,
	IconButton,
} from '@mui/material';
import { LightMode, DarkMode, Notifications, Menu } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import Logo from '../../logo/logo-with-text';
import { THEME_MODE, HEADER_HEIGHT, LIGHT_GRAY, LINKS } from 'utilities';
import { setTheme } from 'store/theme';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setToggleDrawer } from 'store/app';
import NotificationButton from '../../notification/notification-button';
import UserAvatarButton from '../../button/user-avatar-button';

const Header = () => {
	const theme = useTheme();
	const {
		theme: { mode },
		auth: { user },
	} = useAppSelector((store) => store);
	const styles = useStyle(theme, mode);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	return (
		<AppBar style={styles.appBar as any}>
			<Toolbar disableGutters>
				<Container style={styles.container}>
					<Logo
						sx={{
							maxWidth: { xs: '160px', md: '200px' },
						}}
					/>
					<Box
						sx={{
							display: {
								xs: 'none',
								md: 'flex',
							},
						}}
						style={styles.rightAside}
					>
						<Box style={styles.btn}>
							<Button
								onClick={() => dispatch(setTheme(THEME_MODE.light))}
								style={
									mode === THEME_MODE.light
										? styles.activeThemeBtn
										: styles.themeBtn
								}
								size={'large'}
							>
								<LightMode />
							</Button>
							<Button
								onClick={() => dispatch(setTheme(THEME_MODE.dark))}
								style={
									mode === THEME_MODE.dark
										? styles.activeThemeBtn
										: styles.themeBtn
								}
								size={'large'}
							>
								<DarkMode />
							</Button>
						</Box>
						<NotificationButton />
						{user && <UserAvatarButton />}
					</Box>
					<Box
						sx={{
							display: {
								xs: 'flex',
								md: 'none',
							},
							gap: '15px',
						}}
					>
						<Button
							onClick={() => navigate(LINKS.Notifications)}
							size={'large'}
							style={styles.btn}
						>
							<Notifications />
						</Button>
						<IconButton
							sx={{
								border: `1px solid ${theme.palette.primary.main}`,
								borderRadius: '5px',
								display: {
									md: 'none',
								},
							}}
							onClick={() => dispatch(setToggleDrawer(true))}
						>
							<Menu />
						</IconButton>
					</Box>
				</Container>
			</Toolbar>
		</AppBar>
	);
};

const useStyle = (theme: any, mode: any) => ({
	appBar: {
		backgroundColor: theme.palette.background.default,
		position: 'fixed',
		left: '0px',
		top: '0px',
		width: '100%',
		boxShadow: 'none',
		padding: '10px 0px',
		height: HEADER_HEIGHT,
		display: 'flex',
		justifyContent: 'center',
		zIndex: 20,
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		maxWidth: '1300px',
	},
	rightAside: {
		alignItems: 'center',
		gap: theme.spacing(2),
	},
	btn: {
		border: `1px solid ${
			mode === THEME_MODE.dark ? grey[300] : theme.palette.primary.main
		}`,
		color: mode === THEME_MODE.dark ? grey[300] : theme.palette.primary.main,
		overflow: 'hidden',
		minWidth: 'unset',
		borderRadius: '5px',
	},
	themeBtn: {
		minWidth: 'unset',
		borderRadius: '0px',
		color: mode === THEME_MODE.dark ? grey[300] : theme.palette.primary.main,
	},
	activeThemeBtn: {
		backgroundColor:
			mode === THEME_MODE.dark ? grey[300] : theme.palette.primary.main,
		color: mode === THEME_MODE.dark ? theme.palette.primary.main : grey[300],
		minWidth: 'unset',
		borderRadius: '0px',
	},
	avatar: {
		height: '25px',
		width: '25px',
		borderRadius: theme.spacing(1),
		backgroundColor: LIGHT_GRAY,
	},
});

export default Header;
