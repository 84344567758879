import React from 'react';
import { Box } from '@mui/material';
import { Layout, Seo, Services, WalletOperation } from 'components';

const ServicesPage = () => {
	return (
		<Layout>
			<Seo title={'Services'} />
			<Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: '3rem' }}>
				<Services />
				<WalletOperation />
			</Box>
		</Layout>
	);
};

export default ServicesPage;
