import React from 'react';
import { Typography, useTheme, Box, CircularProgress } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { Layout, ManagerInfo, Faqs, Button } from 'components';
import { THEME_MODE, TICKET_TYPE, LINKS } from 'utilities';
import { useAppSelector } from 'store/hooks';

const GetHelpOrFaqs = () => {
	const {
		theme: { mode },
		auth: { isAuthenticating, user },
	} = useAppSelector((store) => store);
	const theme = useTheme();
	const styles = useStyles(theme, mode);
	const navigate = useNavigate();

	console.log(user);

	return (
		<Layout>
			<Box style={styles.container}>
				<Box>
					<Typography style={styles.title} variant={'h5'}>
						Create a ticket
					</Typography>
					<Typography variant={'body1'}>
						Raise a complaint and our team would reach out to you in less than 8
						hours.
					</Typography>
					<Box sx={{ display: 'flex', gap: '10px' }}>
						<Button
							onClick={() =>
								navigate(
									`${LINKS.CreateSupportTicket}?_type=${TICKET_TYPE.COMPLAINT}`
								)
							}
							style={styles.button}
						>
							Raise Complaint
						</Button>
						<Button
							onClick={() => navigate(LINKS.SupportTicket)}
							sx={{
								':hover': {
									backgroundColor: `${theme.palette.secondary.main} !important`,
									color: `${grey['50']} !important`,
								},
							}}
							style={styles.outlineButton}
						>
							View My Ticket
						</Button>
					</Box>
				</Box>
				<Box>
					<Typography style={styles.title} variant={'h5'}>
						Your account manager's info
					</Typography>
					<Typography variant={'body1'}>
						Contact your account manager for assistance on any issues
					</Typography>
				</Box>
				{isAuthenticating ? (
					<Box>
						<CircularProgress />
					</Box>
				) : (
					<>
						{user && user.manager && (
							<>
								{Object.keys(user.manager).length > 0 ? (
									<ManagerInfo />
								) : (
									<Box>
										<Typography>
											You do not have an account manager assign to you
										</Typography>
										<Button style={styles.button}>Contact support</Button>
									</Box>
								)}
							</>
						)}
					</>
				)}
				<Faqs />
			</Box>
		</Layout>
	);
};

const useStyles = (theme: any, mode: string) => ({
	container: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '50px',
	},
	title: {
		fontWeight: '600',
		color: mode === THEME_MODE.dark ? grey[200] : 'inherit',
		marginBottom: theme.spacing(2),
	},
	button: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		marginTop: theme.spacing(2),
		fontWeight: '600',
		border: `1px solid ${theme.palette.secondary.main}`,
		minWidth: '140px',
	},
	outlineButton: {
		backgroundColor: grey['50'],
		color: theme.palette.secondary.main,
		marginTop: theme.spacing(2),
		fontWeight: '600',
		border: `1px solid ${theme.palette.secondary.main}`,
		minWidth: '140px',
	},
});

export default GetHelpOrFaqs;
