import { HTTP_STATUS_CODE } from 'utilities';
import useLogout from './useLogoutUser';

type PropsType = {
	error: any;
	callback?: () => void;
};

type ReturnTypes = {
	data?: any;
	message: string | null;
};

const useHandleError = () => {
	const logout = useLogout();

	return ({ error, callback }: PropsType): ReturnTypes | null | undefined => {
		if (error) {
			if (
				error.response &&
				error.response !== 'undefined' &&
				error.response.data
			) {
				const data = error.response.data;
				const status = error.response.status;

				if (status) {
					switch (status) {
						case HTTP_STATUS_CODE.Unauthorized:
						case HTTP_STATUS_CODE.Forbidden:
							// case HTTP_STATUS_CODE.TooManyRequest:
							typeof callback !== 'undefined' && callback();
							logout();
							if (typeof data === 'string') {
								return { message: data };
							}
							return { data, message: data.message || 'Authorization error' };

						case HTTP_STATUS_CODE.OK:
						case HTTP_STATUS_CODE.NotFound:
						case HTTP_STATUS_CODE.InternalServerError:
						case HTTP_STATUS_CODE.BadRequest:
							if (typeof data === 'string') {
								return { message: data };
							}
							return {
								data,
								message: data.message || 'Something went wrong, try again',
							};

						default:
							return null;
					}
				} else {
					return null;
				}
			} else {
				if (error.message) return { message: error.message, data: null };
			}
		}
	};
};

export default useHandleError;
