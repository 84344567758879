import { apiRequest } from '../config';
import {
	API_ENDPOINTS,
	AirtimeConversion,
	DataResponse,
	INetwork,
	ITransaction,
	IConfirmConvertOtp,
	ENDPOINT_SUBPATH,
	AirtimePurchase,
	InternationalAirtimePurchase,
	IProvider,
	Product,
} from 'utilities';

export const airtimeNetworks = async (params?: {
	[key: string]: any;
}): Promise<DataResponse<INetwork[]>> =>
	apiRequest({
		url: API_ENDPOINTS.AirtimeNetwork,
		method: 'GET',
		params,
	});

export const airtimeConvertNetworks = async (params?: {
	[key: string]: any;
}): Promise<DataResponse<INetwork[]>> =>
	apiRequest({
		url: API_ENDPOINTS.ConvertNetwork,
		method: 'GET',
		params,
	});

export const autoAirtimeConvertNetworks = async (params?: {
	[key: string]: any;
}): Promise<DataResponse<INetwork[]>> =>
	apiRequest({
		url: API_ENDPOINTS.AutoConvertNetwork,
		method: 'GET',
		params,
	});

export const requestAirtimeConvertOtp = async (params?: {
	[key: string]: any;
}): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.AutoConvertAirtime}${ENDPOINT_SUBPATH.RequestOtp}`,
		method: 'GET',
		params,
	});

export const verifyAirtimeConvertOtp = async (
	data: IConfirmConvertOtp
): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.AutoConvertAirtime}${ENDPOINT_SUBPATH.VerifyOtp}`,
		method: 'POST',
		data,
	});

export const airtimePurchases = async (params?: {
	[key: string]: any;
}): Promise<DataResponse<ITransaction[]>> =>
	apiRequest({
		url: API_ENDPOINTS.AirtimeNetwork,
		method: 'GET',
		params,
	});

export const purchaseAirtime = async (
	data: Partial<AirtimePurchase>
): Promise<DataResponse<ITransaction[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.Airtime}${ENDPOINT_SUBPATH.Purchase}`,
		method: 'POST',
		data,
	});

export const airtimeConverts = async (params?: {
	[key: string]: any;
}): Promise<DataResponse<AirtimeConversion[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.ConvertAirtime}${ENDPOINT_SUBPATH.Me}`,
		method: 'GET',
		params,
	});

export const autoAirtimeConverts = async (params?: {
	[key: string]: any;
}): Promise<DataResponse<ITransaction[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.AutoConvertAirtime}/group${ENDPOINT_SUBPATH.Me}`,
		method: 'GET',
		params,
	});

export const convertAirtime = async (
	data: AirtimeConversion
): Promise<DataResponse<AirtimeConversion>> =>
	apiRequest({
		url: API_ENDPOINTS.ConvertAirtime,
		method: 'POST',
		data,
	});

export const autoConvertAirtime = async (
	data: AirtimeConversion
): Promise<DataResponse<AirtimeConversion>> =>
	apiRequest({
		url: API_ENDPOINTS.AutoConvertAirtime,
		method: 'POST',
		data,
	});

export const airtimeTransactions = async (params: {
	[key: string]: any;
}): Promise<DataResponse<ITransaction[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.Airtime}${ENDPOINT_SUBPATH.Me}`,
		method: 'GET',
		params,
	});

export const internationalAirtimeTransactions = async (params: {
	[key: string]: any;
}): Promise<DataResponse<ITransaction[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.InternationalAirtime}/me`,
		method: 'GET',
		params,
	});

export const internationalAirtimeProviders = async (params: {
	[key: string]: any;
}): Promise<DataResponse<IProvider[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.InternationalAirtime}${ENDPOINT_SUBPATH.Providers}`,
		method: 'GET',
		params,
	});

export const internationalAirtimeProducts = async (
	providerId: string
): Promise<DataResponse<Product[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.InternationalAirtime}${ENDPOINT_SUBPATH.Products}/${providerId}`,
		method: 'GET',
	});
export const internationalAirtimePurchase = async (
	data: InternationalAirtimePurchase
): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.InternationalAirtime}/purchase`,
		method: 'POST',
		data,
	});
