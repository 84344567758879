import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ContentCopy } from '@mui/icons-material';
import QRCode from 'react-qr-code';
import { useAlert } from 'hooks';
import { capitalize } from 'utilities';

const PinDetails = ({ pin }: { pin: { [key: string]: any } }) => {
	const alert = useAlert();
	const onCopy = (pinValue: string) =>
		alert({ message: `${pinValue} copied to clipboard`, type: 'success' });

	return (
		<Box>
			<Typography sx={{ fontWeight: 'bold' }}>Pin Details</Typography>
			{Object.keys(pin).map((pinKey) => {
				const pinValue = (pin as any)[pinKey];
				const urlRegex = /(https?:\/\/[^ ]*)/;
				const matchUrl = pinValue.match(urlRegex) as any;

				const url = matchUrl ? matchUrl[0] : '';
				const splitPinValue = url && pinValue.split(url);

				if (pinValue)
					return (
						<>
							<Box
								sx={{
									display: 'flex',
									alignItems: pinValue.length > 40 ? 'flex-start' : 'center',
									justifyContent: 'space-between',
									flexDirection: pinValue.length > 40 ? 'column' : 'row',
								}}
								key={pinKey}
							>
								<Typography>{capitalize(pinKey)}:</Typography>
								{url && Array.isArray(splitPinValue) ? (
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											gap: '6px',
											alignItems: 'center',
										}}
									>
										<Typography sx={{ textAlign: 'center' }}>
											{splitPinValue[0]}
										</Typography>
										<QRCode size={200} value={url} />
										<Typography sx={{ textAlign: 'center' }}>
											{splitPinValue[1]}
										</Typography>
									</Box>
								) : (
									<Box
										sx={{
											display: 'flex',
											gap: '6px',
											alignItems: 'center',
										}}
									>
										<Typography sx={{ maxWidth: '200px' }}>
											{pinValue}
										</Typography>
										{pinKey === 'number' && (
											<CopyToClipboard
												text={pinValue}
												onCopy={() => onCopy(pinValue)}
											>
												<IconButton>
													<ContentCopy sx={{ fontSize: '16px' }} />
												</IconButton>
											</CopyToClipboard>
										)}
									</Box>
								)}
							</Box>
						</>
					);

				return null;
			})}
		</Box>
	);
};

export default PinDetails;
