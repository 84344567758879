import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { LIGHT_GRAY, THEME_MODE, TRANSITION } from 'utilities';
import { useAppSelector } from 'store/hooks';

type Props = {
	logo: any;
	title: string;
	description: string;
	imageWidth?: string;
	link: string;
};

const GenerateEpinItem = ({
	logo: Icon,
	title,
	description,
	imageWidth,
	link,
}: Props) => {
	const mode = useAppSelector((store) => store.theme.mode);
	const navigate = useNavigate();
	const handleNavigate = () => {
		navigate(link);
	};
	const theme = useTheme();
	return (
		<Box
			onClick={() => handleNavigate()}
			sx={{
				background: `url(${require('../../assets/images/stripBg.png')})`,
				transition: TRANSITION,
				padding: '2rem 15px',
				backgroundColor:
					mode === THEME_MODE.dark
						? theme.palette.background.paper
						: LIGHT_GRAY,
				objectFit: 'center',
				backgroundSize: '240%',
				display: 'flex',
				flexDirection: 'column',
				gap: '8px',
				alignItems: 'center',
				borderRadius: '5px',
				cursor: 'pointer',
				'&:hover': {
					transform: 'scale(1.03)',
				},
			}}
		>
			<Box
				sx={{
					maxWidth: imageWidth || '15px',
					img: {
						width: '100%',
					},
				}}
			>
				<Icon
					color={
						mode === THEME_MODE.dark ? grey['100'] : theme.palette.primary.main
					}
				/>
			</Box>
			<Typography
				sx={{
					textAlign: 'center',
					fontWeight: '600',

					color:
						mode === THEME_MODE.dark ? grey['100'] : theme.palette.primary.main,
				}}
				variant={'body1'}
			>
				{title}
			</Typography>
			<Typography
				sx={{
					textAlign: 'center',
					color:
						mode === THEME_MODE.dark ? grey['100'] : theme.palette.primary.main,
				}}
				variant={'body1'}
			>
				{description}
			</Typography>
		</Box>
	);
};

export default GenerateEpinItem;
