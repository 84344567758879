import React from 'react';
import { CopyToClipboard as RCopyToClipboard } from 'react-copy-to-clipboard';
import { IconButton } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { useAlert } from 'hooks';

interface ICopyToClipboard {
	text: string;
}

const CopyToClipboard = ({ text }: ICopyToClipboard) => {
	const alert = useAlert();
	const onCopy = () =>
		alert({ message: `"${text}" copies successfully!!`, type: 'success' });

	return (
		<RCopyToClipboard text={text as string} onCopy={() => onCopy()}>
			<IconButton
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<ContentCopy sx={{ fontSize: '16px', color: 'inherit' }} />
			</IconButton>
		</RCopyToClipboard>
	);
};

export default CopyToClipboard;
