import React from 'react';
import { Box, Typography } from '@mui/material';
import { Layout, NotificationListItem } from 'components';
import { useAppSelector } from 'store/hooks';
import { INotification } from 'utilities';
import { useModal } from 'hooks';

const Notifications = () => {
	const modal = useModal();
	const notifications = useAppSelector(
		(store) => store.notification.notifications
	);

	const handleClickNotification = (notification: INotification) => {
		const title = notification.subject;
		const message = notification.message;
		modal.display({
			// message,
			children: (
				<Box>
					<Typography
						sx={{
							fontWeight: '600',
							fontSize: '20px',
							marginBottom: '20px !important',
						}}
						variant={'body1'}
					>
						{title}
					</Typography>
					<div
						style={{
							margin: '0px !important',
							fontSize: '13px',
						}}
						dangerouslySetInnerHTML={{ __html: message }}
					/>
				</Box>
			),
			hasCloseButton: true,
		});
	};

	return (
		<Layout>
			<Box>
				{notifications && (
					<>
						{notifications.length > 0 ? (
							<Box
								sx={{
									display: 'grid',
									gap: '15px',
								}}
							>
								{notifications.map((notification) => (
									<NotificationListItem
										notification={notification}
										key={notification.id}
										textCount={100}
										onClick={() => handleClickNotification(notification)}
									/>
								))}
							</Box>
						) : (
							<Typography>No available notifications</Typography>
						)}
					</>
				)}
			</Box>
		</Layout>
	);
};

export default Notifications;
