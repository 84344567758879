import { setModalAlert } from '../store/app';
import { useAppDispatch } from '../store/hooks';
import { IModalAlert } from 'utilities';

const useModal = () => {
	const dispatch = useAppDispatch();
	const display = (data: IModalAlert) => dispatch(setModalAlert(data));
	const close = () => dispatch(setModalAlert(null));
	return {
		display,
		close,
	};
};

export default useModal;
