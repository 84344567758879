import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import queryString from 'query-string';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	Layout,
	ResetPinStepper,
	OtpForm,
	ResetPasswordPinForm,
} from 'components';
import Alert from 'components/alert';
import { QUERY_KEYS, LINKS } from 'utilities';
import { useAppSelector } from 'store/hooks';
import { resetPin, validateResetPinOrPassword } from 'api';
import { useHandleError, useModalAlert, useAlert } from 'hooks';

const ResetPin = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const handleError = useHandleError();
	const { search } = useLocation();
	const alert = useAlert();
	const queryClient = useQueryClient();
	const modal = useModalAlert();
	const navigate = useNavigate();
	const { user } = useAppSelector((store) => store.auth);

	const parseQuery = queryString.parse(search);

	const [currentStep, setCurrentStep] = useState<number>(1);
	const [otp, setOtp] = useState<string>('');

	const { isLoading: isResettingPin, mutate: mutateResetPin } = useMutation(
		resetPin,
		{
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						modal({
							title: 'Reset Pin Message',
							message: res.message,
							onClickPrimaryButton: () => modal(null),
							type: 'error',
							primaryButtonText: 'Close',
						});
					}
				}

				if (data && data.success) {
					queryClient.invalidateQueries(QUERY_KEYS.Me);
					setCurrentStep(3);
					setOtp('');
				}
			},
		}
	);

	const { isLoading: isValidatingPin, mutate: mutateValidatePin } = useMutation(
		validateResetPinOrPassword,
		{
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						alert({ message: res.message, type: 'error' });
					}
				}

				if (data && data.success) {
					setCurrentStep(2);
					alert({ message: 'Pin validated successfully', type: 'success' });
				}
			},
		}
	);

	const handleConfirmOtp = (token: string) => {
		setOtp(token);
		mutateValidatePin({
			data: {
				type: 'RESET_PIN',
				token: parseInt(token),
			},
			id: user?.id as string,
		});
	};

	const handleResetPin = (value: string) => {
		mutateResetPin({
			data: {
				pin: value,
				token: parseInt(otp),
			},
			id: user ? user.id : '',
		});
	};

	const handleNavigate = () => {
		if (parseQuery && parseQuery.redirect) {
			return navigate(parseQuery.redirect as string);
		}

		navigate(LINKS.Dashboard);
	};

	return (
		<>
			<Layout>
				<Box style={styles.container}>
					{currentStep !== 3 && <ResetPinStepper step={currentStep} />}

					<Box hidden={currentStep !== 1}>
						<Box>
							<Box sx={{ marginBottom: '2rem' }}>
								<Typography style={styles.title} variant={'h5'}>
									Verify OTP
								</Typography>
								<Typography variant={'body1'}>
									Enter the OTP sent to your email to reset Pin
								</Typography>
							</Box>
							<OtpForm
								isLoading={isValidatingPin}
								callback={(values: string) => handleConfirmOtp(values)}
							/>
						</Box>
					</Box>
					<Box hidden={currentStep !== 2}>
						<ResetPasswordPinForm
							title={'Enter new pin'}
							description={'Type in the new pin you want below'}
							placeholder={'Type in your new pin'}
							label={'New pin'}
							numInputs={4}
							btnText={'Save new pin'}
							onSubmit={(values) => handleResetPin(values)}
							handleGoBack={() => setCurrentStep(1)}
							isLoading={isResettingPin}
						/>
					</Box>
					<Box hidden={currentStep !== 3}>
						<Alert
							title={'Success'}
							alertType={'success'}
							successType={'primary'}
							handlePress={handleNavigate}
							message={'Your pin has been successfully reset'}
							btnText={'Continue'}
						/>
					</Box>
				</Box>
			</Layout>
		</>
	);
};

const useStyles = (theme: any) => ({
	container: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '30px',
		maxWidth: '460px',
	},
	title: {
		fontWeight: '600',
		marginBottom: '8px',
	},
});

export default ResetPin;
