import React from 'react';
import { Typography, Box, styled } from '@mui/material';
import { Adjust, CheckCircleOutlined } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { red, green } from '@mui/material/colors';
import moment from 'moment';
import { useQuery } from 'react-query';
import { grey } from '@mui/material/colors';
import { ChevronLeft } from '@mui/icons-material';
import {
	Layout,
	Button,
	CircularProgress,
	ReplyTicketForm,
	MessageItem,
} from 'components';
import { useAppSelector } from 'store/hooks';
import {
	QUERY_KEYS,
	ITicket,
	TicketReply,
	TICKET_STATUS,
	ErrorBoundaryGuard,
} from 'utilities';
import { useAlert, useHandleError } from 'hooks';
import { tickets } from 'api';

const Message = () => {
	const alert = useAlert();
	const handleError = useHandleError();
	const { token } = useAppSelector((store) => store.auth);
	const params = useParams();
	const navigate = useNavigate();

	const { data, isLoading } = useQuery(
		[QUERY_KEYS.Ticket, params.id],
		() =>
			tickets({
				populate: 'related_transaction',
				_id: params.id,
			}),
		{
			enabled: !!(token && params && params.id),
			onError: (error) => {
				const response = handleError({ error });
				if (response?.message) {
					alert({ message: response.message, type: 'error' });
				}
			},
		}
	);

	return (
		<Layout>
			<BackButton onClick={() => navigate(-1)} startIcon={<ChevronLeft />}>
				Back to Tickets
			</BackButton>
			{isLoading ? (
				<CircularProgress
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				/>
			) : (
				data && (
					<ErrorBoundaryGuard>
						<Box
							sx={{
								display: 'grid',
								gap: '15px',
								gridTemplateColumns: '1fr',
								marginTop: ['30px'],
							}}
						>
							<Box sx={{}}>
								<Title variant={'h6'}>{data.payload[0].subject}</Title>
								<Box
									sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
								>
									<Status
										sx={{
											backgroundColor:
												data.payload[0].status === TICKET_STATUS.OPENED
													? green['600']
													: red['600'],
										}}
									>
										{data.payload[0].status === TICKET_STATUS.OPENED ? (
											<Adjust sx={{ color: grey['50'], fontSize: '20px' }} />
										) : (
											<CheckCircleOutlined
												sx={{ color: grey['50'], fontSize: '20px' }}
											/>
										)}
										<Typography
											sx={{
												color: grey['50'],
												fontWeight: '600',
											}}
											variant={'body2'}
										>
											{data.payload[0].status}
										</Typography>
									</Status>
									<Typography>
										Ticket created on{' '}
										{moment(data.payload[0].createdAt).format('ll')}
									</Typography>
								</Box>
							</Box>
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: '1fr',
									gap: '15px',
								}}
							>
								<ChatBox sx={{}}>
									<MessageItem
										message={data.payload[0].message as string}
										repliedBy={data.payload[0].user as string}
									/>
									{data.payload[0].replies &&
										data.payload[0].replies.map((payload: TicketReply) => (
											<MessageItem
												key={payload.id}
												message={payload.reply}
												repliedBy={payload.repliedBy as string}
											/>
										))}
								</ChatBox>
								<ReplyTicketForm
									ticket={data && (data.payload[0] as ITicket)}
								/>
							</Box>
						</Box>
					</ErrorBoundaryGuard>
				)
			)}
		</Layout>
	);
};

const Title = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold',
	marginBottom: theme.spacing(1),
}));

const BackButton = styled(Button)(({ theme }) => ({
	backgroundColor: `${theme.palette.secondary.main} !important`,
	color: grey['50'],
	justifySelf: 'flex-start',
}));

const Status = styled(Box)(({ theme }) => ({
	borderRadius: '20px',
	// minWidth: '40px',
	display: 'flex',
	alignItems: 'center',
	gap: '6px',
	padding: '8px 15px',
}));

const ChatBox = styled(Box)(({ theme }) => ({
	borderRadius: '6px',
	border: `1px solid ${grey['300']}`,
	backgroundColor: 'white',
	padding: '8px 15px',
	display: 'grid',
	gridTemplateColumns: '1fr',
	gap: '6px',
}));

export default Message;
