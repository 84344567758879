import {
	API_ENDPOINTS,
	Bundle,
	DataResponse,
	ENDPOINT_SUBPATH,
	IBill,
	IVerifiedBill,
	IProvider,
	VerifyBillPayload,
	Product,
} from 'utilities';
import { apiRequest } from '../config';

export const billProviders = async (
	url: string,
	params?: any
): Promise<DataResponse<IProvider[]>> =>
	apiRequest({
		url,
		method: 'GET',
		params,
	});

export const billBundles = async ({
	params,
	url,
}: {
	params: {
		provider?: string;
		service_type?: string;
		product_code?: string;
		isAddOnsEnabled?: boolean;
		[key: string]: any;
	};
	url: string;
}): Promise<DataResponse<Bundle[]>> =>
	apiRequest({
		url: `${url}/packages`,
		method: 'GET',
		params,
	});

export const billPayment = async ({
	data,
	url,
}: {
	data: IBill;
	url: string;
}): Promise<any> =>
	apiRequest({
		url,
		method: 'POST',
		data,
	});

export const validateBillCard = async (
	data: VerifyBillPayload
): Promise<DataResponse<{ payload: IVerifiedBill } | IVerifiedBill>> =>
	apiRequest({
		url: `${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.ValidateBill}`,
		method: 'POST',
		data,
	});

export const billTransactions = async (params: {
	[key: string]: any;
}): Promise<any> =>
	apiRequest({
		url: `${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.Me}`,
		method: 'GET',
		params,
	});

export const billProducts = async (params: {
	[key: string]: any;
}): Promise<DataResponse<Product[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.Bill}/betting/products`,
		method: 'GET',
		params,
	});
