import React from 'react';

type Props = {
	color?: string;
	size?: number;
	innerColor?: string;
};

const Logout = ({ size, color, innerColor }: Props) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M16.8257 10.4772H13.2393H10.2508H6.47449V8.3042L0.728271 12L6.47449 15.6957V13.5227H16.8257V10.4772Z'
				fill={innerColor || '#DCE3E7'}
			/>
			<path
				d='M6.47461 16.4242C6.33722 16.4242 6.20025 16.3853 6.08063 16.3084L0.334406 12.6127C0.125953 12.4785 0 12.2478 0 12C0 11.7522 0.125953 11.5214 0.334406 11.3873L6.08063 7.69157C6.30469 7.54743 6.58978 7.53717 6.82355 7.66485C7.05741 7.79254 7.20295 8.0377 7.20295 8.30423V9.74887H16.8258C17.228 9.74887 17.5542 10.075 17.5542 10.4773V13.5227C17.5542 13.925 17.228 14.2511 16.8258 14.2511H7.203V15.6958C7.203 15.9623 7.05745 16.2074 6.82359 16.3351C6.71461 16.3946 6.59442 16.4242 6.47461 16.4242ZM2.07487 12L5.74617 14.3613V13.5227C5.74617 13.1204 6.07233 12.7943 6.47456 12.7943H16.0973V11.2056H6.47461C6.07242 11.2056 5.74622 10.8795 5.74622 10.4772V9.63862L2.07487 12Z'
				fill={color}
			/>
			<path
				d='M23.2715 23.2965H6.33561C5.93343 23.2965 5.60722 22.9704 5.60722 22.5682V18.8833C5.60722 18.481 5.93338 18.1549 6.33561 18.1549C6.73785 18.1549 7.06401 18.481 7.06401 18.8833V21.8398H22.5432V2.1601H7.06396V5.1166C7.06396 5.51888 6.7378 5.84499 6.33557 5.84499C5.93333 5.84499 5.60718 5.51888 5.60718 5.1166V1.43176C5.60718 1.02948 5.93333 0.703369 6.33557 0.703369H23.2715C23.6736 0.703369 23.9998 1.02948 23.9998 1.43176V22.5683C23.9999 22.9705 23.6737 23.2965 23.2715 23.2965Z'
				fill={color}
			/>
		</svg>
	);
};

export default Logout;
