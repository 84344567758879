import { apiRequest } from '../config';
import {
	API_ENDPOINTS,
	ENDPOINT_SUBPATH,
	IDataPlan,
	DataResponse,
	DataSubscription,
	DataType,
	INetwork,
	ITransaction,
	Product,
	IProvider,
} from 'utilities';

export const dataPlans = async (params: {
	[key: string]: any;
}): Promise<DataResponse<IDataPlan[]>> =>
	apiRequest({
		url: API_ENDPOINTS.DataPlans,
		method: 'GET',
		params,
	});

export const dataTypes = async (params: {
	[key: string]: any;
}): Promise<DataResponse<DataType[]>> =>
	apiRequest({
		url: API_ENDPOINTS.DataTypes,
		method: 'GET',
		params,
	});

export const dataNetworks = async (params?: {
	[key: string]: any;
}): Promise<DataResponse<INetwork[]>> =>
	apiRequest({
		url: API_ENDPOINTS.DataNetwork,
		method: 'GET',
		params,
	});

export const dataSubscriptions = async (params: {
	[key: string]: any;
}): Promise<DataResponse<ITransaction[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.DataSubscription}${ENDPOINT_SUBPATH.Me}`,
		method: 'GET',
		params,
	});

export const internationalDataSubscriptions = async (params: {
	[key: string]: any;
}): Promise<DataResponse<ITransaction[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.InternationalData}${ENDPOINT_SUBPATH.Me}`,
		method: 'GET',
		params,
	});

export const createDataSubscriptions = async (
	data: DataSubscription
): Promise<DataResponse<ITransaction>> =>
	apiRequest({
		url: API_ENDPOINTS.DataSubscription,
		method: 'POST',
		data,
	});

export const internationalDataProducts = async (
	providerId: string
): Promise<DataResponse<Product[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.InternationalData}${ENDPOINT_SUBPATH.Products}/${providerId}`,
		method: 'GET',
	});
export const internationalDataPurchase = async (data: {
	[key: string]: any;
}): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.InternationalData}/purchase`,
		method: 'POST',
		data,
	});

export const internationalDataProviders = async (params: {
	[key: string]: any;
}): Promise<DataResponse<IProvider[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.InternationalData}${ENDPOINT_SUBPATH.Providers}`,
		method: 'GET',
		params,
	});
