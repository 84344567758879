import React from 'react';
import { grey } from '@mui/material/colors';
import { Box, Typography, BoxProps } from '@mui/material';
import { LIGHT_GRAY, THEME_MODE } from 'utilities';
import { useAppSelector } from 'store/hooks';

interface Props extends BoxProps {
	text: string;
	height?: string;
}

const Divider = (props: Props) => {
	const { text, height } = props;
	const { mode } = useAppSelector((store) => store.theme);
	return (
		<Box
			component={'div'}
			sx={{
				...props.sx,
				fontWeight: '600',
				width: '100%',
				position: 'relative',
				display: 'flex',
				alignItems: 'center',
				textAlign: 'center',
				justifyContent: 'center',
				'&:after': {
					content: '""',
					position: 'absolute',
					backgroundColor: LIGHT_GRAY,
					height: height || '2px',
					width: '45%',
					right: '0px',
				},
				'&:before': {
					content: '""',
					position: 'absolute',
					backgroundColor: LIGHT_GRAY,
					height: height || '2px',
					width: '45%',
					left: '0px',
				},
			}}
		>
			<Typography
				sx={{
					fontWeight: '600',
					color: mode === THEME_MODE.dark ? grey[300] : 'initial',
				}}
			>
				{text}
			</Typography>
		</Box>
	);
};

export default Divider;
