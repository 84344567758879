import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { LIGHT_GRAY } from 'utilities';
import InstructionItem from './instruction-item';

const INSTRUCTIONS = [
	{
		step: 1,
		description: `Select network and Input your phone number.`,
	},
	{
		step: 2,
		description: `Insert Otp sent to your Number.`,
	},
	{
		step: 3,
		description: `Insert amount and your network transfer pin. (Share and Sell Pin)`,
	},
	{
		step: 4,
		description: `Airtime will be automatically deducted from your sim and your wallet will be credited.`,
	},
];

type Props = {
	isSticky?: boolean;
};

const InstantAirtimeConvertInstructions = ({ isSticky }: Props) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	return (
		<Box sx={{ position: isSticky ? 'sticky' : 'initial', top: '6rem' }}>
			<Box style={styles.header}>
				<Typography style={styles.bolderText} variant={'body1'}>
					Instructions
				</Typography>
			</Box>
			<Box style={styles.instructionWrapper}>
				{INSTRUCTIONS.map(({ step, description }) => (
					<InstructionItem key={step} step={step} description={description} />
				))}
			</Box>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	header: {
		backgroundColor: LIGHT_GRAY,
		padding: '15px',
		borderRadius: '5px',
		marginBottom: '15px',
		color: theme.palette.primary.main,
	},
	bolderText: {
		fontWeight: '600',
		color: theme.palette.primary.main,
	},
	instructionWrapper: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '15px',
		padding: '0px 15px',
	},
});

export default InstantAirtimeConvertInstructions;
