import React from 'react';
import {
	Box,
	BoxProps,
	CircularProgress as MuiCircularProgress,
	Typography,
} from '@mui/material';
import { SECOUNDARY_COLOR } from 'utilities';

interface Props extends BoxProps {}

const CircularProgress: React.FC<Props> = (props) => {
	return (
		<Box
			sx={{
				width: '100%',
				overflow: 'auto',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',
				...props.sx,
			}}
		>
			<MuiCircularProgress size={'32px'} sx={{ color: SECOUNDARY_COLOR }} />
			<Typography variant={'body2'}>Loading...</Typography>
		</Box>
	);
};

export default CircularProgress;
