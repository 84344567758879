import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BackButton, Layout, SupportTicketForm } from 'components';

const CreateSupportTicket = () => {
	return (
		<Layout>
			<BackButton name={'Back'} sx={{ marginBottom: ['1.5rem'] }} />
			<Grid>
				<Box>
					<Title variant={'h5'}>Support Ticket</Title>
					<Typography variant={'body1'}>
						To Create a Dispute or Complain, enter the following required
						information
					</Typography>
				</Box>
				<SupportTicketForm />
			</Grid>
		</Layout>
	);
};

const Title = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold',
	marginBottom: theme.spacing(1),
}));

const Grid = styled(Box)(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: '1fr',
	gap: theme.spacing(4),
}));

export default CreateSupportTicket;
