import React from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Adjust, CheckCircleOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { green, grey, red } from '@mui/material/colors';
import { Delete } from '@mui/icons-material';
import { ITicket, TICKET_STATUS, THEME_MODE } from 'utilities';
import { LINKS } from 'utilities';
import { useAppSelector } from 'store/hooks';

interface Props {
	ticket: ITicket;
}

const MAX_LENGTH = 50;

const SupportTicketListItem = ({ ticket }: Props) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const {
		theme: { mode },
	} = useAppSelector((store) => store);
	return (
		<Container
			sx={{
				backgroundColor:
					mode === THEME_MODE.light ? 'white' : 'background.paper',
				':hover': {
					backgroundColor: mode === THEME_MODE.light ? grey['50'] : grey['800'],
				},
				':before': {
					backgroundColor:
						mode === THEME_MODE.light
							? theme.palette.primary.main
							: grey['300'],
				},
			}}
		>
			<Box
				onClick={() => navigate(`${LINKS.Message}/${ticket.id}`)}
				sx={{ flex: 1 }}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						gap: '10px',
						marginBottom: '8px',
					}}
				>
					<Title variant={'h6'}>{ticket.subject}</Title>
					<Status
						sx={{
							backgroundColor:
								ticket.status === TICKET_STATUS.OPENED
									? green['600']
									: red['600'],
							display: 'flex',
							alignItems: 'center',
							gap: '3px',
						}}
					>
						{ticket.status === TICKET_STATUS.OPENED ? (
							<Adjust sx={{ color: grey['50'], fontSize: '20px' }} />
						) : (
							<CheckCircleOutlined
								sx={{ color: grey['50'], fontSize: '20px' }}
							/>
						)}
						<Typography
							sx={{ color: grey['50'], fontSize: '10px', fontWeight: '600' }}
							variant={'body2'}
						>
							{ticket.status}
						</Typography>
					</Status>
				</Box>
				<Body variant={'body1'}>
					{ticket.message && ticket.message.length > MAX_LENGTH
						? `${ticket.message.slice(0, MAX_LENGTH)}...`
						: ticket.message}
				</Body>
			</Box>
			<Box
				sx={{
					visibility: 'hidden',
					alignSelf: 'center',
				}}
				className={'delete-container'}
			>
				<DeleteButton size={'small'}>
					<Delete />
				</DeleteButton>
			</Box>
		</Container>
	);
};

const Title = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold',
	fontSize: '18px',
}));

const Body = styled(Typography)(({ theme }) => ({}));

const Container = styled(Box)(({ theme }) => ({
	// border: `1px solid ${grey['200']}`,
	position: 'relative',
	padding: '15px 20px',
	cursor: 'pointer',
	display: 'flex',
	// alignItems: 'center',
	gap: theme.spacing(1),
	':hover': {
		backgroundColor: grey['50'],
	},
	':hover > .delete-container': {
		visibility: 'visible',
	},
	':hover:before': {
		display: 'block',
	},
	':before': {
		content: '""',
		position: 'absolute',
		left: '0px',
		top: '0px',
		height: '100%',
		width: '3px',
		backgroundColor: theme.palette.primary.main,
		display: 'none',
	},
}));

const Status = styled(Box)(({ theme }) => ({
	borderRadius: '15px',
	// minWidth: '40px',
	padding: '5px 10px',
}));

const DeleteButton = styled(IconButton)(({ theme }) => ({
	border: `1px solid ${red['600']}`,
	color: red['600'],

	':hover': {
		color: grey['50'],
		backgroundColor: red['600'],
	},
}));

export default SupportTicketListItem;
