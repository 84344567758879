import React from 'react';
import { Box, Typography } from '@mui/material';

type Props = {
	step: number;
	description: string;
};

const InstructionItem = ({ step, description }: Props) => {
	return (
		<Box>
			<Typography sx={{ fontWeight: '600' }} variant={'body1'}>
				Step {step}
			</Typography>
			<Typography variant={'body1'}>{description}</Typography>
		</Box>
	);
};

export default InstructionItem;
