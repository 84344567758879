import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextInput from '../form-components/text-input';
import Button from '../button';
import { grey } from '@mui/material/colors';
import { LoginPayload, LINKS } from 'utilities';
import { useHandleError, useAlert } from 'hooks';
import { resetPasswordToken } from 'api';

const RequestForgottenPasswordOtpForm = () => {
	const navigate = useNavigate();
	const handleError = useHandleError();
	const alert = useAlert();
	const [isLoading, setLoading] = useState<boolean>(false);
	const theme = useTheme();
	const styles = useStyles(theme);

	const validationSchema = yup.object().shape({
		email: yup.string().email('Invalid email').required('Email is required'),
	});

	const initialValues: LoginPayload = {
		email: '',
	};

	const handleRequestOtp = async (email: string) => {
		setLoading(true);
		try {
			const response = await resetPasswordToken({ email });
			if (response.success) {
				const ref = response.payload.id;
				const email = response.payload.email;
				alert({ message: response.message, type: 'success' });
				navigate(
					`${LINKS.ConfirmForgottenPasswordOtp}?ref=${ref}&email=${email}`,
					{
						state: {
							email,
							ref,
						},
					}
				);
			}
		} catch (error) {
			const response = handleError({ error });
			if (response?.message)
				alert({ message: response.message, type: 'error' });
		}
		setLoading(false);
	};

	const { values, handleChange, handleSubmit, errors, touched } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			handleRequestOtp(values.email as string);
		},
	});

	const { email } = values;

	return (
		<Box>
			<Box sx={{ marginBottom: theme.spacing(4) }}>
				<Typography style={styles.title} variant={'h5'}>
					Forgotten Password
				</Typography>
				<Typography variant={'body1'}>
					Type in your email address and we'll send you and OTP to reset
					password.
				</Typography>
			</Box>
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Email address
					</Typography>
					<TextInput
						error={errors && touched.email && errors.email ? true : false}
						helperText={errors && touched.email && errors.email}
						fullWidth
						placeholder={'Type in your email address'}
						value={email}
						onChange={handleChange('email')}
					/>
				</Box>

				<Button
					loading={isLoading}
					size={'large'}
					style={styles.btn}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Request OTP
				</Button>
				<Button
					onClick={() => {
						navigate(-1);
					}}
					style={styles.btnOutline}
					sx={{
						color: theme.palette.secondary.main,
						':hover': {
							backgroundColor: theme.palette.secondary.main,
							color: grey[50],
						},
					}}
					fullWidth
					size={'large'}
				>
					Go back
				</Button>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	btnOutline: {
		border: `1px solid ${theme.palette.secondary.main}`,
		fontSize: '14px',
		fontWeight: '600',
	},
	togglePassword: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
	forgottenPasswordButton: {
		alignSelf: 'flex-end',
	},
});

export default RequestForgottenPasswordOtpForm;
