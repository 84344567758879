import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import ThemeProvider from './utilities/theme/MuiThemeProvider';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import CookieConsent from 'react-cookie-consent';
import Router from './router';
import ScrollToTop from './utilities/helpers/scroll-to-top';
// import requestPermission from './utilities/helpers/requestPermission';
import { getToken, onMessageListener } from './firebase';
import { useAppSelector } from './store/hooks';
import PushNotificationModal from './components/modal/push-notification-modal';
import { InAppAlert, ModalAlert, VerifyPinModal } from './components';
import { PRIMARY_COLOR, SECOUNDARY_COLOR } from './utilities';
import {
	useLoadUser,
	useLoadWallet,
	useClearTransactionBreakdown,
	useLoadNotificationAlert,
	useLoadNotificationSettings,
	useRequestPermission,
	useAlert,
} from './hooks';

// const init_app = initializeApp(firebaseConfig);

function App() {
	useLoadUser();
	useLoadWallet();
	useClearTransactionBreakdown();
	useLoadNotificationAlert();
	useLoadNotificationSettings();
	const permission = useRequestPermission();

	const alert = useAlert();

	const pushNotification = useAppSelector(
		(store) => store.app.pushNotification
	);

	// Get Firbase token
	useEffect(
		() => {
			if (permission === 'granted') getToken();
		},
		// eslint-disable-next-line
		[permission]
	);

	onMessageListener()
		.then((payload) => {
			const title = payload.notification?.title;
			const body = payload.notification?.body;
			alert({
				message: (
					<Box>
						<Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
						{body && (
							<Typography
								sx={{
									fontSize: '12px',
								}}
							>
								{body}
							</Typography>
						)}
					</Box>
				),
				type: 'info',
			});
		})
		.catch((err) => console.log('failed: ', err));

	return (
		<ThemeProvider>
			<InAppAlert />
			{pushNotification && <PushNotificationModal />}
			<ModalAlert />
			<VerifyPinModal />
			<ScrollToTop>
				<CookieConsent
					location='bottom'
					buttonText='Accept'
					declineButtonText={'Decline'}
					enableDeclineButton={false}
					flipButtons={true}
					style={{
						background: 'white',
						maxWidth: '1200px',
						margin: 'auto',
						left: '50%',
						transform: 'translateX(-50%)',
						color: PRIMARY_COLOR,
						borderTopLeftRadius: '8px',
						borderTopRightRadius: '8px',
						alignItems: 'center',
					}}
					buttonStyle={{
						color: 'white',
						fontSize: '13px',
						borderRadius: '6px',
						backgroundColor: SECOUNDARY_COLOR,
						padding: '12px 30px',
						fontWeight: 'bold',
					}}
					declineButtonStyle={{
						color: 'white',
						backgroundColor: 'red',
						padding: '12px 30px',
						fontWeight: 'bold',
						fontSize: '13px',
						borderRadius: '6px',
					}}
					expires={30}
				>
					{/* We use cookies to ensure you get the best experience on our website.
					By continuing to browse, you agree to our use of cookies. For more
					information, please read our Privacy Policy. */}
					<Typography
						sx={{
							fontSize: ['18px', '24px'],
							// color: PRIMARY_COLOR,
							marginBottom: '8px',
						}}
						variant={'h3'}
					>
						Cookies and Privacy
					</Typography>
					In order to provide you with the best experience, we use some cookies
					on our website. Using our site means you are aware that we are using
					cookies and grant us permission to do so.
				</CookieConsent>
				<TawkMessengerReact
					propertyId={process.env.REACT_APP_TAWKTO_ID}
					widgetId={process.env.REACT_APP_TAWKTO_WIDGET_ID}
				/>
				<Router />
			</ScrollToTop>
		</ThemeProvider>
	);
}

export default App;
