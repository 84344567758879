import { setVerifyPinModal } from '../store/app';
import { useAppDispatch } from '../store/hooks';
import { IVerifyPinMModal } from 'utilities';

const useVerifyPin = () => {
	const dispatch = useAppDispatch();
	return (alert: IVerifyPinMModal | null) => dispatch(setVerifyPinModal(alert));
};

export default useVerifyPin;
