import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { grey } from '@mui/material/colors';
import { Info } from '@mui/icons-material';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import TextInput from '../form-components/text-input';
import Button from '../button';
import {
	WithdrawDetails,
	QUERY_KEYS,
	LINKS,
	formatNumberToCurrency,
} from 'utilities';
import { useHandleError, useModalAlert, useVerifyPin } from 'hooks';
import { withdraw } from 'api';
import { useAppSelector } from 'store/hooks';

type Props = {
	withdrawType: string;
};

const WalletWithdrawForm = ({ withdrawType }: Props) => {
	const theme = useTheme();
	const queryClient = useQueryClient();
	const { pathname } = useLocation();
	const styles = useStyles(theme);
	const modal = useModalAlert();
	const verifyPin = useVerifyPin();
	const handleError = useHandleError();
	const navigate = useNavigate();
	const { user } = useAppSelector((store) => store.auth);
	const redirect = pathname;

	const validationSchema = yup.object().shape({
		amount: yup
			.number()
			.moreThan(999, `Amount must be more than ${formatNumberToCurrency(1000)}`)
			.lessThan(
				500000,
				`Amount cannot be more than ${formatNumberToCurrency(500000)}`
			)
			.required('Specify amount'),
	});

	const { isLoading, mutate } = useMutation(withdraw, {
		onSettled: (data, error) => {
			if (error) {
				const res = handleError({ error });
				if (res?.message) {
					modal({
						title: 'Withdraw Message',
						message: res.message,
						type: 'error',
						onClickPrimaryButton: () => modal(null),
						primaryButtonText: 'Close',
					});
				}
			}

			if (data && data.success) {
				resetForm();
				queryClient.invalidateQueries(QUERY_KEYS.UserWallet);
				verifyPin(null);
				modal({
					title: 'Withdraw Message',
					message: data.message,
					type: 'success',
					onClickPrimaryButton: () => {
						modal(null);
						navigate(LINKS.Dashboard);
					},
					primaryButtonText: 'Go to Dashboard',
				});
			}
		},
	});

	const initialValues: WithdrawDetails = {
		amount: '',
		type: withdrawType,
	};

	const { errors, touched, values, handleChange, handleSubmit, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: (values) => {
				let data = {
					type: values.type,
					amount: values.amount,
				} as typeof initialValues;

				if (user && !user.bvnVerified) {
					modal({
						title: 'Notice',
						message:
							'To continue with  this transaction, you need to complete your KYC level three verification',
						primaryButtonText: 'Proceed',
						hasCloseButton: true,
						onClickPrimaryButton: () => {
							modal(null);
							navigate(
								`${LINKS.Settings}?tab=manage-limit&redirect=${redirect}`,
								{
									state: {
										redirect,
									},
								}
							);
						},
					});

					return;
				}

				const callback = () => {
					verifyPin(null);

					mutate(data);
				};

				verifyPin({
					title: 'Withdraw',
					message: 'Verify transaction pin to continue with the transaction',
					callback,
				});
			},
		});

	const { amount } = values;

	return (
		<Box>
			<Box style={styles.form as any} component={'form'}>
				<Typography style={styles.title} variant={'h5'}>
					Wallet Withdraw
				</Typography>

				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Specify amount
					</Typography>
					<TextInput
						fullWidth
						placeholder={'Type in amount you want to withdraw'}
						error={errors && touched.amount && errors.amount ? true : false}
						helperText={errors && touched.amount && errors.amount}
						value={amount}
						onChange={handleChange('amount')}
					/>
					<Typography
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '6px',
							marginTop: '6px',
						}}
						variant={'body2'}
					>
						<Info />
						<span>{formatNumberToCurrency(50)} withdrawal charge applies.</span>
					</Typography>
				</Box>

				<Button
					loading={isLoading}
					size={'large'}
					style={styles.btn}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Proceed
				</Button>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
	},
});

export default WalletWithdrawForm;
