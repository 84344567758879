import React from 'react';
import { Layout, Seo, ConvertAirtimeServices, BackButton } from 'components';

const ConvertAirtime = () => {
	return (
		<Layout>
			<Seo title={'Convert Airtime'} />
			<BackButton name={'Services'} sx={{ marginBottom: ['2rem'] }} />
			<ConvertAirtimeServices />
		</Layout>
	);
};

export default ConvertAirtime;
