import {
	API_ENDPOINTS,
	DataResponse,
	INotification,
	INotificationSettings,
} from 'utilities';
import { apiRequest } from '../config';

export const notificationSettings = async (): Promise<
	DataResponse<INotificationSettings[]>
> => {
	return apiRequest({
		url: `${API_ENDPOINTS.NotificationSettings}`,
		method: 'GET',
	});
};

export const notifications = async (
	params: Record<string, any>
): Promise<DataResponse<INotification[]>> => {
	return apiRequest({
		url: `${API_ENDPOINTS.Notification}`,
		method: 'GET',
		params,
	});
};

export const updateNotificationSettings = async ({
	id,
	data,
}: {
	id: string;
	data: Partial<INotificationSettings>;
}): Promise<DataResponse<INotificationSettings>> => {
	return apiRequest({
		url: `${API_ENDPOINTS.NotificationSettings}/${id}`,
		method: 'PUT',
		data,
	});
};
