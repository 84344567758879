import React from 'react';
import { Helmet } from 'react-helmet-async';
import { SEO } from 'utilities';

type Props = {
	title?: string;
	description?: string;
	image?: string;
};

const Seo = ({ title, description, image }: Props) => {
	return (
		<div>
			<Helmet>
				<title>{title ? `${title} | ${SEO.meta.title}` : SEO.meta.title}</title>
				<meta name='title' content={title || SEO.meta.title} />
				<meta
					name='description'
					content={description || SEO.meta.description}
				/>

				<meta property='og:type' content='website' />
				<meta property='og:url' content={SEO.meta.siteUrl} />
				<meta property='og:title' content={title || SEO.meta.title} />
				<meta
					property='og:description'
					content={description || SEO.meta.description}
				/>
				<meta
					property='og:image'
					content={image ? image : `${SEO.meta.siteUrl}/${SEO.meta.iconimage}`}
				/>

				<meta property='twitter:card' content='summary_large_image' />
				<meta property='twitter:url' content={SEO.meta.siteUrl} />
				<meta property='twitter:title' content='Airtimeflip' />
				<meta
					property='twitter:description'
					content={description || SEO.meta.description}
				/>
				<meta
					property='twitter:image'
					content={image ? image : `${SEO.meta.siteUrl}/${SEO.meta.iconimage}`}
				></meta>
				<script>
					{`!function(f,b,e,v,n,t,s){
						if(f.fbq)return;n=f.fbq=function(){n.callMethod?
					n.callMethod.apply(n,arguments):n.queue.push(arguments)};
					if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
					n.queue=[];t=b.createElement(e);t.async=!0;
					t.src=v;s=b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t,s)}(window,document,'script',
					'https://connect.facebook.net/en_US/fbevents.js');
					fbq('init', '197922062328218'); 
					fbq('track', 'PageView');
					fbq('track', 'CompleteRegistration');
					`}
				</script>
				<noscript>
					{`<img
						height='1'
						width='1'
						src='https://www.facebook.com/tr?id=197922062328218&ev=PageView
					&noscript=1'
					/>`}
				</noscript>
			</Helmet>
		</div>
	);
};

export default Seo;
