import React from 'react';

type Props = {
	color?: string;
	size?: number;
	innerColor?: string;
};

const Dashboard = ({ size, color, innerColor }: Props) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M22.3326 23.9997H15.1317C14.2123 23.9997 13.4644 23.2518 13.4644 22.3323V15.1315C13.4644 14.2121 14.2123 13.4641 15.1317 13.4641H19.4283C19.8301 13.4641 20.1559 13.7899 20.1559 14.1918C20.1559 14.5936 19.8301 14.9194 19.4283 14.9194H15.1317C15.0148 14.9194 14.9197 15.0145 14.9197 15.1314V22.3323C14.9197 22.4493 15.0148 22.5443 15.1317 22.5443H22.3326C22.4495 22.5443 22.5447 22.4492 22.5447 22.3323V18.7319C22.5447 18.33 22.8705 18.0042 23.2723 18.0042C23.6741 18.0042 23.9999 18.33 23.9999 18.7319V22.3323C24 23.2518 23.252 23.9997 22.3326 23.9997Z'
				fill={color}
			/>
			<path
				d='M0.727783 8.86809V1.66724C0.727783 1.14829 1.14849 0.727539 1.66749 0.727539H8.86842C9.38738 0.727539 9.80813 1.14824 9.80813 1.66724V8.86814C9.80813 9.38709 9.38742 9.80784 8.86842 9.80784H1.66749C1.14853 9.80779 0.727783 9.38704 0.727783 8.86809Z'
				fill={innerColor || '#DCE3E7'}
			/>
			<path
				d='M8.86852 10.5355H1.66759C0.748228 10.5355 0.000244141 9.78759 0.000244141 8.86819V1.66734C0.000244141 0.747937 0.748181 0 1.66759 0H8.86852C9.78788 0 10.5359 0.747937 10.5359 1.66734V8.86823C10.5359 9.78759 9.78793 10.5355 8.86852 10.5355ZM1.66759 1.45528C1.55073 1.45528 1.45557 1.55039 1.45557 1.6673V8.86819C1.45557 8.98514 1.55073 9.0802 1.66759 9.0802H8.86852C8.98538 9.0802 9.08054 8.98509 9.08054 8.86819V1.66734C9.08054 1.55039 8.98538 1.45533 8.86852 1.45533H1.66759V1.45528Z'
				fill={color}
			/>
			<path
				d='M14.1919 8.86809V1.66724C14.1919 1.14829 14.6126 0.727539 15.1316 0.727539H22.3325C22.8515 0.727539 23.2722 1.14824 23.2722 1.66724V8.86814C23.2722 9.38709 22.8515 9.80784 22.3325 9.80784H15.1316C14.6126 9.80779 14.1919 9.38704 14.1919 8.86809Z'
				fill={innerColor || '#DCE3E7'}
			/>
			<path
				d='M22.3326 10.5355H15.1317C14.2123 10.5355 13.4644 9.78759 13.4644 8.86819V1.66734C13.4644 0.747984 14.2123 0 15.1317 0H22.3326C23.252 0 24 0.747937 24 1.66734V8.86823C24 9.78759 23.252 10.5355 22.3326 10.5355ZM15.1317 1.45528C15.0148 1.45528 14.9197 1.55039 14.9197 1.6673V8.86819C14.9197 8.98514 15.0148 9.0802 15.1317 9.0802H22.3326C22.4495 9.0802 22.5447 8.98509 22.5447 8.86819V1.66734C22.5447 1.55039 22.4495 1.45533 22.3326 1.45533H15.1317V1.45528Z'
				fill={color}
			/>
			<path
				d='M0.727783 22.3327V15.1318C0.727783 14.6129 1.14849 14.1921 1.66749 14.1921H8.86842C9.38738 14.1921 9.80813 14.6128 9.80813 15.1318V22.3327C9.80813 22.8516 9.38742 23.2724 8.86842 23.2724H1.66749C1.14853 23.2724 0.727783 22.8516 0.727783 22.3327Z'
				fill={innerColor || '#DCE3E7'}
			/>
			<path
				d='M8.86852 23.9999H1.66759C0.748228 23.9999 0.000244141 23.2519 0.000244141 22.3325V15.1317C0.000244141 14.2123 0.748181 13.4644 1.66759 13.4644H8.86852C9.78788 13.4644 10.5359 14.2123 10.5359 15.1317V22.3325C10.5359 23.2519 9.78793 23.9999 8.86852 23.9999ZM1.66759 14.9196C1.55073 14.9196 1.45557 15.0147 1.45557 15.1317V22.3325C1.45557 22.4495 1.55073 22.5446 1.66759 22.5446H8.86852C8.98538 22.5446 9.08054 22.4494 9.08054 22.3325V15.1317C9.08054 15.0147 8.98538 14.9197 8.86852 14.9197H1.66759V14.9196Z'
				fill={color}
			/>
		</svg>
	);
};

export default Dashboard;
