import * as yup from 'yup';
import { formatNumberToCurrency } from '../helpers';
import { PHONE_REX, PASSWORD } from '../helpers/regular-expression';

const ValidationSchema = {
	OtpVerificationRequest: yup.object().shape({
		email: yup.string().email().required('Specify email'),
	}),
	Login: yup.object().shape({
		email: yup.string().email().required('Specify email address'),
		password: yup.string().required('Specify password'),
	}),
	ValidatePin: yup.object().shape({
		pin: yup.string().required('Specify pin'),
	}),
	ValidateOTP: yup.object().shape({
		otp: yup.string().required('Enter OTP'),
	}),
	VerifyEmail: yup.object().shape({
		code: yup
			.string()
			.required('Specify code sent to your email')
			.matches(/^[0-9]+$/, 'Must be only digits')
			.min(6, 'Code must be exactly 6 digits')
			.max(6, 'Code must be exactly 6 digits'),
	}),
	Register: yup.object().shape({
		email: yup.string().email().required('Specify email address'),
		password: yup
			.string()
			.required('Specify your password')
			.matches(
				PASSWORD,
				'Password must contain at least 8 characters, one uppercase, one number and a special character(!@#$%^&*)'
			),
		confirmPassword: yup
			.string()
			.oneOf([yup.ref('password')], 'Password do not match')
			.required('Confirm password is required'),
		username: yup
			.string()
			.matches(/^\S*$/, 'User name must not contain space')
			.required('Specify username'),
		phone: yup
			.string()
			.required('Specify phone number')
			.matches(PHONE_REX, 'Invalid phone number'),
		referral_code: yup.string(),
		isAgreed: yup
			.bool()
			.required('Checkbox must be checked')
			.oneOf([true], 'Checkbox must be checked'),
	}),
	SelectEPin: yup.object().shape({
		epinType: yup.string().required('Select E-pin type'),
	}),
	BvnVerification: yup.object().shape({
		bvn: yup.number().required('Enter BVN'),
	}),
	IEPin: yup.object().shape({
		epinType: yup
			.string()
			.notOneOf(['Select E-pin type'], 'Select E-pin type')
			.required('Select E-pin type'),
		code: yup
			.number()
			.min(6, 'Code must be 6 digits')
			.required('Specify E-pin code'),
	}),
	ConvertAirtimeToCash: yup.object().shape({
		network: yup
			.string()
			.notOneOf(['Select network provider'], 'Select network provider')
			.required('Select network provider'),
		phone_number: yup
			.string()
			.required('Specify phone number')
			.matches(PHONE_REX, 'Invalid phone number'),
		amount: yup
			.number()
			.positive('Amount must be positive')
			.moreThan(
				499,
				`Amount must be more than ${formatNumberToCurrency('500')}`
			)
			.lessThan(
				50001,
				`Amount must be less than ${formatNumberToCurrency('5000')}`
			)
			.required('Specify amount'),
		noOfRetries: yup.number().required('Select number of share'),
	}),
	CablePayment: yup.object().shape({
		service_type: yup
			.string()
			.notOneOf(['Select TV service'], 'Select TV service')
			.required('Select TV service'),
		smartcard_number: yup
			.number()
			.required('Specify smart card')
			.positive('Smart card must be only digits'),
		monthsPaidFor: yup
			.string()
			.notOneOf(['Select number of month'], 'Select number of month')
			.required('Select number of month'),
		product_code: yup
			.string()
			.notOneOf(['Select subscription plan'], 'Select subscription plan')
			.required('Select subscription plan'),
	}),
	EducationBill: yup.object().shape({
		service_type: yup
			.string()
			.notOneOf(['Select Provider'], 'Select Provider')
			.required('Select Provider'),
		price: yup
			.string()
			.notOneOf(['Select Exam Bundle'], 'Select Exam Bundle')
			.required('Select Exam Bundle'),
		numberOfPins: yup.number().positive().required('Enter number of pin'),
	}),
	PowerBill: yup.object().shape({
		service_type: yup
			.string()
			.notOneOf(['Select electricity provider'], 'Select electricity provider')
			.required('Select electricity provider'),
		meter_number: yup.number().positive().required('Enter meter number'),
		amount: yup.number().positive().required('Enter amount'),
	}),
	InternetSubscription: yup.object().shape({
		service_type: yup
			.string()
			.notOneOf(['Select service provider'], 'Select service provider')
			.required('Select service provider'),
		numberOfPins: yup
			.number()
			.positive('Number of pin must be positive')
			.required('Enter number of pin'),
		price: yup
			.string()
			.notOneOf(['Select internet plan'], 'Select internet plan')
			.required('Select internet plan'),
	}),
	DataSubscription: yup.object().shape({
		number: yup
			.string()
			.required('Specify phone number')
			.matches(PHONE_REX, 'Invalid phone number'),
		plan: yup
			.string()
			.notOneOf(['Select data plan'], 'Select data plan')
			.required('Select data plan'),
		network: yup
			.string()
			.notOneOf(['Select network provider'], 'Select network provider')
			.required('Select network provider'),
		type: yup
			.string()
			.notOneOf(['Select data type'], 'Select data type')
			.required('Select data type'),
	}),
	ProfileDetail: yup.object().shape({
		phone: yup
			.string()
			.required('Specify phone number')
			.matches(PHONE_REX, 'Invalid phone number'),
		email: yup.string().email().required('Enter email'),
		username: yup.string().email().required('Enter user name'),
		firstname: yup.string().required('Enter first name'),
		lastname: yup.string().required('Enter last name'),
	}),
	GenerateAirtime: yup.object().shape({
		network: yup
			.string()
			.notOneOf(['Select service provider'], 'Select service provider')
			.required('Select service provider'),
		amount: yup
			.number()
			.integer()
			.positive('Amount must be positive number')
			.required('Enter amount'),
	}),
	GenerateElectricityEPin: yup.object().shape({
		service_type: yup
			.string()
			.notOneOf(['Select electricity provider'], 'Select electricity provider')
			.required('Select electricity provider'),
		amount: yup
			.number()
			.positive('Amount must be positive number')
			.required('Enter amount'),
	}),
	GenerateData: yup.object().shape({
		network: yup
			.string()
			.notOneOf(['Select network provider'], 'Select network provider')
			.required('Select network provider'),
		plan: yup
			.string()
			.notOneOf(['Select data plan'], 'Select data plan')
			.required('Select data plan'),
	}),
	GenerateCablePin: yup.object().shape({
		service_type: yup
			.string()
			.notOneOf(['Select tv provider'], 'Select tv provider')
			.required('Select tv provider'),
		product_code: yup
			.string()
			.notOneOf(['Select subscription plan'], 'Select subscription plan')
			.required('Select subscription plan'),
		monthsPaidFor: yup
			.string()
			.notOneOf(
				['Select subscription duration'],
				'Select subscription duration'
			)
			.required('Select subscription duration'),
	}),
	GenerateEducationEPin: yup.object().shape({
		service_type: yup
			.string()
			.notOneOf(['Select Exam'], 'Select Exam')
			.required('Select Exam'),
		price: yup
			.string()
			.notOneOf(['Select Exam Bundle'], 'Select Exam Bundle')
			.required('Select Exam Bundle'),
		numberOfPins: yup.number().positive().required('Enter number of pin'),
	}),
};

export default ValidationSchema;
