import React from 'react';

type Props = {
	color?: string;
	height?: number;
	width?: number;
};

const FundWalletIcon = ({ width, height, color }: Props) => {
	return (
		<svg
			width={width || '16'}
			height={height || '17'}
			viewBox='0 0 16 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_2604_6200)'>
				<path
					d='M13.5309 8.78965L8.31965 15.8581C8.16084 16.0735 7.83887 16.0735 7.68009 15.8581L2.46885 8.78965C2.27547 8.52733 2.46275 8.15662 2.78859 8.15662H13.2111C13.537 8.15662 13.7243 8.52733 13.5309 8.78965Z'
					fill={color || '#DCE3E7'}
				/>
				<path
					d='M13.9943 8.15814C13.8442 7.86092 13.544 7.67624 13.211 7.67624H5.46989V1.56359C5.46989 1.50681 5.51604 1.46065 5.57283 1.46065H10.4267C10.4835 1.46065 10.5296 1.50681 10.5296 1.56359V5.10434C10.5296 5.16112 10.4835 5.20727 10.4267 5.20727H7.45539C7.1901 5.20727 6.97504 5.4223 6.97504 5.68761C6.97504 5.9529 7.19007 6.16796 7.45539 6.16796H10.4267C11.0131 6.16796 11.4903 5.6908 11.4903 5.10437V1.56359C11.4903 0.977124 11.0132 0.5 10.4267 0.5H5.57283C4.98639 0.5 4.50923 0.977124 4.50923 1.56359V7.67624H2.78849C2.45545 7.67624 2.1553 7.86086 2.00514 8.15814C1.85499 8.45542 1.88449 8.80658 2.08208 9.07467L7.29335 16.1432C7.45807 16.3666 7.72214 16.5 7.99973 16.5C8.27732 16.5 8.54142 16.3666 8.7061 16.1432L13.9174 9.07461C14.115 8.80655 14.1445 8.45539 13.9943 8.15814ZM7.99976 15.4824L2.95289 8.63692H13.0466L7.99976 15.4824Z'
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id='clip0_2604_6200'>
					<rect
						width='16'
						height='16'
						fill='white'
						transform='translate(0 0.5)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default FundWalletIcon;
