import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { setTransactionBreakdown } from 'store/transaction';

const useClearTransactionBreakdown = () => {
	const dispatch = useAppDispatch();
	const { pathname, search } = useLocation();
	useEffect(
		() => {
			dispatch(setTransactionBreakdown(null));
		},
		//eslint-disable-next-line
		[pathname, search]
	);
};

export default useClearTransactionBreakdown;
