import React from 'react';
import {
	Table,
	Box,
	Typography,
	useTheme,
	TableBody,
	TableHead,
	TableRow,
	styled,
	TableCell,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import CopyToClipboard from 'react-copy-to-clipboard';
import { SquareRounded, ContentCopyRounded } from '@mui/icons-material';
import {
	LIGHT_GRAY,
	SUCCESS_COLOR,
	DANGER_COLOR,
	formatNumberToCurrency,
	ErrorBoundaryGuard,
	checkAmount,
	ITransaction,
} from 'utilities';
import { grey } from '@mui/material/colors';
import Loader from '../loader/table-loader';
import TableEmpty from '../empty/table-empty';
import { useAlert } from 'hooks';

type Props = {
	data: ITransaction[] | null | undefined;
	tableTitle?: string;
	isLoading?: boolean;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundImage: `url(${require('../../assets/images/bgImage.png')})`,
		backgroundColor: LIGHT_GRAY,
		backgroundSize: 'cover',
		backgroundPosition: 'top-left',
		fontSize: '14px',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: '14px',
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(even)': {
		backgroundColor: LIGHT_GRAY,
	},
	'&:nth-of-type(old)': {
		backgroundColor: grey[50],
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

const EPinTransactionTable = ({ data, tableTitle, isLoading }: Props) => {
	const alert = useAlert();
	const theme = useTheme();
	const styles = useStyles(theme);

	return (
		<Box sx={{ overflow: 'auto' }}>
			<Box
				style={styles.dFlex}
				sx={{ justifyContent: 'space-between', marginBottom: theme.spacing(3) }}
			>
				<Typography style={styles.title}>{tableTitle}</Typography>
				<Box style={styles.dFlex}>
					<Box style={styles.dFlex}>
						<SquareRounded sx={{ color: SUCCESS_COLOR }} />= Unused E-pin
					</Box>
					<Box sx={{ fontSize: '24px' }}>/</Box>
					<Box style={styles.dFlex}>
						<SquareRounded sx={{ color: DANGER_COLOR }} />= Used E-pin
					</Box>
				</Box>
			</Box>

			<Table sx={{ overflow: 'auto' }} stickyHeader>
				<TableHead
					sx={{
						'& tr': {
							backgroundColor: LIGHT_GRAY,
						},
					}}
				>
					<TableRow>
						<StyledTableCell>Transaction ID</StyledTableCell>
						<StyledTableCell>Service</StyledTableCell>
						<StyledTableCell>Amount</StyledTableCell>
						<StyledTableCell>E-pin code</StyledTableCell>
						<StyledTableCell>Action</StyledTableCell>
					</TableRow>
				</TableHead>
				<ErrorBoundaryGuard>
					<TableBody>
						{isLoading ? (
							<Loader colSpan={5} />
						) : (
							data && (
								<>
									{data.length > 0 ? (
										data.map((epin: ITransaction) => (
											<StyledTableRow key={epin.id}>
												<StyledTableCell>
													{typeof epin.transaction === 'object' &&
														epin.transaction.reference}
												</StyledTableCell>
												<StyledTableCell>
													{epin?.pin_data && typeof epin?.pin_data === 'object'
														? epin.pin_data.service_type
														: epin.service}
												</StyledTableCell>
												<StyledTableCell>
													{formatNumberToCurrency(checkAmount(epin.amount))}
												</StyledTableCell>
												<StyledTableCell
													sx={{
														color: epin.used ? DANGER_COLOR : SUCCESS_COLOR,
													}}
												>
													{epin.pin}
												</StyledTableCell>
												<StyledTableCell>
													<CopyToClipboard
														text={`${epin.pin}`}
														onCopy={() =>
															alert({
																message: `${epin.pin} copied successfully`,
																type: 'info',
															})
														}
													>
														<Box
															sx={{ cursor: 'pointer', userSelect: 'none' }}
															style={styles.dFlex}
														>
															<ContentCopyRounded fontSize={'small'} />
															<Typography
																variant={'body2'}
																sx={{ fontWeight: '600', userSelect: 'none' }}
															>
																Copy
															</Typography>
														</Box>
													</CopyToClipboard>
												</StyledTableCell>
											</StyledTableRow>
										))
									) : (
										<TableEmpty colSpan={5} text={`No ${tableTitle}`} />
									)}
								</>
							)
						)}
					</TableBody>
				</ErrorBoundaryGuard>
			</Table>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	filterWrapper: {
		display: 'flex',
		gap: '10px',
		alignItems: 'center',
	},
	title: {
		fontWeight: '600',
	},
	dFlex: {
		display: 'flex',
		alignItems: 'center',
		gap: '5px',
	},
});

export default EPinTransactionTable;
