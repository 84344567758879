import React, {
	useEffect,
	useImperativeHandle,
	forwardRef,
	useState,
} from 'react';
import { useMutation } from 'react-query';
import { Box, styled, Typography } from '@mui/material';
import moment from 'moment';
import {
	ITransaction,
	formatNumberToCurrency,
	cleanString,
	TICKET_TYPE,
	PRIORITY,
	checkAmount,
	extractExactTransactionService,
	extractTransactionType,
	TRANSACTION_SERVICE,
	Amount,
} from 'utilities';
import TransactionItem from './transaction-item';
import {
	useVerifyCoupon,
	useSearchDispute,
	useHandleError,
	useAlert,
} from 'hooks';
import Button from 'components/button';
import { createTicket } from 'api';
import CopyItemWithSerialNumber from 'components/copy-items/copy-item-with-serial-number';
import CopyItem from 'components/copy-items';
import ModalLayout from 'components/layout/modal-layout';
import { grey } from '@mui/material/colors';
import PinDetails from './pin-details';

interface Props {
	transactionType?: string;
}

const TransactionDetails = (props: Props, ref: any) => {
	const { verifyCoupon } = useVerifyCoupon();
	const alert = useAlert();
	const handleError = useHandleError();
	const { dispute, searchDispute, isSearchingDispute } = useSearchDispute();
	const [isDisplay, setDisplay] = useState<boolean>(false);
	const [transaction, setTransaction] = useState<null | ITransaction>(null);

	const closeModal = () => {
		setTransaction(null);
		setDisplay(false);
	};

	useImperativeHandle(
		ref,
		() => ({
			display: (transaction: ITransaction) => {
				setTransaction(transaction);
				setDisplay(true);
			},
			close: closeModal,
		}),
		[]
	);

	useEffect(
		() => {
			if (transaction) {
				searchDispute(transaction.id);
				if (transaction.transaction && transaction.transaction.discount_code) {
					const code = transaction.transaction.discount_code;
					verifyCoupon(code.toString());
				}
			}

			// () => clearSearch();
		},
		// eslint-disable-next-line
		[]
	);

	const { isLoading, mutate } = useMutation(createTicket, {
		onSettled: (data, error) => {
			if (error) {
				const response = handleError({
					error,
				});
				if (response?.message)
					alert({ message: response.message, type: 'error' });
			}

			if (data && data.success) {
				alert({ message: 'Dispute created successfully', type: 'success' });
				searchDispute(transaction?.id as string);
			}
		},
	});

	const handleCreateTicket = () => {
		const service = extractExactTransactionService(transaction as ITransaction);
		const data = {
			type: TICKET_TYPE.DISPUTE,
			priority: PRIORITY.MEDIUM,
			subject: service,
			related_transaction: transaction?.id,
			related_transaction_type: service,
			message: 'Raised dispute',
		};
		mutate(data);
	};

	console.log(transaction);

	if (transaction && isDisplay) {
		const transactionsServices =
			transaction?.transactions &&
			Array.isArray(transaction?.transactions) &&
			transaction?.transactions[0]?.service;
		const service =
			transactionsServices ||
			extractExactTransactionService(transaction as ITransaction);
		const type = extractTransactionType(transaction as ITransaction);

		const isAutoConvert =
			TRANSACTION_SERVICE.AIRTIME_AUTO_CONVERSION === props.transactionType;

		const autoAirtimeConvert = isAutoConvert && (
			<>
				<TransactionItem
					label={'Total Amount'}
					value={formatNumberToCurrency(
						typeof transaction?.totalAmount === 'object'
							? transaction.totalAmount.$numberDecimal
							: ''
					)}
				/>
				<TransactionItem
					label={'Total Return Amount'}
					value={formatNumberToCurrency(
						typeof transaction?.totalReturnAmount === 'object'
							? transaction.totalReturnAmount.$numberDecimal
							: ''
					)}
				/>
				{transaction?.transactions && (
					<Box>
						<Typography
							sx={{
								fontWeight: 'bold',
								marginBottom: '8px',
							}}
						>
							Transaction Breakdown
						</Typography>
						<Box
							sx={{
								display: 'grid',
								gap: '8px',
							}}
						>
							{Array.isArray(transaction?.transactions) &&
								transaction?.transactions.map((value) => (
									<Box
										sx={{
											border: `1px solid ${grey['300']}`,
											padding: '8px 15px',
											borderRadius: '8px',
											display: 'grid',
											gap: '8px',
										}}
										key={value.id}
									>
										<Typography>Reference: {value.reference}</Typography>
										<Box
											sx={{
												display: 'grid',
												gridTemplateColumns: 'repeat(2, 1fr)',
												alignItems: 'center',
												justifyContent: 'space-between',
												gap: '8px',
											}}
										>
											<Typography>
												Amount:{' '}
												{formatNumberToCurrency(
													checkAmount(value?.amount as string | Amount)
												)}
											</Typography>
											<Typography>
												Return Amount:{' '}
												{formatNumberToCurrency(
													checkAmount(value?.returnAmount as string | Amount)
												)}
											</Typography>
											<Typography>
												Date: {moment(value.createdAt).format('l')}
											</Typography>
											<Typography>
												Time: {moment(value.createdAt).format('LT')}
											
											</Typography>
										</Box>
										<Typography>Status: {value.status}</Typography>

										<Typography
											sx={{
												span: {
													fontSize: '12px',
												},
											}}
										>
											Network Response: <br />
											<span>{value.networkResponse}</span>
										</Typography>
									</Box>
								))}
						</Box>
					</Box>
				)}
			</>
		);

		return (
			<ModalLayout closeModal={closeModal} hasCloseButton>
				<Box sx={{ width: '100%' }}>
					<Title variant={'h6'}>Transaction Details</Title>
					<Container>
						{service && <TransactionItem label={'Service'} value={service} />}
						{transaction.reference ? (
							<TransactionItem
								label={'Reference'}
								value={transaction.reference}
							/>
						) : (
							transaction.id && (
								<TransactionItem
									label={'Transaction ID'}
									value={transaction.id}
								/>
							)
						)}
						{transaction?.operator && (
							<TransactionItem
								label={'Operator'}
								value={transaction.operator}
							/>
						)}
						{transaction?.product && (
							<TransactionItem label={'Product'} value={transaction.product} />
						)}
						{type && (
							<TransactionItem label={'Type'} value={type.replace(/_/g, ' ')} />
						)}

						{transaction.summary && (
							<TransactionItem label={'Reason'} value={transaction.summary} />
						)}

						{transaction.card_number && (
							<TransactionItem
								label={'Card Number'}
								value={transaction.card_number}
							/>
						)}

						{transaction.network && typeof transaction.network === 'object' && (
							<TransactionItem
								label={'Network'}
								value={transaction.network.name as string}
							/>
						)}

						{transaction.dataType &&
							typeof transaction.dataType === 'object' && (
								<TransactionItem
									label={'Data Type'}
									value={transaction.dataType.name as string}
								/>
							)}

						{transaction.plan && typeof transaction.plan === 'object' && (
							<TransactionItem
								label={'Data Plan'}
								value={transaction.plan.name as string}
							/>
						)}

						{transaction.reference ? (
							<TransactionItem
								label={'Reference'}
								value={transaction.reference}
							/>
						) : (
							transaction.id && (
								<TransactionItem
									label={'Transaction ID'}
									value={transaction.id}
								/>
							)
						)}
						{transaction.pins && transaction.pins.length > 0 && (
							<Box>
								<Typography
									sx={{ fontWeight: 'bold', marginBottom: '8px' }}
									variant={'body1'}
								>
									Pin(s):
								</Typography>
								<Box
									sx={{
										display: 'grid',
										gap: '15px',
									}}
								>
									{transaction.pins.map((pin, key) => (
										<CopyItemWithSerialNumber key={key} pin={pin} />
									))}
								</Box>
							</Box>
						)}

						{transaction.electricity_token && (
							<Box>
								<Typography
									sx={{ fontWeight: 'bold', marginBottom: '8px' }}
									variant={'body1'}
								>
									Electricity Token:
								</Typography>
								<CopyItem
									text={transaction.electricity_token.token}
									textDescription={`Electricity unit of ${transaction.electricity_token.unit}`}
								/>
							</Box>
						)}

						{transaction.withdrawalChannel && (
							<TransactionItem
								label={'Channel'}
								value={transaction.withdrawalChannel}
							/>
						)}
						{transaction.accountNumber && (
							<TransactionItem
								label={'Account Number'}
								value={transaction.accountNumber}
							/>
						)}
						{transaction.paymentGateway && (
							<TransactionItem
								label={'Payment Gateway'}
								value={transaction.paymentGateway}
							/>
						)}

						{transaction.pin_data && transaction.pin_data.service_type && (
							<TransactionItem
								label={'Service Provider'}
								value={cleanString(transaction.pin_data.service_type as string)}
							/>
						)}
						{transaction.pin && typeof transaction.pin === 'string' && (
							<TransactionItem label={'Pin'} value={transaction.pin} hasCopy />
						)}
						{transaction.pin && typeof transaction.pin === 'object' && (
							<PinDetails pin={transaction.pin} />
						)}
						{transaction.amount && (
							<TransactionItem
								label={'Amount'}
								value={formatNumberToCurrency(checkAmount(transaction.amount))}
							/>
						)}
						{transaction.return_amount && (
							<TransactionItem
								label={'Return Amount'}
								value={formatNumberToCurrency(
									checkAmount(`${transaction?.return_amount}`)
								)}
							/>
						)}

						{transaction.number && (
							<TransactionItem label={'Phone'} value={transaction.number} />
						)}
						{transaction.phone_number && (
							<TransactionItem
								label={'Phone'}
								value={transaction.phone_number}
							/>
						)}

						{transaction.sentTo && (
							<TransactionItem label={'Sent To'} value={transaction.sentTo} />
						)}
						{transaction.createdAt && (
							<TransactionItem
								label={'Date'}
								value={moment(transaction.createdAt).format('l')}
							/>
						)}
						{transaction.createdAt && (
							<TransactionItem
								label={'Time'}
								value={moment(transaction.createdAt).format('LT')}
							/>
						)}
						{transaction.status && (
							<TransactionItem label={'Status'} value={transaction.status} />
						)}
						{autoAirtimeConvert}

						{/*
						 ***** Display dispute button if dispute doesn't exist *****
						 */}
						{isSearchingDispute
							? null
							: typeof dispute === 'undefined' && (
									<DisputeButton
										loading={isLoading}
										onClick={handleCreateTicket}
										size={'large'}
									>
										Dispute
									</DisputeButton>
							  )}
					</Container>
				</Box>
			</ModalLayout>
		);
	}

	return null;
};

const Container = styled(Box)(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: '1fr',
	gap: theme.spacing(3),
	width: '100%',
}));

const Title = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold',
	marginBottom: theme.spacing(3),
}));

const DisputeButton = styled(Button)(({ theme }) => ({
	backgroundColor: `${theme.palette.secondary.main} !important`,
	color: 'white',
}));

export default forwardRef(TransactionDetails);
