import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import {
	Layout,
	CircularProgress,
	LoadPowerPinForm,
	Seo,
	BackButton,
} from 'components';
import {
	formatNumberToCurrency,
	API_ENDPOINTS,
	ErrorBoundaryGuard,
	ENDPOINT_SUBPATH,
	IProvider,
} from 'utilities';
import { useAppSelector } from 'store/hooks';
import { useHandleError, useAlert } from 'hooks';
import { billProviders } from 'api';

const LoadPower = () => {
	const handleError = useHandleError();
	const alert = useAlert();
	const { search } = useLocation();
	const { token } = useAppSelector((store) => store.auth);
	const query = queryString.parse(search);

	const [electricityProvider, setElectricityProvider] =
		useState<IProvider | null>(null);

	const [isLoading, setLoading] = useState<boolean>(true);

	/*
	 *Load Electricity Providers
	 */
	useQuery(
		'Load Electricity providers',
		() =>
			billProviders(
				`${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.ElectricityProvider}`
			),
		{
			enabled: !!(token && query && query.service_type),
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				setLoading(false);
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						alert({ message: response.message, type: 'error' });
					}
				}

				if (data && data.success) {
					const electricityProvider = data.payload.find(
						(value: IProvider) => value.type === query.service_type
					);
					if (electricityProvider) setElectricityProvider(electricityProvider);
				}
			},
		}
	);

	return (
		<Layout>
			<Seo title={'Load Power E-Pin'} />
			<ErrorBoundaryGuard>
				{isLoading ? (
					<CircularProgress />
				) : (
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: '1fr',
							gap: '15px',
							maxWidth: '600px',
						}}
					>
						<BackButton name={'E-Pin'} sx={{ marginBottom: ['1.5rem'] }} />
						<Typography sx={{ fontWeight: '600' }} variant={'h5'}>
							Power E-pin
						</Typography>
						<Box>
							<Typography>
								The e-pin you entered gives{' '}
								{query.amount && formatNumberToCurrency(query.amount as string)}{' '}
								worth of{' '}
								<Box component={'span'} sx={{ fontWeight: '600' }}>
									{electricityProvider?.name}.
								</Box>{' '}
								Enter your meter number to load pin.
							</Typography>
						</Box>
						<LoadPowerPinForm
							serviceType={query.service_type as string}
							pin={query.pin as string}
						/>
					</Box>
				)}
			</ErrorBoundaryGuard>
		</Layout>
	);
};

export default LoadPower;
