import React, { useState, useEffect } from 'react';
import {
	Box,
	MenuItem,
	Typography,
	useTheme,
	InputAdornment,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { grey } from '@mui/material/colors';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import TextInput from '../form-components/text-input';
import Button from '../button';
import Select from '../form-components/select';
import {
	QUERY_KEYS,
	API_ENDPOINTS,
	IBill,
	IProvider,
	TICKET_TYPE,
	formatNumberToCurrency,
	ValidationSchema,
	LINKS,
	ENDPOINT_SUBPATH,
	Bundle,
	calculateFinalAmount,
	Coupon,
	EducationPin,
} from 'utilities';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
	useHandleError,
	useVerifyCoupon,
	useAlert,
	useModalAlert,
	useVerifyPin,
	useCheckKycLevelTwo,
} from 'hooks';
import Loader from '../loader';
import TextHolder from '../partials/custom-text-input-holder';
import { billBundles, billPayment, billProviders } from 'api';
import TextPlaceholder from '../partials/custom-text-input-holder';
import { setTransactionBreakdown } from 'store/transaction';
import CopyItemWithSerialNumber from 'components/copy-items/copy-item-with-serial-number';

type Props = {
	isGenerateBill?: boolean;
	enableRequest?: boolean;
};

const SELECT_SERVICE_PROVIDER = 'Select Provider';
const SELECT_EXAM_BUNDLE = 'Select Exam Bundle';

interface IPinsProps {
	pins: EducationPin[];
}

const Pins: React.FC<IPinsProps> = ({ pins }) => {
	return (
		<Box>
			<Typography sx={{ marginBottom: '10px' }} variant={'body1'}>
				Education Pin(s)
			</Typography>
			<Box
				sx={{
					display: 'grid',
					gap: '10px',
				}}
			>
				{pins.map((pin, index) => (
					<CopyItemWithSerialNumber key={index} pin={pin} />
				))}
			</Box>
		</Box>
	);
};

const EductaionBillForm = ({ isGenerateBill, enableRequest }: Props) => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const verifyPin = useVerifyPin();
	const styles = useStyles(theme);
	const checkKycLevel = useCheckKycLevelTwo();
	const alert = useAlert();
	const modal = useModalAlert();
	const handleError = useHandleError();
	const { token } = useAppSelector((store) => store.auth);
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { search } = useLocation();

	const { verifyCoupon, isVerifyingCoupon, coupon, clearCoupon } =
		useVerifyCoupon();

	const [isLoadingEductionBundles, setLoadingEducationBundles] =
		useState<boolean>(false);
	const [dataDataEducationBundles, setDataEducationBundles] = useState<
		Bundle[] | null
	>(null);

	const clearState = () => {
		resetForm();
		setDataEducationBundles(null);
		clearCoupon();
		modal(null);
	};

	/* 
		Update Coupon in Transaction Breakdown
	*/
	useEffect(
		() => {
			if (coupon) {
				dispatch(setTransactionBreakdown({ coupon }));
			}
			if (search) {
				clearState();
			}
		},
		//eslint-disable-next-line
		[coupon, search]
	);

	// Education State
	const initialValues: IBill = {
		service_type: SELECT_SERVICE_PROVIDER,
		price: SELECT_EXAM_BUNDLE,
		numberOfPins: '',
		discount_code: '',
	};

	// Education Bill Payment
	const { isLoading: isMakingPayment, mutate: mutateBillPayment } = useMutation(
		billPayment,
		{
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						modal({
							message: res.message,
							title: 'Education Payment Error',
							primaryButtonText: 'Contact Support',
							// secondaryButtonText: 'Close',
							type: 'error',
							hasCloseButton: true,
							// onClickSecondaryButton: () => modal(null),
							onClickPrimaryButton: () => {
								modal(null);
								navigate(
									`${LINKS.CreateSupportTicket}?_type=${TICKET_TYPE.COMPLAINT}`
								);
							},
						});
					}
				}

				if (data && data.success) {
					queryClient.invalidateQueries(QUERY_KEYS.UserWallet);
					queryClient.invalidateQueries([QUERY_KEYS.RecentTransactions]);
					queryClient.invalidateQueries(QUERY_KEYS.Transactions);

					const amount = data.payload.amount;
					const pins = data.payload.pins;
					const service = data.payload.name;
					// pins: data.payload.pins,
					modal({
						message: `Payment of ${service} for ${formatNumberToCurrency(
							amount
						)} is successful!`,
						title: 'Education Bill Purchase',
						primaryButtonText: 'Buy again',
						secondaryButtonText: 'Close',
						type: 'success',
						children: <Pins pins={pins} />,
						onClickPrimaryButton: () => {
							clearState();
						},
						onClickSecondaryButton: () => {
							clearState();
						},
					});
				}
			},
		}
	);

	const {
		handleSubmit,
		errors,
		touched,
		setFieldValue,
		values,
		handleChange,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema: ValidationSchema.EducationBill,
		onSubmit: (values) => {
			let data = {
				price: values.price,
				numberOfPins: values.numberOfPins,
				service_type: values.service_type,
			} as typeof initialValues;

			let amount = Number(values.price) * Number(values.numberOfPins);

			if (values.discount_code) {
				if (!coupon)
					return alert({ message: 'Verify discount code', type: 'info' });

				if (coupon && coupon.code !== values.discount_code)
					return alert({
						message: `Verified coupon ${coupon.code} doesn't match provided discount code ${values.discount_code} `,
						type: 'info',
					});
				amount = calculateFinalAmount({
					coupon: coupon as Coupon,
					amount: Number(amount),
				});
				data.discount_code = values.discount_code;
			}

			const url = `${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.EducationPayment}`;

			if (checkKycLevel()) {
				verifyPin({
					title: 'Education Bill Purchase',
					message: `Verify pin to confirm purchase of ${
						values.service_type
					} education bill for ${formatNumberToCurrency(Number(amount))}`,
					callback: () => {
						verifyPin(null);
						mutateBillPayment({
							data,
							url,
						});
					},
				});
			}
		},
	});

	const { service_type, numberOfPins, discount_code, price } = values;

	// Load Education Provider
	const {
		isLoading: isLoadingEducationProviders,
		data: dataEducationProviders,
	} = useQuery(
		QUERY_KEYS.EducationProviders,
		() =>
			billProviders(
				`${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.EducationProviders}`
			),
		{
			enabled: !!(token && enableRequest),
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						alert({ message: res.message, type: 'error' });
					}
				}
			},
		}
	);

	const loadEducationBundles = async (provider: string) => {
		const url = `${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.EducationProviders}`;
		setLoadingEducationBundles(true);
		try {
			const data = await billBundles({
				url,
				params: {
					provider,
				},
			});

			if (data && data.success) {
				setDataEducationBundles(data.payload);
			}
		} catch (error) {}
		setLoadingEducationBundles(false);
	};

	const handleSelectEducationProvider = (value: string) => {
		setFieldValue('service_type', value);
		loadEducationBundles(value);
	};

	const handleSelectBundle = (value: string) => {
		setFieldValue('price', value, true);

		dispatch(setTransactionBreakdown({ serviceCost: Number(value) }));
	};

	return (
		<>
			{isVerifyingCoupon && <Loader />}

			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Provider
					</Typography>
					<Select
						fullWidth
						error={touched.service_type && Boolean(errors.service_type)}
						helpertext={touched.service_type && errors.service_type}
						value={service_type}
						onChange={(e) => {
							const value = e.target.value;
							handleSelectEducationProvider(value as string);
						}}
					>
						<MenuItem disabled value={SELECT_SERVICE_PROVIDER}>
							{isLoadingEducationProviders
								? 'Loading...'
								: dataEducationProviders &&
								  dataEducationProviders.payload.length === 0
								? 'Available exam provider'
								: SELECT_SERVICE_PROVIDER}
						</MenuItem>
						{dataEducationProviders &&
							dataEducationProviders.payload.length > 0 &&
							dataEducationProviders.payload.map(
								(provider: IProvider, key: number) => (
									<MenuItem value={provider.service_type} key={key}>
										{provider.name}
									</MenuItem>
								)
							)}
					</Select>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Exam bundle
					</Typography>
					{service_type === SELECT_SERVICE_PROVIDER ? (
						<TextHolder text={'Select exam bundle'} isSelectable />
					) : (
						<Select
							fullWidth
							error={touched.price && Boolean(errors.price)}
							helpertext={touched.price && errors.price}
							value={price}
							onChange={(e) => handleSelectBundle(e.target.value as string)}
						>
							<MenuItem disabled value={SELECT_EXAM_BUNDLE}>
								{isLoadingEductionBundles
									? 'Loading...'
									: dataDataEducationBundles &&
									  dataDataEducationBundles.length === 0
									? `No avialable ${service_type} bundle`
									: 'Select exam bundle'}
							</MenuItem>
							{dataDataEducationBundles &&
								dataDataEducationBundles.length > 0 &&
								dataDataEducationBundles.map((bundle: Bundle, key: number) => (
									<MenuItem value={`${bundle.amount}`} key={key}>
										{`${bundle.description} - ${formatNumberToCurrency(
											bundle.amount
										)}`}
									</MenuItem>
								))}
						</Select>
					)}
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Number of pins
					</Typography>
					{price === SELECT_EXAM_BUNDLE ? (
						<TextHolder text={'Enter number of pins'} />
					) : (
						<TextInput
							error={touched.numberOfPins && Boolean(errors.numberOfPins)}
							helperText={touched.numberOfPins && errors.numberOfPins}
							type={'number'}
							fullWidth
							placeholder={'Enter number of pins'}
							value={numberOfPins}
							onChange={handleChange('numberOfPins')}
						/>
					)}
				</Box>

				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Discount code [optional]
					</Typography>
					{price ? (
						<TextInput
							disabled={price === SELECT_EXAM_BUNDLE}
							fullWidth
							error={touched.discount_code && Boolean(errors.discount_code)}
							helperText={touched.discount_code && errors.discount_code}
							placeholder={'Type in discount code here'}
							value={discount_code}
							onChange={handleChange('discount_code')}
							InputProps={{
								endAdornment: (
									<InputAdornment position='start'>
										<Button
											style={styles.applyBtn}
											onClick={() => {
												verifyCoupon(discount_code as string);
											}}
										>
											Apply
										</Button>
									</InputAdornment>
								),
							}}
						/>
					) : (
						<TextPlaceholder text={'Type in discount code'} />
					)}
				</Box>

				<Button
					loading={isMakingPayment}
					style={styles.btn}
					size={'large'}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					{isGenerateBill ? 'Generate e-Pin' : 'Purchase'}
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default EductaionBillForm;
