import { Amount, Coupon, ITransaction, IUser } from '../types';
import { COUPON_TYPE } from '../constant';
export { default as ScrollToTop } from './scroll-to-top';
export * from './regular-expression';

export const calculatePercentageAmount = ({
	rate,
	amount,
}: {
	rate: string | number;
	amount: string | number;
}) => {
	const parseRate = typeof rate === 'string' ? parseInt(rate) : rate;
	const parseAmount = typeof amount === 'string' ? parseInt(amount) : amount;

	return parseAmount - (parseAmount * parseRate) / 100;
};

export const getActiveLink = ({
	name,
	currentPath: pathname,
}: {
	name: string;
	currentPath: string;
}) => {
	const actualPathName = pathname.split('/')[1];

	const url_path = window && window.location.pathname;
	const regExp = new RegExp(name, 'ig');

	const match = url_path.match(regExp);

	if (actualPathName === '' && name === 'dashboard') {
		return {
			path: 'home',
			isActive: true,
		};
	}
	if (match && Array.isArray(match) && match[0] === actualPathName) {
		return {
			isActive: true,
		};
	} else {
		return {
			path: null,
			isActive: false && pathname.split('/')[1],
		};
	}
};

export const hideContent = (value: string | number) => {
	const convertToString = typeof value === 'number' ? value?.toString() : value;
	let hiddenValue = '';
	for (let i = 1; i <= convertToString.length; i++) {
		hiddenValue += '*';
	}

	return hiddenValue;
};

export const generateRandomNumber = ({
	length,
	suggestion,
}: {
	length: number;
	suggestion?: string;
}) => {
	let text = '';
	const str =
		suggestion ||
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < length; i++) {
		text += str.charAt(Math.floor(Math.random() * str.length));
	}
	return text;
};

// ₦

export const formatNumberToCurrency = (
	value: string | number,
	currency = 'NGN'
) =>
	`${currency}${value.toString().replace(/\B(?=(?=\d*)(\d{3})+(?!\d))/g, ',')}`;

export const Storage = {
	saveItem: (key: string, value: any) => {
		localStorage.setItem(key, JSON.stringify(value));
	},
	getItem: (key: string) => {
		const value = localStorage.getItem(key);
		if (value) {
			return JSON.parse(value);
		}

		return null;
	},
	deleteItem: (key: string) => {
		localStorage.removeItem(key);
	},
};

export const getCoupon = (coupon: Coupon) => {
	const gift =
		coupon.type === COUPON_TYPE.PERCENT
			? `${coupon.gift}%`
			: coupon.type === COUPON_TYPE.AMOUNT
			? formatNumberToCurrency(coupon.gift as string)
			: '';
	return `${coupon.code}-${gift}`;
};

export const calculateDiscountedAmount = ({
	coupon,
	amount,
}: {
	coupon: Coupon;
	amount: number;
}) => {
	const type = coupon.type;
	const gift = coupon.gift as string | Amount;

	if (type === COUPON_TYPE.PERCENT) {
		const discount =
			(parseFloat(checkAmount(gift) as string) / 100) *
			parseFloat(amount.toString());
		return discount;
	}

	return parseFloat(`${checkAmount(gift)}`);
};

export const calculateFinalAmount = ({
	coupon,
	amount,
}: {
	coupon?: Coupon;
	amount: string | number;
}) => {
	if (coupon) {
		const type = coupon.type;
		const gift = coupon.gift as string | Amount;
		if (type === COUPON_TYPE.PERCENT) {
			return (
				parseFloat(`${amount}`) -
				calculateDiscountedAmount({
					coupon,
					amount: parseFloat(amount.toString()),
				})
			);
		}

		return parseFloat(`${amount}`) - parseFloat(checkAmount(gift) as string);
	}

	console.log('Amount::', amount);

	return parseFloat(`${amount}`);
};

export const cleanString = (value: string) => {
	if (value) {
		return value.replace(/_-*/gi, ' ');
	}

	return value;
};

export const checkAmount = (amount: number | string | Amount) => {
	if (typeof amount === 'object') {
		return amount.$numberDecimal;
	}

	return amount;
};

export const yupValidationError = ({ path, message }: any) => {
	return message;
};

export const getMonthlyWeeklyDaily = (name: string) => {
	const dailyRegExp = new RegExp('daily', 'ig');
	const weeklyRegExp = new RegExp('weekly', 'ig');
	const monthlyRegExp = new RegExp('monthly', 'ig');

	if (dailyRegExp.test(name)) {
		return 'Day';
	}
	if (weeklyRegExp.test(name)) {
		return 'Week';
	}
	if (monthlyRegExp.test(name)) {
		return 'Month';
	}

	return 'Month';
};

export const prevDate = (days: number) => {
	const date = new Date();
	return new Date(date.setDate(date.getDate() - days)).toISOString();
};

export const dateRanges = (start: string, end: string) => {
	const startDate = new Date(start).toISOString();
	const endDate = new Date(end).toISOString();

	return `createdAt>${startDate}&createdAt<${endDate}`;
};

export const getFilterDateRange = (days: number) => {
	const todayDate = new Date().toISOString();

	const res = `createdAt>${prevDate(days)}&createdAt<${todayDate}`;

	return res;
};

export const cleanObject = (object: Record<string, any>) => {
	let output = object;
	if (!output) return {};
	const objectKeys = Object.keys(output);
	if (objectKeys.length > 0) {
		for (let i of objectKeys) {
			if (output[i] === '' || !output[i]) {
				delete output[i];
			}
		}
		return output;
	}
};

export const extractExactTransactionService = (transaction: ITransaction) => {
	const service = transaction.service
		? transaction.service
		: transaction.transaction
		? transaction.transaction.service
		: 'No available services';

	return service;
};

export const extractTransactionService = (transaction: ITransaction) => {
	let output = '';
	if (transaction && transaction.service) {
		output += transaction.service;
	}
	if (transaction.transaction && transaction.transaction.service) {
		if (output) {
			output += ` ${transaction.transaction.service}`;
		} else {
			output += transaction.transaction.service;
		}
	}

	if (!output) {
		output = 'No available service';
	}

	return output;
};

export const extractTransactionReference = (transaction: ITransaction) => {
	const reference = transaction.reference
		? transaction.reference
		: transaction.transaction
		? transaction.transaction.reference
		: 'No available reference';
	return reference;
};

export const extractTransactionType = (transaction: ITransaction) => {
	const type = transaction.name
		? transaction.name
		: transaction.type
		? transaction.type
		: transaction.transaction
		? transaction.transaction.type
		: '';
	return type;
};

export const truncateText = (text: string, length: number = 20): string => {
	if (text.length > length) {
		return `${text.substring(0, length)}...`;
	}

	return text;
};

export const removeHtmlTags = (input: string) => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(input, 'text/html');
	return doc.body.textContent || '';
};

export const capitalize = (text: string) => {
	if (!text) return text;

	const splitString = text.split(' ');
	return splitString
		.map(
			(value) =>
				`${value.charAt(0).toUpperCase()}${value.substring(1).toLowerCase()}`
		)
		.join(' ');
};

export const extractUserName = (user: IUser) => {
	let name = '';
	if (user.firstname) name += user.firstname;
	if (user.lastname) name = name ? name + ` ${user.lastname}` : user.lastname;

	if (name) return capitalize(name);
	if (user.username) return user.username;

	return 'No name available';
};
