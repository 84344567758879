import React from 'react';
import { List, ListItem } from '@mui/material';
import { Logout, Settings } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { LINKS } from 'utilities';
import { useModalAlert, useLogoutUser } from 'hooks';

interface Props {
	closePopper?: () => void;
}

const AuthList = ({ closePopper }: Props) => {
	const modal = useModalAlert();
	const logout = useLogoutUser();
	const navigate = useNavigate();

	const handleClick = (link: string) => {
		typeof closePopper !== 'undefined' && closePopper();
		navigate(link);
	};

	const handleDisplayLogoutModal = () => {
		typeof closePopper !== 'undefined' && closePopper();
		modal({
			title: 'Logout',
			message: 'Are you sure you want to logout?',
			primaryButtonText: 'Log me out',
			secondaryButtonText: 'Take me back',
			onClickPrimaryButton: () => {
				modal(null);
				logout();
			},
			onClickSecondaryButton: () => modal(null),
		});
	};

	return (
		<List
			sx={{
				li: {
					cursor: 'pointer',
					display: 'flex',
					alignItems: 'center',
					gap: '8px',
				},
			}}
			disablePadding
		>
			<ListItem onClick={() => handleClick(LINKS.Settings)}>
				<Settings /> Settings
			</ListItem>
			<ListItem onClick={() => handleDisplayLogoutModal()}>
				<Logout /> Log out
			</ListItem>
		</List>
	);
};

export default AuthList;
