import {
	API_ENDPOINTS,
	DataResponse,
	ENDPOINT_SUBPATH,
	IEPin,
	ITransaction,
	UseEPin,
} from 'utilities';
import { apiRequest } from '../config';

export const generateEPin = async (data: {
	[key: string]: any;
}): Promise<DataResponse<IEPin>> =>
	apiRequest({
		url: API_ENDPOINTS.IEPin,
		method: 'POST',
		data,
	});

export const ePins = async (params?: {
	[key: string]: any;
}): Promise<DataResponse<ITransaction[]>> => {
	return apiRequest({
		url: `${API_ENDPOINTS.IEPin}${ENDPOINT_SUBPATH.Me}`,
		method: 'GET',
		params,
	});
};

export const validateEPin = async (
	code: string
): Promise<DataResponse<IEPin>> => {
	return apiRequest({
		url: `${API_ENDPOINTS.IEPin}/validate/${code}`,
		method: 'GET',
	});
};

export const useEPin = async (data: UseEPin): Promise<DataResponse<any>> => {
	return apiRequest({
		url: `${API_ENDPOINTS.IEPin}/use`,
		method: 'PUT',
		data,
	});
};
