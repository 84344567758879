import Login from './auth/login';
import VerifyAccount from './auth/verify-account';
import Dashboard from './dashboard';
import SignUp from './auth/sign-up';
import Services from './services';
import TransactionHistory from './transaction-history';
import PendingTransactions from './transaction-history/pending-transaction';
import PurchaseAirtime from './services/purchase-airtime';
import PurchaseData from './services/purchase-data';
import ConvertAirtime from './services/convert-airtime';
import Bills from './services/bills';
import EPin from './e-pin';
import GenerateData from './e-pin/generate/data';
import GenerateAirtime from './e-pin/generate/airtime';
import GenerateBills from './e-pin/generate/bill';
import ViewAirtime from './e-pin/view/airtime';
import ViewData from './e-pin/view/data';
import ViewBills from './e-pin/view/bill';
import LoadEducation from './e-pin/load-epin/education';
import LoadAirtime from './e-pin/load-epin/airtime';
import LoadCable from './e-pin/load-epin/cable';
import LoadPower from './e-pin/load-epin/power';
import LoadData from './e-pin/load-epin/data';
import LoadInternetSubscription from './e-pin/load-epin/internet-subscription';
import Settings from './settings';
import ResetPassword from './settings/reset-password';
import ResetPin from './settings/reset-pin';
import BvnVerification from './settings/bvn-verification';
import AddBank from './settings/add-bank';
import GetHelpOrFaqs from './get-help-or-faqs';
import Referral from './referral';
import REFERRAL_HISTORY from './referral/history';
import ConfirmLoginOtp from './auth/confirm-login-otp';
import TopupWallet from './wallet/topup';
import Withdraw from './wallet/withdraw';
import NotFound from './not-found';
import Transfer from './wallet/transfer';
import Notifications from './notification';
import SupportTicket from './support-ticket';
import CreateSupportTicket from './support-ticket/create';
import Message from './support-ticket/message';
import Initial from './initial';
import ConfirmTwoFactorAuthToken from './settings/confirm-two-factor-auth-token';
import ValidateIdentityCard from './settings/validate-identiity-card';
import ManualConvertAirtime from './services/convert-airtime/manual-convert-airtime';
import InstantConvertAirtime from './services/convert-airtime/instant-convert-airtime';
import RequestAirtimeConvertOtp from './services/convert-airtime/instant-convert-airtime/request-otp';
import ConfirmAirtimeConvertOtp from './services/convert-airtime/instant-convert-airtime/confirm-otp';
import LevelTwoVerification from './settings/level-two-verification';
import RequestForgottenPasswordOtp from './auth/forget-password/request-otp';
import ConfirmForgottenPasswordOtp from './auth/forget-password/confirm-otp';
import AuthResetPassword from './auth/forget-password/reset-password';
import FundBettingAccount from './services/fund-betting-account';

// New Screens
import InternationalAirtimeData from './services/intenational-airtime-data';
import PurchaseInternationalAirtimeData from './services/intenational-airtime-data/purchase-airtime-data';

import ESim from './services/e-sim';

import GiftCards from './services/giftcards';

import EVoucher from './services/e-voucher';

const Pages = {
	Initial,
	Auth: {
		Login,
		VerifyAccount,
		ConfirmLoginOtp,
		SignUp,
		RequestForgottenPasswordOtp,
		ConfirmForgottenPasswordOtp,
		AuthResetPassword,
	},
	Wallet: {
		TopupWallet,
		Withdraw,
		Transfer,
	},
	Dashboard,
	Services: {
		Initial: Services,
		PurchaseAirtime,
		PurchaseData,
		FundBettingAccount,
		ConvertAirtime: {
			Index: ConvertAirtime,
			ManualConvertAirtime,
			InstantConvertAirtime,
			RequestAirtimeConvertOtp,
			ConfirmAirtimeConvertOtp,
		},
		Bills,
		InternationalAirtimeData: {
			Index: InternationalAirtimeData,
			Purchase: PurchaseInternationalAirtimeData,
		},
		ESim,
		GiftCards,
		EVoucher,
	},
	TransactionHistory: {
		Initial: TransactionHistory,
		PendingTransactions,
	},
	EPin: {
		Initial: EPin,
		GenerateData,
		GenerateAirtime,
		GenerateBills,
		ViewBills,
		ViewData,
		ViewAirtime,
		LoadEducation,
		LoadAirtime,
		LoadCable,
		LoadPower,
		LoadInternetSubscription,
		LoadData,
	},
	Settings: {
		Initial: Settings,
		ResetPassword,
		ResetPin,
		AddBank,
		BvnVerification,
		ConfirmTwoFactorAuthToken,
		ValidateIdentityCard,
		LevelTwoVerification,
	},
	GetHelpOrFaqs,
	Referral,
	REFERRAL_HISTORY,
	Notifications,
	SupportTicket,
	CreateSupportTicket,
	Message,
	NotFound,
};

export default Pages;
