import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Layout, ReferralTable, Seo } from 'components';
import { THEME_MODE } from 'utilities';
import { useAppSelector } from 'store/hooks';

const REFERRAL_HISTORY = () => {
	const theme = useTheme();
	const { mode } = useAppSelector((store) => store.theme);
	const styles = useStyles(theme, mode);

	return (
		<Layout>
			<Seo title={'Referral History'} />
			<Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: '15px' }}>
				<Typography style={styles.title} variant={'h5'}>
					Referral History
				</Typography>
				<ReferralTable />
			</Box>
		</Layout>
	);
};

const useStyles = (theme: any, mode: string) => ({
	title: {
		fontWeight: '600',
		color: mode === THEME_MODE.light ? theme.palette.primary.main : grey[200],
	},
});

export default REFERRAL_HISTORY;
