import React, { useEffect, useState } from 'react';
import {
	Box,
	MenuItem,
	Typography,
	useTheme,
	InputAdornment,
	SelectChangeEvent,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useFormik } from 'formik';
import { grey } from '@mui/material/colors';
import {
	QUERY_KEYS,
	INetwork,
	IDataPlan,
	checkAmount,
	ValidationSchema,
	formatNumberToCurrency,
	DataType,
	calculateFinalAmount,
	Coupon,
	LINKS,
	TICKET_TYPE,
	BENEFICIARY_TYPE,
	TRANSACTION_SERVICE,
} from 'utilities';
import TextInput from '../form-components/text-input';
import Button from '../button';
import Select from '../form-components/select';
import CheckBox from 'components/form-components/check-box';
import { useAppSelector } from 'store/hooks';
import Loader from '../loader';
import {
	useModalAlert,
	useVerifyPin,
	useVerifyCoupon,
	useHandleError,
	useAlert,
	useCreateBeneficiary,
} from 'hooks';
import {
	createDataSubscriptions,
	dataNetworks,
	dataPlans,
	dataTypes,
} from 'api';
import TextPlaceholder from '../partials/custom-text-input-holder';
import { useAppDispatch } from 'store/hooks';
import { setTransactionBreakdown } from 'store/transaction';
import SelectBeneficiary from 'components/beneficiary/select-beneficiary';

const SELECT_PROVIDER = 'Select network provider';
const SELECT_PLAN = 'Select data plan';
const SELECT_TYPE = 'Select data type';

interface InitialValues {
	plan: string;
	number: string;
	type?: string;
	network?: string;
	discount_code?: string;
}

const PurchaseDataForm = () => {
	/* Hooks */
	const theme = useTheme();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const alert = useAlert();
	const modal = useModalAlert();
	const handleError = useHandleError();
	const verifyPin = useVerifyPin();
	const { verifyCoupon, isVerifyingCoupon, coupon } = useVerifyCoupon();
	const styles = useStyles(theme);
	const { token } = useAppSelector((store) => store.auth);
	const queryClient = useQueryClient();

	/* State */
	const [selectedPlan, setSelectedPlan] = useState<null | IDataPlan>(null);
	const [dataDataTypes, setDataDataTypes] = useState<null | DataType[]>(null);
	const [dataDataPlans, setDataDataPlans] = useState<null | IDataPlan[]>(null);
	const [recipientPhone, setRecipientPhone] = useState('');
	const [isLoadingDataTypes, setLoadingDataTypes] = useState(false);
	const [isLoadingDataPlans, setLoadingDataPlans] = useState(false);

	const [isSaveBeneficiary, setSaveBeneficiary] = useState<boolean>(false);
	const [beneficiary, setBeneficiary] = useState<null | { [key: string]: any }>(
		null
	);

	const { createBeneficiary } = useCreateBeneficiary(() => {
		setBeneficiary(null);
	});

	const clearStates = () => {
		setRecipientPhone('');
		setSelectedPlan(null);
		resetForm();
	};

	const initialValues: InitialValues = {
		plan: SELECT_PLAN,
		number: '',
		network: SELECT_PROVIDER,
		type: SELECT_TYPE,
		discount_code: '',
	};

	/*
	 *Update Coupon
	 */
	useEffect(
		() => {
			if (coupon) {
				dispatch(setTransactionBreakdown({ coupon }));
			}
		},

		// eslint-disable-next-line
		[coupon]
	);

	const {
		isLoading: isLoadingNetwork,
		data: dataDataNetworks,
		error: errorDataNetwork,
	} = useQuery(QUERY_KEYS.DataNetwork, () => dataNetworks({ isActive: true }), {
		enabled: !!token,
		refetchOnWindowFocus: false,
	});

	const dataErrorMessage = handleError({ error: errorDataNetwork })?.message;

	/*
	 *Load Data Type
	 */
	const loadDataTypes = async (network: string) => {
		setLoadingDataTypes(true);
		setDataDataTypes(null);
		try {
			const data = await dataTypes({
				network,
				isActive: true,
			});
			if (data && data.success) {
				setDataDataTypes(data.payload);
			}
		} catch (error) {
			const response = handleError({ error });
			if (response?.message)
				alert({ message: response.message, type: 'error' });
		}
		setLoadingDataTypes(false);
	};

	// Load Data Plan

	const loadDataPlans = async (dataType: string) => {
		setDataDataPlans(null);
		setLoadingDataPlans(true);
		try {
			const data = await dataPlans({
				dataType,
				isActive: true,
				sort: 'level',
			});
			if (data && data.success) {
				setDataDataPlans(data.payload);
			}
		} catch (error) {}
		setLoadingDataPlans(false);
	};

	const { isLoading: isPurchasingData, mutate: mutatePurchaseData } =
		useMutation(createDataSubscriptions, {
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						modal({
							title: 'Purchase Data Error',
							message: res.message,
							type: 'error',
							hasCloseButton: true,
							primaryButtonText: 'Contact Support',
							onClickPrimaryButton: () => {
								modal(null);
								navigate(
									`${LINKS.CreateSupportTicket}?_type=${TICKET_TYPE.COMPLAINT}`
								);
							},
						});
					}
				}

				if (data && data.success) {
					if (isSaveBeneficiary) {
						createBeneficiary({
							type: BENEFICIARY_TYPE.DATA,
							record: beneficiary,
						});
					}
					resetForm();
					queryClient.invalidateQueries(QUERY_KEYS.UserWallet);
					queryClient.invalidateQueries([QUERY_KEYS.Transactions]);
					queryClient.invalidateQueries([QUERY_KEYS.RecentTransactions]);
					const amount = formatNumberToCurrency(selectedPlan?.amount as string);
					modal({
						title: 'Data Subscription Message',
						message: `Data subscription plan of ${selectedPlan?.name} for ${amount} to ${recipientPhone} is successful!`,
						primaryButtonText: 'Buy data again',
						callback: clearStates,
						type: 'success',
						onClickPrimaryButton: () => {
							modal(null);
							// navigate(LINKS.Dashboard);
						},
						secondaryButtonText: 'Close',
						onClickSecondaryButton: () => {
							modal(null);
						},
					});
				}
			},
		});

	const {
		errors,
		touched,
		handleChange,
		handleSubmit,
		values,
		setFieldValue,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema: ValidationSchema.DataSubscription,

		onSubmit: (values) => {
			let data = {
				plan: values.plan,
				number: values.number,
			} as typeof initialValues;

			const discount_code = values.discount_code;

			let amount = Number(selectedPlan?.amount as string);

			if (discount_code) {
				if (!coupon) {
					alert({ message: 'Apply discount code to continue', type: 'info' });
					return;
				}

				if (coupon && coupon.code !== discount_code)
					return alert({
						message: `Verified discount code doesn't match provided code, try verifying discount code again`,
						type: 'info',
					});

				data.discount_code = discount_code;
				amount = calculateFinalAmount({
					coupon: coupon as Coupon,
					amount,
				});
			}

			if (isSaveBeneficiary)
				setBeneficiary({
					number: values.number,
					transactionType: TRANSACTION_SERVICE.DATA_SUBSCRIPTION,
					provider:
						dataDataNetworks &&
						dataDataNetworks.payload.find(
							(network) => network.id === values.network
						),
				});

			const handlePurchase = () => {
				verifyPin(null);
				mutatePurchaseData(data);
			};

			verifyPin({
				title: 'Purchase Data',
				message: `Verify transaction pin to confirm Data subscription plan of ${
					selectedPlan?.name
				} for ${formatNumberToCurrency(amount)} to ${values.number}.`,
				callback: handlePurchase,
			});
		},
	});

	const { plan, number, network, discount_code, type } = values;

	const handleSelectBeneficiary = (beneficiary: { [key: string]: any }) => {
		console.log(beneficiary);
		const phone = beneficiary.record.number;
		const provider = beneficiary.record.provider;

		setFieldValue('number', phone);

		setFieldValue('network', provider?.id);
		loadDataTypes(provider?.id as string);
	};

	return (
		<>
			{isVerifyingCoupon && <Loader />}
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Network provider
					</Typography>
					<Select
						fullWidth
						error={errors && touched.network && errors.network ? true : false}
						helpertext={errors && touched.network && errors.network}
						value={network}
						onChange={(e) => {
							const value = e.target.value;
							setFieldValue('network', value);
							loadDataTypes(value as string);
						}}
					>
						<MenuItem value={SELECT_PROVIDER} disabled>
							{isLoadingNetwork
								? 'Loading...'
								: dataErrorMessage
								? dataErrorMessage
								: dataDataNetworks && dataDataNetworks.payload.length === 0
								? 'No avaliable network'
								: SELECT_PROVIDER}
						</MenuItem>
						{dataDataNetworks &&
							dataDataNetworks.payload.length > 0 &&
							dataDataNetworks.payload.map((network: INetwork) => (
								<MenuItem value={network.id} key={network.id}>
									{network.name}
								</MenuItem>
							))}
					</Select>
				</Box>

				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Data type
					</Typography>
					{network === SELECT_PROVIDER ? (
						<TextPlaceholder isSelectable text={'Select data type'} />
					) : (
						<Select
							fullWidth
							error={errors && touched.type && errors.type ? true : false}
							helpertext={errors && touched.type && errors.type}
							value={type}
							onChange={(e: SelectChangeEvent<unknown>) => {
								const value = e.target.value as string;
								loadDataPlans(value);
								setFieldValue('type', value);
							}}
						>
							<MenuItem disabled value={SELECT_TYPE}>
								{network === SELECT_PROVIDER
									? 'Select a network provider'
									: isLoadingDataTypes
									? 'Loading...'
									: dataDataTypes && dataDataTypes.length === 0
									? 'No avilable data types'
									: SELECT_TYPE}
							</MenuItem>
							{dataDataTypes &&
								dataDataTypes.length > 0 &&
								dataDataTypes.map((type: DataType) => (
									<MenuItem value={type.id} key={type.id}>
										{type.name}
									</MenuItem>
								))}
						</Select>
					)}
				</Box>

				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Data plan
					</Typography>
					{type === SELECT_TYPE ? (
						<TextPlaceholder isSelectable text={'Select data plan'} />
					) : (
						<Select
							fullWidth
							error={errors && touched.plan && errors.plan ? true : false}
							helpertext={errors && touched.plan && errors.plan}
							value={plan}
							onChange={(e: SelectChangeEvent<unknown>) => {
								const value = e.target.value;
								if (dataDataPlans) {
									const filter_plan = dataDataPlans.find(
										(plan: IDataPlan) => plan.id === value
									);
									if (filter_plan) {
										setSelectedPlan(filter_plan);
										const amount = Number(
											checkAmount(filter_plan.amount as string)
										);
										dispatch(setTransactionBreakdown({ serviceCost: amount }));
									}
								}
								setFieldValue('plan', value);
							}}
						>
							<MenuItem disabled value={SELECT_PLAN}>
								{type === SELECT_TYPE
									? 'Select a data type'
									: isLoadingDataPlans
									? 'Loading...'
									: dataDataPlans && dataDataPlans.length === 0
									? 'No available data types'
									: SELECT_PLAN}
							</MenuItem>
							{dataDataPlans &&
								dataDataPlans.length > 0 &&
								dataDataPlans.map((plan: IDataPlan) => (
									<MenuItem value={plan.id} key={plan.id}>
										{plan.name} for{' '}
										{formatNumberToCurrency(plan.amount as string)}
									</MenuItem>
								))}
						</Select>
					)}
				</Box>

				<Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginBottom: '6px',
						}}
					>
						<Typography
							sx={{
								marginBottom: '0px !important',
							}}
							style={styles.label}
							component={'label'}
							variant={'body1'}
						>
							Phone number
						</Typography>
						<SelectBeneficiary
							onSelect={handleSelectBeneficiary}
							type={BENEFICIARY_TYPE.DATA}
						/>
					</Box>
					<TextInput
						fullWidth
						error={errors && touched.number && errors.number ? true : false}
						helperText={errors && touched.number && errors.number}
						placeholder={'Type in phone number here'}
						value={number}
						onChange={(e: any) => {
							const value = e.target.value;
							setRecipientPhone(value);
							setFieldValue('number', value);
						}}
					/>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Discount code [optional]
					</Typography>
					{plan === SELECT_PLAN ? (
						<TextPlaceholder text={'Type in discount code here'} />
					) : (
						<TextInput
							fullWidth
							placeholder={'Type in discount code here'}
							value={discount_code}
							onChange={handleChange('discount_code')}
							InputProps={{
								endAdornment: (
									<InputAdornment position='start'>
										{discount_code && (
											<Button
												style={styles.applyBtn}
												onClick={(e: React.FormEvent<HTMLButtonElement>) => {
													e.preventDefault();

													verifyCoupon(discount_code);
												}}
											>
												Apply
											</Button>
										)}
									</InputAdornment>
								),
							}}
						/>
					)}
				</Box>
				<Box>
					<CheckBox
						label={'Save number for later'}
						value={isSaveBeneficiary}
						onChange={() => setSaveBeneficiary(!isSaveBeneficiary)}
					/>
				</Box>
				<Button
					loading={isPurchasingData}
					style={styles.btn}
					size={'large'}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Purchase
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default PurchaseDataForm;
