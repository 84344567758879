import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useTheme, Box, Typography } from '@mui/material';

import {
	Layout,
	Pagination,
	DataSubscriptionsTable,
	AirtimeTransactionsTable,
	CableSubscriptionsTable,
	InternetSubscriptionsTable,
	EducationTransactionsTable,
	ElectricityTransactionsTable,
	EpinTransactionsTable,
	WalletFundingTable,
	WalletTransfersTable,
	WalletWithdrawalTable,
	BettingFundingsTable,
	AirtimeConvertTransactionsTable,
	AutoAirtimeConvertsTable,
	ReferralTransactionsTable,
	ReversalTransactionsTable,
	InternationalAirtimeDataTransactionsTable,
	EVoucherGiftcardTransactionTable,
} from 'components';
import {
	MAX_RECORDS,
	ErrorBoundaryGuard,
	Metadata,
	TRANSACTION_SERVICE,
	ITransaction,
	IFilterTransaction,
} from 'utilities';
import TransactionTableFilter from 'components/filter/transaction-table-filter';
import { useAppSelector } from 'store/hooks';
import { usePageTitle } from 'hooks';
import {
	useQueryAirtimeTransactions,
	useQueryBillTransactions,
	useQueryConvertAirtimes,
	useQueryDataSubscriptions,
	useQueryEPinTransactions,
	useQueryWalletFundings,
	useQueryWalletTransfers,
	useQueryWalletWithdrawals,
	useQueryAutoConvertAirtimes,
	useQueryTransactions,
	useQueryInternationalAirtimeTransactions,
	useQueryInternationalDataTransactions,
	useQueryVoucherTransactions,
	useQueryGiftCardTransactions,
	useQueryESimTransactions,
} from 'hooks/transactions';

type TDataTransaction = {
	service: string;
	data: ITransaction[] | null;
};

const TransactionHistory = () => {
	usePageTitle('Transaction History');
	const theme = useTheme();
	const mode = useAppSelector((store) => store.theme.mode);

	const filterTransactionBy = useAppSelector(
		(store) => store.transactionState.filterTransactionBy
	);

	const styles = useStyles(theme, mode);

	const [count, setCount] = useState<number>(1);
	const [page, setPage] = useState<number>(1);

	const [total, setTotal] = useState(0);

	const maxRecordRef = useRef<number>(MAX_RECORDS);
	const skipValue = useRef<number>(0);
	const serviceRef = useRef<string>('');

	const [dataTransactions, setDataTransaction] =
		useState<null | TDataTransaction>(null);

	const handleSetTotal = (metadata: Metadata) => {
		if (Object.keys(metadata).length > 0) {
			const total = metadata?.total;
			const count = Math.ceil(Number(total) / maxRecordRef.current);
			setCount(count);

			setTotal(total);
		}
	};

	const resetQueryValue = (service: string) => {
		if (serviceRef.current !== service) {
			skipValue.current = 0;
			setPage(1);
		}
		serviceRef.current = service;
	};

	const { isLoadingTransactions: isLoadingTransactionsRec, queryTransactions } =
		useQueryTransactions(({ data, metadata, service }) => {
			handleSetTotal(metadata as Metadata);

			setDataTransaction({
				service: service as string,
				data,
			});
		});

	const { isLoadingDataSubscriptions, queryDataSubscriptions } =
		useQueryDataSubscriptions((data, metadata) => {
			handleSetTotal(metadata as Metadata);

			setDataTransaction({
				service: TRANSACTION_SERVICE.DATA_SUBSCRIPTION,
				data,
			});
		});

	const { queryAirtimeTransactions, isLoadingAirtimeTransactions } =
		useQueryAirtimeTransactions((data, metadata) => {
			handleSetTotal(metadata as Metadata);
			setDataTransaction({
				service: TRANSACTION_SERVICE.AIRTIME_TOP_UP,
				data,
			});
		});

	const { queryInterAirtimeTransactions, isLoadingInterAirtimeTransactions } =
		useQueryInternationalAirtimeTransactions((data, metadata) => {
			handleSetTotal(metadata as Metadata);
			setDataTransaction({
				service: TRANSACTION_SERVICE.INTERNATIONAL_AIRTIME_TOP_UP,
				data,
			});
		});

	// useQueryInternationalDataTransactions

	const { queryInterDataTransactions, isLoadingInterDataTransactions } =
		useQueryInternationalDataTransactions((data, metadata) => {
			handleSetTotal(metadata as Metadata);
			setDataTransaction({
				service: TRANSACTION_SERVICE.INTERNATIONAL_DATA_SUBSCRIPTION,
				data,
			});
		});

	const { queryConvertAirtimes, isLoadingConvertAirtime } =
		useQueryConvertAirtimes((data, metadata) => {
			handleSetTotal(metadata as Metadata);
			setDataTransaction({
				service: TRANSACTION_SERVICE.AIRTIME_CONVERSION,
				data,
			});
		});

	const { isLoadingBillTransactions, queryBillTransactions } =
		useQueryBillTransactions(({ data, service, metadata }) => {
			handleSetTotal(metadata as Metadata);
			setDataTransaction({
				service,
				data,
			});
		});

	const { isLoadingEPinTransactions, queryEPinTransactions } =
		useQueryEPinTransactions((data, metadata) => {
			handleSetTotal(metadata as Metadata);
			setDataTransaction({
				service: TRANSACTION_SERVICE.EPIN,
				data,
			});
		});

	const { isLoadingWalletFundings, queryWalletFundings } =
		useQueryWalletFundings((data, metadata) => {
			handleSetTotal(metadata as Metadata);
			setDataTransaction({
				service: TRANSACTION_SERVICE.CARD_TOP_UP,
				data,
			});
		});

	const { isLoadingWalletTransfers, queryWalletTransfers } =
		useQueryWalletTransfers((data, metadata) => {
			handleSetTotal(metadata as Metadata);
			setDataTransaction({
				service: TRANSACTION_SERVICE.WALLET_TRANSFER,
				data,
			});
		});

	const { isLoadingWalletWithdrawals, queryWalletWithdrawals } =
		useQueryWalletWithdrawals((data, metadata) => {
			handleSetTotal(metadata as Metadata);
			setDataTransaction({
				service: TRANSACTION_SERVICE.WITHDRAWAL,
				data,
			});
		});

	const { isLoadingAutoAirtimeConverts, queryAutoAirtimeConverts } =
		useQueryAutoConvertAirtimes((data, metadata) => {
			handleSetTotal(metadata as Metadata);
			setDataTransaction({
				service: TRANSACTION_SERVICE.AIRTIME_AUTO_CONVERSION,
				data,
			});
		});

	const { isLoadingVoucherTransactions, queryVoucherTransactions } =
		useQueryVoucherTransactions((data, metadata) => {
			handleSetTotal(metadata as Metadata);
			setDataTransaction({
				service: TRANSACTION_SERVICE.EVOUCHER,
				data,
			});
		});

	const { isLoadingGiftCardTransactions, queryGiftCardTransactions } =
		useQueryGiftCardTransactions((data, metadata) => {
			handleSetTotal(metadata as Metadata);
			setDataTransaction({
				service: TRANSACTION_SERVICE.GIFT_CARD,
				data,
			});
		});

	const { isLoadingESimTransactions, queryESimTransactions } =
		useQueryESimTransactions((data, metadata) => {
			handleSetTotal(metadata as Metadata);
			setDataTransaction({
				service: TRANSACTION_SERVICE.ESIM,
				data,
			});
		});

	const isLoadingTransactions =
		isLoadingDataSubscriptions ||
		isLoadingAirtimeTransactions ||
		isLoadingConvertAirtime ||
		isLoadingBillTransactions ||
		isLoadingEPinTransactions ||
		isLoadingWalletFundings ||
		isLoadingWalletTransfers ||
		isLoadingWalletWithdrawals ||
		isLoadingAutoAirtimeConverts ||
		isLoadingTransactionsRec ||
		isLoadingInterAirtimeTransactions ||
		isLoadingInterDataTransactions ||
		isLoadingGiftCardTransactions ||
		isLoadingVoucherTransactions ||
		isLoadingESimTransactions;

	const switchHandleSubmit = async (value?: IFilterTransaction) => {
		let payload: { [key: string]: any } = {
			limit: maxRecordRef.current,
			sort: '-createdAt',
		};

		const service = filterTransactionBy?.service;

		resetQueryValue(service as string);

		if (filterTransactionBy?.status)
			payload.status = filterTransactionBy.status;
		// if (service) payload.service = filterTransactionBy.service;
		if (filterTransactionBy?.date) {
			const dateFilter = new URLSearchParams(filterTransactionBy?.date);
			payload = {
				...payload,
				...Object.fromEntries(dateFilter),
			};
		}

		if (skipValue.current > 0) payload.skip = skipValue.current;

		if (service === TRANSACTION_SERVICE.DATA_SUBSCRIPTION) {
			payload.populate = 'plan,dataType,network,plan.network';
			queryDataSubscriptions(payload);
			return;
		}

		if (service === TRANSACTION_SERVICE.AIRTIME_TOP_UP) {
			payload.populate = 'plan,dataType,network,plan.network';
			queryAirtimeTransactions(payload);
			return;
		}

		// queryInterAirtimeTransactions

		if (service === TRANSACTION_SERVICE.INTERNATIONAL_AIRTIME_TOP_UP) {
			payload.populate = 'plan,dataType,network,plan.network';
			queryInterAirtimeTransactions(payload);
			return;
		}

		// queryInterDataTransactions

		if (service === TRANSACTION_SERVICE.INTERNATIONAL_DATA_SUBSCRIPTION) {
			payload.populate = 'plan,dataType,network,plan.network';
			queryInterDataTransactions(payload);
			return;
		}

		if (service === TRANSACTION_SERVICE.AIRTIME_CONVERSION) {
			payload.populate = 'plan,dataType,network,plan.network';
			queryConvertAirtimes(payload);
			return;
		}

		if (service === TRANSACTION_SERVICE.AIRTIME_AUTO_CONVERSION) {
			queryAutoAirtimeConverts(payload);
			return;
		}

		if (service === TRANSACTION_SERVICE.CARD_TOP_UP) {
			payload.populate = 'user';
			queryWalletFundings(payload);
			return;
		}

		if (service === TRANSACTION_SERVICE.WALLET_TRANSFER) {
			payload.populate = 'userTo,userFrom,transactionFrom';
			queryWalletTransfers(payload);
			return;
		}

		if (service === TRANSACTION_SERVICE.WITHDRAWAL) {
			queryWalletWithdrawals(payload);
			return;
		}

		if (service === TRANSACTION_SERVICE.EPIN) {
			payload.populate = 'user,pin_data.network';
			payload.service = filterTransactionBy?.service;
			queryEPinTransactions(payload);
			return;
		}

		if (service === TRANSACTION_SERVICE.EVOUCHER) {
			queryVoucherTransactions(payload);
			return;
		}

		if (service === TRANSACTION_SERVICE.GIFT_CARD) {
			queryGiftCardTransactions(payload);
			return;
		}

		if (service === TRANSACTION_SERVICE.ESIM) {
			queryESimTransactions(payload);
			return;
		}

		if (
			service === TRANSACTION_SERVICE.CABLE ||
			service === TRANSACTION_SERVICE.INTERNET ||
			service === TRANSACTION_SERVICE.ELECTRICITY ||
			service === TRANSACTION_SERVICE.EDUCATION ||
			service === TRANSACTION_SERVICE.BETTING
		) {
			payload.type = service;

			await queryBillTransactions(payload);
			return;
		}

		if (
			service === TRANSACTION_SERVICE.REFERRAL_BONUS ||
			service === TRANSACTION_SERVICE.REVERSAL
		) {
			payload.service = service;

			await queryTransactions(payload);
			return;
		}
	};

	useEffect(
		() => {
			// setDataTransaction(null); // Clear data transaction
			switchHandleSubmit();
		},
		// eslint-disable-next-line
		[filterTransactionBy]
	);

	const handlePageChange = (page: number) => {
		setPage(page);
		skipValue.current = (page - 1) * maxRecordRef.current;
		switchHandleSubmit();
	};

	return (
		<Layout>
			<Box style={styles.container}>
				<Box
					sx={{
						display: 'flex',
						overflow: 'hidden',
						alignItems: { md: 'center' },
						justifyContent: 'space-between',
						gap: theme.spacing(2),
						flexDirection: {
							xs: 'column',
							md: 'row',
						},
					}}
				>
					<Typography style={styles.title as CSSProperties} variant={'h5'}>
						Transactions
					</Typography>
					<TransactionTableFilter onFilter={() => setDataTransaction(null)} />
				</Box>
				<ErrorBoundaryGuard
					errorMessage={
						'Something went wrong with retrieving transaction records'
					}
				>
					{filterTransactionBy.service ===
						TRANSACTION_SERVICE.DATA_SUBSCRIPTION && (
						<DataSubscriptionsTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}

					{filterTransactionBy?.service ===
						TRANSACTION_SERVICE.AIRTIME_TOP_UP && (
						<AirtimeTransactionsTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}

					{(filterTransactionBy?.service ===
						TRANSACTION_SERVICE.INTERNATIONAL_AIRTIME_TOP_UP ||
						filterTransactionBy?.service ===
							TRANSACTION_SERVICE.INTERNATIONAL_DATA_SUBSCRIPTION) && (
						<InternationalAirtimeDataTransactionsTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}

					{(filterTransactionBy?.service === TRANSACTION_SERVICE.EVOUCHER ||
						filterTransactionBy?.service === TRANSACTION_SERVICE.GIFT_CARD ||
						filterTransactionBy?.service === TRANSACTION_SERVICE.ESIM) && (
						<EVoucherGiftcardTransactionTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}

					{filterTransactionBy?.service ===
						TRANSACTION_SERVICE.AIRTIME_CONVERSION && (
						<AirtimeConvertTransactionsTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}

					{filterTransactionBy?.service ===
						TRANSACTION_SERVICE.AIRTIME_AUTO_CONVERSION && (
						<AutoAirtimeConvertsTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}

					{filterTransactionBy?.service === TRANSACTION_SERVICE.CABLE && (
						<CableSubscriptionsTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}

					{filterTransactionBy?.service === TRANSACTION_SERVICE.INTERNET && (
						<InternetSubscriptionsTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}
					{filterTransactionBy?.service === TRANSACTION_SERVICE.EDUCATION && (
						<EducationTransactionsTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}
					{filterTransactionBy?.service === TRANSACTION_SERVICE.ELECTRICITY && (
						<ElectricityTransactionsTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}
					{filterTransactionBy?.service === TRANSACTION_SERVICE.EPIN && (
						<EpinTransactionsTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}
					{filterTransactionBy?.service === TRANSACTION_SERVICE.CARD_TOP_UP && (
						<WalletFundingTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}
					{filterTransactionBy?.service ===
						TRANSACTION_SERVICE.WALLET_TRANSFER && (
						<WalletTransfersTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}
					{filterTransactionBy?.service === TRANSACTION_SERVICE.WITHDRAWAL && (
						<WalletWithdrawalTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}
					{filterTransactionBy?.service === TRANSACTION_SERVICE.BETTING && (
						<BettingFundingsTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}
					{filterTransactionBy?.service ===
						TRANSACTION_SERVICE.REFERRAL_BONUS && (
						<ReferralTransactionsTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}
					{filterTransactionBy?.service === TRANSACTION_SERVICE.REVERSAL && (
						<ReversalTransactionsTable
							isLoading={isLoadingTransactions}
							data={dataTransactions?.data as ITransaction[]}
						/>
					)}
				</ErrorBoundaryGuard>

				{total > maxRecordRef.current && (
					<Pagination
						size={'large'}
						variant={'outlined'}
						shape={'rounded'}
						page={page}
						count={count}
						onChange={(e, number) => handlePageChange(number)}
					/>
				)}
			</Box>
		</Layout>
	);
};

const useStyles = (theme: any, mode?: any) => ({
	container: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '10px',
	},
	title: {
		fontWeight: '600',
		textTransform: 'capitalize',
	},
});

export default TransactionHistory;
