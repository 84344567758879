import { useAppSelector } from 'store/hooks';
import { useNavigate, useLocation } from 'react-router-dom';

import useModalAlert from '../use-modal';
import { LINKS } from 'utilities';

const useCheckKycLevelTwo = () => {
	const modal = useModalAlert();
	const { user } = useAppSelector((store) => store.auth);
	const navigate = useNavigate();
	const { pathname, search } = useLocation();

	const redirectLink = `${pathname}${search}`;

	// useEffect(
	// 	() => {
	// 		const script = document.createElement('script');

	// 		script.type = 'text/javascript';
	// 		script.src = 'https://dashboard.qoreid.com/qoreid-sdk/qoreid.js';
	// 		const _window: any = window;
	// 		_window.kycLevelTwoUpdate = kycLevelTwoUpdate;
	// 		_window.appQueryClient = queryClient;
	// 		_window.reLoadUser = reloadUser;
	// 		_window.setAlert = setAlert;

	// 		document.body.appendChild(script);

	// 		return () => {
	// 			document.body.removeChild(script);
	// 		};
	// 	},
	// 	// eslint-disable-next-line
	// 	[]
	// );

	// const handleVerifyIdentity = () => {
	// 	const sdkButton: any = document.querySelector('.sdkButton');
	// 	sdkButton.click();
	// };

	const checkKycLevelTwo = () => {
		if (user) {
			const { kycLevel } = user;
			if (kycLevel === 1) {
				modal.display({
					title: 'Kyc Level Verification',
					message:
						'You can not perform this operation. You are required to verify your identity before performing any transactions',
					hasCloseButton: true,
					primaryButtonText: 'Verify Identity',
					onClickPrimaryButton: () => {
						// navigate(`${LINKS.LevelTwoVerification}?intended_url=${pathname}`);
						navigate(`${LINKS.LevelTwoVerification}?redirect=${redirectLink}`, {
							state: {
								redirect: redirectLink,
							},
						});
						modal.close();
					},
				});
				return false;
			}
			return true;
		}
	};

	return checkKycLevelTwo;
};

export default useCheckKycLevelTwo;
