import { useState } from 'react';
import { verifyUser as verifyUserApi } from 'api';
import useHandleError from './useHandleError';
import useToastAlert from './useToastAlert';
import { EMAIL_REX, IUser } from 'utilities';

interface IPayload {
	email?: string;
	username?: string;
}

const useVerifyUser = () => {
	const alert = useToastAlert();
	const handleError = useHandleError();
	const [verifiedUser, setVerifiedUser] = useState<null | IUser>(null);
	const [isVerifyingUser, setVerifyingUser] = useState<boolean>(false);
	const verifyUser = async (searchValue: string) => {
		if (!searchValue) {
			alert({ message: 'Enter user email or user name', type: 'info' });

			return;
		}

		let payload = {} as IPayload;

		if (EMAIL_REX.test(searchValue)) {
			payload.email = searchValue;
		} else {
			payload.username = searchValue;
		}

		setVerifyingUser(true);
		try {
			const data = await verifyUserApi(payload);
			if (data && data.success) {
				const user = data.payload[0];
				setVerifiedUser(user);
			}
		} catch (error) {
			const response = handleError({ error });
			if (response?.message)
				alert({ message: response.message, type: 'error' });
		}
		setVerifyingUser(false);
	};

	const clearVerifiedUser = () => setVerifiedUser(null);

	return {
		verifyUser,
		isVerifyingUser,
		verifiedUser,
		clearVerifiedUser,
	};
};

export default useVerifyUser;
