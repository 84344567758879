import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMutation, useQueryClient } from 'react-query';
import {
	DANGER_COLOR,
	LIGHT_GRAY,
	QUERY_KEYS,
	BankAccount,
	THEME_MODE,
} from 'utilities';
import { useHandleError, useAlert } from 'hooks';
import { setDefaultBank, deleteBank } from 'api';
import CustomCheckbox from '../form-components/custom-checkbox';
import Button from '../button';
import Loader from '../loader';
import { useAppSelector } from 'store/hooks';

type Props = {
	active?: string | undefined;
	bank: BankAccount;
};

const BankInformationItem = ({ active, bank }: Props) => {
	const theme = useTheme();
	const mode = useAppSelector((store) => store.theme.mode);
	const isDark = mode === THEME_MODE.dark;
	const alert = useAlert();
	const handleError = useHandleError();
	const styles = useStyles(theme);

	const queryClient = useQueryClient();
	const { isLoading: isUpdatingBank, mutate: mutateSetDefaultBank } =
		useMutation(setDefaultBank, {
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						alert({ message: res.message, type: 'error' });
					}
				}

				if (data && data.success) {
					queryClient.invalidateQueries(QUERY_KEYS.Me);
				}
			},
		});

	const { isLoading: isDeletingBank, mutate: mutateDeleteBank } = useMutation(
		deleteBank,
		{
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						alert({ message: res.message, type: 'error' });
					}
				}

				if (data && data.success) {
					queryClient.invalidateQueries(QUERY_KEYS.Me);
					queryClient.invalidateQueries(QUERY_KEYS.UserBanks);
				}
			},
		}
	);

	const handleSetDefault = () => {
		mutateSetDefaultBank({
			defaultBank: bank.id,
		});
	};

	const handleDeleteBank = () => {
		mutateDeleteBank({
			id: bank.id as string,
			data: {
				supressAdmin: true,
			},
		});
	};

	return (
		<>
			{(isUpdatingBank || isDeletingBank) && <Loader />}
			<Box>
				<Box
					sx={{
						padding: '20px',
						background: `url(${require('../../assets/images/stripBg.png')})`,
						backgroundColor: isDark
							? theme.palette.background.paper
							: LIGHT_GRAY,
						backgroundSize: '320%',
						backgroundPosition: 'start',
						borderRadius: '5px',
						marginBottom: '5px',
						height: '120px',
						alignItems: 'center',
						display: 'flex',
						cursor: 'pointer',
						':hover': {
							backgroundColor: isDark
								? theme.palette.background.paper
								: LIGHT_GRAY,
						},
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '6px',
							flex: 1,
						}}
					>
						<Typography
							sx={{
								fontWeight: '600',
								color: isDark ? grey['100'] : theme.palette.primary.main,
							}}
							variant={'body1'}
						>
							{bank.accountName}
						</Typography>
						<Typography
							sx={{ color: isDark ? grey['100'] : theme.palette.primary.main }}
							variant={'body1'}
						>
							{bank.bankName}
						</Typography>
						<Typography
							sx={{ color: isDark ? grey['100'] : theme.palette.primary.main }}
							variant={'body1'}
						>
							{bank.accountNumber}
						</Typography>
					</Box>
					<CustomCheckbox
						ischecked={active}
						style={{ alignSelf: 'start' }}
						size={'25px'}
					/>
				</Box>
				<Box style={styles.dFlex}>
					{!active && (
						<Box style={styles.dFlex}>
							<Button
								onClick={() => handleSetDefault()}
								style={styles.makeDefaultBtn}
							>
								Make default
							</Button>
							<Box style={styles.verticalLine} />
						</Box>
					)}
					<Button onClick={() => handleDeleteBank()} style={styles.deleteBtn}>
						Delete account
					</Button>
				</Box>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	dFlex: {
		display: 'flex',
		alignItems: 'center',
		gap: '6px',
	},
	deleteBtn: {
		minWidth: 'unset',
		padding: '0px',
		color: DANGER_COLOR,
		fontWeight: '600',
	},
	makeDefaultBtn: {
		minWidth: 'unset',
		padding: '0px',
		color: theme.palette.primary.main,
		fontWeight: '600',
	},
	verticalLine: {
		height: '15px',
		margin: '0px 6px',
		width: '3px',
		backgroundColor: LIGHT_GRAY,
	},
});

export default BankInformationItem;
