import React, { CSSProperties } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Lottie from 'react-lottie';
import Button from '../button';
import { grey, red } from '@mui/material/colors';
import { DANGER_COLOR } from 'utilities';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setModalAlert } from '../../store/app';
import * as success from '../../assets/json-file/success.json';
import * as failed from '../../assets/json-file/failed.json';
import * as pending from '../../assets/json-file/pending.json';
import ModalLayout from '../layout/modal-layout';

const ModalAlert = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const dispatch = useAppDispatch();

	const { modalAlert } = useAppSelector((store) => store.app);

	// Lottie Option
	const defaultOptions = (animationData: any) => ({
		loop: false,
		autoplay: true,
		animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	});

	if (modalAlert) {
		const {
			title,
			message,
			primaryButtonText,
			secondaryButtonText,
			onClickPrimaryButton,
			onClickSecondaryButton,
			children,
			type,
			hasCloseButton,
			callback,
		} = modalAlert;
		const closeModal = () => {
			typeof callback !== 'undefined' && callback();
			dispatch(setModalAlert(null));
		};
		return (
			<ModalLayout hasCloseButton={hasCloseButton} closeModal={closeModal}>
				<Box style={styles.main as CSSProperties}>
					{type && (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							{type === 'success' && (
								<Lottie
									options={defaultOptions(success)}
									height={140}
									width={140}
								/>
							)}
							{type === 'error' && (
								<Lottie
									options={defaultOptions(failed)}
									height={140}
									width={140}
								/>
							)}
							{type === 'pending' && (
								<Lottie
									options={defaultOptions(pending)}
									height={140}
									width={140}
								/>
							)}
						</Box>
					)}

					{title && (
						<Typography
							sx={{
								fontWeight: '600',
								fontSize: '20px',
								textAlign: 'center',
								// marginBottom: '20px',
							}}
							variant={'body1'}
						>
							{title}
						</Typography>
					)}
					{message && (
						<Typography sx={{ textAlign: 'center' }} variant={'body1'}>
							{message}
						</Typography>
					)}

					{children && <Box>{children}</Box>}
					{primaryButtonText && (
						<Button
							onClick={onClickPrimaryButton}
							fullWidth
							size={'large'}
							sx={{
								backgroundColor: `${theme.palette.secondary.main} !important`,
								color: grey[50],
								fontWeight: '600',
							}}
						>
							{primaryButtonText}
						</Button>
					)}

					{secondaryButtonText && (
						<Button
							onClick={onClickSecondaryButton}
							fullWidth
							size={'large'}
							sx={{
								backgroundColor: `${
									type === 'error' ? red['600'] : theme.palette.secondary.main
								} !important`,
								color: grey[50],
								marginBottom: '10px',
								fontWeight: '600',
							}}
						>
							{secondaryButtonText}
						</Button>
					)}
				</Box>
			</ModalLayout>
		);
	}

	return null;
};

const useStyles = (theme: any) => ({
	main: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
		position: 'relative',
	},
	closeButton: {
		position: 'absolute',
		right: '0px',
		top: '0px',
		color: DANGER_COLOR,
	},
});

export default ModalAlert;
