import React from 'react';
import { Box, Drawer as MuiDrawer, IconButton, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { DANGER_COLOR, DRAWER_WIDTH } from 'utilities';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setToggleDrawer } from 'store/app';
import NavList from '../../nav-list';
import LogoWithText from '../../logo/logo-with-text';
import style from './style.module.css';

const Drawer = () => {
	const theme = useTheme();
	const { isToggleDrawer } = useAppSelector((store) => store.app);
	const dispatch = useAppDispatch();
	return (
		<Box style={style}>
			<MuiDrawer
				sx={{
					display: {
						md: 'none',
					},
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: DRAWER_WIDTH,
						padding: '20px',
					},
					padding: '20px',
				}}
				open={isToggleDrawer}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						width: '100%',
						marginBottom: '2rem',
					}}
				>
					<Box
						sx={{
							maxWidth: '160px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<LogoWithText />
					</Box>
					<IconButton
						sx={{
							position: 'absolute',
							right: theme.spacing(1),
							top: theme.spacing(1),
							color: DANGER_COLOR,
						}}
						onClick={() => dispatch(setToggleDrawer(false))}
					>
						<Close />
					</IconButton>
				</Box>
				<NavList />
			</MuiDrawer>
		</Box>
	);
};

export default Drawer;
