import React, { CSSProperties, useState } from 'react';
import { Box, Typography, useTheme, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { VisibilityOutlined } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { useAppSelector } from 'store/hooks';
import {
	formatNumberToCurrency,
	hideContent,
	KYC_LEVEL,
	LINKS,
} from 'utilities';
import { useModal } from 'hooks';

const Wallet = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const modal = useModal();
	const { isLoading, wallet } = useAppSelector((store) => store.walletState);
	const styles = useStyles(theme);

	const [isBalanceVisible, setBalanceVisible] = useState<boolean>(false);

	const toggleBalanceVisibility = () => setBalanceVisible(!isBalanceVisible);

	const mode = useAppSelector((store) => store.theme.mode);
	const kycLevel = useAppSelector((store) => store.auth.user?.kycLevel);

	const handleFundWallet = () => {
		if (parseInt(`${kycLevel}`) < 2) {
			const nestedMessage =
				kycLevel === 1
					? 'National identity verification'
					: kycLevel === 2
					? 'bank verification number'
					: '';
			modal.display({
				title: 'Verification required',
				message: `Unable to perform action, kindly perform your ${nestedMessage} to continue`,
				primaryButtonText: 'Continue',
				secondaryButtonText: 'Cancel',
				onClickPrimaryButton: () => {
					modal.close();
					// typeof handleChangeTab === 'function' && handleChangeTab(3);
					if (kycLevel === KYC_LEVEL.LevelOne) {
						navigate(
							`${LINKS.LevelTwoVerification}?redirect=${LINKS.WalletTopup}`,
							{
								state: {
									redirect: LINKS.WalletTopup,
								},
							}
						);
					}
				},
				onClickSecondaryButton: modal.close,
			});
			return;
		}

		navigate(LINKS.WalletTopup);
	};

	return (
		<Box
			sx={{
				padding: {
					xs: '10px',
					sm: '2rem 8px',
					md: '3rem 1.5rem',
				},
				img: {
					display: 'block',
					width: '100%',
				},
				minHeight: {
					xs: '200px',
					md: 'initial',
				},
			}}
			style={styles.container as any}
		>
			<Typography sx={{ textAlign: 'center' }}>Wallet balance</Typography>
			<Box style={styles.balanceWrapper}>
				{isLoading ? (
					<Typography variant={'h6'} style={styles.balance}>
						---
					</Typography>
				) : (
					wallet &&
					wallet.balance && (
						<Typography variant={'h6'} style={styles.balance}>
							{isBalanceVisible
								? formatNumberToCurrency(
										hideContent(
											typeof wallet.balance === 'string'
												? wallet.balance
												: wallet.balance.$numberDecimal
										)
								  )
								: formatNumberToCurrency(
										typeof wallet.balance === 'string'
											? wallet.balance
											: wallet.balance.$numberDecimal
								  )}
						</Typography>
					)
				)}
				<IconButton onClick={() => toggleBalanceVisibility()}>
					<VisibilityOutlined style={styles.visibilityIcon} />
				</IconButton>
			</Box>
			<Box style={styles.quickActionWrapper} sx={{ display: 'flex' }}>
				<Box
					onClick={handleFundWallet}
					style={styles.quickAction}
					sx={{
						flexDirection: {
							xs: 'column',
							md: 'row',
						},
					}}
				>
					<Box style={styles.imageWrapper}>
						<img
							src={require('assets/icons/topup-wallet.png')}
							alt={'Topup fund'}
						/>
					</Box>
					<Typography
						variant={'body1'}
						style={styles.quickActionText as CSSProperties}
					>
						Fund wallet
					</Typography>
				</Box>
				<Box style={styles.verticalLine} />
				<Box
					onClick={() => navigate(LINKS.WalletWithdraw)}
					style={styles.quickAction}
					sx={{
						flexDirection: {
							xs: 'column',
							md: 'row',
						},
					}}
				>
					<Typography
						variant={'body1'}
						style={styles.quickActionText as CSSProperties}
						sx={{
							order: {
								xs: 2,
								md: 1,
							},
						}}
					>
						Withdraw funds
					</Typography>
					<Box style={styles.imageWrapper}>
						<img
							src={require('assets/icons/withdraw-fund.png')}
							alt={'Withdraw fund'}
						/>
					</Box>
				</Box>
				<Box style={styles.verticalLine} />
				<Box
					onClick={() => navigate(LINKS.WalletTransfer)}
					style={styles.quickAction}
					sx={{
						flexDirection: {
							xs: 'column',
							md: 'row',
						},
					}}
				>
					<Typography
						variant={'body1'}
						style={styles.quickActionText as CSSProperties}
						sx={{
							order: {
								xs: 2,
								md: 1,
							},
						}}
					>
						Transfer funds
					</Typography>
					<Box style={styles.imageWrapper}>
						<img
							src={require('assets/icons/transfer-fund.png')}
							alt={'Transfer fund'}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	container: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: theme.spacing(2),
		color: grey[50],
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		gap: theme.spacing(2),
	},
	balanceWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: theme.spacing(2),
		marginBottom: theme.spacing(3),
	},
	balance: {
		fontWeight: '600',
	},
	visibilityIcon: {
		fontSize: '18px',
		color: grey[50],
	},
	quickActionWrapper: {
		display: 'flex',
		gap: theme.spacing(2),
		alignItems: 'center',
		justifyContent: 'space-around',
	},
	verticalLine: {
		width: '2px',
		backgroundColor: grey[50],
		height: '12px',
	},
	quickAction: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(2),
		cursor: 'pointer',
	},
	quickActionText: {
		fontWeight: '600',
		fontSize: '12px',
		whiteSpace: 'nowrap',
	},
	imageWrapper: {
		maxWidth: '16px',
		flexShrink: '0',
	},
});

export default Wallet;
