import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import { LIGHT_GRAY, THEME_MODE } from 'utilities';
import { useAppSelector } from 'store/hooks';
import { grey } from '@mui/material/colors';

type Props = {
	icon: any;
	title: string;
	description: string;
	iconSize?: string;
	link: string;
	onClick?: (e) => void;
};

const QuickServiceItem = ({
	icon: Icon,
	title,
	description,
	iconSize,
	link,
	onClick,
}: Props) => {
	const theme = useTheme();
	const { mode } = useAppSelector((store) => store.theme);
	return (
		<NavLink
			onClick={onClick}
			style={{ textDecoration: 'none', color: 'inherit' }}
			to={link}
		>
			<Box
				sx={{
					height: '200px',
					backgroundImage: `url(${require('assets/images/bgImage.png')})`,
					backgroundColor:
						mode === THEME_MODE.dark
							? theme.palette.background.paper
							: LIGHT_GRAY,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					display: 'flex',
					flexDirection: 'column',
					gap: (theme) => theme.spacing(3),
					justifyContent: 'flex-start',
					padding: '1.5rem 15px',
					borderRadius: '5px',
					// marginRight: (theme) => theme.spacing(2),
				}}
			>
				<Box
					sx={{
						maxWidth: iconSize ? iconSize : '25px',
						margin: '0px auto',
						img: {
							width: '100%',
						},
					}}
				>
					{/* <img src={icon} alt={title} /> */}
					<Icon
						color={
							mode === THEME_MODE.dark
								? grey['100']
								: theme.palette.primary.main
						}
					/>
				</Box>
				<Typography
					sx={{
						fontWeight: '600',
						textAlign: 'center',
						color:
							mode === THEME_MODE.dark
								? grey['100']
								: theme.palette.primary.main,
					}}
				>
					{title}
				</Typography>
				<Typography
					sx={{
						fontSize: '13px',
						textAlign: 'center',
						color:
							mode === THEME_MODE.dark
								? grey['400']
								: theme.palette.primary.main,
					}}
					variant={'body1'}
				>
					{description}
				</Typography>
			</Box>
		</NavLink>
	);
};

export default QuickServiceItem;
