import React from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { Layout, IdentityVerificationForm } from 'components';
import { LINKS } from 'utilities';

const LevelTwoVerification = () => {
	const styles = useStyles();
	const navigate = useNavigate();

	const { search, state } = useLocation();
	const parseUrl: Record<string, any> = queryString.parse(search);

	const callback = () => {
		if (parseUrl && parseUrl.redirect) {
			return navigate(parseUrl.redirect, { replace: true });
		}

		if (state && state.redirect) {
			return navigate(parseUrl.redirect, { replace: true, state: {} });
		}

		navigate(LINKS.Dashboard);
	};

	return (
		<Layout>
			<Box style={styles.container}>
				<Typography style={styles.title} variant={'h5'}>
					Identity Verification
				</Typography>
				<IdentityVerificationForm callback={callback} />
			</Box>
		</Layout>
	);
};

const useStyles = () => ({
	container: {
		maxWidth: '540px',
	},
	title: {
		fontWeight: '600',
		marginBottom: '20px',
	},
});

export default LevelTwoVerification;
