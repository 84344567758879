import React from 'react';
import { AuthLayout, Seo, VerifyEmailForm } from 'components';

const VerifyAccount = () => {
	return (
		<AuthLayout>
			<Seo title={'Verify Account'} />
			<VerifyEmailForm />
		</AuthLayout>
	);
};

export default VerifyAccount;
