import React, { useRef } from 'react';
import moment from 'moment';
import {
	Amount,
	ITransaction,
	checkAmount,
	formatNumberToCurrency,
} from 'utilities';
import AppTable from './components/table';
import TransactionDetails from 'components/transaction-details';

interface Props {
	data: ITransaction[] | null;
	isLoading?: boolean;
}

const ReversalTransactionsTable = ({ data, isLoading }: Props) => {
	const transactionDetailsRef = useRef<any>(null);
	const handleRowClick = (transaction: ITransaction) => {
		if (transactionDetailsRef.current)
			transactionDetailsRef.current.display(transaction);
	};
	return (
		<>
			<TransactionDetails ref={transactionDetailsRef} />
			<AppTable
				canClickRow
				onRowClick={handleRowClick}
				numberOfColumns={5}
				isLoading={isLoading}
				header={[
					'Reference',
					'Balance Before',
					'Balance After',

					'Created At',
					'Status',
				]}
				body={
					data &&
					data.map((value) => {
						return {
							data: [
								value.reference,
								formatNumberToCurrency(
									checkAmount((value.balanceBefore as string | Amount) || '')
								),
								formatNumberToCurrency(
									checkAmount((value.balanceAfter as string | Amount) || '')
								),
								moment(value.createdAt).format('ll'),
								value.status,
							],
							rawData: value,
						};
					})
				}
				emptyText={'No reversal transaction'}
			/>
		</>
	);
};

export default ReversalTransactionsTable;
