import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import ServicesCard from '../card/services';
import { THEME_MODE, LINKS } from 'utilities';
import { useAppSelector } from 'store/hooks';

const SERVICES = [
	{
		title: 'Convert Airtime',
		description: `Easily convert your over recharged airtime and get paid in minutes.`,

		img: require('../../assets/images/convert-airtime.png'),
		link: LINKS.ConvertAirtime,
	},
	{
		title: 'Bill Payments',
		description: `Conveniently make cable, power, educational and several other bills.`,
		img: require('../../assets/images/pay-bill.png'),
		link: LINKS.PayBill,
	},
	{
		title: 'Airtime Purchase',
		description: `Make airtime purchases to any network and pay from your mobile wallet.`,
		img: require('../../assets/images/purchase-airtime.png'),
		link: LINKS.PurchaseAirtime,
	},
	{
		title: 'Data Purchase',
		description:
			'Make data purchases to any network and pay from your mobile wallet.',
		img: require('../../assets/images/purchase-data.png'),
		link: LINKS.PurchaseData,
	},
	{
		title: 'Fund Betting Account',
		description:
			'Let us help you fund your betting account to bet on your favorite teams.',
		img: require('../../assets/images/betting.png'),
		link: LINKS.FundBettingAccount,
	},
	{
		title: 'International Airtime and Data',
		description: `Buy international airtime and data at mind blowing rates.`,
		img: require('../../assets/images/purchase-airtime.png'),
		link: LINKS.InternationalAirtimeData,
		isNew: true,
	},
	{
		title: 'E-Sim',
		description: `Get an electronic sim of your favorite network.`,
		img: require('../../assets/images/e-sim.png'),
		link: LINKS.ESim,
		isNew: true,
	},
	{
		title: 'Giftcards',
		description: `Buy your giftcards with ease in few easy clicks.`,
		img: require('../../assets/images/pay-bill.png'),
		link: LINKS.GiftCards,
		isNew: true,
	},
	// {
	// 	title: 'E-Vouchers',
	// 	description: `Get E-voucher that can be easily redeemed`,
	// 	img: require('../../assets/images/voucher.png'),
	// 	link: LINKS.EVouchers,
	// 	isNew: true,
	// },
];

const Services = () => {
	const theme = useTheme();
	const themeMode = useAppSelector((store) => store.theme.mode);
	const styles = useStyles(theme, themeMode);
	return (
		<div>
			<Typography style={styles.title} variant={'h5'}>
				Services
			</Typography>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: {
						xs: '1fr',
						sm: 'repeat(2, 1fr)',
					},
					gap: '10px',
				}}
			>
				{SERVICES.map((data, key) => (
					<ServicesCard data={data} key={key} isNew={data.isNew} />
				))}
			</Box>
		</div>
	);
};

const useStyles = (theme: any, mode?: any) => ({
	title: {
		marginBottom: theme.spacing(3),
		fontWeight: '600',
		color: mode === THEME_MODE.dark ? grey[300] : 'inherit',
	},
});

export default Services;
