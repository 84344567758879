import { useEffect } from 'react';

const usePageTitle = (title: string) => {
	useEffect(() => {
		document.title = title ? `${title} | Airtimeflip` : 'Airtimeflp';
	}, [title]);
	return null;
};

export default usePageTitle;
