import { useQuery } from 'react-query';
import { Box, Typography } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { QUERY_KEYS, IUser, SECOUNDARY_COLOR } from 'utilities';
import { me } from 'api';
import { setUser } from '../store/auth';
import useToastAlert from './useToastAlert';
import useHandleResponse from './useHandleError';
import useLogout from './useLogoutUser';
import useModalAlert from './useSetModalAlert';
import { Image, Button, TransactionPinForm } from 'components';
import { useAlert } from 'hooks';

const SetupPinModal = () => {
	return (
		<Box>
			<Box sx={{ marginBottom: '30px' }}>
				<Typography
					sx={{ marginBottom: '10px', fontWeight: 'bold' }}
					variant={'h6'}
				>
					Setup your Transaction PIN
				</Typography>
				<Typography variant={'body1'}>Enter transaction pin</Typography>
			</Box>
			<TransactionPinForm />
		</Box>
	);
};

const useLoadUser = () => {
	const {
		auth: { token },
		app: { modalAlert },
	} = useAppSelector((store) => store);
	const logout = useLogout();
	const alert = useToastAlert();
	const handleResponse = useHandleResponse();
	const dispatch = useAppDispatch();
	const modal = useModalAlert();

	// Load user
	useQuery([QUERY_KEYS.Me], me, {
		enabled: !!token,
		retry: 1,
		onSettled: (data, error) => {
			if (error) {
				const response = handleResponse({ error });
				if (response?.message) {
					alert({ type: 'error', message: response.message });
				}
			}

			if (data && data.success) {
				if (Array.isArray(data.payload)) {
					const user: IUser = data.payload[0];
					if (user.suspended) {
						alert({
							message: 'Account has been suspended, Contact support',
							type: 'info',
						});
						return logout();
					}

					// if (user.suspended) {
					// 	alert({
					// 		message: 'Account has been suspended, Contact support',
					// 		type: 'info',
					// 	});
					// 	return logout();
					// }

					if (!modalAlert && !user.hasPin) {
						modal({
							children: (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										flexDirection: 'column',
										gap: '20px',
									}}
								>
									<Image
										sx={{ width: '100px' }}
										src={require('../assets/icons/lock.png')}
										alt={'Lock'}
									/>
									<Typography sx={{ fontWeight: 'bold' }} variant={'h6'}>
										Welcome to AirtimeFlip
									</Typography>
									<Typography sx={{ textAlign: 'center' }} variant={'body1'}>
										Let’s go ahead to setup your Transaction PIN for payment
										verification
									</Typography>
									<Button
										sx={{
											backgroundColor: `${SECOUNDARY_COLOR} !important`,
											marginTop: '2rem',
											color: 'white',
											minWidth: '180px',
										}}
										onClick={() =>
											modal({
												children: <SetupPinModal />,
											})
										}
										size={'large'}
									>
										Continue
									</Button>
								</Box>
							),
						});
						// dispatch(setDisplayPinInfo(true));
					}
					dispatch(setUser(user));
				} else {
					const user = data.payload as IUser;
					dispatch(setUser(user));
				}
			}
		},
	});
};

export const useReloadUser = () => {
	const dispatch = useAppDispatch();
	const alert = useAlert();
	const handleResponse = useHandleResponse();
	return async () => {
		try {
			const response = await me();
			if (response.success) {
				const payload = response.payload;
				if (Array.isArray(payload)) {
					const user = payload[0];
					dispatch(setUser(user));
				} else {
					dispatch(setUser(payload));
				}
			}
		} catch (error) {
			const response = handleResponse({ error });
			if (response?.message)
				alert({
					message: response.message,
					type: 'error',
				});
		}
	};
};

export default useLoadUser;
