import React, { useEffect, useState } from 'react';
import {
	Box,
	MenuItem,
	Typography,
	useTheme,
	InputAdornment,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { grey } from '@mui/material/colors';
import { useFormik } from 'formik';
import TextInput from '../form-components/text-input';
import Button from '../button';
import Select from '../form-components/select';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
	QUERY_KEYS,
	IBill,
	API_ENDPOINTS,
	IProvider,
	ICablePlan,
	AvailablePricingOption,
	TICKET_TYPE,
	ValidationSchema,
	LINKS,
	ENDPOINT_SUBPATH,
	formatNumberToCurrency,
	Bundle,
	getMonthlyWeeklyDaily,
	calculateFinalAmount,
	Coupon,
	BENEFICIARY_TYPE,
	TRANSACTION_SERVICE,
} from 'utilities';
import Loader from '../loader';
import CustomTextInputHolder from '../partials/custom-text-input-holder';
import {
	useAlert,
	useHandleError,
	useVerifyCoupon,
	useModalAlert,
	useVerifyPin,
	useVerifyBillAccount,
	useCheckKycLevelTwo,
	useCreateBeneficiary,
} from 'hooks';
import { billBundles, billPayment, billProviders } from 'api';
import { setTransactionBreakdown } from 'store/transaction';
import CheckBox from 'components/form-components/check-box';
import SelectBeneficiary from 'components/beneficiary/select-beneficiary';
import Checkbox from '../form-components/check-box';

type Props = {
	enableRequest?: boolean;
};

const SELECT_SERVICE_PROVIDER = 'Select TV service';
const SELECT_SUBSCRIPTION_PLAN = 'Select subscription plan';
const SELECT_SUBSCRIPTION_ADDON = 'Select subscription add-on';
const SELECT_DURATION = 'Select number of month';

const CablePaymentForm = ({ enableRequest = false }: Props) => {
	const handleError = useHandleError();
	const verifyPin = useVerifyPin();
	const dispatch = useAppDispatch();
	const checkKycLevelTwo = useCheckKycLevelTwo();
	const modal = useModalAlert();
	const alert = useAlert();
	const theme = useTheme();
	const navigate = useNavigate();
	const { search } = useLocation();
	const styles = useStyles(theme);
	const queryClient = useQueryClient();
	const { token } = useAppSelector((store) => store.auth);
	const {
		isVerifyingBillAccount,
		verifyBillAccount,
		billAccount,
		clearBillAccount,
	} = useVerifyBillAccount();
	const { isVerifyingCoupon, verifyCoupon, coupon, clearCoupon } =
		useVerifyCoupon();

	// States
	const [selectedPlan, setSelectedPlan] = useState<Bundle | null>(null);
	const [dataCablePlans, setDataCablePlans] = useState<Bundle[] | null>(null);
	const [isLoadingCablePlans, setLoadingCablePlans] = useState<boolean>(false);
	const [hasAddOn, setHasAddOn] = useState<boolean>(false);

	const [dataAddOns, setDataAddOns] = useState<Bundle[] | null>(null);
	const [selectedAddOn, setSelectedAddOn] = useState<null | Bundle>(null);
	const [isLoadingAddons, setLoadingAddons] = useState<boolean>(false);

	const [isSaveBeneficiary, setSaveBeneficiary] = useState<boolean>(false);
	const [beneficiary, setBeneficiary] = useState<null | { [key: string]: any }>(
		null
	);

	// available pricing options
	const selectedPlanOrAddon =
		hasAddOn && selectedAddOn ? selectedAddOn : selectedPlan;

	const { createBeneficiary } = useCreateBeneficiary(() => {
		setBeneficiary(null);
	});

	const clearState = () => {
		setSelectedPlan(null);
		setDataCablePlans(null);
		clearCoupon();
		clearBillAccount();
		resetForm();
	};

	useEffect(
		() => {
			if (coupon) {
				dispatch(setTransactionBreakdown({ coupon }));
			}
			// Clear state when page change
			if (search) {
				clearState();
			}
		},
		//eslint-disable-next-line
		[coupon, search]
	);

	// Cable Payment Mutation
	const { isLoading: isMakingPayment, mutate: mutateBillPayment } = useMutation(
		billPayment,
		{
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						modal({
							message: res.message,
							title: 'Cable Payment Error',
							primaryButtonText: 'Contact Support',
							type: 'error',
							hasCloseButton: true,
							onClickPrimaryButton: () => {
								modal(null);
								navigate(
									`${LINKS.CreateSupportTicket}?_type=${TICKET_TYPE.COMPLAINT}`
								);
							},
						});
					}
				}

				if (data && data.success) {
					if (isSaveBeneficiary)
						createBeneficiary({
							type: BENEFICIARY_TYPE.CABLE,
							record: beneficiary,
						});
					resetForm();

					queryClient.invalidateQueries(QUERY_KEYS.UserWallet);
					queryClient.invalidateQueries([QUERY_KEYS.Transactions]);
					modal({
						message: `Cable payment was successfully!`,
						title: 'Cable Payment',
						primaryButtonText: 'Make another payment',
						secondaryButtonText: 'Close',
						type: 'success',
						onClickPrimaryButton: () => {
							modal(null);
							clearState();
						},
						onClickSecondaryButton: () => {
							modal(null);
							clearState();
						},
					});
				}
			},
		}
	);

	// Cable Payment Initial Values

	const initialValues: IBill = {
		service_type: SELECT_SERVICE_PROVIDER,
		smartcard_number: '',
		monthsPaidFor: SELECT_DURATION,
		price: '',
		product_code: SELECT_SUBSCRIPTION_PLAN,
		discount_code: '',
		addon: SELECT_SUBSCRIPTION_ADDON,
	};

	// Load Cable Providers

	const { isLoading: isLoadingCableProvider, data: dataCableProviders } =
		useQuery(
			QUERY_KEYS.CableProviders,
			() =>
				billProviders(`${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.CableProvider}`),
			{
				enabled: !!(token && enableRequest),
				refetchOnWindowFocus: false,
				onSettled: (data, error) => {
					if (error) {
						const res = handleError({ error });
						if (res?.message) {
							alert({ message: res.message, type: 'error' });
						}
					}
				},
			}
		);

	// Cable Payment Formik initialization

	const {
		values,
		handleSubmit,
		errors,
		touched,
		handleChange,
		resetForm,
		setFieldValue,
		setFieldError,
	} = useFormik({
		initialValues,
		validationSchema: ValidationSchema.CablePayment,
		onSubmit: (values) => {
			let data = {
				service_type: values.service_type,
				product_code: values.product_code,
				smartcard_number: values.smartcard_number,
				price: values.price,
				monthsPaidFor: parseFloat(`${values.monthsPaidFor}`),
			} as typeof initialValues;

			let amount = parseFloat(`${values.price}`);

			if (hasAddOn) {
				if (selectedAddOn && selectedAddOn.code !== values.addon) {
					setFieldError('addOn', SELECT_SUBSCRIPTION_ADDON);

					return;
				}

				setFieldError('addOn', '');
				data.product_code = values.addon;
			}

			if (discount_code) {
				if (!coupon) {
					return alert({ message: 'Verify Coupon', type: 'info' });
				}
				if (coupon && values.discount_code !== coupon.code) {
					setFieldError(
						'discount_code',
						`Verified discount doesn't match provided discount code`
					);
					return alert({
						message: `Verified discount doesn't match provided discount code`,
						type: 'info',
					});
				}

				setFieldError('discount_code', ``);

				amount = calculateFinalAmount({
					coupon: coupon as Coupon,
					amount,
				});

				data.discount_code = values.discount_code;
			}

			if (isSaveBeneficiary) {
				setBeneficiary({
					smartcard_number: values.smartcard_number,
					provider:
						dataCableProviders &&
						dataCableProviders.payload.find(
							(provider) => provider.id === values.service_type
						),
					transactionType: TRANSACTION_SERVICE.CABLE,
				});
			}

			const url = `${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.CablePayment}`;

			if (checkKycLevelTwo()) {
				verifyPin({
					title: 'Cable Payment',
					message: `Verify transaction pin to confirm cable payment of ${
						selectedPlan?.name
					} for ${formatNumberToCurrency(amount)}.`,
					callback: () => {
						verifyPin(null);
						mutateBillPayment({
							url,
							data,
						});
					},
				});
			}
		},
	});

	const {
		service_type,
		monthsPaidFor,
		smartcard_number,
		discount_code,
		product_code,
		price,
		addon,
	} = values;

	/* 
		Load Cable  Plans
	*/

	const loadCablePlans = async (provider: string) => {
		const url = `${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.CableProvider}`;
		// Clear Selected plan state
		setSelectedPlan(null);
		setLoadingCablePlans(true);
		try {
			const data = await billBundles({
				url,
				params: {
					provider,
				},
			});

			if (data && data.success) {
				setDataCablePlans(data.payload);
			}
		} catch (error) {}
		setLoadingCablePlans(false);
	};

	// Load Add-ons
	const loadCableAddOns = async () => {
		const url = `${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.CableProvider}`;
		// Clear Selected plan state
		setSelectedAddOn(null);
		setLoadingAddons(true);
		try {
			const data = await billBundles({
				url,
				params: {
					provider: service_type,
					isAddOnsEnabled: true,
					product_code: selectedPlan?.code,
				},
			});

			if (data && data.success) {
				setDataAddOns(data.payload);
			}
		} catch (error) {}
		setLoadingAddons(false);
	};

	// Account Number Change
	const handleAccountNumberChange = (value: string) => {
		setFieldValue('smartcard_number', value);
		if (value.length === 10) {
			verifyBillAccount({
				account_number: value,
				service_type: service_type as string,
			});
		}

		if (billAccount && (value.length < 10 || value.length > 10)) {
			clearBillAccount();
		}
	};

	const handleSelectCableService = (value: string) => {
		setFieldValue('service_type', value);
		setFieldValue('product_code', SELECT_SUBSCRIPTION_PLAN);
		setFieldValue('monthsPaidFor', SELECT_DURATION);
		setHasAddOn(false);
		setFieldValue('addon', SELECT_SUBSCRIPTION_ADDON);
		dispatch(setTransactionBreakdown({ serviceCost: 0 }));
		loadCablePlans(value as string);
	};

	const handleSelectCablePlan = (value: string) => {
		// Reset States
		setFieldValue('product_code', value);
		setFieldValue('monthsPaidFor', SELECT_DURATION);
		setFieldValue('price', '');
		setHasAddOn(false);
		setFieldValue('addon', SELECT_SUBSCRIPTION_ADDON);

		dispatch(setTransactionBreakdown({ serviceCost: 0 }));
		const selectedPlan =
			dataCablePlans &&
			dataCablePlans.find((plan: Bundle) => plan.code === value);

		setSelectedPlan(selectedPlan as Bundle);
	};

	// Manage select addon
	const handleSelectAddon = (value: string) => {
		setFieldValue('addon', value);
		setFieldValue('monthsPaidFor', SELECT_DURATION);
		setFieldValue('price', '');
		dispatch(setTransactionBreakdown({ serviceCost: 0 }));
		const selectedAddon =
			dataAddOns && dataAddOns.find((plan: Bundle) => plan.code === value);

		setSelectedAddOn(selectedAddon as Bundle); // Update state
	};

	const handleSelectSubscriptionDuration = (value: number) => {
		setFieldValue('monthsPaidFor', value);
		const pricingOption =
			selectedPlanOrAddon &&
			selectedPlanOrAddon.availablePricingOptions.find(
				(option: AvailablePricingOption) => option.monthsPaidFor === value
			);
		if (pricingOption) {
			dispatch(setTransactionBreakdown({ serviceCost: pricingOption?.price }));
			setFieldValue('price', pricingOption.price);
		}

		// setAvailablePricingOption(pricingOption as AvailablePricingOption);
	};

	const handleSelectBeneficiary = (beneficiary: { [key: string]: any }) => {
		const smartcard_number = beneficiary.record.smartcard_number;
		const provider = beneficiary.record.provider;
		setFieldValue('smartcard_number', smartcard_number);
		setFieldValue('service_type', provider.service_type);
	};

	// Check Addon
	const handleCheckAddon = (checked: boolean) => {
		setHasAddOn(checked);
		if (checked) {
			loadCableAddOns();
		} else {
			// Clear Addon State
			setFieldValue('addon', SELECT_SUBSCRIPTION_ADDON);
			setSelectedAddOn(null);
			setFieldValue('monthsPaidFor', SELECT_DURATION);
			setFieldValue('price', '');
			dispatch(setTransactionBreakdown({ serviceCost: 0 }));
		}
	};

	return (
		<>
			{(isVerifyingBillAccount || isVerifyingCoupon) && <Loader />}
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Cable service
					</Typography>
					<Select
						fullWidth
						error={
							errors && touched.service_type && errors.service_type
								? true
								: false
						}
						helpertext={errors && touched.service_type && errors.service_type}
						value={service_type}
						onChange={(e) => {
							const value = e.target.value;
							handleSelectCableService(value as string);
						}}
					>
						<MenuItem disabled value={SELECT_SERVICE_PROVIDER}>
							{isLoadingCableProvider
								? 'Loading...'
								: dataCableProviders && dataCableProviders.payload.length === 0
								? 'No avialable TV service'
								: SELECT_SERVICE_PROVIDER}
						</MenuItem>
						{dataCableProviders &&
							dataCableProviders.payload.length > 0 &&
							dataCableProviders.payload.map(
								(provider: IProvider, key: number) => (
									<MenuItem value={provider.service_type} key={key}>
										{provider.name}
									</MenuItem>
								)
							)}
					</Select>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Subscription plan
					</Typography>
					{service_type === SELECT_SERVICE_PROVIDER ? (
						<CustomTextInputHolder text={SELECT_SUBSCRIPTION_PLAN} />
					) : (
						<Select
							error={touched.product_code && errors.product_code ? true : false}
							helpertext={touched.product_code && errors.product_code}
							fullWidth
							value={product_code}
							onChange={(e) => {
								const value = e.target.value;
								handleSelectCablePlan(value as string);
							}}
						>
							<MenuItem disabled value={SELECT_SUBSCRIPTION_PLAN}>
								{isLoadingCablePlans
									? 'Loading...'
									: dataCablePlans && dataCablePlans.length === 0
									? 'No available plan'
									: SELECT_SUBSCRIPTION_PLAN}
							</MenuItem>
							{dataCablePlans &&
								dataCablePlans.length > 0 &&
								dataCablePlans.map((plan: ICablePlan, key: number) => (
									<MenuItem value={plan.code} key={key}>
										{plan.name}
									</MenuItem>
								))}
						</Select>
					)}
				</Box>

				{new RegExp('dstv', 'ig').test(`${service_type}`) && (
					<>
						<Box>
							<Checkbox
								label={'Add-on'}
								checked={hasAddOn}
								onChange={(e) => {
									const checked = e.target.checked;

									handleCheckAddon(checked);
								}}
							/>
						</Box>
						{hasAddOn && (
							<Box>
								<Typography
									style={styles.label}
									component={'label'}
									variant={'body1'}
								>
									Subscription Addon
								</Typography>
								{product_code === SELECT_SUBSCRIPTION_PLAN ? (
									<CustomTextInputHolder text={SELECT_SUBSCRIPTION_ADDON} />
								) : (
									<Select
										error={touched.addon && errors.addon ? true : false}
										helpertext={touched.addon && errors.addon}
										fullWidth
										value={addon}
										onChange={(e) => {
											const value = e.target.value;
											handleSelectAddon(value as string);
										}}
									>
										<MenuItem disabled value={SELECT_SUBSCRIPTION_ADDON}>
											{isLoadingAddons
												? 'Loading...'
												: dataAddOns && dataAddOns.length === 0
												? 'No available addon'
												: SELECT_SUBSCRIPTION_ADDON}
										</MenuItem>
										{dataAddOns &&
											dataAddOns.length > 0 &&
											dataAddOns.map((plan: ICablePlan, key: number) => (
												<MenuItem value={plan.code} key={key}>
													{plan.name}
												</MenuItem>
											))}
									</Select>
								)}
							</Box>
						)}
					</>
				)}

				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						No. of{' '}
						{selectedPlanOrAddon
							? getMonthlyWeeklyDaily(selectedPlanOrAddon.name)
							: 'Month/week'}
					</Typography>
					{product_code === SELECT_SUBSCRIPTION_PLAN ? (
						<CustomTextInputHolder
							text={'Select number of month'}
							isSelectable
						/>
					) : (
						<Select
							error={
								touched.monthsPaidFor && errors.monthsPaidFor ? true : false
							}
							helpertext={touched.monthsPaidFor && errors.monthsPaidFor}
							fullWidth
							value={monthsPaidFor}
							onChange={(e) => {
								const value = e.target.value;
								handleSelectSubscriptionDuration(Number(value));
							}}
						>
							<MenuItem value={SELECT_DURATION}>{`Select number of ${
								selectedPlanOrAddon
									? getMonthlyWeeklyDaily(selectedPlanOrAddon.name)
									: 'Month/week'
							}`}</MenuItem>
							{selectedPlanOrAddon &&
								selectedPlanOrAddon.availablePricingOptions.map(
									(value: AvailablePricingOption, key: number) => (
										<MenuItem
											value={value.monthsPaidFor}
											key={key}
										>{`${formatNumberToCurrency(value.price)} for ${
											value.monthsPaidFor
										} ${getMonthlyWeeklyDaily(
											selectedPlanOrAddon.name
										)}(s)`}</MenuItem>
									)
								)}
						</Select>
					)}
				</Box>
				{price && (
					<Box>
						<Typography
							style={styles.label}
							component={'label'}
							variant={'body1'}
						>
							Subscription cost
						</Typography>
						<CustomTextInputHolder text={formatNumberToCurrency(price)} />
					</Box>
				)}
				<Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginBottom: '6px',
						}}
					>
						<Typography
							sx={{
								marginBottom: '0px !important',
							}}
							style={styles.label}
							component={'label'}
							variant={'body1'}
						>
							Smart card number
						</Typography>
						<SelectBeneficiary
							onSelect={handleSelectBeneficiary}
							type={BENEFICIARY_TYPE.CABLE}
						/>
					</Box>
					{service_type && service_type === SELECT_SERVICE_PROVIDER ? (
						<CustomTextInputHolder text={'Type in your smart card number'} />
					) : (
						<TextInput
							fullWidth
							error={
								touched.smartcard_number && errors.smartcard_number
									? true
									: false
							}
							helperText={touched.smartcard_number && errors.smartcard_number}
							placeholder={'Type in your smart card number'}
							type={'number'}
							value={smartcard_number}
							onChange={(e) => {
								if (smartcard_number && smartcard_number.length > 10) {
									handleAccountNumberChange(
										smartcard_number.slice(1, smartcard_number.length)
									);
								} else {
									handleAccountNumberChange(e.target.value);
								}
							}}
						/>
					)}
				</Box>

				{billAccount && (
					<Box>
						<Typography
							style={styles.label}
							component={'label'}
							variant={'body1'}
						>
							Customer name
						</Typography>
						<CustomTextInputHolder text={billAccount.user.name} />
					</Box>
				)}

				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Discount code [optional]
					</Typography>
					<TextInput
						fullWidth
						error={Boolean(touched.discount_code && errors.discount_code)}
						helperText={touched.discount_code && errors.discount_code}
						placeholder={'Type in your discount code'}
						value={discount_code}
						onChange={handleChange('discount_code')}
						InputProps={{
							endAdornment: (
								<InputAdornment position='start'>
									<Button
										onClick={() => {
											verifyCoupon(discount_code as string);
											// setValidatingDiscountCode(true);
										}}
										disableRipple
										style={styles.applyBtn}
									>
										Apply
									</Button>
								</InputAdornment>
							),
						}}
					/>
				</Box>

				<Box>
					<CheckBox
						label={'Save smart card number for later'}
						value={isSaveBeneficiary}
						onChange={() => setSaveBeneficiary(!isSaveBeneficiary)}
					/>
				</Box>

				<Button
					loading={isMakingPayment}
					size={'large'}
					style={styles.btn}
					type={'submit'}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Purchase
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default CablePaymentForm;
