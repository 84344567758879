import { API_ENDPOINTS } from '../../utilities';
import { apiRequest } from '../config';

export const beneficiaries = async (params?: {
	[key: string]: any;
}): Promise<any> => {
	return apiRequest({
		url: `${API_ENDPOINTS.Beneficiary}`,
		method: 'GET',
		params,
	});
};

export const createBeneficiary = async (data: {
	[key: string]: any;
}): Promise<any> => {
	return apiRequest({
		url: `${API_ENDPOINTS.Beneficiary}`,
		method: 'POST',
		data,
	});
};

export const deleteBeneficiary = async (id: string): Promise<any> => {
	return apiRequest({
		url: `${API_ENDPOINTS.Beneficiary}/${id}`,
		method: 'DELETE',
	});
};
