import React from 'react';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { Layout, BvnVerificationForm } from 'components';
import { LINKS } from 'utilities';

const BvnVerification = () => {
	const styles = useStyles();
	const navigate = useNavigate();

	const { search, state } = useLocation();
	const parseUrl: Record<string, any> = queryString.parse(search);

	const callback = () => {
		if (parseUrl && parseUrl.redirect) {
			return navigate(parseUrl.redirect, { replace: true });
		}

		if (state && state.redirect) {
			return navigate(parseUrl.redirect, { replace: true, state: {} });
		}

		navigate(LINKS.Dashboard);
	};

	return (
		<Layout>
			<Box style={styles.container}>
				<BvnVerificationForm
					hideSkipButton
					text={`Please type in your BVN below and ensure that the names attached to your BVN
					match the first and last names of your name on AirtimeFlip`}
					callback={callback}
				/>
			</Box>
		</Layout>
	);
};

const useStyles = () => ({
	container: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '30px',
		maxWidth: '600px',
	},
	title: {
		fontWeight: '600',
		marginBottom: '20px',
	},
});

export default BvnVerification;
