import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { grey } from '@mui/material/colors';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextInput from '../form-components/text-input';
import Button from '../button';
import Loader from '../loader';
import CustomPlaceholder from '../partials/custom-text-input-holder';
import { UseEPin, LINKS } from 'utilities';
import {
	useHandleError,
	useVerifyBillAccount,
	useVerifyPin,
	useModalAlert,
} from 'hooks';
import { useEPin } from 'api';

interface Props {
	serviceType: string;
	pin: string;
}

const LoadPowerEpinForm = ({ pin, serviceType }: Props) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const navigate = useNavigate();
	const modal = useModalAlert();
	const verifyPin = useVerifyPin();
	const handleError = useHandleError();
	const { isVerifyingBillAccount, verifyBillAccount, billAccount } =
		useVerifyBillAccount();

	const initialValues: { meter_number: string } = {
		meter_number: '',
	};

	const validationSchema = yup.object().shape({
		meter_number: yup
			.string()
			.required('Enter meter number')
			.matches(/^\d{11}$/, 'Meter number must be 11 digits'),
	});

	const { isLoading, mutate } = useMutation(useEPin, {
		onSettled: (data, error) => {
			if (error) {
				const response = handleError({ error });
				if (response?.message) {
					modal({
						title: 'Power E-Pin',
						message: response?.message as string,
						type: 'error',
						onClickPrimaryButton: () => modal(null),
						primaryButtonText: 'Close',
					});
				}
			}

			if (data && data.success) {
				resetForm();
				/* 
					!TODO
						pin: data.electricity_token?.token,
				*/
				modal({
					title: 'Power E-Pin',
					message: data.message as string,
					type: 'success',
					secondaryButtonText: 'Close',
					onClickSecondaryButton: () => modal(null),
					onClickPrimaryButton: () => {
						modal(null);
						navigate(LINKS.Dashboard);
					},
					primaryButtonText: 'Go to Dashboard',
				});
			}
		},
	});

	const { handleSubmit, errors, touched, values, setFieldValue, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: ({ meter_number }) => {
				const data = {
					pin,
					user_data: {
						meter_number,
					},
				} as UseEPin;

				verifyPin({
					title: 'Load Power E-pin',
					message: 'Verify transaction pin to load power pin',
					callback: () => mutate(data),
				});
			},
		});

	return (
		<>
			{isVerifyingBillAccount && <Loader />}
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Meter number
					</Typography>
					<TextInput
						fullWidth
						error={touched.meter_number && errors.meter_number ? true : false}
						helperText={touched.meter_number && errors.meter_number}
						placeholder={'Type in your meter number here'}
						value={values.meter_number}
						onChange={(e) => {
							const value = e.currentTarget.value;
							setFieldValue('meter_number', value);
							if (value.length === 11) {
								verifyBillAccount({
									service_type: serviceType,
									account_number: value,
								});
							}
						}}
					/>
				</Box>

				{billAccount && <CustomPlaceholder text={billAccount.user.name} />}

				<Button
					loading={isLoading}
					style={styles.btn}
					size={'large'}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Load E-pin
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
});

export default LoadPowerEpinForm;
