import { TableRow, styled, TableCell } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { grey } from '@mui/material/colors';
import { LIGHT_GRAY, THEME_MODE } from 'utilities';

interface Props {
	mode?: string;
}

export const StyledTableCell = styled(TableCell)<Props>(({ theme, mode }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundImage: `url(/images/bgImage.png')`,
		backgroundColor:
			mode === THEME_MODE.dark ? theme.palette.background.paper : LIGHT_GRAY,
		backgroundSize: 'cover',
		backgroundPosition: 'top-left',
		fontSize: '14px',
		fontWeight: 'bold',
		color: mode === THEME_MODE.dark ? grey['100'] : theme.palette.primary.main,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: '14px',
		whiteSpace: 'no-wrap',
		cursor: 'pointer',
		color: mode === THEME_MODE.dark ? grey['300'] : theme.palette.primary.main,
	},
	whiteSpace: 'nowrap',
}));

export const StyledTableRow = styled(TableRow)<Props>(({ theme, mode }) => ({
	// color: theme.palette.primary.main,
	'& th, & td': {
		borderColor:
			mode === THEME_MODE.dark ? theme.palette.background.paper : 'none',
	},
	'&:nth-of-type(even)': {
		backgroundColor:
			mode === THEME_MODE.dark ? theme.palette.background.paper : LIGHT_GRAY,
	},
	'&:nth-of-type(odd)': {
		backgroundColor:
			mode === THEME_MODE.dark ? theme.palette.background.default : grey['50'],
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));
