import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { grey } from '@mui/material/colors';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { QUERY_KEYS } from 'utilities';
import { useHandleError, useModalAlert } from 'hooks';
import { kycLevelThreeUpdate } from 'api';
import TextInput from '../form-components/text-input';
import Button from '../button';

type Props = {
	hideSkipButton?: boolean;
	text?: string;
	callback?: () => void;
	handleSkip?: () => void;
};

const BvnVerificationForm = ({
	hideSkipButton,
	text,
	callback,
	handleSkip,
}: Props) => {
	const theme = useTheme();
	const handleError = useHandleError();
	const styles = useStyles(theme);
	const modal = useModalAlert();
	const queryClient = useQueryClient();

	const initialValues = {
		bvn: '',
	};

	const validationSchema = yup.object().shape({
		bvn: yup
			.string()
			.matches(/\d{11}/, 'BVN must be a 11 digit number')
			.required('Enter BVN'),
	});

	const { isLoading, mutate } = useMutation(kycLevelThreeUpdate, {
		onSettled: (data, error) => {
			if (error) {
				const res = handleError({ error });
				if (res && res.message) {
					const regExp = new RegExp('PAYLOAD exists', 'ig');
					const message = res.message;

					const responseMsg = regExp.test(message)
						? 'BVN has already been used.'
						: message;

					modal({
						type: 'error',
						title: 'BVN verification',
						message: responseMsg,
						primaryButtonText: 'Close',
						onClickPrimaryButton: () => modal(null),
					});
				}
			}
			if (data && data.success) {
				queryClient.invalidateQueries(QUERY_KEYS.Me);
				queryClient.invalidateQueries(QUERY_KEYS.VerificationState);

				resetForm();
				modal({
					type: 'success',
					title: 'BVN Verification',
					message: data.message,
					primaryButtonText: 'Continue',
					onClickPrimaryButton: () => {
						modal(null);
						typeof callback !== 'undefined' && callback();
					},
				});
			}
		},
	});

	const { touched, values, errors, handleSubmit, handleChange, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: (values) => {
				mutate({ payload: values.bvn, type: 'BVN' });
			},
		});

	return (
		<Box>
			<Box sx={{ marginBottom: theme.spacing(4) }}>
				<Typography style={styles.title} variant={'h5'}>
					BVN Verification
				</Typography>
				<Typography variant={'body1'}>
					{text ||
						`Please type in your BVN below and ensure that the names attached to your bvn
					match the first and last names you submitted to us on the first step`}
				</Typography>
			</Box>
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Bank verification number
					</Typography>
					<TextInput
						fullWidth
						error={errors && touched.bvn && errors.bvn ? true : false}
						helperText={errors && touched.bvn && errors.bvn}
						placeholder={'Type in your bank verification number'}
						value={values.bvn}
						onChange={handleChange('bvn')}
					/>
				</Box>

				<Box>
					<Button
						fullWidth
						style={styles.btn}
						size={'large'}
						loading={isLoading}
						onClick={(e: React.FormEvent<HTMLButtonElement>) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						Submit for verification
					</Button>
					{!hideSkipButton && (
						<Button
							fullWidth
							size={'large'}
							style={styles.btnOutline}
							sx={{
								color: theme.palette.secondary.main,
								':hover': {
									backgroundColor: theme.palette.secondary.main,
									color: grey[50],
								},
							}}
							onClick={(e: React.FormEvent<HTMLButtonElement>) => {
								e.preventDefault();
								typeof handleSkip !== 'undefined' && handleSkip();
							}}
						>
							Skip for now
						</Button>
					)}
				</Box>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	btnOutline: {
		border: `1px solid ${theme.palette.secondary.main}`,
		fontWeight: '500',
	},
	emailText: {
		fontWeight: '600',
	},
});

export default BvnVerificationForm;
