import React from 'react';
import {
	Layout,
	TwoColGrid,
	WalletWithBreakdown,
	Seo,
	PurchaseDataForm,
	H5Typography,
	BackButton,
} from 'components';

const PurchaseDataPage = () => {
	return (
		<Layout>
			<Seo title={'Purchase Data'} />
			<TwoColGrid
				rightAside={<WalletWithBreakdown isStickySidebar top={'6rem'} />}
			>
				<BackButton name={'Services'} sx={{ marginBottom: ['2rem'] }} />
				<H5Typography name={'Purchase Data'} />

				<PurchaseDataForm />
			</TwoColGrid>
		</Layout>
	);
};

export default PurchaseDataPage;
