import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { LIGHT_GRAY } from 'utilities';
import InstructionItem from './instruction-item';

type Props = {
	isSticky?: boolean;
	network?: string; // 'MTN' | '9MOBILE' | 'GLO' | 'AIRTEL';
};

const ManualAirtimeConvertInstructions = ({ isSticky, network }: Props) => {
	const theme = useTheme();
	const styles = useStyles(theme);

	const switchDescription = () => {
		switch (network) {
			case 'MTN':
				return `Proceed to transfer specified amount to the designated number. Use USSD code dial: *321*Recipient's phone number*Amount*PIN# (Default PIN; 1234 or 0000). Please note that you can transfer up to N50,000 Daily, with limit of N5,000 on singular transfer.`;

			case 'AIRTEL':
				return `Proceed to transfer specified amount to the designated number. Use USSD code dial: *432*1*Recipient's phone number*Amount*PIN# (Default PIN; 1234 or 0000). Please note that you can transfer up to N20,000 Daily. `;

			case 'GLO':
				return `Proceed to transfer specified amount to the designated number. Use USSD code dial: *131*Recipient's phone number*Amount*PIN# (Default PIN;  00000). Please note that you can transfer up to N5,000 Daily. with limit of N1,000 on singular transfer.`;

			case '9MOBILE':
				return `Proceed to transfer specified amount to the designated number. Use USSD code dial: *223*PiN*Amount* Recipient's phone number# (Default PIN; 0000 or 1234). Please note that you can transfer up to 100,000 daily, with limit of N20,000  on singular transfer.`;

			default:
				return '';
		}
	};

	const getInstruction = () => {
		return [
			{
				step: 1,
				description: `Fill in the fields accurately with required details.`,
			},
			{
				step: 2,
				description: switchDescription(),
			},
			{
				step: 3,
				description: `Keep a screenshot of confirmation of transfer from network provider for approval purposes`,
			},
		];
	};

	const instruction = getInstruction();

	if (network) {
		return (
			<Box sx={{ position: isSticky ? 'sticky' : 'initial', top: '6rem' }}>
				<Box style={styles.header}>
					<Typography style={styles.bolderText} variant={'body1'}>
						Instructions
					</Typography>
				</Box>
				<Box style={styles.instructionWrapper}>
					{instruction &&
						instruction.map(({ step, description }) => (
							<InstructionItem
								key={step}
								step={step}
								description={description}
							/>
						))}
				</Box>
			</Box>
		);
	}

	return null;
};

const useStyles = (theme: any) => ({
	header: {
		backgroundColor: LIGHT_GRAY,
		padding: '15px',
		borderRadius: '5px',
		marginBottom: '15px',
		color: theme.palette.primary.main,
	},
	bolderText: {
		fontWeight: '600',
		color: theme.palette.primary.main,
	},
	instructionWrapper: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '15px',
		padding: '0px 15px',
	},
});

export default ManualAirtimeConvertInstructions;
