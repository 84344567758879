import {
	API_ENDPOINTS,
	DataResponse,
	ENDPOINT_SUBPATH,
	ITransaction,
} from 'utilities';
import { apiRequest } from '../config';

export const transactions = async (params: {
	[key: string]: any;
}): Promise<DataResponse<ITransaction[]>> => {
	const url = `${API_ENDPOINTS.Transactions}${ENDPOINT_SUBPATH.Me}`;
	return apiRequest({
		url,
		method: 'GET',
		params,
	});
};

export const walletWithdrawal = async (params?: {
	[key: string]: any;
}): Promise<DataResponse<ITransaction[]>> =>
	apiRequest({
		method: 'GET',
		url: `${API_ENDPOINTS.Withdraw}`,
		params,
	});

export const walletFunding = async (params?: {
	[key: string]: any;
}): Promise<DataResponse<ITransaction[]>> =>
	apiRequest({
		method: 'GET',
		url: `${API_ENDPOINTS.Funding}${ENDPOINT_SUBPATH.Me}`,
		params,
	});

export const walletTransfers = async (params?: {
	[key: string]: any;
}): Promise<DataResponse<ITransaction[]>> =>
	apiRequest({
		method: 'GET',
		url: `${API_ENDPOINTS.Transfer}${ENDPOINT_SUBPATH.Me}`,
		params,
	});
