import React, { useState } from 'react';
import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Button from '../button';
import Select from '../form-components/select';
import {
	QUERY_KEYS,
	INetwork,
	IDataPlan,
	EPIN_SERVICES,
	ValidationSchema,
	LINKS,
} from 'utilities';
import { useAppSelector } from 'store/hooks';
import {
	useAlert,
	useHandleError,
	useVerifyPin,
	useModalAlert,
	useCheckKycLevelTwo,
} from 'hooks';
import { dataNetworks, dataPlans, generateEPin } from 'api';
import CopyItem from 'components/copy-items';

const SELECT_NETWORK_PROVIDER = 'Select network provider';
const SELECT_DATA_PLAN = 'Select data plan';

const GenerateDataForm = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const navigate = useNavigate();
	const alert = useAlert();
	const modal = useModalAlert();
	const handleError = useHandleError();
	const verifyPin = useVerifyPin();
	const queryClient = useQueryClient();
	const checkKycLevel = useCheckKycLevelTwo();

	/* 
		State
	*/
	const [isLoadingDataPlans, setLoadingDataPlans] = useState<boolean>(false);
	const { token } = useAppSelector((store) => store.auth);
	const [dataDataPlans, setDataDataPlans] = useState<IDataPlan[] | null>(null);
	const [selectedNetwork, setSelectedNetwork] = useState<INetwork | null>(null);
	const [selectedDataPlan, setSelectedDataPlan] = useState<IDataPlan | null>(
		null
	);

	const clearState = () => {
		setSelectedNetwork(null);
		setDataDataPlans(null);
		setSelectedDataPlan(null);
		resetForm();
	};

	const initialValues = {
		network: SELECT_NETWORK_PROVIDER,
		plan: SELECT_DATA_PLAN,
	};

	const { handleSubmit, errors, touched, values, setFieldValue, resetForm } =
		useFormik({
			initialValues,
			validationSchema: ValidationSchema.GenerateData,
			onSubmit: (values) => {
				const data = {
					service: EPIN_SERVICES.DATA,
					pin_data: {
						plan: values.plan,
					},
				};

				// Display Verify transaction pin modal
				if (checkKycLevel()) {
					verifyPin({
						title: 'Cable Subscription E-Pin',
						message: `Verify transaction pin to confirm ${selectedNetwork?.name} E-Pin generation of ${selectedDataPlan?.name}`,
						callback: () => {
							verifyPin(null);
							mutateGenerateEPin(data);
						},
					});
				}
			},
		});

	const { plan, network } = values;

	const { data: dataDataNetworks, isLoading: isLoadingDataNetwork } = useQuery(
		[QUERY_KEYS.DataNetwork],
		() => dataNetworks({ isActive: true }),
		{
			enabled: !!token,
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						alert({ message: response.message, type: 'error' });
					}
				}
			},
		}
	);

	// Load Data Plan

	const loadDataPlans = async (network: string) => {
		setLoadingDataPlans(true);
		try {
			const data = await dataPlans({ network, isActive: true });
			if (data.success) {
				setDataDataPlans(data.payload);
			}
		} catch (error) {
			const errorResponse = handleError({ error });
			alert({ message: errorResponse?.message, type: 'error' });
		}
		setLoadingDataPlans(false);
	};

	const { isLoading: isGeneratingEPin, mutate: mutateGenerateEPin } =
		useMutation(generateEPin, {
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						alert({
							message: response.message,
							type: 'error',
						});
					}
				}

				if (data && data.success) {
					queryClient.invalidateQueries(QUERY_KEYS.UserWallet);
					queryClient.invalidateQueries(QUERY_KEYS.Transactions);
					queryClient.invalidateQueries([QUERY_KEYS.RecentTransactions]);

					const pin = data.payload.pin;

					modal({
						title: 'Data EPin',
						message: `Generation of ${selectedDataPlan?.name} is successfully!`,
						primaryButtonText: 'Go to Dashboard',
						hasCloseButton: true,
						type: 'success',
						children: <CopyItem text={pin} />,
						callback: clearState,
						onClickPrimaryButton: () => {
							modal(null);
							clearState();
							navigate(LINKS.Dashboard);
						},
					});
				}
			},
		});

	const handleSelectNetwork = (value: string) => {
		setFieldValue('network', value);
		loadDataPlans(value as string);
		const network =
			dataDataNetworks &&
			dataDataNetworks.payload.find((network) => network.id === value);
		if (network) setSelectedNetwork(network);
	};

	const handleSelectDataPlan = (value: string) => {
		setFieldValue('plan', value);

		const plan =
			dataDataPlans && dataDataPlans.find((plan) => plan.id === value);
		if (plan) setSelectedDataPlan(plan);
	};

	return (
		<>
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Network provider
					</Typography>
					<Select
						fullWidth
						error={touched.network && errors.network ? true : false}
						helpertext={touched.network && errors.network}
						value={network}
						onChange={(e) => {
							const value = e.target.value;
							handleSelectNetwork(value as string);
						}}
					>
						<MenuItem disabled value={SELECT_NETWORK_PROVIDER}>
							{isLoadingDataNetwork
								? 'Loading...'
								: dataDataNetworks && dataDataNetworks.payload.length === 0
								? 'No avaliable network'
								: SELECT_NETWORK_PROVIDER}
						</MenuItem>
						{dataDataNetworks &&
							dataDataNetworks.payload.length > 0 &&
							dataDataNetworks.payload.map((network: INetwork) => (
								<MenuItem
									value={network.id}
									disabled={!network.isActive}
									key={network.id}
								>
									{network.name}
								</MenuItem>
							))}
					</Select>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Data plan
					</Typography>

					<Select
						fullWidth
						error={touched.plan && errors.plan ? true : false}
						helpertext={touched.plan && errors.plan}
						value={plan}
						onChange={(e) => handleSelectDataPlan(e.target.value as string)}
					>
						<MenuItem disabled value={SELECT_DATA_PLAN}>
							{isLoadingDataPlans
								? 'Loading'
								: dataDataNetworks && dataDataPlans?.length === 0
								? 'No available plans'
								: SELECT_DATA_PLAN}
						</MenuItem>

						{dataDataPlans &&
							dataDataPlans.length > 0 &&
							dataDataPlans.map((plan: IDataPlan) => (
								<MenuItem key={plan.id} value={plan.id}>
									{plan.name}
								</MenuItem>
							))}
					</Select>
				</Box>
				<Button
					loading={isGeneratingEPin}
					style={styles.btn}
					size={'large'}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Generate e-Pin
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default GenerateDataForm;
