import { useQuery } from 'react-query';
import { INotification, QUERY_KEYS, STORAGE_KEYS, Storage } from 'utilities';
import { notifications } from 'api';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setNotificationAlert, setNotifications } from 'store/notification';

const checkAndDispatchNotification = (
	notification: INotification,
	dispatch: Function
) => {
	let localNotification = Storage.getItem(STORAGE_KEYS.NotificationAlert);
	const notificationPayload = { ...notification, count: 1 };

	if (localNotification) {
		if (localNotification.id !== notification.id) {
			Storage.saveItem(STORAGE_KEYS.NotificationAlert, notificationPayload);
			dispatch(setNotificationAlert(notificationPayload));
		} else {
			if (localNotification.count >= 1) {
				dispatch(setNotificationAlert(null));
			}
		}
	} else {
		Storage.saveItem(STORAGE_KEYS.NotificationAlert, notificationPayload);
		dispatch(setNotificationAlert(notificationPayload));
	}
};

const useLoadNotificationAlert = () => {
	const { token } = useAppSelector((store) => store.auth);
	const dispatch = useAppDispatch();
	useQuery(
		QUERY_KEYS.NotificationAlert,
		() =>
			notifications({
				sort: '-createdAt',
				limit: 20,
			}),
		{
			enabled: !!token,

			onSettled: (data) => {
				if (data && data.success) {
					const payload = data.payload;
					dispatch(setNotifications(payload));
					// dispatch(setNotificationAlert({...payload[0], count: 1}));
					Array.isArray(payload) &&
						payload.length > 0 &&
						checkAndDispatchNotification(payload[0], dispatch);
				}
			},
		}
	);
};

export default useLoadNotificationAlert;
