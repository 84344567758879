import React from 'react';
import {
	Layout,
	Seo,
	BackButton,
	TwoColGrid,
	H5Typography,
	WalletWithBreakdown,
	GiftCardForm,
} from 'components';

const PAGE = `Gift cards`;

const Giftcards = () => {
	return (
		<Layout>
			<Seo title={PAGE} />
			<TwoColGrid
				rightAside={<WalletWithBreakdown top={'6rem'} isStickySidebar />}
			>
				<BackButton name={'Services'} sx={{ marginBottom: ['2rem'] }} />
				<H5Typography name={PAGE} />
				<GiftCardForm />
			</TwoColGrid>
		</Layout>
	);
};

export default Giftcards;
