import React from 'react';
import { Box, useTheme } from '@mui/material';

type Props = {
	step: number;
};

const ResetPassword = ({ step }: Props) => {
	const theme = useTheme();
	const styles = useStyles(theme);

	const activeColor = theme.palette.secondary.main;

	return (
		<Box style={styles.container}>
			<Box sx={{ backgroundColor: activeColor }} style={styles.line} />
			<Box sx={{ backgroundColor: activeColor }} style={styles.circle} />
			<Box
				sx={{
					backgroundColor: step >= 2 ? activeColor : '#FCE2C0',
				}}
				style={styles.line}
			/>
			<Box
				sx={{
					backgroundColor: step >= 2 ? activeColor : '#FCE2C0',
				}}
				style={styles.circle}
			/>
			<Box
				sx={{
					backgroundColor: step >= 3 ? activeColor : '#FCE2C0',
				}}
				style={styles.line}
			/>
			<Box
				sx={{
					backgroundColor: step >= 3 ? activeColor : '#FCE2C0',
				}}
				style={styles.circle}
			/>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
	},
	line: {
		// backgroundColor: theme.palette.secondary.main,
		height: theme.spacing(1),
		flex: 1,
	},
	circle: {
		height: theme.spacing(3),
		width: theme.spacing(3),
		// backgroundColor: theme.palette.secondary.main,
		borderRadius: theme.spacing(2),
	},
});

export default ResetPassword;
