import axios, { AxiosRequestConfig } from 'axios';
import { Storage, STORAGE_KEYS, Configs } from 'utilities';

const BASE_URL = Configs.BASE_URL as string;
const API_KEY = Configs.API_KEY as string;

interface ConfigTypes extends AxiosRequestConfig {
	token?: string;
}

const apiRequest = async (config?: ConfigTypes) => {
	const token = Storage.getItem(STORAGE_KEYS.UserToken) || '';

	const res = await axios({
		url: config?.url,
		baseURL: BASE_URL,
		method: config?.method,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
			apikey: API_KEY,
		},
		data: config?.data,
		params: config?.params,
	});

	return res.data;
};

export default apiRequest;
