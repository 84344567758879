import React, { useState, MouseEvent, CSSProperties } from 'react';
import {
	Box,
	useTheme,
	Popper,
	ClickAwayListener,
	List,
	ListItemButton,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import Button from '../button';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
	THEME_MODE,
	TRANSACTION_STATUS,
	TRANSACTION_SERVICE,
	SORT_BY_DAYS,
	getFilterDateRange,
	dateRanges,
	capitalize,
} from 'utilities';
import DatePicker from 'components/form-components/date-picker';
import { setTransactionFilter } from 'store/transaction';

interface IState {
	status?: string;
	service?: string;
	range?: string;
}

interface ITransactionTableFilter {
	onFilter?: () => void;
}

type recordType = 'status' | 'service';

const TransactionTableFilter = ({ onFilter }: ITransactionTableFilter) => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const filterTransactionBy = useAppSelector(
		(store) => store.transactionState.filterTransactionBy
	);
	const mode = useAppSelector((store) => store.theme.mode);
	const styles = useStyles(theme, mode);

	const [serviceAnchorEl, setServiceAnchorEl] = useState<null | HTMLElement>(
		null
	);
	const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(
		null
	);
	const [dayAnchorEl, setDayAnchorEl] = useState<null | HTMLElement>(null);

	const [isDisplayPicker, setDisplayPicker] = useState<boolean>(false);

	const handleServiceClick = (e: MouseEvent<HTMLElement>) => {
		setServiceAnchorEl(serviceAnchorEl ? null : e.currentTarget);
	};
	const handleClickStatus = (e: MouseEvent<HTMLElement>) => {
		setStatusAnchorEl(statusAnchorEl ? null : e.currentTarget);
	};
	const handleDayClick = (e: MouseEvent<HTMLElement>) => {
		setDayAnchorEl(dayAnchorEl ? null : e.currentTarget);
	};

	const handleFilter = (obj?: { value?: string; type?: recordType }) => {
		let state: IState = {};
		if (obj?.type === 'status') state.status = obj.value;
		if (obj?.type === 'service') state.service = obj.value;

		setStatusAnchorEl(null);
		setServiceAnchorEl(null);
		dispatch(setTransactionFilter(state));
		typeof onFilter === 'function' && onFilter();
	};

	const handleSelectDateRangeOption = (range: any) => {
		if (!range) {
			setDayAnchorEl(null);
			dispatch(
				setTransactionFilter({
					date: '',
				})
			);
		}

		if (range === SORT_BY_DAYS.CUSTOM) {
			return setDisplayPicker(true);
		}

		if (
			range === SORT_BY_DAYS.LAST_30_DAYS ||
			range === SORT_BY_DAYS.LAST_7_DAYS
		) {
			const days = range === SORT_BY_DAYS.LAST_30_DAYS ? 30 : 7;
			const ranges = getFilterDateRange(days);
			dispatch(
				setTransactionFilter({
					date: ranges,
				})
			);
			typeof onFilter === 'function' && onFilter();

			setDayAnchorEl(null);
		}
	};

	const handleApplyChange = (range: any) => {
		const { startDate, endDate } = range;

		dispatch(
			setTransactionFilter({
				date: dateRanges(startDate, endDate),
			})
		);
		typeof onFilter === 'function' && onFilter();

		setDayAnchorEl(null);
	};

	return (
		<Box
			sx={{
				overflow: 'auto',
				display: 'flex',
				gap: '10px',
				button: {
					minWidth: '140px',
					borderColor:
						mode === THEME_MODE.dark ? grey[300] : theme.palette.primary.main,
					color:
						mode === THEME_MODE.dark ? grey[300] : theme.palette.primary.main,
				},
			}}
		>
			<ClickAwayListener onClickAway={() => setDayAnchorEl(null)}>
				<Box>
					<Button
						sx={{ minWidth: ['120px !important', '180px !important'] }}
						style={styles.button as CSSProperties}
						onClick={(e) => handleDayClick(e)}
						variant={'outlined'}
						endIcon={<ArrowDropDown />}
					>
						By Days
					</Button>
					<Popper
						sx={{ zIndex: theme.zIndex.modal }}
						open={Boolean(dayAnchorEl)}
						anchorEl={dayAnchorEl}
					>
						{isDisplayPicker ? (
							<DatePicker
								onApplyChange={handleApplyChange}
								cancelPicker={() => setDisplayPicker(false)}
							/>
						) : (
							<List
								sx={{
									maxHeight: 250,
									height: '100%',
									overflowY: 'auto',
									'& .MuiListItemButton-root': {
										textTransform: 'capitalize',
									},
									'& .MuiListItemButton-root:hover': {
										backgroundColor: theme.palette.primary.main,
										color: grey[50],
									},
								}}
								style={styles.list}
							>
								{Object.values(SORT_BY_DAYS).map((value) => (
									<ListItemButton
										onClick={() => handleSelectDateRangeOption(value)}
										key={value}
									>
										{capitalize(value)}
									</ListItemButton>
								))}
								<ListItemButton onClick={() => handleSelectDateRangeOption('')}>
									Reset
								</ListItemButton>
							</List>
						)}
					</Popper>
				</Box>
			</ClickAwayListener>
			<ClickAwayListener onClickAway={() => setServiceAnchorEl(null)}>
				<Box>
					<Button
						sx={{ minWidth: ['120px !important', '180px !important'] }}
						style={styles.button as CSSProperties}
						onClick={(e) => handleServiceClick(e)}
						variant={'outlined'}
						endIcon={<ArrowDropDown />}
					>
						{capitalize(
							`${filterTransactionBy?.service?.replace(/_/g, ' ')}`
						) || 'Filter by service'}
					</Button>
					<Popper
						sx={{ zIndex: theme.zIndex.modal }}
						open={Boolean(serviceAnchorEl)}
						anchorEl={serviceAnchorEl}
					>
						<List
							sx={{
								height: 250,
								overflowY: 'auto',
								'& .MuiListItemButton-root': {
									textTransform: 'capitalize',
								},
								'& .MuiListItemButton-root:hover': {
									backgroundColor: theme.palette.primary.main,
									color: grey[50],
								},
							}}
							style={styles.list}
						>
							{Object.values(TRANSACTION_SERVICE).map((value) => (
								<ListItemButton
									onClick={() => handleFilter({ value, type: 'service' })}
									key={value}
								>
									{value === TRANSACTION_SERVICE.CARD_TOP_UP
										? 'Card/Bank Funding'
										: capitalize(value.replace(/_/g, ' '))}
								</ListItemButton>
							))}
						</List>
					</Popper>
				</Box>
			</ClickAwayListener>
			<ClickAwayListener onClickAway={() => setStatusAnchorEl(null)}>
				<Box>
					<Button
						sx={{ minWidth: ['120px !important', '180px !important'] }}
						style={styles.button as CSSProperties}
						onClick={(e) => handleClickStatus(e)}
						variant={'outlined'}
						endIcon={<ArrowDropDown />}
					>
						{capitalize(`${filterTransactionBy?.status}`) || 'Filter by status'}
					</Button>
					<Popper
						sx={{ zIndex: theme.zIndex.modal }}
						open={Boolean(statusAnchorEl)}
						anchorEl={statusAnchorEl}
					>
						<List
							sx={{
								maxHeight: 250,
								height: '100%',
								overflowY: 'auto',
								'& .MuiListItemButton-root': {
									textTransform: 'capitalize',
								},
								'& .MuiListItemButton-root:hover': {
									backgroundColor: theme.palette.primary.main,
									color: grey[50],
								},
							}}
							style={styles.list}
						>
							<ListItemButton
								onClick={() => handleFilter({ value: '', type: 'status' })}
							>
								ALL
							</ListItemButton>
							{Object.values(TRANSACTION_STATUS).map((value) => {
								if (value === TRANSACTION_STATUS.APPROVED) return null;
								return (
									<ListItemButton
										onClick={() => handleFilter({ value, type: 'status' })}
										key={value}
									>
										{capitalize(value)}
									</ListItemButton>
								);
							})}
						</List>
					</Popper>
				</Box>
			</ClickAwayListener>
		</Box>
	);
};

const useStyles = (theme: any, mode: string) => ({
	button: {
		whiteSpace: 'nowrap',
	},
	list: {
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(1),
		backgroundColor: theme.palette.background.paper,
		marginTop: theme.spacing(2),
	},
});

export default TransactionTableFilter;
