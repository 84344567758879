import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { formatNumberToCurrency, LIGHT_GRAY, THEME_MODE } from 'utilities';
import { useAppSelector } from 'store/hooks';

type Props = {
	name: string;
	amount: number | string;
	hasBackgroundColor?: boolean;
	isPercent?: boolean;
};

const ListItem = ({
	name,
	amount,
	hasBackgroundColor,
	isPercent = false,
}: Props) => {
	const theme = useTheme();
	const { mode } = useAppSelector((store) => store.theme);
	const isDark = mode === THEME_MODE.dark;

	const fixAmount =
		!isPercent && parseFloat(`${amount}`) < 0
			? amount.toString().substring(1)
			: amount;

	const color =
		hasBackgroundColor && isDark
			? grey[100]
			: isDark
			? grey['100']
			: theme.palette.primary.main;

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				padding: '15px',

				backgroundColor: hasBackgroundColor
					? isDark
						? theme.palette.background.paper
						: LIGHT_GRAY
					: 'unset',
				borderRadius: '5px',
			}}
		>
			<Typography
				sx={{
					color,
				}}
				variant={'body1'}
			>
				{name}
			</Typography>
			<Typography
				sx={{
					color,
				}}
				variant={'body1'}
			>
				{isPercent
					? `${amount}%`
					: formatNumberToCurrency(parseFloat(`${fixAmount}`).toFixed(2) || 0)}
			</Typography>
		</Box>
	);
};

export default ListItem;
