import React from 'react';

type Props = {
	color?: string;
	height?: number;
	width?: number;
};

const PurchaseAirtimeIcon = ({ width, height, color }: Props) => {
	return (
		<svg
			width='16'
			height='24'
			viewBox='0 0 16 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_2604_78956)'>
				<path
					opacity='0.5'
					d='M15.035 3.3997V2.19882C15.035 1.78527 14.8707 1.38866 14.5783 1.09624C14.2858 0.803813 13.8892 0.639526 13.4757 0.639526H2.52461C2.11001 0.640474 1.71276 0.806079 1.42025 1.09991C1.12774 1.39374 0.963928 1.79173 0.964848 2.20633V3.3997H15.035Z'
					fill='#DCE3E7'
				/>
				<path
					opacity='0.5'
					d='M0.964844 18.6816V21.8012C0.964844 22.2147 1.12913 22.6113 1.42155 22.9038C1.71397 23.1962 2.11059 23.3605 2.52414 23.3605H13.4757C13.8893 23.3605 14.2859 23.1962 14.5783 22.9038C14.8707 22.6114 15.035 22.2148 15.035 21.8012V18.6816H0.964844Z'
					fill='#DCE3E7'
				/>
				<path
					opacity='0.5'
					d='M12.4111 7.03503H9.17969V9.82886H12.4111V7.03503Z'
					fill='#DCE3E7'
				/>
				<path
					opacity='0.5'
					d='M6.82321 12.4545H3.5918V15.2483H6.82321V12.4545Z'
					fill='#DCE3E7'
				/>
				<path
					d='M13.4904 0H2.53073C1.94605 0 1.38531 0.232267 0.971875 0.645703C0.558439 1.05914 0.326172 1.61988 0.326172 2.20456V21.7955C0.326172 22.3802 0.55843 22.9409 0.971857 23.3543C1.38528 23.7677 1.94601 24 2.53069 24H13.4708C14.0555 24 14.6163 23.7677 15.0297 23.3543C15.4431 22.9409 15.6754 22.3801 15.6754 21.7954V16.5219C15.677 16.3539 15.6137 16.1919 15.4986 16.0696C15.3835 15.9473 15.2256 15.8742 15.0579 15.8656C14.9717 15.863 14.8859 15.8777 14.8056 15.909C14.7252 15.9403 14.652 15.9874 14.5902 16.0476C14.5285 16.1078 14.4795 16.1798 14.4462 16.2593C14.4129 16.3388 14.3959 16.4242 14.3963 16.5105V18.0421H1.60527V4.03926H14.3963V12.0011C14.3947 12.169 14.458 12.3311 14.5731 12.4534C14.6882 12.5757 14.8461 12.6488 15.0138 12.6574C15.1 12.66 15.1858 12.6452 15.2662 12.6139C15.3465 12.5826 15.4198 12.5355 15.4815 12.4752C15.5433 12.415 15.5922 12.343 15.6256 12.2635C15.6589 12.1839 15.6758 12.0985 15.6754 12.0122V2.20553C15.6775 1.62351 15.4486 1.06443 15.0389 0.650958C14.6293 0.237485 14.0724 0.00338159 13.4904 0ZM14.3963 19.3212V21.8069C14.3963 21.927 14.3727 22.0458 14.3267 22.1567C14.2808 22.2676 14.2135 22.3683 14.1286 22.4532C14.0437 22.5381 13.943 22.6054 13.8321 22.6513C13.7212 22.6973 13.6024 22.7209 13.4823 22.7209H2.51924C2.39922 22.7209 2.28037 22.6973 2.16949 22.6513C2.0586 22.6054 1.95784 22.5381 1.87297 22.4532C1.7881 22.3683 1.72078 22.2676 1.67484 22.1567C1.62891 22.0458 1.60527 21.927 1.60527 21.8069V19.3212H14.3963ZM1.60527 2.7265V2.19306C1.60527 1.95066 1.70157 1.7182 1.87297 1.54679C2.04437 1.37539 2.27684 1.27909 2.51924 1.27909H13.4823C13.6023 1.27909 13.7212 1.30274 13.8321 1.34867C13.943 1.39461 14.0437 1.46193 14.1286 1.5468C14.2135 1.63168 14.2808 1.73244 14.3267 1.84333C14.3727 1.95423 14.3963 2.07308 14.3963 2.19311V2.7265L1.60527 2.7265Z'
					fill={color || '#28536B'}
				/>
				<path
					d='M8.00342 21.459C8.24694 21.459 8.44434 21.2616 8.44434 21.0181C8.44434 20.7746 8.24694 20.5771 8.00342 20.5771C7.75991 20.5771 7.5625 20.7746 7.5625 21.0181C7.5625 21.2616 7.75991 21.459 8.00342 21.459Z'
					fill={color || '#28536B'}
				/>
				<path
					d='M8.00417 22.104C7.78934 22.104 7.57933 22.0403 7.40071 21.921C7.22209 21.8016 7.08286 21.632 7.00065 21.4335C6.91844 21.235 6.89693 21.0166 6.93884 20.8059C6.98075 20.5952 7.0842 20.4017 7.23611 20.2498C7.38802 20.0979 7.58156 19.9944 7.79226 19.9525C8.00296 19.9106 8.22136 19.9321 8.41983 20.0143C8.61831 20.0965 8.78795 20.2358 8.9073 20.4144C9.02665 20.593 9.09036 20.803 9.09036 21.0178C9.09003 21.3058 8.97548 21.5819 8.77185 21.7855C8.56822 21.9892 8.29214 22.1037 8.00417 22.104ZM8.00417 20.8134C7.96375 20.8134 7.92424 20.8254 7.89063 20.8479C7.85703 20.8703 7.83083 20.9022 7.81537 20.9396C7.7999 20.9769 7.79585 21.018 7.80374 21.0576C7.81162 21.0973 7.83108 21.1337 7.85966 21.1623C7.88824 21.1909 7.92465 21.2103 7.96429 21.2182C8.00393 21.2261 8.04503 21.222 8.08237 21.2066C8.11971 21.1911 8.15162 21.1649 8.17407 21.1313C8.19653 21.0977 8.20851 21.0582 8.20851 21.0178C8.20845 20.9636 8.1869 20.9117 8.14859 20.8734C8.11028 20.8351 8.05834 20.8135 8.00417 20.8134Z'
					fill={color || '#28536B'}
				/>
				<path
					d='M6.78848 10.4685H3.59221C3.4222 10.4685 3.25915 10.4009 3.13893 10.2807C3.01871 10.1605 2.95117 9.99744 2.95117 9.82743V7.0408C2.95117 6.86967 3.01916 6.70554 3.14017 6.58453C3.26118 6.46352 3.4253 6.39554 3.59644 6.39554H6.77171C6.93965 6.39392 7.10169 6.45732 7.22395 6.57245C7.34621 6.68759 7.41921 6.84555 7.42767 7.01327C7.43059 7.09904 7.41622 7.18453 7.38541 7.26463C7.35461 7.34473 7.30799 7.41782 7.24835 7.47953C7.18871 7.54124 7.11726 7.59032 7.03825 7.62384C6.95924 7.65736 6.8743 7.67464 6.78848 7.67464H4.23027V9.18936H6.77172C6.93965 9.18775 7.1017 9.25114 7.22396 9.36628C7.34621 9.48141 7.41921 9.63937 7.42767 9.80709C7.43059 9.89287 7.41622 9.97836 7.38542 10.0585C7.35461 10.1386 7.308 10.2117 7.24836 10.2734C7.18872 10.3351 7.11726 10.3842 7.03826 10.4177C6.95925 10.4512 6.8743 10.4685 6.78848 10.4685Z'
					fill={color || '#28536B'}
				/>
				<path
					d='M12.4043 10.4684H9.18434C9.0132 10.4684 8.84907 10.4005 8.72806 10.2794C8.60705 10.1584 8.53906 9.9943 8.53906 9.82316V7.04077C8.53906 6.86964 8.60705 6.70551 8.72806 6.5845C8.84907 6.46349 9.0132 6.39551 9.18434 6.39551H12.4043C12.5754 6.39551 12.7396 6.46349 12.8606 6.5845C12.9816 6.70551 13.0496 6.86964 13.0496 7.04077V9.82316C13.0496 9.9943 12.9816 10.1584 12.8606 10.2794C12.7396 10.4005 12.5754 10.4684 12.4043 10.4684ZM9.81817 9.18933H11.7705V7.67461H9.81817V9.18933Z'
					fill={color || '#28536B'}
				/>
				<path
					d='M6.81642 15.8878H3.59644C3.5117 15.8878 3.4278 15.8711 3.34951 15.8386C3.27122 15.8062 3.20009 15.7587 3.14017 15.6988C3.08025 15.6388 3.03272 15.5677 3.00029 15.4894C2.96786 15.4111 2.95117 15.3272 2.95117 15.2425V12.4601C2.95117 12.289 3.01916 12.1248 3.14017 12.0038C3.26118 11.8828 3.4253 11.8148 3.59644 11.8148H6.81642C6.98756 11.8148 7.15168 11.8828 7.27269 12.0038C7.39371 12.1248 7.46169 12.289 7.46169 12.4601V15.2425C7.46169 15.3272 7.445 15.4111 7.41257 15.4894C7.38015 15.5677 7.33261 15.6388 7.27269 15.6988C7.21278 15.7587 7.14164 15.8062 7.06335 15.8386C6.98507 15.8711 6.90116 15.8878 6.81642 15.8878ZM4.23027 14.6087H6.18259V13.0939H4.23027V14.6087Z'
					fill={color || '#28536B'}
				/>
				<path
					d='M12.4039 15.8878H9.2286C9.06069 15.8894 8.89865 15.826 8.7764 15.7109C8.65414 15.5958 8.58113 15.4379 8.57264 15.2702C8.5697 15.1844 8.58406 15.0989 8.61486 15.0188C8.64565 14.9386 8.69226 14.8655 8.75191 14.8038C8.81155 14.7421 8.88301 14.693 8.96202 14.6595C9.04104 14.6259 9.12599 14.6087 9.21183 14.6087H11.77V13.0939H9.22859C9.06066 13.0955 8.89861 13.0322 8.77635 12.917C8.65409 12.8019 8.58109 12.6439 8.57264 12.4762C8.56971 12.3904 8.58408 12.3049 8.61489 12.2248C8.64569 12.1447 8.6923 12.0716 8.75195 12.0099C8.81159 11.9482 8.88305 11.8991 8.96205 11.8656C9.04106 11.8321 9.126 11.8148 9.21183 11.8148H12.4081C12.4923 11.8148 12.5756 11.8314 12.6534 11.8636C12.7312 11.8958 12.8019 11.9431 12.8614 12.0026C12.9209 12.0621 12.9681 12.1328 13.0003 12.2105C13.0326 12.2883 13.0491 12.3717 13.0491 12.4559V15.2425C13.0491 15.3272 13.0324 15.4111 13 15.4894C12.9676 15.5677 12.9201 15.6388 12.8601 15.6988C12.8002 15.7587 12.7291 15.8062 12.6508 15.8386C12.5725 15.8711 12.4886 15.8878 12.4039 15.8878Z'
					fill={color || '#28536B'}
				/>
			</g>
			<defs>
				<clipPath id='clip0_2604_78956'>
					<rect
						width='15.3492'
						height='24'
						fill='white'
						transform='translate(0.326172)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default PurchaseAirtimeIcon;
