import React, { useState } from 'react';
import { Box, Typography, useTheme, Collapse } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { useAppSelector } from 'store/hooks';
import Button from '../button';
import ListItem from './list-item';
import {
	calculateFinalAmount,
	calculateDiscountedAmount,
	THEME_MODE,
	TRANSITION,
} from 'utilities';

const BillBreakdown = () => {
	const theme = useTheme();
	const {
		theme: { mode },
		transactionState: { transactionBreakdown },
	} = useAppSelector((store) => store);
	const styles = useStyles(theme, mode);

	const [isExpand, setExpand] = useState<boolean>(true);

	const charge = transactionBreakdown?.charge;
	const amount = transactionBreakdown ? transactionBreakdown.serviceCost : 0;
	const coupon = transactionBreakdown && transactionBreakdown.coupon;
	const chargePayableAmount =
		charge &&
		parseFloat(`${amount}`) +
			(parseFloat(`${amount}`) * parseFloat(`${charge}`)) / 100;

	const payableAmount = charge
		? (chargePayableAmount as string)
		: coupon
		? calculateFinalAmount({
				coupon,
				amount: parseFloat(`${amount}`),
		  })
		: amount;

	const discount = coupon
		? calculateDiscountedAmount({ coupon, amount: parseFloat(`${amount}`) })
		: 0;

	return (
		<Box>
			<Button onClick={() => setExpand(!isExpand)} fullWidth style={styles.btn}>
				<Typography style={styles.title} variant={'body1'}>
					Break down
				</Typography>
				<ArrowDropDown
					sx={{
						transition: TRANSITION,
						transform: isExpand ? 'rotate(180deg)' : 'rotate(0deg)',
					}}
				/>
			</Button>
			<Collapse in={isExpand}>
				<ListItem
					hasBackgroundColor
					name={'Service cost'}
					amount={amount ? `${amount}` : 0}
				/>
				<ListItem
					name={charge ? 'Charge' : 'Discount'}
					amount={charge ? charge : discount}
				/>
				<ListItem
					hasBackgroundColor
					name={'Final cost'}
					amount={payableAmount || 0}
				/>
			</Collapse>
		</Box>
	);
};

const useStyles = (theme: any, mode: string) => ({
	title: {
		color: THEME_MODE.dark === mode ? grey[300] : theme.palette.primary.main,
		fontWeight: '600',
	},
	btn: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '12px 15px',
		marginBottom: '8px',
	},
});

export default BillBreakdown;
