import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import {
	Layout,
	TwoColGrid,
	ConvertAirtimeForm,
	Seo,
	ManualAirtimeConvertInstructions,
	BackButton,
} from 'components';

const ManualConvertAirtime = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const [network, setNetwork] = useState<string>('');
	return (
		<Layout>
			<Seo title={'Manual Convert Airtime'} />
			<TwoColGrid
				canGoBack
				rightAside={
					<ManualAirtimeConvertInstructions network={network} isSticky />
				}
			>
				<BackButton name={'Convert Airtime'} sx={{ marginBottom: ['2rem'] }} />
				<Box>
					<Typography style={styles.title} variant={'h5'}>
						Manual Airtime to Cash
					</Typography>
					<ConvertAirtimeForm
						selectNetwork={(network) => setNetwork(network)}
					/>
				</Box>
			</TwoColGrid>
		</Layout>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: '20px',
	},
});

export default ManualConvertAirtime;
