import React from 'react';
import {
	Layout,
	Seo,
	BackButton,
	TwoColGrid,
	H5Typography,
	WalletWithBreakdown,
	EVoucherForm,
} from 'components';

const PAGE = `E-Voucher`;

const EVoucher = () => {
	return (
		<Layout>
			<Seo title={PAGE} />
			<TwoColGrid
				rightAside={<WalletWithBreakdown top={'6rem'} isStickySidebar />}
			>
				<BackButton name={'Services'} sx={{ marginBottom: ['2rem'] }} />
				<H5Typography name={PAGE} />
				<EVoucherForm />
			</TwoColGrid>
		</Layout>
	);
};

export default EVoucher;
