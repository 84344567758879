import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { grey, red } from '@mui/material/colors';
import OtpInput from 'react-otp-input';
import Button from '../button';
import { useAlert, useHandleError, useModalAlert } from 'hooks';
import { setPin } from 'api';
import moduleStyles from './style.module.css';
import { QUERY_KEYS } from 'utilities';

type Props = {
	callback?: () => void;
	btnText?: string;
};

const PinForm = ({ callback, btnText = 'Continue' }: Props) => {
	const theme = useTheme();
	const alert = useAlert();
	const modal = useModalAlert();
	const handleError = useHandleError();
	const styles = useStyles(theme);
	const queryClient = useQueryClient();

	const validationSchema = yup.object().shape({
		pin: yup
			.string()
			.required('Enter transaction PIN')
			.matches(/^[0-9]+$/, 'Pin must only digits')
			.min(4, 'Pin must be exactly 4 digits')
			.max(4, 'Pin must be exactly 4 digits'),
		confirmPin: yup
			.string()
			.oneOf([yup.ref('pin'), null], 'Pin do not match')
			.required('Enter confirm transaction PIN'),
	});

	const initialValues: { pin: string; confirmPin: string } = {
		pin: '',
		confirmPin: '',
	};

	const { mutate, isLoading } = useMutation(setPin, {
		onSettled: (data, error) => {
			if (data && data.success) {
				typeof callback !== 'undefined' && callback();
				queryClient.invalidateQueries(QUERY_KEYS.Me);
				modal({
					title: 'Pin Setup',
					message: 'Transaction pin set up successfully!',
					type: 'success',
					primaryButtonText: 'Close',
					onClickPrimaryButton: () => modal(null),
				});
			}

			if (error) {
				const response = handleError({
					error,
				});

				if (response?.message) {
					alert({ message: response.message, type: 'error' });
				}
			}
		},
	});

	const { values, handleChange, handleSubmit, errors, touched } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			mutate({ pin: values.pin });
		},
	});

	const { pin, confirmPin } = values;

	return (
		<Box style={styles.form as any} component={'form'}>
			<Box>
				<Typography style={styles.label} component={'label'} variant={'body1'}>
					Transaction PIN
				</Typography>

				<OtpInput
					errorStyle={touched.pin && errors.pin ? true : false}
					focusStyle={styles.focusStyle}
					inputStyle={moduleStyles.otpInputStyle}
					value={pin}
					onChange={handleChange('pin')}
					isInputSecure
					numInputs={4}
					separator={<Box style={styles.separator} />}
				/>
				{touched.pin && errors.pin && (
					<Typography style={styles.errorText} variant={'body2'}>
						{errors.pin}
					</Typography>
				)}
			</Box>

			<Box>
				<Typography style={styles.label} component={'label'} variant={'body1'}>
					Confirm Transaction PIN
				</Typography>

				<OtpInput
					errorStyle={touched.confirmPin && errors.confirmPin ? true : false}
					focusStyle={styles.focusStyle}
					inputStyle={moduleStyles.otpInputStyle}
					value={confirmPin}
					onChange={handleChange('confirmPin')}
					isInputSecure
					numInputs={4}
					separator={<Box style={styles.separator} />}
				/>
				{touched.confirmPin && errors.confirmPin && (
					<Typography style={styles.errorText} variant={'body2'}>
						{errors.confirmPin}
					</Typography>
				)}
			</Box>

			<Button
				loading={isLoading}
				size={'large'}
				style={styles.btn}
				onClick={(e: React.FormEvent<HTMLButtonElement>) => {
					e.preventDefault();
					handleSubmit();
				}}
			>
				{btnText}
			</Button>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	separator: {
		width: '15px',
	},
	inputStyle: {
		height: '55px',
		width: '55px',
		fontSize: '14px',
		color: theme.palette.primary.main,
		borderRadius: theme.spacing(1),
		border: `1px solid ${theme.palette.primary.main}`,
	},
	focusStyle: {
		outlineColor: theme.palette.primary.main,
	},
	errorText: {
		color: red[800],
		marginTop: theme.spacing(1),
	},
});

export default PinForm;
