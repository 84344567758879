// import './style.module.css';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setNotificationAlert } from 'store/notification';
import { ALERT_TYPE } from 'utilities';
import ModalLayout from 'components/layout/modal-layout';
import Image from 'components/image';

const InAppAlert = () => {
	const dispatch = useAppDispatch();
	const { alert } = useAppSelector((store) => store.notification);

	const closeAlert = () => dispatch(setNotificationAlert(null));

	if (alert && alert.type === ALERT_TYPE.IN_APP) {
		const { subject, imageUrl } = alert;
		return (
			<ModalLayout hasCloseButton={true} closeModal={closeAlert}>
				<Box
					sx={{
						display: 'grid',
						gap: '20px',
						'& p': {
							margin: '0px !important',
						},
					}}
				>
					<Image sx={{}} src={imageUrl} alt={subject} />
					<Typography
						variant={'h4'}
						sx={{
							fontWeight: 'bold',
							fontSize: ['18px', '24px'],
						}}
					>
						{subject}
					</Typography>

					<div
						style={{
							margin: '0px !important',
							fontSize: '13px',
						}}
						dangerouslySetInnerHTML={{ __html: alert?.message }}
					/>
				</Box>
			</ModalLayout>
		);
	}

	return null;
};

// const AlertContainer = styled(Card)(({ theme }) => ({
// 	zIndex: theme.zIndex.appBar + 1,
// 	position: 'absolute',
// 	right: 0,
// 	marginRight: theme.spacing(2),
// 	top: theme.spacing(3),
// 	boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
// 	padding: theme.spacing(3),
// 	borderRadius: theme.spacing(2),
// 	maxWidth: '640px',
// }));

export default InAppAlert;
