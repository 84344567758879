import React from 'react';
import {
	CircularProgress,
	Table,
	Box,
	TableBody,
	TableHead,
	TableRow,
	useTheme,
	styled,
	TableCell,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { Check, Close, AccessTime } from '@mui/icons-material';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import {
	LIGHT_GRAY,
	SUCCESS_COLOR,
	PENDING_COLOR,
	DANGER_COLOR,
	checkAmount,
	THEME_MODE,
	extractTransactionReference,
	extractExactTransactionService,
} from 'utilities';
import {
	TRANSACTION_STATUS,
	ITransaction,
	formatNumberToCurrency,
} from 'utilities';
// import FilterIcon from '../icons/filter';
import Empty from '../empty/table-empty';

import { useAppSelector } from 'store/hooks';

type Props = {
	data: ITransaction[] | null | undefined;
	isLoading?: boolean;
	filterData?: (filterValue: string) => void;
};

const TransactionHistoryTable = ({ data, isLoading }: Props) => {
	const theme = useTheme();
	const { mode } = useAppSelector((store) => store.theme);

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundImage: `url(${require('../../assets/images/bgImage.png')})`,
			backgroundColor:
				mode === THEME_MODE.dark ? theme.palette.background.paper : LIGHT_GRAY,
			backgroundSize: 'cover',
			backgroundPosition: 'top-left',
			fontSize: '14px',
			fontWeight: 'bold',
			color:
				mode === THEME_MODE.dark ? grey['100'] : theme.palette.primary.main,
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: '14px',
			whiteSpace: 'no-wrap',
			cursor: 'pointer',
			color:
				mode === THEME_MODE.dark ? grey['300'] : theme.palette.primary.main,
		},
		whiteSpace: 'nowrap',
	}));

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		// color: theme.palette.primary.main,
		'& th, & td': {
			borderColor:
				mode === THEME_MODE.dark ? theme.palette.background.paper : 'none',
		},
		'&:nth-of-type(even)': {
			backgroundColor:
				mode === THEME_MODE.dark ? theme.palette.background.paper : LIGHT_GRAY,
		},
		'&:nth-of-type(odd)': {
			backgroundColor:
				mode === THEME_MODE.dark
					? theme.palette.background.default
					: grey['50'],
		},
		// hide last border
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	}));

	return (
		<>
			<Box sx={{ overflow: 'auto' }}>
				<Table sx={{ overflow: 'auto' }} stickyHeader>
					<TableHead
						sx={{
							'& tr': {
								// backgroundColor: LIGHT_GRAY,
								// color: theme.palette.primary.main,
							},
						}}
					>
						<TableRow>
							<StyledTableCell>Transaction ID</StyledTableCell>
							<StyledTableCell>Transaction type</StyledTableCell>
							<StyledTableCell>Amount</StyledTableCell>
							<StyledTableCell>Date</StyledTableCell>
							<StyledTableCell>Time</StyledTableCell>
							<StyledTableCell>Status</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody
						sx={{
							'& tr': {
								color: theme.palette.primary.main,
							},
						}}
					>
						{isLoading ? (
							<StyledTableRow>
								<StyledTableCell colSpan={6}>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<CircularProgress />
									</Box>
								</StyledTableCell>
							</StyledTableRow>
						) : data && data.length > 0 ? (
							data.map((data: ITransaction) => (
								<StyledTableRow key={data.id}>
									<StyledTableCell>
										{extractTransactionReference(data)}
									</StyledTableCell>
									<StyledTableCell>
										{extractExactTransactionService(data)}
									</StyledTableCell>
									<StyledTableCell>
										{data.transaction
											? formatNumberToCurrency(
													checkAmount(data.transaction.amount)
											  )
											: formatNumberToCurrency(checkAmount(data.amount))}
									</StyledTableCell>
									<StyledTableCell>
										{moment(data.createdAt).format('ll')}
									</StyledTableCell>
									<StyledTableCell>
										{moment(data.createdAt).format('LT')}
									</StyledTableCell>
									<StyledTableCell>
										<Box
											sx={{
												display: 'flex',
												color:
													data.status === TRANSACTION_STATUS.SUCCESSFUL ||
													data.status === TRANSACTION_STATUS.APPROVED
														? SUCCESS_COLOR
														: data.status === TRANSACTION_STATUS.PENDING
														? PENDING_COLOR
														: DANGER_COLOR,
												alignItems: 'center',
												gap: theme.spacing(2),
											}}
										>
											<Box
												sx={{
													color:
														data.status === TRANSACTION_STATUS.SUCCESSFUL ||
														data.status === TRANSACTION_STATUS.APPROVED
															? SUCCESS_COLOR
															: data.status === TRANSACTION_STATUS.PENDING
															? PENDING_COLOR
															: DANGER_COLOR,
													padding: '5px',
													position: 'relative',
													height: '20px',
													width: '20px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													svg: {
														fontSize: '14px',
													},
												}}
											>
												{data.type === 'CREDIT' ? (
													<Check />
												) : data.status === TRANSACTION_STATUS.SUCCESSFUL ||
												  data.status === TRANSACTION_STATUS.APPROVED ? (
													<Check />
												) : data.status === TRANSACTION_STATUS.PENDING ? (
													<AccessTime />
												) : (
													<Close />
												)}
												<Box
													sx={{
														backgroundColor:
															data.status === TRANSACTION_STATUS.SUCCESSFUL ||
															data.status === TRANSACTION_STATUS.APPROVED
																? SUCCESS_COLOR
																: data.status === TRANSACTION_STATUS.PENDING
																? PENDING_COLOR
																: DANGER_COLOR,

														height: '100%',
														width: '100%',
														position: 'absolute',
														zIndex: '0',
														opacity: '0.15',
														borderRadius: '50%',
													}}
												/>
											</Box>
											{data.status}
										</Box>
									</StyledTableCell>
								</StyledTableRow>
							))
						) : (
							<Empty colSpan={6} text={'No Transaction'} />
						)}
					</TableBody>
				</Table>
			</Box>
		</>
	);
};

export default TransactionHistoryTable;
