import { useQuery } from 'react-query';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { QUERY_KEYS } from 'utilities';
import { wallet } from 'api';
import useToastAlert from './useToastAlert';
import useHandleResponse from './useHandleError';
import { setWallet } from '../store/wallet';

const useLoadWallet = () => {
	const { token } = useAppSelector((store) => store.auth);
	const alert = useToastAlert();
	const handleResponse = useHandleResponse();
	const dispatch = useAppDispatch();

	// Load user
	useQuery([QUERY_KEYS.UserWallet], wallet, {
		enabled: !!token,
		retry: 1,
		onSettled: (data, error) => {
			if (error) {
				const response = handleResponse({ error });
				if (response?.message) {
					alert({ type: 'error', message: response.message });
				}
			}

			if (data && data.success) {
				if (Array.isArray(data.payload)) {
					const wallet = data.payload[0];

					dispatch(setWallet(wallet));
				}
			}
		},
	});
};

export default useLoadWallet;
