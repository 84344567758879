import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Button, CreateAnAccountForm } from 'components';

const CreateAnAccount = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	return (
		<Box>
			<CreateAnAccountForm />
			<Box
				sx={{
					margin: '2rem 0px',
					position: 'relative',
					// display: 'flex',
					// alignItems: 'center',
					textAlign: 'center',
					':after': {
						content: '""',
						position: 'absolute',
						height: '1px',
						width: '45%',
						top: '50%',
						transform: 'translateX(-50)',
						backgroundColor: grey[400],
						right: '0px',
					},
					':before': {
						content: '""',
						position: 'absolute',
						top: '50%',
						transform: 'translateX(-50)',
						height: '1px',
						width: '45%',
						backgroundColor: grey[400],
						left: '0px',
					},
				}}
			>
				<Typography
					variant={'body1'}
					sx={{
						fontWeight: '600',
					}}
				>
					OR
				</Typography>
			</Box>
			<Box>
				<Typography
					sx={{ marginBottom: '15px', fontWeight: '600' }}
					variant={'h5'}
				>
					Login to account
				</Typography>
				<Typography variant={'body1'}>
					Click the login button if you already have an account.
				</Typography>
				<Button
					onClick={() => navigate('/auth/login')}
					fullWidth
					size={'large'}
					sx={{
						backgroundColor: theme.palette.secondary.main,
						color: grey[50],
						fontWeight: '600',
						marginTop: '16px',
						':hover': {
							backgroundColor: theme.palette.secondary.main,
						},
					}}
				>
					Login
				</Button>
			</Box>
		</Box>
	);
};

export default CreateAnAccount;
