import React from 'react';
import { Box, Typography, useTheme, IconButton } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BankAccount, LIGHT_GRAY } from 'utilities';
import { useAlert } from 'hooks';

type Props = {
	bankAccount: BankAccount;
};

const VirtualAccountItem = ({
	bankAccount: { bankName, accountNumber },
}: Props) => {
	const theme = useTheme();
	const alert = useAlert();
	const onCopy = () =>
		alert({
			message: `"${accountNumber}" copies successfully!!`,
			type: 'info',
		});
	return (
		<Box>
			<Box
				sx={{
					padding: '20px',
					background: `url(${require('../../assets/images/stripBg.png')})`,
					backgroundColor: LIGHT_GRAY,
					backgroundSize: '320%',
					backgroundPosition: 'start',
					borderRadius: '5px',
					marginBottom: '5px',
					height: '120px',
					alignItems: 'center',
					display: 'flex',
					':hover': {
						backgroundColor: LIGHT_GRAY,
					},
				}}
			>
				<Box
					sx={{ display: 'flex', flexDirection: 'column', gap: '6px', flex: 1 }}
				>
					<Box>
						<Typography
							sx={{ color: theme.palette.primary.main }}
							variant={'body2'}
						>
							Bank name
						</Typography>
						<Typography
							sx={{ color: theme.palette.primary.main, fontWeight: '600' }}
							variant={'body1'}
						>
							{bankName}
						</Typography>
					</Box>
					<Box>
						<Typography
							sx={{ color: theme.palette.primary.main }}
							variant={'body2'}
						>
							Account number
						</Typography>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: '6px',
							}}
						>
							<Typography
								sx={{ color: theme.palette.primary.main, fontWeight: '600' }}
								variant={'body1'}
							>
								{accountNumber}
							</Typography>
							<CopyToClipboard
								text={accountNumber as string}
								onCopy={() => onCopy()}
							>
								<IconButton>
									<ContentCopy sx={{ fontSize: '16px' }} />
								</IconButton>
							</CopyToClipboard>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default VirtualAccountItem;
