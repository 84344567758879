import React from 'react';
import { useQuery } from 'react-query';
import { Box } from '@mui/material';
import { QUERY_KEYS, BankAccount } from 'utilities';
import { useAppSelector } from 'store/hooks';
import { useHandleError, useAlert } from 'hooks';
import { myBanks } from 'api';
import BankInformationItem from './bank-information-item';
import CircularProgress from '../loader/circular-progress';
import AddNewbank from './add-new-bank';

type IBankInformation = {
	handleChangeTab?: (value: number) => void;
};

const BankInformation = ({ handleChangeTab }: IBankInformation) => {
	const alert = useAlert();
	const handleError = useHandleError();
	const { token, user } = useAppSelector((store) => store.auth);

	const { isLoading, data } = useQuery(QUERY_KEYS.UserBanks, myBanks, {
		enabled: !!token,
		refetchOnWindowFocus: false,
		onSettled: (data, error) => {
			if (error) {
				const res = handleError({ error });
				if (res?.message) {
					alert({ message: res.message, type: 'error' });
				}
			}
		},
	});

	return (
		<Box>
			{isLoading ? (
				<CircularProgress
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				/>
			) : (
				data && (
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: {
								xs: '1fr',
								sm: 'repeat(2, 1fr)',
								md: 'repeat(3, 1fr)',
							},
							gap: '10px',
						}}
					>
						<>
							{data.payload.length > 0 &&
								data.payload.map((bank: BankAccount) => (
									<BankInformationItem
										key={bank.id}
										bank={bank}
										active={
											user && user.defaultBank === bank.id
												? 'active'
												: undefined
										}
									/>
								))}
						</>

						<AddNewbank handleChangeTab={handleChangeTab} />
					</Box>
				)
			)}
		</Box>
	);
};

export default BankInformation;
