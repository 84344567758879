import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import TextInput from '../form-components/text-input';
import Button from '../button';
import { grey } from '@mui/material/colors';
import { QUERY_KEYS, TransferDetails, LINKS, EMAIL_REX } from 'utilities';
import TextPlaceholder from '../partials/custom-text-input-holder';
import {
	useHandleError,
	useAlert,
	useModalAlert,
	useVerifyPin,
	useVerifyUser,
} from 'hooks';
import { transfer } from 'api';

const WalletTransferForm = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const verifyPin = useVerifyPin();
	const navigate = useNavigate();
	const modal = useModalAlert();
	const queryClient = useQueryClient();
	const alert = useAlert();
	const handleError = useHandleError();
	const { isVerifyingUser, verifyUser, verifiedUser, clearVerifiedUser } =
		useVerifyUser();

	const validationSchema = yup.object().shape({
		amount: yup.number().required('Specify amount'),
		userEmailOrName: yup.string().required('Enter recepient email/user name'),
	});

	const { isLoading, mutate } = useMutation(transfer, {
		onSettled: (data, error) => {
			if (error) {
				const res = handleError({ error });
				if (res?.message) {
					modal({
						title: 'Transfer Message',
						message: res.message,
						type: 'error',
						onClickPrimaryButton: () => modal(null),
						primaryButtonText: 'Close',
					});
				}
			}

			if (data && data.success) {
				resetForm();
				clearVerifiedUser();
				queryClient.invalidateQueries(QUERY_KEYS.UserWallet);

				modal({
					title: 'Transfer Message',
					message: data.message,
					type: 'success',
					onClickPrimaryButton: () => {
						navigate(LINKS.Dashboard);
						modal(null);
					},
					primaryButtonText: 'Go to Dashboard',
				});
			}
		},
	});

	const initialValues: TransferDetails = {
		amount: '',
		userEmailOrName: '',
	};

	const {
		errors,
		touched,
		values,
		handleChange,
		handleSubmit,
		resetForm,
		setFieldValue,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			if (!verifiedUser) return alert({ message: 'Verify user', type: 'info' });

			if (verifiedUser) {
				if (
					EMAIL_REX.test(values.userEmailOrName as string) &&
					verifiedUser.email !== values.userEmailOrName
				)
					return alert({
						message: `Verified user email doesn't match provided email, verify user again`,
						type: 'info',
					});
				if (
					!EMAIL_REX.test(values.userEmailOrName as string) &&
					verifiedUser.username !== values.userEmailOrName
				)
					return alert({
						message: `Verified user name doesn't match provided name, verify user again`,
						type: 'info',
					});
			}

			let data = { amount: values.amount } as typeof initialValues;
			data.email = verifiedUser.email;

			verifyPin({
				title: 'Confirm Transfer',
				message: 'Verify  transaction Pin to confirm transfer',
				callback: () => {
					verifyPin(null);
					mutate(data);
				},
			});
		},
	});

	const { amount, userEmailOrName } = values;

	const handleChangeUserEmailOrName = (value: string) => {
		clearVerifiedUser();
		setFieldValue('userEmailOrName', value);
	};

	return (
		<>
			{/* {isVerifyingUser && <Loader />} */}

			<Box style={styles.form as any} component={'form'}>
				<Typography style={styles.title} variant={'h5'}>
					Wallet Transfer
				</Typography>

				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Specify amount
					</Typography>
					<TextInput
						fullWidth
						type={'number'}
						placeholder={'Type in amount you want to topup'}
						error={errors && touched.amount && errors.amount ? true : false}
						helperText={errors && touched.amount && errors.amount}
						value={amount}
						onChange={handleChange('amount')}
					/>
				</Box>

				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Recipient email/user name
					</Typography>
					<TextInput
						fullWidth
						placeholder={'Type in recipient email/username'}
						error={
							errors && touched.userEmailOrName && errors.userEmailOrName
								? true
								: false
						}
						helperText={
							errors && touched.userEmailOrName && errors.userEmailOrName
						}
						value={userEmailOrName}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleChangeUserEmailOrName(e.target.value)
						}
					/>
				</Box>

				{verifiedUser && (
					<Box>
						<Typography
							style={styles.label}
							component={'label'}
							variant={'body1'}
						>
							Recipient Name
						</Typography>
						<TextPlaceholder
							text={`${verifiedUser.firstname} ${verifiedUser.lastname}`}
						/>
					</Box>
				)}
				{amount && userEmailOrName && (
					<Button
						loading={isLoading || isVerifyingUser}
						size={'large'}
						style={styles.btn}
						onClick={(e: React.FormEvent<HTMLButtonElement>) => {
							e.preventDefault();
							if (!verifiedUser) return verifyUser(userEmailOrName);
							handleSubmit();
						}}
					>
						{verifiedUser &&
						(verifiedUser.email === userEmailOrName ||
							verifiedUser?.username === userEmailOrName)
							? 'Proceed'
							: 'Verify User'}
					</Button>
				)}
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
	},
});

export default WalletTransferForm;
