import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { Layout, Button, CircularProgress, Seo, BackButton } from 'components';
import {
	formatNumberToCurrency,
	ErrorBoundaryGuard,
	API_ENDPOINTS,
	ENDPOINT_SUBPATH,
} from 'utilities';
import { useAppSelector } from 'store/hooks';
import { useModalAlert, useHandleError, useAlert } from 'hooks';
import { billBundles, useEPin } from 'api';

const LoadEducation = () => {
	const alert = useAlert();
	const handleError = useHandleError();
	const modal = useModalAlert();
	const theme = useTheme();
	const { token } = useAppSelector((store) => store.auth);
	const { search } = useLocation();
	const query = queryString.parse(search);

	const [isLoading, setLoading] = useState<boolean>(true);

	const { isLoading: isLoadingEpin, mutate } = useMutation(useEPin, {
		onSettled: (data, error) => {
			if (error) {
				const response = handleError({ error });

				modal({
					title: 'Education E-Pin',
					message: response?.message as string,
					type: 'error',
					onClickPrimaryButton: () => modal(null),
					primaryButtonText: 'Close',
				});
			}

			if (data && data.success) {
				console.log(data);
			}
		},
	});

	const handleLoadEPin = () => {
		mutate({
			pin: query.pin as string,
		});
	};

	const { data: dataEducationBundles } = useQuery(
		'Load Education Bundle',
		() =>
			billBundles({
				url: `${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.EducationProviders}`,
				params: {
					provider: query.service_type as string,
				},
			}),
		{
			enabled: !!(token && query && query.service_type),

			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				setLoading(false);
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						alert({ message: response.message, type: 'error' });
					}
				}
			},
		}
	);

	return (
		<Layout>
			<Seo title={'Load Educatiion E-Pin'} />
			<ErrorBoundaryGuard>
				{isLoading ? (
					<CircularProgress />
				) : (
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: '1fr',
							gap: '15px',
							maxWidth: '600px',
						}}
					>
						<BackButton name={'E-Pin'} sx={{ marginBottom: ['1.5rem'] }} />
						<Typography sx={{ fontWeight: '600' }} variant={'h5'}>
							Education E-pin
						</Typography>
						<Box>
							<Typography>
								The e-pin you entered gives{' '}
								{query.amount && formatNumberToCurrency(query.amount as string)}{' '}
								worth of{' '}
								<Box component={'span'} sx={{ fontWeight: '600' }}>
									{dataEducationBundles &&
										dataEducationBundles.payload[0]?.description}
									.
								</Box>{' '}
							</Typography>
						</Box>
						<Button
							loading={isLoadingEpin}
							size={'large'}
							onClick={handleLoadEPin}
							sx={{
								backgroundColor: `${theme.palette.secondary.main} !important`,
								color: grey['50'],
							}}
						>
							Load E Pin
						</Button>
					</Box>
				)}
			</ErrorBoundaryGuard>
		</Layout>
	);
};

export default LoadEducation;
