import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'utilities';
import { notificationSettings } from 'api';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setNotificationSettings } from 'store/notification';

const useLoadNotificationSettings = () => {
	const { token } = useAppSelector((store) => store.auth);
	const dispatch = useAppDispatch();
	useQuery(QUERY_KEYS.NotificationSettings, () => notificationSettings(), {
		enabled: !!token,
		refetchOnWindowFocus: false,
		onSettled: (data) => {
			if (data && data.success) {
				dispatch(setNotificationSettings(data.payload[0]));
			}
		},
	});
};

export default useLoadNotificationSettings;
