import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Layout, Seo, BackButton, ServicesCard } from 'components';
import { LINKS, THEME_MODE } from 'utilities';
import { useAppSelector } from 'store/hooks';
import { grey } from '@mui/material/colors';

const PAGE = 'International Airtime and Data';

const SERVICES = [
	{
		title: 'International Airtime',
		description: 'Buy international airtime at mind-blowing rates',

		img: require('../../../assets/images/purchase-airtime.png'),
		link: `${LINKS.InternationalAirtimeData}/airtime`,
	},
	{
		title: 'International Data',
		description: 'Buy international airtime at mind-blowing rates',

		img: require('../../../assets/images/purchase-airtime.png'),
		link: `${LINKS.InternationalAirtimeData}/data`,
	},
];

const ServicesType = () => {
	const theme = useTheme();
	const themeMode = useAppSelector((store) => store.theme.mode);
	const styles = useStyles(theme, themeMode);
	return (
		<div>
			<Typography style={styles.title} variant={'h5'}>
				{PAGE}
			</Typography>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: {
						xs: '1fr',
						sm: 'repeat(2, 1fr)',
					},
					gap: '10px',
				}}
			>
				{SERVICES.map((data, key) => (
					<ServicesCard
						sx={{
							gridTemplateColumns: '0.6fr 0.4fr',
						}}
						data={data}
						key={key}
						isNew
					/>
				))}
			</Box>
		</div>
	);
};

const InternationalAirtimeData = () => {
	return (
		<Layout>
			<Seo title={PAGE} />
			<BackButton name={'Services'} sx={{ marginBottom: ['2rem'] }} />
			<ServicesType />
		</Layout>
	);
};

const useStyles = (theme: any, mode?: any) => ({
	title: {
		marginBottom: theme.spacing(3),
		fontWeight: '600',
		color: mode === THEME_MODE.dark ? grey[300] : 'inherit',
	},
});

export default InternationalAirtimeData;
