import {
	API_ENDPOINTS,
	DataResponse,
	ENDPOINT_SUBPATH,
	IPhoneVerifiedData,
} from 'utilities';
import { apiRequest } from '../config';

export const parseMsisdn = async (params: {
	phone?: string;
}): Promise<DataResponse<IPhoneVerifiedData>> => {
	return apiRequest({
		url: `${API_ENDPOINTS.InternationalAirtimeData}${ENDPOINT_SUBPATH.ParseMsisdn}`,
		method: 'GET',
		params,
	});
};
