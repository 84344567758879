import { TypographyProps, Typography } from '@mui/material';
import React from 'react';

interface Props extends TypographyProps {
	name: string;
}

const H5Typography: React.FC<Props> = ({ name, ...rest }) => {
	return (
		<Typography
			{...rest}
			sx={{ fontWeight: 'bold', marginBottom: '20px', ...rest.sx }}
			variant={'h5'}
		>
			{name}
		</Typography>
	);
};

export default H5Typography;
