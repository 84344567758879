import {
	API_ENDPOINTS,
	WithdrawDetails,
	TransferDetails,
	IFundWallet,
	DataResponse,
	IVirtualAccount,
	IWallet,
	ENDPOINT_SUBPATH,
	ITransaction,
} from 'utilities';
import { apiRequest } from '../config';

export const wallet = async (): Promise<DataResponse<IWallet[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.Wallet}${ENDPOINT_SUBPATH.Me}`,
		method: 'GET',
	});

export const virtualAccount = async (): Promise<
	DataResponse<IVirtualAccount>
> =>
	apiRequest({
		url: `${API_ENDPOINTS.VirtualAccount}${ENDPOINT_SUBPATH.Me}`,
		method: 'GET',
	});

export const fundWallet = async (
	data: IFundWallet
): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.Funding}/card`,
		method: 'POST',
		data,
	});

export const withdraw = async (
	data: WithdrawDetails
): Promise<DataResponse<any>> =>
	apiRequest({
		url: API_ENDPOINTS.Withdraw,
		method: 'POST',
		data,
	});

export const transfer = async (
	data: TransferDetails
): Promise<DataResponse<any>> =>
	apiRequest({
		url: API_ENDPOINTS.Transfer,
		method: 'POST',
		data,
	});

export const bankTranfers = async (params?: {
	[key: string]: any;
}): Promise<DataResponse<ITransaction[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.Transfer}${ENDPOINT_SUBPATH.Me}`,
		method: 'GET',
		params,
	});
