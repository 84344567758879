import React, { ReactNode } from 'react';
import { Box, Container } from '@mui/material';
import LogoWithText from '../logo/logo-with-text';
import Image from '../image';

type Props = {
	children: ReactNode;
};

const AuthLayout = ({ children }: Props) => {
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: {
					xs: '1fr',
					md: '5.5fr 4.5fr',
					lg: '6fr 4fr',
				},
				minHeight: '100vh',
			}}
		>
			<Container>
				<Box
					sx={{
						width: '100%',
						maxWidth: '560px',
						margin: {
							xs: '2rem auto',
							md: '4rem auto',
						},
					}}
				>
					<LogoWithText
						sx={{
							display: {
								md: 'none',
							},
							width: {
								xs: '160px',
								md: '200px',
							},
							img: {
								width: '100%',
								display: 'block',
							},
							marginBottom: '3rem',
						}}
					/>
					{children}
				</Box>
			</Container>
			<Box
				sx={{
					display: {
						xs: 'none',
						md: 'block',
					},
					backgroundImage: `url(${require('../../assets/images/auth-bg.png')})`,
					backgroundRepeat: `no-repeat, repeat`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					height: '100vh',
					width: '40%',
					right: '0px',
					position: 'fixed',
				}}
			>
				<Image
					sx={{
						img: {
							position: 'absolute',
							maxWidth: { md: '180px', lg: '200px' },
							top: '35%',
							left: '50%',
							transform: 'translate(-50%, -35%)',
						},
					}}
					src={require('../../assets/images/appLogoWithText.png')}
					alt={'App-logo-with-text'}
				/>
			</Box>
		</Box>
	);
};

export default AuthLayout;
