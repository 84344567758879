import React from 'react';
import {
	Layout,
	TwoColGrid,
	WalletWithBreakdown,
	Seo,
	PurchaseAirtimeForm,
	BackButton,
	H5Typography,
} from 'components';

const PurchaceAirtimePage = () => {
	return (
		<Layout>
			<Seo title={'Purchase Airtime'} />
			<TwoColGrid
				rightAside={<WalletWithBreakdown top={'6rem'} isStickySidebar />}
			>
				<BackButton name={'Services'} sx={{ marginBottom: ['2rem'] }} />
				<H5Typography name={'Purchase Airtime'} />
				<PurchaseAirtimeForm />
			</TwoColGrid>
		</Layout>
	);
};

export default PurchaceAirtimePage;
