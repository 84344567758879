import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Storage, STORAGE_KEYS, IUser } from 'utilities';

// Define the initial state using that type

type InitialStateTypes = {
	isAuthenticated: boolean;
	user: IUser | null;
	token: string;
	isAuthenticating: boolean;
};

const initialState: InitialStateTypes = {
	isAuthenticated: false,
	isAuthenticating: true,
	user: null,
	token: Storage.getItem(STORAGE_KEYS.UserToken) || null,
};

export const userSlice = createSlice({
	name: 'authState',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<IUser | null>) => {
			state.isAuthenticated = Boolean(action.payload);
			state.user = action.payload;
			state.isAuthenticating = false;
		},
		setToken: (state, action: PayloadAction<string>) => {
			state.token = action.payload;
		},

		logOut: (state) => {
			Storage.deleteItem(STORAGE_KEYS.UserToken);
			state.isAuthenticated = false;
			state.token = '';
			state.user = null;
		},
	},
});

export const { setUser, setToken, logOut } = userSlice.actions;

export default userSlice.reducer;
