import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import CreateAnAccount from './create-an-account';
import {
	AuthLayout,
	// BvnVerificationForm,
	SignupStepper,
	Seo,
	VerifyEmailForm,
} from 'components';
import { REGISTRATION_STEP } from 'utilities';

const SignUp = () => {
	const location = useLocation();

	const { step } = queryString.parse(location.search);

	const [currentStep, setCurrentStep] = useState<number>(1);

	useEffect(() => {
		switch (step) {
			case REGISTRATION_STEP.EMAIL_VERIFICATION:
				return setCurrentStep(2);

			case REGISTRATION_STEP.IDENTITY_VERIFICATION:
				return setCurrentStep(3);

			default:
				setCurrentStep(1);
		}
	}, [step]);

	const title =
		currentStep === 1
			? 'Create an Account'
			: currentStep === 2
			? 'Verify Email'
			: currentStep === 3
			? 'Identify Verification'
			: '';

	return (
		<AuthLayout>
			<Seo title={title} />
			<Box sx={{ marginBottom: '3rem' }}>
				<SignupStepper step={currentStep} />
			</Box>
			<Box hidden={currentStep !== 1}>
				<CreateAnAccount />
			</Box>
			<Box hidden={currentStep !== 2}>
				<VerifyEmailForm />
			</Box>
			{/* <Box hidden={currentStep !== 3}>
				<SignupIdentifyVerification />
			</Box> */}
		</AuthLayout>
	);
};

export default SignUp;
