import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWallet } from 'utilities';

// Define the initial state using that type
type InitialStateTypes = {
	wallet: IWallet | null;
	isLoading: boolean;
};

const initialState: InitialStateTypes = {
	wallet: null,
	isLoading: true,
};

export const appSlice = createSlice({
	name: 'walletState',
	initialState,
	reducers: {
		setWallet: (state, action: PayloadAction<IWallet | null>) => {
			state.wallet = action.payload;
			state.isLoading = false;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
	},
});

export const { setWallet, setLoading } = appSlice.actions;

export default appSlice.reducer;
