import React from 'react';
import { Button, useTheme, ButtonProps } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { THEME_MODE } from 'utilities';

interface IBackButtonProps extends ButtonProps {
	name?: string;
	onClick?: () => void;
}

const BackButton: React.FC<IBackButtonProps> = ({ name, onClick, ...rest }) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const { mode } = useAppSelector((state) => state.theme);

	const color =
		mode === THEME_MODE.dark ? grey['50'] : theme.palette.primary.main;

	const handleClick = () => {
		if (typeof onClick === 'function') return onClick();

		navigate(-1);
	};

	return (
		<Button
			{...rest}
			onClick={handleClick}
			sx={{
				...rest.sx,
				color,
				border: `0.5px solid ${color}`,
				paddingRight: '10px',
			}}
			startIcon={<ChevronLeft />}
			size={'small'}
		>
			{name || 'Back'}
		</Button>
	);
};

export default BackButton;
