import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import {
	Layout,
	TwoColGrid,
	GenerateDataPinForm,
	WalletBalance,
	Seo,
	BackButton,
} from 'components';

const GenerateData = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	return (
		<Layout>
			<Seo title={'Generate Data E-Pin'} />
			<TwoColGrid rightAside={<WalletBalance isSticky />}>
				<BackButton name={'E-Pin'} sx={{ marginBottom: ['1.5rem'] }} />
				<Box>
					<Typography style={styles.title} variant={'h5'}>
						Generate data e-pin
					</Typography>
					<GenerateDataPinForm />
				</Box>
			</TwoColGrid>
		</Layout>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: '20px',
	},
});

export default GenerateData;
