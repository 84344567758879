import React, { CSSProperties } from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import {
	formatNumberToCurrency,
	DANGER_COLOR,
	SUCCESS_COLOR,
	LIGHT_PRIMARY_COLOR,
} from 'utilities';
import ModalLayout from '../layout/modal-layout';

type Props = {
	amount?: string;
	transferNumber?: string;
	service?: string;
	callback?: () => void;
};

const AirtimeConvertModal = ({
	amount,
	transferNumber = '08109022197',
	service,
	callback,
}: Props) => {
	const theme = useTheme();
	const styles = useStyles(theme);

	const dialingCode = () => {
		switch (service) {
			case 'MTN':
				return `*321*Recipient's phone number*Amount*PIN# (Default PIN; 1234 or 0000).`;

			case 'AIRTEL':
				return `*432*1*Recipient's phone number*Amount*PIN# (Default PIN; 1234 or 0000).`;

			case 'GLO':
				return `*131*Recipient's phone number*Amount*PIN# (Default PIN;  00000).`;

			case '9MOBILE':
				return `*223*PiN*Amount* Recipient's phone number# (Default PIN; 0000 or 1234).`;

			default:
				return '';
		}
	};

	return (
		<ModalLayout
			hasCloseButton
			closeModal={() => typeof callback !== 'undefined' && callback()}
		>
			<Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: '20px' }}>
				<Box>
					<Typography
						style={{ ...styles.boldText, marginBottom: '8px' }}
						variant={'h6'}
					>
						Airtime Conversion Request Received!
					</Typography>
					<Typography variant={'body1'}>
						Airtime conversion request successfully created. To complete the
						requeest, transfer{' '}
						<Box style={styles.boldText} component={'span'}>
							{formatNumberToCurrency(amount || '')}
						</Box>{' '}
						worth of airtime to the number below
					</Typography>
				</Box>
				<Box style={{ ...styles.box, backgroundColor: red[50] }}>
					<Typography
						style={{ ...styles.boldText, color: SUCCESS_COLOR }}
						variant={'h6'}
					>
						{transferNumber}
					</Typography>
					<Typography
						style={{ ...styles.boldText, color: DANGER_COLOR }}
						variant={'body1'}
					>
						Never save the above phone number
					</Typography>
					<Typography
						style={{ ...styles.boldText, color: DANGER_COLOR }}
						variant={'body1'}
					>
						WE DON'T ACCEPT VTU OR RECHARGE PIN
					</Typography>
				</Box>
				<Box style={{ ...styles.box, backgroundColor: red[50] }}>
					<Typography
						style={{ ...styles.boldText, color: DANGER_COLOR }}
						variant={'body1'}
					>
						PLEASE USE THE DISPLAY PHONE NUMBER ONLY ONCE BECAUSE A NEW NUMBER
						WILL BE PROVIDED FOR EVERY TRANSACTION SO AS TO AVOID LOSS OF
						AIRTIME
					</Typography>
				</Box>
				<Box>
					<Typography
						style={{ ...styles.boldText, marginBottom: '8px' }}
						variant={'h6'}
					>
						How to transfer {service} Airtime
					</Typography>
					<Box style={{ ...styles.box, backgroundColor: LIGHT_PRIMARY_COLOR }}>
						<Typography style={styles.boldText} variant={'body1'}>
							Using USSD
						</Typography>
						<Typography style={styles.boldText} variant={'body1'}>
							Dail: {dialingCode()}
						</Typography>
					</Box>
				</Box>
				{/* <Box style={{ ...styles.box, backgroundColor: LIGHT_PRIMARY_COLOR }}>
					<Typography
						style={{ ...styles.boldText, color: SUCCESS_COLOR }}
						variant={'h6'}
					>
						How to change transfer PIN
					</Typography>
					<Typography style={styles.boldText} variant={'body1'}>
						{regExp.test(NetworkTypes.MTN)
							? `Dail: ${ChangeTransferPinTypes.MTN}`
							: regExp.test(NetworkTypes.Airtel)
							? `Send: ${ChangeTransferPinTypes.Airtel}`
							: regExp.test(NetworkTypes.Glo)
							? `Dail: ${ChangeTransferPinTypes.Glo}`
							: 'none'}
					</Typography>
				</Box> */}
				<Button
					onClick={() => {
						typeof callback !== 'undefined' && callback();
					}}
					size={'large'}
					style={styles.button as CSSProperties}
				>
					Complete
				</Button>
			</Box>
		</ModalLayout>
	);
};

const useStyles = (theme: any) => ({
	boldText: {
		fontWeight: '600',
	},
	box: {
		padding: '15px',
		borderRadius: '8px',
	},
	button: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		marginTop: '1rem',
		textTransform: 'uppercase',
		fontWeight: '600',
	},
});

export default AirtimeConvertModal;
