import React, { useState, CSSProperties, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import { Box, Typography, useTheme, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { grey } from '@mui/material/colors';
import { useMutation, useQuery } from 'react-query';
import TextInput from '../form-components/text-input';
import Button from '../button';
import CheckBox from '../form-components/check-box';
import {
	NUMBER_CHARACTERS,
	UPPER_CHARACTERS,
	SPECIAL_CHARACTERS,
	LINKS,
	STORAGE_KEYS,
	Storage,
	REFERRAL_CODE,
	SignupPayload,
	ValidationSchema,
	LANDING_PAGE_URL,
	extractUserName,
	IUser,
} from 'utilities';
import TextPlaceholder from '../partials/custom-text-input-holder';
import Loader from '../loader';
import { useHandleError, useAlert, useModal } from 'hooks';
import { register, verifyUser, emailExtensions } from 'api';
import { useAppSelector } from 'store/hooks';
import EmailField from 'components/form-components/email-field';

interface IVerifiedUser {
	firstname: string;
	lastname: string;
	email: string;
}

type PasswordChecksProps = {
	isCheck?: boolean;
	text?: string;
};

const PasswordChecks = ({ isCheck, text }: PasswordChecksProps) => {
	const theme = useTheme();
	const styles = useStyles(theme);

	return (
		<Box style={styles.passwordCheckList}>
			<Box
				sx={{
					height: '20px',
					width: '20px',
					minWidth: 'unset',
					backgroundColor: '#CCD6DC',
					borderRadius: '5px',
					padding: `4px`,
				}}
			>
				{isCheck && (
					<Box
						sx={{
							height: '100%',
							width: '100%',
							backgroundColor: (theme) => theme.palette.primary.main,
							borderRadius: '2.5px',
						}}
					></Box>
				)}
			</Box>
			<Typography variant={'body2'}>{text}</Typography>
		</Box>
	);
};

const CreateAnAccountForm = () => {
	const navigate = useNavigate();
	const alert = useAlert();
	const modal = useModal();
	const handleError = useHandleError();

	const mode = useAppSelector((store) => store.theme.mode);

	const theme = useTheme();
	const styles = useStyles(theme);
	const [isTogglePassword, setTogglePassword] = useState(false);
	const [isToggleConfirmPassword, setToggleConfirmPassword] = useState(false);

	const [isVerifyingCode, setVerifyingCode] = useState<boolean>(false);
	const [verifiedUser, setVerifiedUser] = useState<null | IVerifiedUser>(null);
	const location = useLocation();
	const query = queryString.parse(location.search);

	const initialValues: SignupPayload = {
		email: '',
		password: '',
		confirmPassword: '',
		username: '',
		referral_code: '',
		phone: '',
		isAgreed: false,
	};

	// Email Extension Query
	const { isLoading: isLoadingEmailExts, data: dataEmailExtensions } = useQuery(
		['email-extensions'],
		emailExtensions
	);

	const emailExtensionsPayload =
		dataEmailExtensions &&
		dataEmailExtensions.payload &&
		Object.values(dataEmailExtensions.payload).length > 0 &&
		Object.values(dataEmailExtensions.payload);

	const { mutate, isLoading } = useMutation(register, {
		onSettled: (data, error) => {
			if (error) {
				const response = handleError({ error });
				if (response?.message)
					alert({ message: response?.message, type: 'error' });
			}
			if (data && data.success) {
				handleResetForm();

				Storage.saveItem(STORAGE_KEYS.UserEmail, data.payload.email);
				navigate(`${LINKS.Register}?step=email_verification`);
			}
		},
	});

	const {
		handleChange,
		errors,
		touched,
		values,
		handleSubmit,
		resetForm,
		setValues,
		setFieldValue,
		setFieldError,
	} = useFormik({
		initialValues,
		validationSchema: ValidationSchema.Register,
		onSubmit: (values) => {
			const { referral_code, isAgreed, confirmPassword, email, ...rest } =
				values;
			const payload: any = {
				...rest,
			};

			if (/\s/.test(values.username)) {
				console.log(username);
				setFieldError('username', 'User name must not contain space');
				return;
			}

			setFieldError('username', '');

			if (email) {
				const emailExtension = (email as string)?.split('@')[1];
				if (!emailExtensionsPayload?.includes(emailExtension)) {
					modal.display({
						title: 'Create account error',
						message: `Email address must have the following extension ${emailExtensionsPayload.join(
							', '
						)}`,
						type: 'error',
						primaryButtonText: 'Close',
						onClickPrimaryButton: modal.close,
					});

					return;
				}

				payload.email = email;
			}

			if (referral_code) {
				payload.referral_code = referral_code;
			}
			mutate(payload);
		},
	});

	function handleResetForm() {
		resetForm();
		setValues({
			...values,
			isAgreed: false,
		});
	}

	const {
		email,
		phone,
		username,
		referral_code,
		password,
		isAgreed,
		confirmPassword,
	} = values;

	const handleVerifyUser = async (code: string) => {
		setVerifyingCode(true);
		try {
			const data = await verifyUser({ code });

			if (
				data.payload &&
				Array.isArray(data.payload) &&
				data.payload.length > 0
			) {
				setVerifiedUser(data.payload[0]);
			} else {
				alert({
					message: `Unable to verify code: ${code}`,
					type: 'info',
				});
			}
		} catch (error) {
			const response = handleError({ error });
			if (response?.message)
				alert({ message: response.message, type: 'error' });
		}
		setVerifyingCode(false);
	};

	useEffect(
		() => {
			if (query && query[REFERRAL_CODE]) {
				handleVerifyUser(query[REFERRAL_CODE] as string);
				setFieldValue('referral_code', query[REFERRAL_CODE]);
			}
		},
		// eslint-disable-next-line
		[]
	);

	const onReferralCodeChange = (value: string) => {
		setVerifiedUser(null);
		if (value.length === 6) {
			handleVerifyUser(value);
		}
		setFieldValue('referral_code', value, true);
	};

	const handleSelectOption = (option: string) => {};

	return (
		<Box>
			{isVerifyingCode && <Loader />}
			<Box sx={{ marginBottom: theme.spacing(4) }}>
				<Typography style={styles.title} variant={'h5'}>
					Create an Account
				</Typography>
				<Typography variant={'body1'}>
					Type in your details below with accurate information to create your
					account
				</Typography>
			</Box>
			<Box style={styles.form as any} component={'form'}>
				<TextInput
					labelText='Username'
					fullWidth
					placeholder={'Type in your username'}
					error={errors && touched.username && errors.username ? true : false}
					helperText={errors && touched.username && errors.username}
					value={username}
					onChange={handleChange('username')}
				/>

				<EmailField
					labelText='Email address'
					isLoading={isLoadingEmailExts}
					extensions={emailExtensionsPayload}
					fullWidth
					type={'email'}
					placeholder={'Type in your email address'}
					error={errors && touched.email && errors.email ? true : false}
					helperText={errors && touched.email && errors.email}
					value={email}
					onChange={(value) => {
						setFieldValue('email', value);
					}}
				/>

				<TextInput
					fullWidth
					labelText={'Phone number'}
					placeholder={'Type in your phone number'}
					error={errors && touched.phone && errors.phone ? true : false}
					helperText={errors && touched.phone && errors.phone}
					value={phone}
					onChange={handleChange('phone')}
				/>

				<Box>
					<TextInput
						labelText='Password'
						fullWidth
						type={isTogglePassword ? 'text' : 'password'}
						placeholder={'Type in your password here'}
						error={errors && touched.password && errors.password ? true : false}
						helperText={errors && touched.password && errors.password}
						value={password}
						onChange={handleChange('password')}
						InputProps={{
							endAdornment: (
								<InputAdornment position='start'>
									<Typography
										variant={'body1'}
										onClick={() => setTogglePassword(!isTogglePassword)}
										style={styles.showPassword as CSSProperties}
									>
										{isTogglePassword ? 'hide' : 'show'}
									</Typography>
								</InputAdornment>
							),
						}}
					/>
					<Box sx={{ marginTop: '8px' }}>
						<Typography variant={'body1'}>
							Password must contain at least 8 characters, one uppercase, one
							number and one special case character
						</Typography>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: '8px',
								marginTop: '8px',
							}}
						>
							<PasswordChecks
								isCheck={UPPER_CHARACTERS.test(password || '')}
								text={'At least one uppercase'}
							/>
							<PasswordChecks
								isCheck={NUMBER_CHARACTERS.test(password || '')}
								text={'At least one number character'}
							/>
							<PasswordChecks
								isCheck={SPECIAL_CHARACTERS.test(password || '')}
								text={'At least one special character'}
							/>
							<PasswordChecks
								isCheck={Boolean(password && password?.length >= 8)}
								text={'At least eight characters'}
							/>
						</Box>
					</Box>
				</Box>

				<TextInput
					labelText='Confirm password'
					fullWidth
					type={isToggleConfirmPassword ? 'text' : 'password'}
					placeholder={'Type in your password here'}
					error={
						errors && touched.confirmPassword && errors.confirmPassword
							? true
							: false
					}
					helperText={
						errors && touched.confirmPassword && errors.confirmPassword
					}
					value={confirmPassword}
					onChange={handleChange('confirmPassword')}
					InputProps={{
						endAdornment: (
							<InputAdornment position='start'>
								<Typography
									variant={'body1'}
									onClick={() =>
										setToggleConfirmPassword(!isToggleConfirmPassword)
									}
									style={styles.showPassword as CSSProperties}
								>
									{isToggleConfirmPassword ? 'hide' : 'show'}
								</Typography>
							</InputAdornment>
						),
					}}
				/>

				<TextInput
					fullWidth
					labelText='Referral code [optional]'
					placeholder={'Type in referral code here'}
					type={'number'}
					error={
						errors && touched.referral_code && errors.referral_code
							? true
							: false
					}
					helperText={errors && touched.referral_code && errors.referral_code}
					value={referral_code}
					// onChange={handleChange('referral_code')}
					onChange={(e) => {
						if (referral_code && referral_code.length === 11) {
							onReferralCodeChange(
								referral_code.slice(0, referral_code.length - 1)
							);
						} else {
							onReferralCodeChange(e.target.value);
						}
					}}
				/>

				{verifiedUser && (
					<Box>
						<Typography
							style={styles.label}
							component={'label'}
							variant={'body1'}
						>
							Referral name
						</Typography>
						<TextPlaceholder text={extractUserName(verifiedUser as IUser)} />
					</Box>
				)}

				<Box>
					<CheckBox
						helpertext={errors && touched.isAgreed && errors.isAgreed}
						value={isAgreed}
						onChange={handleChange('isAgreed')}
						label={
							<>
								By creating your account you agree to our{' '}
								<Link
									style={{ textDecoration: 'none' }}
									to={`${LANDING_PAGE_URL}/terms-and-conditions`}
								>
									<Typography
										sx={{
											color: theme.palette.secondary.main,
											fontWeight: '600',
											textDecoration: 'none',
										}}
										component={'span'}
										variant={'body1'}
									>
										Term and Conditions
									</Typography>
								</Link>
							</>
						}
					/>
				</Box>
				<Button
					loading={isLoading}
					size={'large'}
					style={styles.btn}
					onClick={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Next
				</Button>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	showPassword: {
		fontSize: '12px',
		color: theme.palette.secondary.main,
		fontWeight: '600',
		userSelect: 'none',
		cursor: 'pointer',
	},
	passwordCheckList: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
	},
});

export default CreateAnAccountForm;
