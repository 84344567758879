import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import {
	Layout,
	TwoColGrid,
	AirtimeConvertOtpForm,
	Seo,
	InstantAirtimeConvertInstructions,
	BackButton,
} from 'components';

const InstantConvertAirtime = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	return (
		<Layout>
			<Seo title={'Instant Convert Airtime'} />
			<TwoColGrid rightAside={<InstantAirtimeConvertInstructions isSticky />}>
				<BackButton name={'Convert Airtime'} sx={{ marginBottom: ['2rem'] }} />
				<Box>
					<Typography style={styles.title} variant={'h5'}>
						Instant Airtime to Cash
					</Typography>
					<AirtimeConvertOtpForm />
				</Box>
			</TwoColGrid>
		</Layout>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: '20px',
	},
});

export default InstantConvertAirtime;
