import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useQuery } from 'react-query';
import { useAppSelector } from 'store/hooks';
import { BankAccount, QUERY_KEYS } from 'utilities';
import VirtualAccountItem from './virtual-account-item';
import { useAlert, useHandleError } from 'hooks';
import { virtualAccount } from 'api';

const VirtualAccount = () => {
	const alert = useAlert();
	const handleError = useHandleError();
	const { token } = useAppSelector((store) => store.auth);

	const { isLoading, data: dataVirtualAccount } = useQuery(
		QUERY_KEYS.VirtualAccount,
		virtualAccount,
		{
			enabled: !!token,
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						alert({ message: response.message, type: 'error' });
					}
				}
			},
		}
	);

	return (
		<Box>
			{isLoading ? (
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<CircularProgress />
				</Box>
			) : (
				dataVirtualAccount && (
					<Box
						sx={{
							display: 'grid',
							gap: (theme) => theme.spacing(2),
							gridTemplateColumns: {
								xs: '1fr',
								sm: 'repeat(2, 1fr)',
							},
						}}
					>
						{dataVirtualAccount.payload.accounts.map(
							(bankAccount: BankAccount, key: number) => (
								<VirtualAccountItem key={key} bankAccount={bankAccount} />
							)
						)}
					</Box>
				)
			)}
		</Box>
	);
};

export default VirtualAccount;
