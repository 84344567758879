import React from 'react';
import { AuthLayout, RequestForgottenPasswordOtpForm, Seo } from 'components';

const RequestOtp = () => {
	return (
		<AuthLayout>
			<Seo title={'Request forgotten password OTP'} />

			<RequestForgottenPasswordOtpForm />
		</AuthLayout>
	);
};

export default RequestOtp;
