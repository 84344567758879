import { useState } from 'react';
import { verifyCoupon as verifyCouponApi } from 'api';
import useHandleError from './useHandleError';
import useToastAlert from './useToastAlert';
import { Coupon } from 'utilities';

const useVerifyCoupon = () => {
	const alert = useToastAlert();
	const handleError = useHandleError();
	const [coupon, setCoupon] = useState<null | Coupon>(null);
	const [isVerifyingCoupon, setVerifyingCoupon] = useState<boolean>(false);
	const verifyCoupon = async (code: string) => {
		const code$ = code.trim();
		if (!code) {
			alert({ message: 'Enter coupon code', type: 'info' });
		}
		setVerifyingCoupon(true);
		try {
			const data = await verifyCouponApi(code$);
			if (data && data.success) {
				const coupon = data.payload;
				alert({
					message: `Coupon ${code$} verified successfully!`,
					type: 'success',
				});
				setCoupon(coupon);
			}
		} catch (error) {
			const response = handleError({ error });
			if (response?.message)
				alert({ message: response.message, type: 'error' });
		}
		setVerifyingCoupon(false);
	};

	const clearCoupon = () => setCoupon(null);

	return {
		verifyCoupon,
		isVerifyingCoupon,
		coupon,
		clearCoupon,
	};
};

export default useVerifyCoupon;
