import React from 'react';
import { Box, Typography } from '@mui/material';
import VerifyPinForm from '../forms/verify-pin-form';
import { useAppSelector } from 'store/hooks';
import ModalLayout from '../layout/modal-layout';
import { useVerifyPin } from 'hooks';

const VerifyPinModal = () => {
	const { verifyPinModal } = useAppSelector((store) => store.app);
	const verifyPin = useVerifyPin();
	const close = () => verifyPin(null);

	if (verifyPinModal) {
		const { title, message, callback } = verifyPinModal;
		return (
			<ModalLayout hasCloseButton closeModal={close}>
				<Box sx={{ marginBottom: '30px' }}>
					<Typography
						sx={{ marginBottom: '10px', fontWeight: '600' }}
						variant={'h6'}
					>
						{title}
					</Typography>
					{message && <Typography variant={'body1'}>{message}</Typography>}
				</Box>
				<VerifyPinForm callback={callback} />
			</ModalLayout>
		);
	}

	return null;
};

export default VerifyPinModal;
