import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { Box, Typography, useTheme, CircularProgress } from '@mui/material';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import { useQueryClient } from 'react-query';
import { Layout, Seo, UploadImageButton, Image, Button } from 'components';
import { useHandleError, useModalAlert, useAlert } from 'hooks';
import { LINKS, QUERY_KEYS, Configs, API_ENDPOINTS } from 'utilities';
import { useAppSelector } from 'store/hooks';
import axios from 'axios';

interface PreviewImageProps {
	image: string;
	file: any;
	callback?: () => void;
}

const PreviewImage: React.FC<PreviewImageProps> = ({
	image,
	file,
	callback,
}) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const alert = useAlert();
	const queryClient = useQueryClient();
	const handleError = useHandleError();
	const { token } = useAppSelector((store) => store.auth);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isSubmittingImage, setSubmittingImage] = useState<boolean>(false);

	useEffect(() => {
		if (image) {
			setLoading(true);

			setTimeout(() => {
				setLoading(false);
			}, 3000);
		}
	}, [image]);

	const handleSubmit = async () => {
		const formData = new FormData();
		formData.append('file-upload', file);

		setSubmittingImage(true);

		try {
			const res = await axios.put(
				`${Configs.BASE_URL}${API_ENDPOINTS.User}/verify-level-3`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			const data = res.data;
			if (data && data.success) {
				queryClient.invalidateQueries(QUERY_KEYS.Me);
				typeof callback !== 'undefined' && callback();
			}
		} catch (error) {
			setSubmittingImage(false);
			const res = handleError({ error });
			if (res?.message) {
				alert({ message: res.message, type: 'error' });
			}
		}
	};

	// console.log(file);

	return (
		<Box
			style={styles.imagePreviewContainer as CSSProperties}
			sx={{
				width: '100%',
				height: '100%',
				'&:after': {
					position: 'absolute',
					content: '""',
					height: '100%',
					width: '100%',
					top: 0,
					left: 0,
					background: 'rgba(250, 250, 250, 0.5)',
					display: isLoading ? 'block' : 'none',
					// backgroundColor: isLoading ? grey[50] : 'unset',
				},
			}}
		>
			{isLoading && (
				<CircularProgress style={styles.spinner as CSSProperties} />
			)}
			<Image src={image} />
			{!isLoading && (
				<Button
					onClick={handleSubmit}
					loading={isSubmittingImage}
					style={styles.button}
					size={'large'}
				>
					Submit
				</Button>
			)}
		</Box>
	);
};

const ValidateIdentityCard = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const navigate = useNavigate();
	const modal = useModalAlert();
	const { state, search } = useLocation();

	const parseUrl = queryString.parse(search);

	const handleNavigate = () => {
		if (parseUrl && parseUrl.intended_url) {
			return navigate(parseUrl.intended_url as string, {
				replace: true,
				state: {
					redirect: parseUrl.redirect || state.redirect,
				},
			});
		}
		if (parseUrl && parseUrl.redirect) {
			return navigate(parseUrl.redirect as string, {
				replace: true,
				state: {},
			});
		}

		if (state && state.intended_url) {
			return navigate(state.intended_url as string, {
				state: {
					redirect: parseUrl.redirect || state.redirect,
				},
			});
		}

		if (state && state.redirect) {
			return navigate(state.redirect as string, {
				state: {},
			});
		}

		navigate(LINKS.Dashboard);
	};

	const handlePreviewImage = useCallback(
		(file: any, image: string) => {
			if (image) {
				modal({
					title: 'Preview Image',
					children: (
						<PreviewImage
							callback={() =>
								modal({
									title: 'Validate ID Card',
									message:
										'ID card has been submitted successfully! Pending for KYC level three upgrade',
									type: 'success',
									primaryButtonText: 'Close',
									onClickPrimaryButton: () => {
										modal(null);
										handleNavigate();
									},
								})
							}
							file={file}
							image={image}
						/>
					),
					hasCloseButton: true,
					callback: () => {
						modal(null);
					},
				});
			}
		},
		// eslint-disable-next-line
		[]
	);

	return (
		<Layout>
			<Seo title={'Confirm two factor authentication token'} />
			<Box style={styles.container}>
				<Box>
					<Typography style={styles.title} variant={'h5'}>
						Vaildate ID Card
					</Typography>
					<Typography variant={'body1'}>
						Upload a copy of your identification card.
					</Typography>
				</Box>
				<UploadImageButton
					size={'large'}
					sx={{
						backgroundColor: (theme) =>
							`${theme.palette.secondary.main} !important`,
						color: grey['50'],
						maxWidth: '200px',
					}}
					setFile={handlePreviewImage}
				>
					Upload ID Card
				</UploadImageButton>
			</Box>
		</Layout>
	);
};

const useStyles = (theme: any) => ({
	container: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '30px',
		maxWidth: '540px',
	},
	imagePreviewContainer: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(2),
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
	},
	spinner: {
		position: 'absolute',
		color: grey['50'],
	},
	title: {
		fontWeight: '600',
		marginBottom: '20px',
	},
	resendOtp: {
		fontWeight: 'bold',
		cursor: 'pointer',
	},
	button: {
		backgroundColor: theme.palette.secondary.main,
		color: grey['50'],
		minWidth: '160px',
	},
});

export default ValidateIdentityCard;
