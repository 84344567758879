import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';
import { LIGHT_GRAY, LINKS, THEME_MODE } from 'utilities';
import { useAppSelector } from 'store/hooks';
import { grey } from '@mui/material/colors';

type IAddNewbank = {
	handleChangeTab?: (tabValue: number) => void;
};

const AddNewbank = ({ handleChangeTab }: IAddNewbank) => {
	const theme = useTheme();
	const navigate = useNavigate();

	const mode = useAppSelector((store) => store.theme.mode);
	const isDark = mode === THEME_MODE.dark;

	const handleClick = () => {
		navigate(LINKS.AddBank);
	};

	return (
		<Button
			onClick={handleClick}
			sx={{
				padding: '20px',
				background: `url(${require('../../assets/images/stripBg.png')})`,
				backgroundColor: isDark ? theme.palette.background.paper : LIGHT_GRAY,
				backgroundSize: '320%',
				backgroundPosition: 'start',
				borderRadius: '5px',
				marginBottom: '5px',
				height: '120px',
				display: 'flex',
				flexDirection: 'column',
				':hover': {
					backgroundColor: isDark ? theme.palette.background.paper : LIGHT_GRAY,
				},
			}}
		>
			<Add
				sx={{
					marginBottom: '10px',
					color: isDark ? grey['100'] : theme.palette.primary.main,
				}}
			/>
			<Typography
				sx={{
					color: isDark ? grey['100'] : theme.palette.primary.main,
				}}
				variant={'body1'}
			>
				Add bank account
			</Typography>
		</Button>
	);
};

export default AddNewbank;
