import React from 'react';
import { Box, Typography, Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setPushNotification } from '../../store/app';
import Image from '../image';
import ModalLayout from '../layout/modal-layout';

const PushNotificationModal = () => {
	const dispatch = useAppDispatch();
	const { pushNotification } = useAppSelector((store) => store.app);

	const closeModal = () => {
		dispatch(setPushNotification(null));
	};

	return (
		<ModalLayout
			hasCloseButton
			closeModal={() => closeModal()}
			sx={{ paddingTop: '3rem', paddingBottom: '3rem', maxWidth: '640px' }}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					gap: '20px',
				}}
			>
				<Image
					sx={{ width: '100px' }}
					src={
						pushNotification?.image || require('../../assets/icons/lock.png')
					}
					alt={'Lock'}
				/>
				{pushNotification?.title && (
					<Title variant={'h6'}>{pushNotification.title}</Title>
				)}
				<Text variant={'body1'}>{pushNotification?.body}</Text>
				<Button onClick={() => closeModal()} size={'large'}>
					Close
				</Button>
			</Box>
		</ModalLayout>
	);
};

const Title = styled(Typography)(({ theme }) => ({
	fontWeight: '600',
	textAlign: 'center',
}));

const Text = styled(Typography)(({ theme }) => ({
	textAlign: 'center',
}));

const Button = styled(MuiButton)(({ theme }) => ({
	textAlign: 'center',
	backgroundColor: `${theme.palette.secondary.main} !important`,
	color: grey[50],
	minWidth: '180px',
	fontWeight: '600',
}));

export default PushNotificationModal;
