import React, { useRef } from 'react';
import moment from 'moment';
import { ITransaction, checkAmount, formatNumberToCurrency } from 'utilities';
import AppTable from './components/table';
import TransactionDetails from 'components/transaction-details';
import { useAppSelector } from 'store/hooks';

interface Props {
	data: ITransaction[] | null;
	isLoading?: boolean;
}

const DataSubscriptionTable = ({ data, isLoading }: Props) => {
	const transactionDetailsRef = useRef<any>(null);
	const handleRowClick = (transaction: ITransaction) => {
		if (transactionDetailsRef.current)
			transactionDetailsRef.current.display(transaction);
	};
	return (
		<>
			<TransactionDetails ref={transactionDetailsRef} />
			<AppTable
				canClickRow
				onRowClick={handleRowClick}
				numberOfColumns={8}
				isLoading={isLoading}
				header={[
					'Reference',
					'Network',
					'Plan',
					'Data Type',
					'Phone Number',
					'Amount',
					'Created At',
					'Status',
				]}
				body={
					data &&
					data.map((value) => {
						const network =
							(typeof value?.plan === 'object' &&
								value?.plan?.network &&
								typeof value?.plan?.network === 'object' &&
								value?.plan?.network?.name) ||
							'';
						const plan =
							(typeof value.plan === 'object' && value.plan.name) || '';
						const dataType =
							(typeof value.dataType === 'object' && value.dataType.name) || '';

						return {
							data: [
								value.reference,
								network,
								plan,
								dataType,
								value?.number,
								formatNumberToCurrency(checkAmount(`${value?.amount}`)),
								moment(value.createdAt).format('ll'),
								value.status,
							],
							rawData: value,
						};
					})
				}
				emptyText={'No data subscription'}
			/>
		</>
	);
};

export default DataSubscriptionTable;
