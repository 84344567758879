import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { Layout, CopyReferralCode, Link, Seo } from 'components';
import { THEME_MODE, LINKS } from 'utilities';
import { useAppSelector } from 'store/hooks';

const Referral = () => {
	const theme = useTheme();
	const {
		theme: { mode },
		auth: { user },
	} = useAppSelector((store) => store);
	const styles = useStyles(theme, mode);
	return (
		<Layout>
			<Seo title={'Referral'} />
			<Box
				sx={{
					display: 'grid',
					alignItems: 'center',
					justifyContent: 'center',
					gridTemplateColumns: {
						xs: '1fr',
						md: 'repeat(2, 1fr)',
					},
					gap: '3rem',
				}}
			>
				<Box style={styles.leftContent as any}>
					<Typography style={styles.title} variant={'h4'}>
						Refer Friends and Earn together.
					</Typography>
					<Typography style={styles.descriptionText} variant={'body1'}>
						Earn up to NGN 500 on every referral, when your friend tops up their
						account and pay a bill on AirtimeFlip.
					</Typography>
					<Link style={styles.referralLink} to={LINKS.REFERRAL_HISTORY}>
						<Box component={'span'}>Referral History</Box>
						<ArrowForwardIos style={styles.arrowIcon} />
					</Link>

					<CopyReferralCode code={user?.code as string} />
				</Box>
				<Box
					sx={{
						img: {
							width: '100%',
						},
					}}
				>
					<img
						src={require('../../assets/images/referral.png')}
						alt={'Referral'}
					/>
				</Box>
			</Box>
		</Layout>
	);
};

const useStyles = (theme: any, mode: string) => ({
	leftContent: {
		display: 'flex',
		flexDirection: 'column',
		gap: '30px',
	},
	title: {
		fontWeight: '600',
		color: mode === THEME_MODE.light ? theme.palette.primary.main : grey[200],
	},
	descriptionText: {
		color: theme.palette.secondary.main,
	},
	referralLink: {
		padding: '0px',
		alignSelf: 'start',
		display: 'flex',
		alignItems: 'center',
	},
	arrowIcon: {
		color: mode === THEME_MODE.light ? theme.palette.primary.main : grey[200],
		fontSize: '16px',
	},
});

export default Referral;
