import React from 'react';

type Props = {
	color?: string;
	size?: number;
	innerColor?: string;
};

const Settings = ({ size, color, innerColor }: Props) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g>
				<path
					d='M11.9998 17.2749C14.9131 17.2749 17.2748 14.9132 17.2748 12C17.2748 9.08666 14.9131 6.72498 11.9998 6.72498C9.08654 6.72498 6.72485 9.08666 6.72485 12C6.72485 14.9132 9.08654 17.2749 11.9998 17.2749Z'
					fill={innerColor || '#DCE3E7'}
				/>
				<path
					d='M12 17.9344C8.72784 17.9344 6.06567 15.2722 6.06567 12C6.06567 8.72784 8.72784 6.06567 12 6.06567C15.2722 6.06567 17.9344 8.72784 17.9344 12C17.9344 15.2722 15.2722 17.9344 12 17.9344ZM12 7.38445C9.45496 7.38445 7.38441 9.45501 7.38441 12.0001C7.38441 14.5452 9.45496 16.6157 12 16.6157C14.5451 16.6157 16.6157 14.5452 16.6157 12.0001C16.6157 9.45501 14.5451 7.38445 12 7.38445Z'
					fill={color}
				/>
				<path
					d='M13.0961 24.0001H10.9038C10.144 24.0001 9.52592 23.382 9.52592 22.6222V21.7216C8.59194 21.4852 7.70408 21.1173 6.87538 20.6232L6.23821 21.2604C5.98072 21.5179 5.56321 21.5179 5.30568 21.2604C5.04815 21.0029 5.0482 20.5854 5.30568 20.3278L6.31593 19.3176C6.53882 19.0948 6.88813 19.0607 7.14983 19.2365C8.11264 19.8832 9.17661 20.3241 10.3122 20.5469C10.6216 20.6077 10.8446 20.8788 10.8446 21.194V22.6222C10.8446 22.6548 10.8711 22.6812 10.9037 22.6812H13.096C13.1286 22.6812 13.1551 22.6548 13.1551 22.6222V21.194C13.1551 20.8788 13.3781 20.6077 13.6874 20.5469C14.8231 20.324 15.8871 19.8832 16.8498 19.2365C17.1115 19.0608 17.461 19.0948 17.6837 19.3176L18.694 20.3278C18.717 20.3509 18.7545 20.3509 18.7775 20.3278L20.3277 18.7776C20.3507 18.7546 20.3507 18.7171 20.3277 18.6941L19.3175 17.6839C19.0947 17.461 19.0607 17.1116 19.2364 16.85C19.8831 15.8871 20.324 14.8232 20.5468 13.6875C20.6076 13.3782 20.8787 13.1552 21.1939 13.1552H22.6221C22.6546 13.1552 22.6811 13.1287 22.6811 13.0961V10.9038C22.6811 10.8712 22.6546 10.8447 22.6221 10.8447H21.1939C20.8787 10.8447 20.6076 10.6216 20.5468 10.3124C20.3239 9.17672 19.8831 8.1127 19.2364 7.14994C19.0607 6.88828 19.0947 6.53888 19.3175 6.31603L20.3277 5.30578C20.5852 5.0483 21.0027 5.0483 21.2603 5.30578C21.5178 5.56327 21.5178 5.98078 21.2603 6.23831L20.6231 6.87548C21.1172 7.70414 21.4851 8.59204 21.7215 9.52603H22.6221C23.3819 9.52603 24 10.1441 24 10.9039V13.0962C24 13.856 23.3819 14.4741 22.6221 14.4741H21.7215C21.4851 15.408 21.1172 16.2959 20.6231 17.1246L21.2603 17.7618C21.7975 18.299 21.7975 19.173 21.2603 19.7103L19.7101 21.2605C19.1729 21.7977 18.2988 21.7976 17.7616 21.2605L17.1244 20.6233C16.2957 21.1174 15.4078 21.4853 14.4738 21.7217V22.6223C14.4739 23.382 13.8559 24.0001 13.0961 24.0001Z'
					fill={color}
				/>
				<path
					d='M3.20587 18.8874C3.03712 18.8874 2.86832 18.823 2.7396 18.6942C2.48212 18.4367 2.48212 18.0192 2.7396 17.7617L3.37682 17.1245C2.88267 16.2958 2.51484 15.4079 2.27845 14.4739H1.37784C0.618093 14.474 0 13.8559 0 13.0962V10.9039C0 10.1441 0.618093 9.52601 1.37784 9.52601H2.2785C2.51479 8.59203 2.88276 7.70417 3.37687 6.87547L2.73965 6.2383C2.20246 5.70111 2.20246 4.82704 2.73965 4.2898L4.2899 2.73956C4.82708 2.20237 5.70116 2.20242 6.23839 2.73956L6.87557 3.37678C7.70422 2.88271 8.59203 2.51479 9.52611 2.2784V1.37784C9.52606 0.618093 10.1441 0 10.9038 0H13.0961C13.8559 0 14.474 0.618093 14.474 1.37784V2.2785C15.4081 2.51489 16.2959 2.88281 17.1245 3.37687L17.7617 2.73965C18.0192 2.48217 18.4367 2.48217 18.6943 2.73965C18.9519 2.99714 18.9518 3.41465 18.6943 3.67218L17.684 4.68243C17.4611 4.90523 17.1118 4.9393 16.8501 4.76352C15.8874 4.11688 14.8234 3.67602 13.6877 3.45309C13.3784 3.39234 13.1554 3.12121 13.1554 2.80603V1.37784C13.1554 1.34526 13.1289 1.31878 13.0963 1.31878H10.9039C10.8714 1.31878 10.8449 1.34526 10.8449 1.37784V2.80603C10.8449 3.12121 10.6218 3.39234 10.3125 3.45309C9.17684 3.67598 8.11278 4.11688 7.15011 4.76352C6.88846 4.9393 6.53905 4.90527 6.31621 4.68243L5.30577 3.67213C5.28276 3.64898 5.2453 3.64907 5.22229 3.67213L3.67209 5.22238C3.64907 5.2454 3.64907 5.28285 3.67209 5.30587L4.68233 6.31611C4.90513 6.539 4.93916 6.88836 4.76343 7.15002C4.1167 8.11283 3.67584 9.1768 3.45299 10.3124C3.39224 10.6218 3.12112 10.8448 2.80593 10.8448H1.37775C1.34517 10.8448 1.31868 10.8713 1.31868 10.9039V13.0962C1.31868 13.1288 1.34517 13.1553 1.37775 13.1553H2.80593C3.12112 13.1553 3.39224 13.3783 3.45299 13.6876C3.67588 14.8233 4.11674 15.8873 4.76343 16.85C4.93916 17.1117 4.90513 17.4611 4.68233 17.6839L3.67209 18.6942C3.54332 18.823 3.37462 18.8874 3.20587 18.8874Z'
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id='clip0_1342_75912'>
					<rect width='24' height='24' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Settings;
