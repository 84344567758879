import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Box, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { grey } from '@mui/material/colors';
import { useMutation, useQueryClient } from 'react-query';
import TextInput from '../form-components/text-input';
import Button from '../button';
import { useAppDispatch } from 'store/hooks';
import Loader from '../loader';
import {
	QUERY_KEYS,
	PAYMENT_METHOD,
	LINKS,
	formatNumberToCurrency,
	yupValidationError,
	FUND_WALLET_CHARGE,
} from 'utilities';
import { useAlert, useHandleError, useModalAlert } from 'hooks';
import { fundWallet } from 'api';
import { setTransactionBreakdown } from 'store/transaction';
import { MonnifyCheckout } from '../checkout';

const MAX_AMOUNT = 10000;

const WalletTopupForm = () => {
	const navigate = useNavigate();
	const theme = useTheme();
	const handleError = useHandleError();
	const modal = useModalAlert();
	const styles = useStyles(theme);
	const alert = useAlert();
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	const monifyRef = useRef<any>(null);

	const [finalAmount, setFinalAmount] = useState<string>('');
	const [error, setError] = useState<string>('');

	const validationSchema = yup.object().shape({
		amount: yup
			.number()
			.integer('Amount can not be in decimal')
			.positive('Amount can not be negative')
			.lessThan(
				MAX_AMOUNT,
				`Deposit amount can not be more than ${formatNumberToCurrency(
					MAX_AMOUNT
				)}`
			)
			.required('Enter amount'),
	});

	const { values, handleSubmit, setFieldValue, errors, touched } = useFormik({
		initialValues: {
			amount: '',
		},
		validationSchema,
		onSubmit: (values) => {
			if (monifyRef && monifyRef.current) {
				monifyRef.current.initialize({ amount: Number(finalAmount) });
			}
		},
	});

	const { mutate, isLoading } = useMutation(fundWallet, {
		onSettled: (data, error) => {
			if (error) {
				const response = handleError({ error });
				if (response?.message) {
					alert({ message: response.message, type: 'error' });
				}
			}
			if (data && data.success) {
				queryClient.invalidateQueries(QUERY_KEYS.UserWallet);
				queryClient.invalidateQueries(QUERY_KEYS.Transactions);

				setFinalAmount('');
				dispatch(setTransactionBreakdown(null));
				modal({
					title: 'Wallet Topup',
					message: data.message,
					onClickPrimaryButton: () => {
						modal(null);
						navigate(LINKS.Dashboard);
					},
					primaryButtonText: 'Go to Dashboard',
					type: 'success',
				});
			}
		},
	});

	const handleValidation = async (value?: string) => {
		const amount = Number(value);

		dispatch(
			setTransactionBreakdown({
				serviceCost: amount,
				charge: FUND_WALLET_CHARGE.toString(),
			})
		);

		try {
			const res = await validationSchema.validate({ amount });
			if (res) setError('');
		} catch (error) {
			const message = yupValidationError(error);
			setError(message);
		}
	};

	/* const makePayment = (txnref: string) => {
		mutate({
			reference: txnref,
			amount: Number(finalAmount),
			paymentGateway: PAYMENT_METHOD.Interswitch,
		});
	}; */

	const makePayment = (txnres: any) => {
		console.log(txnres);
		mutate({
			reference: txnres.paymentReference,
			amount: Number(txnres.authorizedAmount),
			paymentGateway: PAYMENT_METHOD.Monnify,
		});
	};

	// Interswitch config
	/* const merchantCode = process.env.REACT_APP_MARCHANT_CODE as string;

	const paymentParameters = {
		merchantCode,
		payItemID: `Default_Payable_${merchantCode}`,
		customerEmail: user?.email as string,
		redirectURL: window.location.href,
		text: 'Continue',
		mode: 'TEST',
		transactionReference: generateRandomNumber({ length: 10 }),
		amount: `${parseInt(finalAmount) * 100}`, // KOBO
		style: {
			width: '100%',
			height: '50px',
			fontWeight: '600',
			fontSize: '14px',
			cursor: 'pointer',
			borderRadius: theme.spacing(1),
			border: '0px',
			color: '#fff',
			backgroundColor: theme.palette.secondary.main,
			// display: 'none',
		},
		callback: (response: any) => {
			switch (response.resp) {
				case '00':
					return makePayment(response.txnref);
				case 'Z6':
					return alert({ message: 'Transaction cancel', type: 'info' });
				case '51':
					return alert({
						message: 'Insufficient funds to make payment',
						type: 'info',
					});
				case 'Z1':
					return alert({
						message: 'Something went wrong, try again',
						type: 'info',
					});

				default:
					return '';
			}
		},
	}; */

	const onChangeAmount = (value: string) => {
		handleValidation(value);
		const charge = Number(value) + (Number(value) * FUND_WALLET_CHARGE) / 100;
		// setAmount(value);
		setFinalAmount(charge.toString());
		setFieldValue('amount', value);
	};

	return (
		<Box>
			{isLoading && <Loader />}
			<MonnifyCheckout ref={monifyRef} onSuccess={makePayment} />

			<Box
				style={styles.form as any}
				onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
					e.preventDefault();
				}}
				component={'form'}
			>
				<Typography style={styles.title} variant={'h5'}>
					Wallet Topup
				</Typography>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Specify amount
					</Typography>
					<TextInput
						fullWidth
						placeholder={'Type in amount you want to topup'}
						error={Boolean(touched.amount && errors.amount)}
						helperText={touched.amount && errors.amount}
						value={values.amount}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							const value = e.target.value;
							onChangeAmount(value);
						}}
					/>
				</Box>
				{/* {amount && !error ? (
					<InterswitchPay {...paymentParameters} />
				) : (
					<Button
						type={'submit'}
						onClick={(e) => {
							e.preventDefault();
							handleValidation(amount);
						}}
						size={'large'}
						style={styles.btn}
					>
						Continue
					</Button>
				)} */}
				<Button
					type={'submit'}
					onClick={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
					size={'large'}
					style={styles.btn}
				>
					Continue
				</Button>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
	},
});

export default WalletTopupForm;
