import { apiRequest } from '../config';
import {
	API_ENDPOINTS,
	ENDPOINT_SUBPATH,
	DataResponse,
	ITransaction,
	Product,
	IProvider,
} from 'utilities';

export interface IESimPurchase {
	amount: number;
	productId: number;
	operatorId: number;
	product: string;
}

export const eSimTransactions = async (params: {
	[key: string]: any;
}): Promise<DataResponse<ITransaction[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.ESim}${ENDPOINT_SUBPATH.Me}`,
		method: 'GET',
		params,
	});

export const purchaseESim = async (
	data: IESimPurchase
): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.ESim}/purchase`,
		method: 'POST',
		data,
	});

export const eSimProducts = async (
	providerId: string
): Promise<DataResponse<Product[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.ESim}${ENDPOINT_SUBPATH.Products}/${providerId}`,
		method: 'GET',
	});

export const eSimProviders = async (): Promise<DataResponse<IProvider[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.ESim}${ENDPOINT_SUBPATH.Providers}`,
		method: 'GET',
	});
