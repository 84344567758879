import React from 'react';
import { Box, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import {
	Layout,
	Button,
	SupportTicketListItem,
	CircularProgress,
	BackButton,
} from 'components';
import { QUERY_KEYS, ITicket, THEME_MODE, LINKS, TICKET_TYPE } from 'utilities';
import { useAlert, useHandleError } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { tickets } from 'api';

const SupportTicket = () => {
	const alert = useAlert();
	const handleError = useHandleError();
	const {
		auth: { token },
		theme: { mode },
	} = useAppSelector((store) => store);
	const navigate = useNavigate();

	const { data, isLoading } = useQuery(
		[QUERY_KEYS.Tickets],
		() =>
			tickets({
				populate: 'related_transaction',
				sort: '-createdAt',
			}),
		{
			enabled: !!token,
			onError: (error) => {
				const response = handleError({ error });
				if (response?.message) {
					alert({ message: response.message, type: 'error' });
				}
			},
		}
	);

	return (
		<Layout>
			<BackButton name={'Get Help/FAQs'} sx={{ marginBottom: ['1.5rem'] }} />
			<Box
				sx={{
					marginBottom: '30px',
					display: 'flex',
					gap: '15px',
					alignItems: 'center',
				}}
			>
				<Title variant={'h5'}>Support Ticket</Title>
				<CreateTicketButton
					startIcon={<Add />}
					onClick={() =>
						navigate(
							`${LINKS.CreateSupportTicket}?_type=${TICKET_TYPE.COMPLAINT}`
						)
					}
				>
					Create ticket
				</CreateTicketButton>
			</Box>
			{isLoading ? (
				<CircularProgress />
			) : (
				data &&
				data.payload && (
					<>
						{data.payload.length > 0 ? (
							<Box
								sx={{
									border: `1px solid ${
										mode === THEME_MODE.light ? grey['200'] : grey['900']
									}`,
									'& > div': {
										borderBottom: `1px solid ${
											mode === THEME_MODE.light ? grey['200'] : grey['800']
										}`,
									},
									'& > div:last-child': {
										borderBottom: `none !important`,
									},
								}}
							>
								{data.payload.map((ticket: ITicket) => (
									<SupportTicketListItem key={ticket.id} ticket={ticket} />
								))}
							</Box>
						) : (
							<Box>No record</Box>
						)}
					</>
				)
			)}
		</Layout>
	);
};

const Title = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold',
	marginBottom: theme.spacing(1),
}));

const CreateTicketButton = styled(Button)(({ theme }) => ({
	backgroundColor: `${theme.palette.secondary.main} !important`,
	color: grey['50'],
}));

export default SupportTicket;
