import React, { useEffect, useState } from 'react';
import {
	Box,
	MenuItem,
	Typography,
	useTheme,
	InputAdornment,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import TextInput from '../form-components/text-input';
import Button from '../button';
import Select from '../form-components/select';
import {
	QUERY_KEYS,
	API_ENDPOINTS,
	ENDPOINT_SUBPATH,
	IBill,
	IProvider,
	Bundle,
	EPIN_SERVICES,
	TICKET_TYPE,
	formatNumberToCurrency,
	LINKS,
	ValidationSchema,
	calculateFinalAmount,
	Coupon,
	IPin,
} from 'utilities';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
	useVerifyCoupon,
	useHandleError,
	useModalAlert,
	useVerifyPin,
	useAlert,
	useCheckKycLevelTwo,
} from 'hooks';
import Loader from '../loader';
import TextHolder from '../partials/custom-text-input-holder';
import { billBundles, billPayment, billProviders, generateEPin } from 'api';
import TextPlaceholder from '../partials/custom-text-input-holder';
import { setTransactionBreakdown } from 'store/transaction';
import CopyItemWithSerialNumber from 'components/copy-items/copy-item-with-serial-number';

type Props = {
	isGenerateBill?: boolean;
	enableRequest?: boolean;
};

const SELECT_INTERNET_PROVIDER = 'Select service provider';
const SELECT_INTERNET_PLAN = 'Select internet plan';

const PROVIDER_URL = `${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.InternetProvider}`;

interface IPinsProps {
	pins: IPin[];
}

const Pins: React.FC<IPinsProps> = ({ pins }) => {
	return (
		<Box>
			<Typography sx={{ marginBottom: '10px' }} variant={'body1'}>
				Internet Pin(s)
			</Typography>
			<Box
				sx={{
					display: 'grid',
					gap: '10px',
				}}
			>
				{pins.map((pin, index) => (
					<CopyItemWithSerialNumber key={index} pin={pin} />
				))}
			</Box>
		</Box>
	);
};

const InternetSubscriptionForm = ({ isGenerateBill, enableRequest }: Props) => {
	const alert = useAlert();
	const dispatch = useAppDispatch();
	const verifyPin = useVerifyPin();
	const modal = useModalAlert();
	const handleError = useHandleError();
	const theme = useTheme();
	const styles = useStyles(theme);
	const { token } = useAppSelector((store) => store.auth);
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { search } = useLocation();
	const checkKycLevel = useCheckKycLevelTwo();

	/* 
	State
	*/

	const { verifyCoupon, isVerifyingCoupon, coupon, clearCoupon } =
		useVerifyCoupon();

	const [isLoadingInternetPlans, setLoadingInternetPlans] =
		useState<boolean>(false);
	const [dataInternetPlans, setDataInternetPlans] = useState<Bundle[] | null>(
		null
	);

	const initialValues: IBill = {
		service_type: SELECT_INTERNET_PROVIDER,
		numberOfPins: '',
		price: SELECT_INTERNET_PLAN,
		discount_code: '',
	};

	const clearState = () => {
		resetForm();
		setDataInternetPlans(null);
		clearCoupon();
	};

	useEffect(
		() => {
			if (coupon) {
				dispatch(setTransactionBreakdown({ coupon }));
			}
			if (search) {
				clearState();
			}
		},
		//eslint-disable-next-line
		[]
	);

	const { isLoading: isMakingPayment, mutate: mutateBillPayment } = useMutation(
		billPayment,
		{
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						modal({
							message: res.message,
							title: 'Internet Subscription Error',
							hasCloseButton: true,
							type: 'error',
							primaryButtonText: 'Contact Support',
							onClickPrimaryButton: () => {
								modal(null);
								navigate(
									`${LINKS.CreateSupportTicket}?_type=${TICKET_TYPE.COMPLAINT}`
								);
							},
						});
					}
				}

				if (data && data.success) {
					queryClient.invalidateQueries(QUERY_KEYS.UserWallet);
					queryClient.invalidateQueries([QUERY_KEYS.Transactions]);
					queryClient.invalidateQueries([QUERY_KEYS.RecentTransactions]);
					const pins = data.payload.pins;

					console.log(data);

					modal({
						message: `Internet subscription  was successfully!!`,
						title: 'Internet Subscription',
						primaryButtonText: 'Subscribe again',
						hasCloseButton: true,
						type: 'success',
						children: <Pins pins={pins} />,
						onClickPrimaryButton: () => {
							modal(null);
							clearState();
						},
					});
				}
			},
		}
	);

	// Generate IEPin

	const { isLoading: isGeneratingEPin, mutate: mutateGenerateEPin } =
		useMutation(generateEPin, {
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						modal({
							message: response.message,
							title: 'Internet  Subscription Error',
							hasCloseButton: true,
							type: 'error',
							primaryButtonText: 'Contact Support',
							onClickPrimaryButton: () => {
								modal(null);
								navigate(
									`${LINKS.CreateSupportTicket}?_type=${TICKET_TYPE.COMPLAINT}`
								);
							},
						});
					}
				}

				if (data && data.success) {
					resetForm();
					queryClient.invalidateQueries(QUERY_KEYS.UserWallet);
					queryClient.invalidateQueries(QUERY_KEYS.Transactions);
					dispatch(setTransactionBreakdown(null));

					/* 
						!TODO
						pin: data.payload.pin,
						pins: data.payload.pins,
					*/

					modal({
						title: 'Internet Subscription E-Pin',
						message: data.message,
						type: 'success',
						hasCloseButton: true,
						primaryButtonText: 'Generate another E-Pin',
						onClickPrimaryButton: () => {
							modal(null);
						},
					});
				}
			},
		});

	const {
		handleSubmit,
		errors,
		touched,
		values,
		setFieldValue,
		resetForm,
		handleChange,
	} = useFormik({
		initialValues,
		validationSchema: ValidationSchema.InternetSubscription,
		onSubmit: (values) => {
			let amount = Number(values.price) * Number(values.numberOfPins);
			let pin_data = {
				service_type: values.service_type,
				numberOfPins: values.numberOfPins as string,
				price: values.price,
			} as typeof initialValues;

			if (isGenerateBill && checkKycLevel()) {
				verifyPin({
					title: 'Generate Pin',
					message: 'Verify transaction pin to generate pin',
					callback: () =>
						mutateGenerateEPin({
							service: EPIN_SERVICES.INTERNET,
							pin_data,
						}),
				});
				return;
			}

			if (values.discount_code) {
				if (!coupon) {
					return alert({ message: 'Verify coupon code', type: 'info' });
				}

				if (coupon && coupon.code !== values.discount_code) {
					return alert({
						message: `Verified coupon ${coupon.code} doesn't match provided coupon code ${values.discount_code}.`,
						type: 'info',
					});
				}

				pin_data.discount_code = values.discount_code;
				amount = calculateFinalAmount({
					coupon: coupon as Coupon,
					amount,
				});
			}

			if (checkKycLevel()) {
				verifyPin({
					title: 'Internet Subscription',
					message: `Confirm the payment of ${formatNumberToCurrency(
						Number(amount)
					)} for ${values.service_type} subscription plan`,
					callback: () => {
						verifyPin(null);
						mutateBillPayment({
							url: `${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.InternetPayment}`,
							data: pin_data,
						});
					},
				});
			}

			//Verify ITransaction pin
		},
	});

	const { numberOfPins, service_type, discount_code, price } = values;

	// Load Internet Providers
	const { isLoading: isLoadingInternetProviders, data: dataInternetProviders } =
		useQuery(
			QUERY_KEYS.InternetProvider,
			() =>
				billProviders(
					`${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.InternetProvider}`
				),
			{
				enabled: !!token,
				refetchOnWindowFocus: false,
				onSettled: (data, error) => {
					if (error) {
						const res = handleError({ error });
						if (res && res.message) {
							alert({ message: res.message, type: 'error' });
						}
					}
				},
			}
		);

	const loadInternetPlans = async (provider: string) => {
		setLoadingInternetPlans(true);
		try {
			const data = await billBundles({
				url: PROVIDER_URL,
				params: {
					provider,
				},
			});
			if (data && data.success) {
				setDataInternetPlans(data.payload);
			}
		} catch (error) {}
		setLoadingInternetPlans(false);
	};

	const handleSelectProvider = (value: string) => {
		setDataInternetPlans(null);
		setFieldValue('service_type', value);
		loadInternetPlans(value);
	};

	const handleSelectPlan = (value: string) => {
		setFieldValue('price', value);
		dispatch(setTransactionBreakdown({ serviceCost: Number(value) }));
	};

	const handleVerifyCoupon = () => {
		if (!price && price !== SELECT_INTERNET_PLAN && !numberOfPins)
			return alert({
				message: `Select internet plan and number of pin before verifying discount code `,
				type: 'info',
			});

		if (discount_code) return verifyCoupon(discount_code);

		alert({
			message: 'Enter discount code',
			type: 'info',
		});
	};

	return (
		<>
			{isVerifyingCoupon && <Loader />}
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Service provider
					</Typography>
					<Select
						fullWidth
						error={
							errors && touched.service_type && errors.service_type
								? true
								: false
						}
						helpertext={errors && touched.service_type && errors.service_type}
						value={service_type}
						onChange={(e) => handleSelectProvider(e.target.value as string)}
					>
						<MenuItem disabled value={SELECT_INTERNET_PROVIDER}>
							{isLoadingInternetProviders
								? 'Loading...'
								: dataInternetProviders &&
								  dataInternetProviders.payload.length === 0
								? 'No available provider'
								: SELECT_INTERNET_PROVIDER}
						</MenuItem>
						{dataInternetProviders &&
							dataInternetProviders.payload.length > 0 &&
							dataInternetProviders.payload.map(
								(provider: IProvider, key: number) => (
									<MenuItem value={provider.service_type} key={key}>
										{provider.service_type}
									</MenuItem>
								)
							)}
					</Select>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Internet plan
					</Typography>
					{service_type && service_type === SELECT_INTERNET_PROVIDER ? (
						<TextPlaceholder text={SELECT_INTERNET_PLAN} />
					) : (
						<Select
							fullWidth
							error={touched.price && errors.price ? true : false}
							helpertext={touched.price && errors.price}
							value={price}
							onChange={(e) => handleSelectPlan(e.target.value as string)}
						>
							<MenuItem disabled value={SELECT_INTERNET_PLAN}>
								{isLoadingInternetPlans
									? 'Loading...'
									: dataInternetPlans && dataInternetPlans.length === 0
									? 'No available internet plan'
									: SELECT_INTERNET_PLAN}
							</MenuItem>
							{dataInternetPlans &&
								dataInternetPlans.length > 0 &&
								dataInternetPlans.map((bundle: Bundle, key: number) => (
									<MenuItem value={bundle.amount} key={key}>
										Internet plans for {formatNumberToCurrency(bundle.amount)}
									</MenuItem>
								))}
						</Select>
					)}
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Number of pins
					</Typography>
					{price !== SELECT_INTERNET_PLAN ? (
						<TextInput
							error={
								errors && touched.numberOfPins && errors.numberOfPins
									? true
									: false
							}
							helperText={errors && touched.numberOfPins && errors.numberOfPins}
							type={'number'}
							fullWidth
							placeholder={'Enter number of pins'}
							value={numberOfPins}
							onChange={handleChange('numberOfPins')}
						/>
					) : (
						<TextHolder text={'Enter number of pins'} />
					)}
				</Box>

				{!isGenerateBill && (
					<Box>
						<Typography
							style={styles.label}
							component={'label'}
							variant={'body1'}
						>
							Discount code [optional]
						</Typography>
						<TextInput
							fullWidth
							error={
								touched.discount_code && errors.discount_code ? true : false
							}
							helperText={touched.discount_code && errors.discount_code}
							placeholder={'Type in discount code here'}
							value={discount_code}
							onChange={handleChange('discount_code')}
							InputProps={{
								endAdornment: (
									<InputAdornment position='start'>
										<Button
											style={styles.applyBtn}
											onClick={(e: React.FormEvent<HTMLButtonElement>) => {
												e.preventDefault();
												handleVerifyCoupon();
											}}
										>
											Apply
										</Button>
									</InputAdornment>
								),
							}}
						/>
					</Box>
				)}

				<Button
					loading={isMakingPayment || isGeneratingEPin}
					type={'submit'}
					size={'large'}
					style={styles.btn}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					{isGenerateBill ? 'Generate e-Pin' : 'Purchase'}
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default InternetSubscriptionForm;
