import {
	Box,
	Popper,
	TextFieldProps,
	styled,
	ListItem,
	List,
} from '@mui/material';
import React, { forwardRef, useRef, useState } from 'react';
import TextInput from './text-input';
import { useAppSelector } from 'store/hooks';
import { DARK_CARD_COLOR, THEME_MODE } from 'utilities';

type Props = {
	isLoading?: boolean;
	labelText?: string;
	extensions?: string[];
	onChange?: (value: string) => void;
};

// const CEmailField = forwardRef((props: any, ref: any) => {
// 	return <TextInput ref={ref} {...props} />;
// });

const EmailField = (props: TextFieldProps & Props) => {
	const extensions = props?.extensions;
	const mode = useAppSelector((store) => store.theme.mode);
	const [isOpenPopper, setOpenPopper] = useState<boolean>(false);
	const emailFieldRef = useRef<any>(null);
	const onChange = props.onChange;

	const handleSelectOption = (option: string) => {
		if (props.value && typeof onChange === 'function') {
			onChange(`${props?.value}${option}`);
			setOpenPopper(false);
		}
	};

	return (
		<Box
			sx={{
				position: 'relative',
			}}
		>
			<TextInput
				labelText={props?.labelText}
				fullWidth={true}
				type={'email'}
				disabled={props.isLoading}
				placeholder={props.isLoading ? 'Loading...' : props.placeholder}
				error={props.error}
				helperText={props?.helperText}
				value={props.value}
				onChange={(e) => {
					const email = e.target.value;
					typeof onChange === 'function' && onChange(email);

					if (
						email.indexOf('@') !== -1 &&
						email.match('@') &&
						!email[email.indexOf('@') + 1]
					) {
						setOpenPopper(true);
					} else {
						setOpenPopper(false);
					}
				}}
			/>
			{isOpenPopper && (
				<Listbox mode={mode}>
					{extensions?.map((option, index) => (
						<ListItem
							key={index}
							onClick={() => handleSelectOption(option)}
							value={option}
						>
							{option}
						</ListItem>
					))}
				</Listbox>
			)}
		</Box>
	);
};

const Listbox = styled(List)<{ mode?: string }>(({ theme, mode }) => ({
	margin: 0,
	padding: '4px 0px',
	top: '90px',
	zIndex: 1,
	position: 'absolute',
	listStyle: 'none',
	backgroundColor: mode === THEME_MODE.dark ? DARK_CARD_COLOR : 'white',
	borderRadius: theme.spacing(1),
	overflow: 'auto',
	border: '1px solid rgba(0,0,0,.25)',
	width: '100%',
	height: 180,

	'& li': {
		padding: '8px 16.5px',
		cursor: 'pointer',
	},
}));

export default EmailField;
