import { useState, useEffect } from 'react';
import useToastAlert from './useToastAlert';

const useRequestPermission = () => {
	const alert = useToastAlert();
	const [permission, setPermission] =
		useState<`${NotificationPermission}`>('default');

	const requestPermission = async () => {
		const permission = await Notification.requestPermission();
		setPermission(permission);
		switch (permission) {
			case 'denied':
				alert({
					message: 'Notification permission denied.',
					type: 'info',
				});

				break;
			default:
				break;
		}
	};

	useEffect(
		() => {
			requestPermission();
		},
		// eslint-disable-next-line
		[]
	);

	return permission;
};

export default useRequestPermission;
