import React, { useState, useRef, ReactNode } from 'react';
import {
	Typography,
	Box,
	styled,
	useTheme,
	ClickAwayListener,
	Popper,
} from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { ArrowDropDown, Padding } from '@mui/icons-material';
import { DARK_CARD_COLOR, THEME_MODE } from 'utilities';
import { useAppSelector } from 'store/hooks';
import zIndex from '@mui/material/styles/zIndex';

interface IOption {
	label: string;
	value?: string | number;
}

interface Props {
	isLoading?: boolean;
	helpertext?: string | null | undefined | false;
	value?: string;
	onChange?: (value: string) => void;
	options: IOption[] | null;
	labelText?: string | ReactNode;
	error?: boolean;
	placeholder?: string;
	disabled?: boolean;
	searchTextPlaceholder?: string;
}

const CustomSelect = ({
	isLoading,
	helpertext,
	onChange,
	options,
	labelText,
	error,
	placeholder,
	disabled,
	searchTextPlaceholder,
	value,
}: Props) => {
	const mode = useAppSelector((store) => store.theme.mode);
	const theme = useTheme();
	const [searchText, setSearchText] = useState<string>('');
	const [filterOptions, setFilterOptions] = useState<null | IOption[]>(null);
	const buttonRef = useRef<HTMLButtonElement | null>(null);

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSearch = (e) => {
		const value = e.target.value;
		setSearchText(value);

		const regExp = new RegExp(value, 'ig');

		const filterOptions = options?.filter((option: any) =>
			regExp.test(option.label)
		);
		if (filterOptions) setFilterOptions(filterOptions);
	};

	const handleSelectOption = (option: IOption) => {
		if (option.value === '') return;

		typeof onChange === 'function' && onChange(option.value as string);

		// Clear State
		setSearchText('');
		setFilterOptions(null);

		handleClose(); // Close popper
	};

	const dataOptions = searchText ? (filterOptions as IOption[]) : options;

	return (
		<Box>
			{labelText && (
				<Box
					sx={{
						marginBottom: theme.spacing(2),
					}}
				>
					{typeof labelText === 'string' ? (
						<Typography
							variant={'body1'}
							component={'label'}
							sx={{
								display: 'inline-block',
								fontWeight: '600',
							}}
						>
							{labelText}
						</Typography>
					) : (
						labelText
					)}
				</Box>
			)}
			<ClickAwayListener onClickAway={handleClose}>
				<Box sx={{ position: 'relative' }}>
					<Button
						ref={buttonRef}
						onClick={handleClick}
						error={error}
						isFocus={Boolean(anchorEl)}
						disabled={disabled}
						mode={mode}
					>
						<Typography variant='body1'>
							{isLoading
								? 'Loading...'
								: options?.find((item) => item.value === value)?.label ||
								  placeholder}
						</Typography>

						<ArrowDropDown
							sx={{
								position: 'absolute',
								right: '10px',
								color: grey[500],
							}}
						/>
					</Button>

					<Popper
						sx={{
							width: buttonRef.current?.clientWidth,
						}}
						open={Boolean(anchorEl)}
						anchorEl={anchorEl}
					>
						<Listbox
							height={options && options?.length > 0 ? '200px' : '80px'}
							mode={mode}
						>
							<Box
								sx={{
									width: '100%',
									backgroundColor:
										mode === THEME_MODE.dark ? DARK_CARD_COLOR : 'white',
									position: 'relative',
									zIndex: 4,
									border: 'none',
									borderBottom: `1px solid ${grey[200]}`,
									outline: 'none',
								}}
							>
								<SearchInput
									fullWidth
									mode={mode}
									placeholder={searchTextPlaceholder || 'Search...'}
									value={searchText}
									onChange={handleSearch}
								/>
							</Box>

							{dataOptions && dataOptions.length > 0 ? (
								<ul>
									{dataOptions?.map((option, index) => (
										<li
											key={index}
											style={{
												display: option.value === '' ? 'none' : 'block',
											}}
											onClick={() => handleSelectOption(option)}
											value={option.value}
										>
											{option.label}
										</li>
									))}
								</ul>
							) : (
								<li style={{ textAlign: 'center' }}>No available option</li>
							)}
						</Listbox>
					</Popper>
				</Box>
			</ClickAwayListener>
			{helpertext && (
				<Typography
					sx={{ color: red[800], padding: '3px 14px 0px', fontSize: '0.75rem' }}
					variant={'body2'}
				>
					{helpertext}
				</Typography>
			)}
		</Box>
	);
};

const Listbox = styled('div')<{ mode?: string; height?: string }>(
	({ theme, mode, height = '180px' }) => ({
		margin: 0,
		padding: '4px 0px',
		// top: '110px',
		zIndex: 1,
		position: 'absolute',
		backgroundColor: mode === THEME_MODE.dark ? DARK_CARD_COLOR : 'white',
		borderRadius: theme.spacing(1),
		overflow: 'hidden',
		border: '1px solid rgba(0,0,0,.25)',
		width: '100%',
		top: '5px',
		height,

		'& > ul': {
			listStyle: 'none',
			position: 'absolute',
			top: '25px',
			left: 0,
			padding: '0px',
			overflow: 'auto',
			height: '100%',
			zIndex: 0,
			width: '100%',
		},

		'& li': {
			padding: '0px 16.5px',
			margin: '10px 0px',
			cursor: 'pointer',
		},
		'& li.Mui-focused': {
			backgroundColor: theme.palette.primary.main,
			color: 'white',
			cursor: 'pointer',
		},

		'& li:active': {
			backgroundColor: '#2977f5',
			color: 'white',
		},
	})
);

const SearchInput = styled('input')<{
	mode?: string;
	fullWidth?: boolean;
}>(({ mode, fullWidth }) => ({
	width: fullWidth ? '100%' : 200,
	fontSize: 'inherit',

	// background: 'unset !important',
	// backgroundColor: mode === THEME_MODE.dark ? DARK_CARD_COLOR : 'white',
	// zIndex: 10,
	color: mode === THEME_MODE.dark ? grey['100'] : grey['700'],
	border: 'none',
	// borderBottom: `1px solid ${grey[200]}`,
	outline: 'none',
	padding: '10px 14px',
}));

const Button = styled('button')<{
	mode?: string;
	error?: boolean;
	isFocus?: boolean;
}>(({ theme, mode, error, isFocus }) => ({
	display: 'flex',
	alignItems: 'center',
	border: `1px solid ${
		error
			? red['600']
			: isFocus
			? mode === THEME_MODE.dark
				? 'white'
				: theme.palette.primary.main
			: grey['600']
	}`,
	outlineWidth: '5px',
	outlineColor: error
		? red['600']
		: isFocus
		? theme.palette.primary.main
		: grey['600'],
	padding: '14px',
	borderRadius: theme.spacing(1),
	width: '100%',
	backgroundColor: mode === THEME_MODE.dark ? DARK_CARD_COLOR : 'white',
	color: mode === THEME_MODE.dark ? grey['100'] : grey['700'],
	cursor: 'pointer',
}));

export default CustomSelect;
