import React from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import {
	Layout,
	LoadDataPinForm,
	CircularProgress,
	Seo,
	BackButton,
} from 'components';
import { formatNumberToCurrency, ErrorBoundaryGuard } from 'utilities';
import { useAppSelector } from 'store/hooks';
import { useHandleError, useAlert } from 'hooks';
import { dataPlans } from 'api';

const LoadData = () => {
	const alert = useAlert();
	const handleError = useHandleError();
	const { search } = useLocation();
	const query = queryString.parse(search);

	const { token } = useAppSelector((store) => store.auth);

	const { isLoading, data: dataDataPlans } = useQuery(
		'Load Data Network',
		() => dataPlans({ populate: 'network', _id: query.plan }),
		{
			enabled: !!(token && query && query.plan),
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						alert({ message: response.message, type: 'error' });
					}
				}
			},
		}
	);

	return (
		<Layout>
			<Seo title={'Load Data E-pin'} />
			<ErrorBoundaryGuard>
				{isLoading ? (
					<CircularProgress />
				) : (
					<Box
						sx={{
							maxWidth: '600px',
							display: 'grid',
							gridTemplateColumns: '1fr',
							gap: '15px',
						}}
					>
						<BackButton name={'E-Pin'} sx={{ marginBottom: ['1.5rem'] }} />
						<Typography sx={{ fontWeight: '600' }} variant={'h5'}>
							Data E-pin
						</Typography>
						<Typography>
							The e-pin you entered gives{' '}
							{query.amount && formatNumberToCurrency(query.amount as string)}{' '}
							worth of{' '}
							<Box component={'span'} sx={{ fontWeight: '600' }}>
								{dataDataPlans && dataDataPlans.payload[0]?.name} Data.
							</Box>{' '}
							Enter your phone number to load pin.
						</Typography>
						<LoadDataPinForm pin={query.pin as string} />
					</Box>
				)}
			</ErrorBoundaryGuard>
		</Layout>
	);
};

export default LoadData;
