import React, { CSSProperties } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useMutation } from 'react-query';
import { grey } from '@mui/material/colors';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextInput from '../form-components/text-input';
import Button from '../button';
import { PHONE_REX, UseEPin } from 'utilities';
import { useModalAlert, useHandleError, useVerifyPin } from 'hooks';
import { useEPin } from 'api';

interface Props {
	pin: string;
}

const LoadAirtimeEpinForm = ({ pin }: Props) => {
	const modal = useModalAlert();
	const verifyPin = useVerifyPin();
	const handleError = useHandleError();
	const theme = useTheme();
	const styles = useStyles(theme);

	const validationSchema = yup.object().shape({
		phone_number: yup
			.string()
			.required('Enter phone number')
			.matches(PHONE_REX, 'Invalid phone number'),
	});

	const initialValues: { phone_number: string } = {
		phone_number: '',
	};

	const { isLoading, mutate: mutateUseEPin } = useMutation(useEPin, {
		onSettled: (data, error) => {
			if (error) {
				const response = handleError({ error });

				if (response?.message) {
					modal({
						title: 'Airtime E-Pin',
						message: response.message,
						type: 'error',
						onClickPrimaryButton: () => modal(null),
						primaryButtonText: 'Close',
					});
				}
			}
		},
	});

	const { values, handleSubmit, touched, errors, handleChange } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: ({ phone_number }) => {
			const data: UseEPin = {
				pin,
				user_data: {
					phone_number,
				},
			};

			// Verify transaction Pin
			verifyPin({
				title: 'Airtime E-Pin',
				message: 'Verify transaction pin to load Airtime pin',
				callback: () => {
					verifyPin(null);
					mutateUseEPin(data);
				},
			});
		},
	});

	return (
		<>
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Phone number
					</Typography>
					<TextInput
						fullWidth
						error={touched.phone_number && errors.phone_number ? true : false}
						helperText={touched.phone_number && errors.phone_number}
						placeholder={'Type in your phone number here'}
						value={values.phone_number}
						onChange={handleChange('phone_number')}
					/>
				</Box>

				<Button
					loading={isLoading}
					style={styles.btn as CSSProperties}
					size={'large'}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Load E-pin
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default LoadAirtimeEpinForm;
