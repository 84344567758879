import React from 'react';
import { Box } from '@mui/material';
import WalletBalance from '../wallet-balance';
import BillBreakdown from '../../bill-breakdown';

type Props = {
	isStickySidebar?: boolean;
	top?: string;
};

const WalletWithBreakdown = ({ isStickySidebar, top }: Props) => {
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: '1fr',
				gap: '15px',
				position: isStickySidebar ? 'sticky' : 'initial',
				top: top || '6rem',
				overflow: 'auto',
			}}
		>
			<WalletBalance />
			<BillBreakdown />
		</Box>
	);
};

export default WalletWithBreakdown;
