import { useAppDispatch } from '../store/hooks';
import { logOut } from '../store/auth';
import {
	setNotificationSettings,
	setNotificationAlert,
} from 'store/notification';
import { setWallet } from 'store/wallet';

const useLogoutUser = () => {
	const dispatch = useAppDispatch();
	return () => {
		dispatch(setWallet(null));
		dispatch(setNotificationSettings(null));
		dispatch(setNotificationAlert(null));
		dispatch(logOut());
	};
};

export default useLogoutUser;
