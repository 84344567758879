import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextInput from '../form-components/text-input';
import { grey } from '@mui/material/colors';
import Button from '../button';
import { UseEPin } from 'utilities';
import {
	useAlert,
	useVerifyPin,
	useModalAlert,
	useHandleError,
	useVerifyBillAccount,
} from 'hooks';
import CustomPlaceholder from '../partials/custom-text-input-holder';
import { useEPin } from 'api';

interface Props {
	pin: string;
	serviceType: string;
}

const LoadCableEpinForm = ({ pin, serviceType }: Props) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const alert = useAlert();
	const verifyPin = useVerifyPin();
	const { verifyBillAccount, isVerifyingBillAccount, billAccount } =
		useVerifyBillAccount();
	const modal = useModalAlert();
	const handleError = useHandleError();

	const validationSchema = yup.object().shape({
		smartcard_number: yup
			.string()
			.required('Enter cable number')
			.matches(/^[0-9]{10}$/, 'Cable number must be only 10 digits'),
	});

	const initialValues = {
		smartcard_number: '',
	};

	const { isLoading, mutate } = useMutation(useEPin, {
		onSettled: (data, error) => {
			if (error) {
				const response = handleError({ error });
				if (response?.message) {
					modal({
						title: 'Data E-Pin',
						message: response?.message as string,
						type: 'error',
						primaryButtonText: 'Close',
						onClickPrimaryButton: () => modal(null),
					});
				}
			}
		},
	});

	const { errors, touched, values, handleSubmit, handleChange } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: ({ smartcard_number }) => {
			const data: UseEPin = {
				pin,
				user_data: {
					smartcard_number,
				},
			};

			verifyPin({
				title: 'Data  E-Pin',
				message: 'Verify transaction pin to load cable pin',
				callback: () => {
					verifyPin(null);
					mutate(data);
				},
			});
		},
	});

	const handleVerifyAccount = () => {
		if (/^[0-9]{10}$/.test(values.smartcard_number)) {
			return verifyBillAccount({
				account_number: values.smartcard_number,
				service_type: serviceType as string,
			});
		}

		alert({ message: 'Enter a valid smart card number', type: 'info' });
	};

	return (
		<>
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Smart card number
					</Typography>
					<TextInput
						fullWidth
						error={
							touched.smartcard_number && errors.smartcard_number ? true : false
						}
						helperText={touched.smartcard_number && errors.smartcard_number}
						placeholder={'Type in your smart card here'}
						value={values.smartcard_number}
						onChange={handleChange('smartcard_number')}
					/>
				</Box>
				{billAccount && (
					<Box>
						<Typography
							style={styles.label}
							component={'label'}
							variant={'body1'}
						>
							Customer name
						</Typography>
						<CustomPlaceholder text={billAccount.user.name} />
					</Box>
				)}

				<Button
					loading={isLoading || isVerifyingBillAccount}
					style={styles.btn}
					size={'large'}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						if (
							billAccount &&
							billAccount.user.accountNumber === values.smartcard_number
						) {
							handleSubmit();
						} else {
							handleVerifyAccount();
						}
					}}
				>
					{billAccount &&
					billAccount.user.accountNumber === values.smartcard_number
						? 'Load E-pin'
						: 'Verify Cable Number'}
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default LoadCableEpinForm;
