import React from 'react';
import { grey } from '@mui/material/colors';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
	ListItem,
	List,
	useTheme,
	ListItemText,
	Box,
	Typography,
} from '@mui/material';
import ServicesIcon from '../icons/services';
import DashboardIcon from '../icons/dashboard';
import EPinIcon from '../icons/e-pins';
import GetHelpIcon from '../icons/get-help';
import SettingsIcon from '../icons/settings';
import TransactionHistoryIcon from '../icons/transaction-history';
import LogoutIcon from '../icons/logout';
import GiftIcon from '../icons/gift';
import {
	DRAWER_LINKS,
	LIGHT_PRIMARY_COLOR,
	THEME_MODE,
	getActiveLink,
} from 'utilities';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setToggleDrawer, setDisplayLogoutModal } from '../../store/app';
import { useLogoutUser, useModal } from 'hooks';
import './style.module.css';

const Link = ({
	name,
	pathname,
	linkText,
	icon,
	currentPath,
	isNew,
}: {
	name: string;
	pathname: string;
	linkText: string;
	icon?: any;
	currentPath: string;
	isNew?: boolean;
}) => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const { mode } = useAppSelector((store) => store.theme);
	const styles = useStyles(theme, mode);
	const navigate = useNavigate();

	const onClick = () => {
		dispatch(setToggleDrawer(false));
		navigate(pathname, { state: {} });
	};

	return (
		<NavLink
			onClick={(e) => {
				e.preventDefault();
			}}
			style={styles.link}
			to={{ pathname }}
		>
			<ListItem
				onClick={onClick}
				style={styles.link}
				sx={{
					position: 'relative',
					padding: '12px 0px',
					'&:after': {
						content: '""',
						position: 'absolute',
						display: getActiveLink({ name, currentPath }).isActive
							? 'block'
							: 'none',
						top: '0px',
						right: '0px',
						borderRadius: theme.spacing(1),
						width: '3px',
						height: '100%',
						backgroundColor: theme.palette.secondary.main,
					},
				}}
			>
				{icon}
				<ListItemText
					sx={{
						color: getActiveLink({ name, currentPath }).isActive
							? theme.palette.secondary.main
							: mode === THEME_MODE.dark
							? grey[300]
							: theme.palette.primary.main,
						marginLeft: theme.spacing(1),
						flexGrow: 0,
						'& .MuiTypography-root': {
							fontWeight: getActiveLink({ name, currentPath }).isActive
								? 'bold'
								: 'normal',
						},
					}}
				>
					{linkText}
				</ListItemText>
				{isNew && (
					<Box
						sx={{
							padding: ['3px 10px'],
							borderRadius: '15px',
							backgroundColor: theme.palette.primary.main,
							marginLeft: theme.spacing(2),
							// position: 'absolute',
							// top: '10px',
							// right: '10px',
						}}
					>
						<Typography
							sx={{ color: 'white', fontWeight: 'bold', fontSize: '10px' }}
							variant={'body2'}
						>
							New
						</Typography>
					</Box>
				)}
			</ListItem>
		</NavLink>
	);
};

const NavList = () => {
	const theme = useTheme();
	const {
		theme: { mode },
	} = useAppSelector((store) => store);
	const dispatch = useAppDispatch();
	const styles = useStyles(theme, mode);
	const logout = useLogoutUser();
	const modal = useModal();

	const initialColor =
		mode === THEME_MODE.dark ? grey[300] : theme.palette.primary.main;
	const activeColor = theme.palette.secondary.main;

	const { pathname } = useLocation();

	const handleDisplayLogoutModal = () => {
		modal.display({
			title: 'Logout',
			message: 'Are you sure you want to logout?',
			primaryButtonText: 'Log me out',
			secondaryButtonText: 'Take me back',
			onClickPrimaryButton: () => {
				modal.close();
				logout();
			},
			onClickSecondaryButton: () => modal.close(),
		});
	};

	return (
		<List
			className='sidebar'
			sx={{
				display: 'flex',
				flexDirection: 'column',
				padding: '0px',
				gap: '1rem',
				svg: {
					marginRight: (theme) => theme.spacing(2),
					color: (theme) => theme.palette.primary.main,
				},
			}}
		>
			<Link
				icon={
					<DashboardIcon
						size={25}
						innerColor={
							getActiveLink({ name: 'dashboard', currentPath: pathname })
								.isActive
								? LIGHT_PRIMARY_COLOR
								: mode === THEME_MODE.dark
								? grey[500]
								: LIGHT_PRIMARY_COLOR
						}
						color={
							getActiveLink({ name: 'dashboard', currentPath: pathname })
								.isActive
								? activeColor
								: initialColor
						}
					/>
				}
				name={'dashboard'}
				currentPath={pathname}
				pathname={DRAWER_LINKS.Dashboard.path}
				linkText={DRAWER_LINKS.Dashboard.name}
			/>
			<Link
				isNew
				icon={
					<ServicesIcon
						size={25}
						innerColor={
							getActiveLink({ name: 'services', currentPath: pathname })
								.isActive
								? LIGHT_PRIMARY_COLOR
								: mode === THEME_MODE.dark
								? grey[500]
								: LIGHT_PRIMARY_COLOR
						}
						color={
							getActiveLink({ name: 'services', currentPath: pathname })
								.isActive
								? activeColor
								: initialColor
						}
					/>
				}
				name={'services'}
				currentPath={pathname}
				pathname={DRAWER_LINKS.Services.path}
				linkText={DRAWER_LINKS.Services.name}
			/>
			<Link
				icon={
					<TransactionHistoryIcon
						size={25}
						innerColor={
							getActiveLink({
								name: 'transaction-history',
								currentPath: pathname,
							}).isActive
								? LIGHT_PRIMARY_COLOR
								: mode === THEME_MODE.dark
								? grey[500]
								: LIGHT_PRIMARY_COLOR
						}
						color={
							getActiveLink({
								name: 'transaction-history',
								currentPath: pathname,
							}).isActive
								? activeColor
								: initialColor
						}
					/>
				}
				name={'transaction-history'}
				currentPath={pathname}
				pathname={DRAWER_LINKS.TransactionHistory.path}
				linkText={DRAWER_LINKS.TransactionHistory.name}
			/>
			<Link
				icon={
					<EPinIcon
						size={25}
						innerColor={
							getActiveLink({
								name: 'e-pin',
								currentPath: pathname,
							}).isActive
								? LIGHT_PRIMARY_COLOR
								: mode === THEME_MODE.dark
								? grey[500]
								: LIGHT_PRIMARY_COLOR
						}
						color={
							getActiveLink({
								name: 'e-pin',
								currentPath: pathname,
							}).isActive
								? activeColor
								: initialColor
						}
					/>
				}
				name={'e-pin'}
				currentPath={pathname}
				pathname={DRAWER_LINKS.IEPin.path}
				linkText={DRAWER_LINKS.IEPin.name}
			/>
			<Link
				icon={
					<GiftIcon
						// width={30}
						// height={30}
						size={33}
						color={
							getActiveLink({
								name: 'referral',
								currentPath: pathname,
							}).isActive
								? activeColor
								: initialColor
						}
					/>
				}
				name={'referral'}
				currentPath={pathname}
				pathname={DRAWER_LINKS.Referral.path}
				linkText={DRAWER_LINKS.Referral.name}
			/>
			<Link
				icon={
					<SettingsIcon
						size={25}
						innerColor={
							getActiveLink({
								name: 'settings',
								currentPath: pathname,
							}).isActive
								? LIGHT_PRIMARY_COLOR
								: mode === THEME_MODE.dark
								? grey[500]
								: LIGHT_PRIMARY_COLOR
						}
						color={
							getActiveLink({
								name: 'settings',
								currentPath: pathname,
							}).isActive
								? activeColor
								: initialColor
						}
					/>
				}
				name={'settings'}
				currentPath={pathname}
				pathname={DRAWER_LINKS.Settings.path}
				linkText={DRAWER_LINKS.Settings.name}
			/>
			<Link
				icon={
					<GetHelpIcon
						size={25}
						innerColor={
							getActiveLink({
								name: 'get-help_faqs',
								currentPath: pathname,
							}).isActive
								? LIGHT_PRIMARY_COLOR
								: mode === THEME_MODE.dark
								? grey[500]
								: LIGHT_PRIMARY_COLOR
						}
						color={
							getActiveLink({
								name: 'get-help_faqs',
								currentPath: pathname,
							}).isActive
								? activeColor
								: initialColor
						}
					/>
				}
				name={'get-help_faqs'}
				currentPath={pathname}
				pathname={DRAWER_LINKS.GetHelp.path}
				linkText={DRAWER_LINKS.GetHelp.name}
			/>

			<ListItem
				onClick={() => {
					dispatch(setDisplayLogoutModal(true));
					dispatch(setToggleDrawer(false));
					handleDisplayLogoutModal();
				}}
				disableGutters
				style={styles.link}
			>
				<LogoutIcon color={initialColor} size={25} />
				<Box sx={{ marginLeft: '5px', color: 'inherit' }} component={'span'}>
					Logout
				</Box>
			</ListItem>
		</List>
	);
};

const useStyles = (theme: any, mode: any) => ({
	link: {
		textDecoration: 'none',
		cursor: 'pointer',
		color: mode === THEME_MODE.dark ? grey[400] : theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
	},
	listItemText: {
		color: mode === THEME_MODE.dark ? grey[400] : theme.palette.primary.main,
		marginLeft: theme.spacing(1),
	},
	activeListItemText: {
		color: theme.palette.secondary.main,
		marginLeft: theme.spacing(1),
		fontWeight: 600,
	},
});

export default NavList;
