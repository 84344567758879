import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useAlert } from 'hooks';

type Props = {
	text: string;
	textDescription?: string;
};

const CopyItem = ({ text, textDescription }: Props) => {
	const theme = useTheme();
	const alert = useAlert();
	const onCopy = () =>
		alert({ message: `"${text}" copies successfully!!`, type: 'success' });
	return (
		<Box width={'100%'}>
			{textDescription && (
				<Typography sx={{ marginBottom: theme.spacing(1) }} variant={'body1'}>
					{textDescription}
				</Typography>
			)}
			<Box
				sx={{
					border: `1px solid ${theme.palette.primary.main}`,
					padding: '8px 20px',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					color: theme.palette.primary.main,
					fontWeight: '600',
					borderRadius: '5px',
				}}
			>
				<Typography variant={'body1'}>{text}</Typography>
				<CopyToClipboard text={text as string} onCopy={() => onCopy()}>
					<Button
						sx={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<ContentCopy sx={{ fontSize: '16px', marginRight: '5px' }} />
						<Typography variant={'body1'}>copy</Typography>
					</Button>
				</CopyToClipboard>
			</Box>
		</Box>
	);
};

export default CopyItem;
