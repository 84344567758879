import React, { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../button';
import { DANGER_COLOR, LINKS } from 'utilities';
import { useAppSelector } from 'store/hooks';
import Loader from '../loader';
import { useHandleError, useAlert } from 'hooks';
import { twoFAToken } from 'api';

const TwoFactorAuth = () => {
	const theme = useTheme();
	const alert = useAlert();
	const handleError = useHandleError();
	const navigate = useNavigate();
	const { pathname, search } = useLocation();
	const styles = useStyles(theme);
	const { user } = useAppSelector((store) => store.auth);
	const [isRequestingToken, setRequestingToken] = useState<boolean>(false);

	const requestToken = async () => {
		setRequestingToken(true);
		try {
			const data = await twoFAToken();
			if (data.success) {
				navigate(
					`${LINKS.ConfirmTwoFactorToken}?redirect=${pathname}${search}`,
					{
						state: { redirect: `${pathname}${search}` },
					}
				);
			}
		} catch (error) {
			const response = handleError({ error });
			if (response?.message)
				alert({ message: response.message, type: 'error' });
		}
		setRequestingToken(false);
	};

	return (
		<>
			{isRequestingToken && <Loader />}

			<Box
				sx={{
					display: 'flex',
					gridTemplateColumns: 'repeat(2, 1fr)',
					button: {
						fontSize: '14px',
						fontWeight: '600',
						border: `1px solid ${theme.palette.secondary.main}`,
					},
				}}
			>
				<Button
					disabled={user && user.twoFactorAuth ? true : false}
					sx={{
						backgroundColor: user && user.twoFactorAuth ? '#FCE2C0' : 'unset',
					}}
					onClick={requestToken}
					style={styles.activateBtn}
					size={'large'}
					fullWidth
				>
					Activate
				</Button>
				<Box component={'span'} style={styles.verticalLine} />
				<Button
					disabled={user && !user.twoFactorAuth ? true : false}
					sx={{
						backgroundColor: user && !user.twoFactorAuth ? '#FCE2C0' : 'unset',
					}}
					onClick={requestToken}
					style={styles.deactivateBtn}
					size={'large'}
					fullWidth
				>
					Deactivate
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	container: {
		display: 'grid',
		gridTemplateColumn: '1fr',
		gap: '30px',
	},
	activateBtn: {
		borderTopRightRadius: '0px',
		borderBottomRightRadius: '0px',
		borderRight: '0px',
		color: theme.palette.secondary.main,
		// backgroundColor: '#FCE2C0',
	},
	deactivateBtn: {
		borderTopLeftRadius: '0px',
		borderBottomLeftRadius: '0px',
		borderLeft: '0px',
	},
	btn: {
		fontSize: '14px',
		maxWidth: '180px',
		fontWeight: '600',
		color: theme.palette.secondary.main,
		border: `1px solid ${theme.palette.secondary.main}`,
	},
	deleteBtn: {
		fontSize: '14px',
		maxWidth: '180px',
		fontWeight: '600',
		color: DANGER_COLOR,
		border: `1px solid ${DANGER_COLOR}`,
	},
	verticalLine: {
		backgroundColor: theme.palette.secondary.main,
		width: '2px',
		height: '100%',
	},
});

export default TwoFactorAuth;
