import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useMutation } from 'react-query';
import Button from '../button';
import { LIGHT_PRIMARY_COLOR } from 'utilities';
import { updateNotificationSettings } from 'api';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setNotificationSettings } from 'store/notification';
import { useHandleError, useAlert } from 'hooks';

type Props = {
	title: string;
	description: string;
	active?: boolean;
	type: string;
};

const NotificationItem = ({ title, description, active, type }: Props) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const alert = useAlert();
	const handleError = useHandleError();
	const { settings } = useAppSelector((store) => store.notification);

	const dispatch = useAppDispatch();

	const { mutate } = useMutation(updateNotificationSettings, {
		onSettled: (data, error) => {
			if (data && data.success) {
				alert({
					message: 'Notification updated successfully!',
					type: 'success',
				});
				dispatch(setNotificationSettings(data.payload));
			}

			if (error) {
				const response = handleError({ error });
				if (response?.message)
					alert({ message: response.message, type: 'error' });
			}
		},
	});

	const handleUpdateNotification = () => {
		mutate({
			id: settings?.id as string,
			data: { [type]: !active },
		});
	};

	return (
		<Box style={styles.container}>
			<Typography variant={'body1'} style={styles.title}>
				{title}
			</Typography>
			<Typography variant={'body1'} sx={{ marginBottom: '20px' }}>
				{description}
			</Typography>
			<Box
				sx={{
					button: {
						border: `1px solid ${theme.palette.secondary.main}`,
						color: theme.palette.secondary.main,
					},
					'button:nth-of-type(1)': {
						borderTopRightRadius: '0px',
						borderBottomRightRadius: '0px',
						borderRight: '0px',
					},
					'button:last-child': {
						borderTopLeftRadius: '0px',
						borderBottomLeftRadius: '0px',
					},
				}}
				style={styles.btnWrapper}
			>
				<Button
					onClick={handleUpdateNotification}
					sx={{ backgroundColor: active ? LIGHT_PRIMARY_COLOR : 'unset' }}
					size={'large'}
				>
					Yes
				</Button>
				<Button
					onClick={handleUpdateNotification}
					sx={{ backgroundColor: !active ? LIGHT_PRIMARY_COLOR : 'unset' }}
					size={'large'}
				>
					No
				</Button>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	container: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		// gap: '4px',
	},
	title: {
		fontWeight: '600',
	},
	btnWrapper: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
	},
	activeBtn: {
		backgroundColor: LIGHT_PRIMARY_COLOR,
	},
});

export default NotificationItem;
