import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, useTheme } from '@mui/material';
import { ChevronRightRounded } from '@mui/icons-material';
import { LIGHT_GRAY, THEME_MODE, LINKS } from 'utilities';
import { useAppSelector } from 'store/hooks';
import { grey } from '@mui/material/colors';

const Todo = () => {
	const muiTheme = useTheme();
	const { auth, theme } = useAppSelector((store) => store);
	const navigate = useNavigate();
	const user = auth.user;
	const mode = theme.mode;

	const [taskIndex, setTaskIndex] = useState<number>(0);
	const [currentTask, setCurrentTask] = useState<string>('');
	const [link, setLink] = useState<string>('');

	/* useEffect(() => {
		if (user) {
			const { verified, bvnVerified, hasPin, kycLevel } = user;

			if (!verified) {
				return setCurrentTask('Verify account');
			} else {
				setTaskIndex(3);
			}
			if (!hasPin) {
				return setCurrentTask('Set up Transaction Pin');
			} else {
				setTaskIndex(2);
			}
			if (kycLevel === 1) {
				setLink(LINKS.LevelTwoVerification);
				return setCurrentTask('Verify Identity');
			} else {
				setTaskIndex(1);
			}
			if (kycLevel === 2 && !bvnVerified) {
				setLink(LINKS.BvnVerification);
				return setCurrentTask('Verify BVN');
			} else {
				setTaskIndex(0);
			}
		}
	}, [user]); */

	useEffect(() => {
		if (user) {
			const { bvnVerified, hasPin, kycLevel } = user;

			const count =
				(bvnVerified ? 0 : 1) + (hasPin ? 0 : 1) + (kycLevel < 2 ? 1 : 0);

			setTaskIndex(count);

			if (kycLevel === 1) {
				setLink(LINKS.LevelTwoVerification);
				return setCurrentTask('Verify Identity(NIN)');
			}
			if (kycLevel === 2 && !bvnVerified) {
				setLink(LINKS.BvnVerification);
				return setCurrentTask('Verify BVN');
			}
		}
	}, [user]);

	if (taskIndex === 0) return null;

	return (
		<Box
			sx={{
				backgroundImage: `url(${require('../../assets/images/bgImage.png')})`,
				backgroundColor:
					mode === THEME_MODE.dark
						? muiTheme.palette.background.paper
						: LIGHT_GRAY,
				backgroundSize: '100%',
				display: 'flex',
				borderRadius: '4px',
				alignItems: 'center',
				justifyContent: 'space-between',
				padding: {
					xs: '1rem',
					md: '2rem',
				},
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: (theme) => theme.spacing(3),
				}}
			>
				<Typography
					sx={{
						fontWeight: '600',
						color:
							mode === THEME_MODE.dark
								? grey['100']
								: muiTheme.palette.primary.main,
					}}
					variant={'h5'}
				>
					To-do list
				</Typography>
				<Typography
					sx={{
						color:
							mode === THEME_MODE.dark
								? grey['300']
								: muiTheme.palette.primary.main,
					}}
				>
					You still have about {taskIndex} task{taskIndex > 1 ? 's' : ''} to
					complete for your profile to be fully completed.
				</Typography>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						gap: (theme) => theme.spacing(2),
						cursor: 'pointer',
					}}
				>
					<Typography
						onClick={() => navigate(link)}
						sx={{
							fontWeight: '600',
							userSelect: 'none',
							color:
								mode === THEME_MODE.dark
									? grey['300']
									: muiTheme.palette.primary.main,
						}}
					>
						{currentTask}
					</Typography>
					<ChevronRightRounded />
				</Box>
			</Box>
			<Box sx={{ position: 'relative' }}>
				<CircularProgress
					size={'80px'}
					variant='determinate'
					value={(4 - taskIndex) * 25}
				/>
				<Box
					sx={{
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						position: 'absolute',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Typography
						variant={'body1'}
						sx={{
							fontWeight: '600',
							fontSize: '18px',

							color:
								mode === THEME_MODE.dark
									? grey['300']
									: muiTheme.palette.primary.main,
						}}
					>
						{(4 - taskIndex) * 25}%
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default Todo;
