import React, { useState } from 'react';
import { Box, Typography, useTheme, InputAdornment } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import TextInput from '../form-components/text-input';
import Button from '../button';
import { grey } from '@mui/material/colors';
import {
	LoginPayload,
	STORAGE_KEYS,
	ValidationSchema,
	LINKS,
	Storage,
} from 'utilities';
import Divider from '../divider';
import { setUser, setToken } from '../../store/auth';
import { useAppDispatch } from 'store/hooks';
import { useHandleError, useModalAlert, useAlert } from 'hooks';
import { login, resendVerificationEmail } from 'api';

const ACCOUNT_NOT_VERIFIED = 'Account Not Verified';

const LoginForm = () => {
	const navigate = useNavigate();
	const handleError = useHandleError();
	const modal = useModalAlert();
	const alert = useAlert();
	const { state } = useLocation();
	const urlState = state as { [key: string]: any };
	const dispatch = useAppDispatch();
	const [isDisplayPassword, setDisplayPassword] = useState<boolean>(false);
	const theme = useTheme();
	const styles = useStyles(theme);

	const initialValues: LoginPayload = {
		password: '',
		email: Storage.getItem(STORAGE_KEYS.UserEmail) || '',
	};

	const navigateTo = (link: string) => {
		modal(null);
		navigate(link);
	};

	const { isLoading: isResendingCode, mutate: mutateResendVerificationCode } =
		useMutation(resendVerificationEmail, {
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message)
						alert({ message: response?.message, type: 'error' });
				}
				if (data && data.success) {
					alert({
						message: 'Verification code sent successfully!',
						type: 'info',
					});
					navigate(LINKS.VerifyAccount);
				}
			},
		});

	const { mutate: mutateLogin, isLoading: isLogining } = useMutation(login, {
		onSettled: (data, error) => {
			if (data && data.success) {
				const payload = data.payload;

				if (payload.twoFactorAuth) {
					modal({
						title: 'Login Message',
						message: data.message,
						type: 'success',
						primaryButtonText: 'Continue',
						onClickPrimaryButton: () => {
							modal(null);
							navigateTo(LINKS.ConfirmLoginOtp);
						},
					});
					return;
				}

				const { user, token } = data.payload;

				Storage.saveItem(STORAGE_KEYS.UserToken, token);
				dispatch(setUser(user));
				dispatch(setToken(token));

				// Switch to intendedd url

				if (urlState) {
					// const { _redirect } = urlState;
				}

				navigate(LINKS.Dashboard);
			}

			if (error) {
				const response = handleError({
					error,
				});

				if (response?.message) {
					const errorRegExp = new RegExp(ACCOUNT_NOT_VERIFIED, 'gi');
					if (errorRegExp.test(response.message)) {
						modal({
							title: 'Login Message',
							message: response.message,
							type: 'error',
							primaryButtonText: 'Verify Account',
							onClickPrimaryButton: () => {
								modal(null);
								handleVerifyEmail();
							},
						});
						return;
					}

					alert({ message: response.message, type: 'error' });
				}
			}
		},
	});

	const { values, handleChange, handleSubmit, errors, touched } = useFormik({
		initialValues,
		validationSchema: ValidationSchema.Login,
		onSubmit: (values) => {
			Storage.saveItem(STORAGE_KEYS.UserEmail, values.email);
			mutateLogin({
				password: values.password,
				email: values.email,
			});
		},
	});

	const { password, email } = values;

	function handleVerifyEmail() {
		if (email) {
			mutateResendVerificationCode({ email });
		} else {
			alert({ message: 'Something went wrong', type: 'info' });
		}
	}

	return (
		<Box>
			<Box sx={{ marginBottom: theme.spacing(4) }}>
				<Typography style={styles.title} variant={'h5'}>
					Login account
				</Typography>
				<Typography variant={'body1'}>
					Type in your details below with to log back into your account.
				</Typography>
			</Box>
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						User name/Email address
					</Typography>
					<TextInput
						error={errors && touched.email && errors.email ? true : false}
						helperText={errors && touched.email && errors.email}
						fullWidth
						placeholder={'Type in your username or email address'}
						value={email}
						onChange={handleChange('email')}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '6px',
						width: '100%',
					}}
				>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Password
					</Typography>

					<TextInput
						error={errors && touched.password && errors.password ? true : false}
						helperText={errors && touched.password && errors.password}
						fullWidth
						type={isDisplayPassword ? 'text' : 'password'}
						placeholder={'Type in your password here'}
						value={password}
						onChange={handleChange('password')}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<Button
										style={styles.togglePassword}
										onClick={(e: React.FormEvent<HTMLButtonElement>) => {
											setDisplayPassword(!isDisplayPassword);
										}}
									>
										{isDisplayPassword ? 'Hide' : 'Show'}
									</Button>
								</InputAdornment>
							),
						}}
					/>
					<Button
						onClick={() => navigate(LINKS.RequestForgottenPasswordOtp)}
						style={styles.forgottenPasswordButton}
					>
						Forgotten password
					</Button>
				</Box>
				<Button
					loading={isLogining || isResendingCode}
					size={'large'}
					style={styles.btn}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Login
				</Button>
			</Box>
			<Divider sx={{ margin: '3rem 0px' }} text={'OR'} />
			<Button
				style={styles.btn}
				fullWidth
				size={'large'}
				onClick={(e: React.FormEvent<HTMLButtonElement>) => {
					e.preventDefault();
					navigate(LINKS.Register);
				}}
			>
				Sign up
			</Button>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	togglePassword: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
	forgottenPasswordButton: {
		alignSelf: 'flex-end',
	},
});

export default LoginForm;
