import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { EducationPin } from 'utilities';
import { useAlert } from 'hooks';

type Props = {
	pin: EducationPin;
};

const CopyItemWithSerialNumber = ({ pin }: Props) => {
	const theme = useTheme();
	const alert = useAlert();
	const onCopy = (pin: string) =>
		alert({ message: `"${pin}" copies successfully!!`, type: 'info' });

	return (
		<Box
			sx={{
				border: `1px solid ${theme.palette.primary.main}`,
				padding: '8px 20px',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				color: theme.palette.primary.main,
				borderRadius: '5px',
				width: '100%',
			}}
		>
			<Box>
				<Typography variant={'body1'}>Pin: {pin.pin}</Typography>
				<Typography variant={'body2'}>
					Serial Number: {pin.serialNumber}
				</Typography>
			</Box>

			<CopyToClipboard text={pin.pin} onCopy={() => onCopy(pin.pin)}>
				<Button
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<ContentCopy sx={{ fontSize: '16px', marginRight: '5px' }} />
					<Typography>copy</Typography>
				</Button>
			</CopyToClipboard>
		</Box>
	);
};

export default CopyItemWithSerialNumber;
