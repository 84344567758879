import appLogo from '../../assets/icons/app-logo.png';

export const PRIMARY_COLOR = '#28536B';
export const LIGHT_PRIMARY_COLOR = '#FDEDD7';
export const SECOUNDARY_COLOR = '#F38D04';
export const TRANSITION = 'all 0.3s';
export const DRAWER_WIDTH = '80%';
export const PENDING_COLOR = '#30AF9F';
export const DANGER_COLOR = '#E84E51';
export const SUCCESS_COLOR = '#389651';
export const LIGHT_GRAY = '#DCE3E7';
export const HEADER_HEIGHT = '80px';
export const DARK_BACKGROUND_COLOR = '#09171f';
export const DARK_CARD_COLOR = '#102430';
export const MAX_RECORDS = 20;
export const BOX_SHADOW = `0px 0px 8px rgba(0, 0, 0, 0.1)`;
export const REFERRAL_CODE = 'referral_code';

export const REDIRECT = '_redirect';

export const VALIDATE_BVN_MESSAGE =
	'Unable to proceed, please ensure you verify your BVN';

export const FAQS = [
	{
		question: 'What is Airtimeflip?',
		answer: `AirtimeFlip is your no 1 airtime to cash converter in Nigeria. We convert your over recharged airtime to cash. We also provide other services which includes Airtime and data purchase, utility and educational bill payments, cable tv subscriptions and lots more.`,
	},
	{
		question: 'What are your hours of operation?',
		answer: `We operate 24/7, we are always available to serve you better. However in the event of server maintenance or any of such activities you might experience difficulty or inability to use our website or app..`,
	},
	{
		question: 'Is there a stipulated time for payment?',
		answer: `You get paid as soon as your transaction is processed and your airtime received.

    Sometimes, due to a delay which can occur as a result of failed network from your bank or our payment partner. payment may strech as long as 30 minutes to 4 hours.
    
    If payment isn’t made after then, please feel free to contact us and lay a complaint.
    `,
	},
	{
		question: 'Are there any charges when I withdraw from my wallet?',
		answer: `In a few easy steps, you can top up your airtime on all available
		networks in Nigeria. Simply register/login and you are good to go.`,
	},
	{
		question: 'Are there any charges when I withdraw from my wallet?',
		answer: `Withdrawal is free for all banks, but, there is a deposit fee of 1% for funding via bank transfer and 1.5% for wallets funded via card.`,
	},
];

export const SEO = {
	meta: {
		title: 'Airtimeflip',
		titleTemplate: '%s',
		description:
			'AirtimeFlip is your no 1 airtime to cash converter in Nigeria. We convert your over recharged airtime to cash. We also provide other services which includes Airtime and data purchase, utility and educational bill payments, cable tv subscriptions and lots more',
		siteUrl: 'https://airtimeflip.com',
		image: appLogo,
		iconimage: appLogo,
		twitterUsername: '@airtimeflip',
	},
	ga: 'UA-XXXXXXXXX-X',
};

export const STORAGE_KEYS = {
	themeMode: '@Storage_Theme_mode',
	UserToken: '@Storage:User_token',
	AccessToken: '@Storage:Access_token',
	UserEmail: '@Storage:User_email',
	NotificationAlert: '@Storage:Notification_Alert',
};

export const THEME_MODE = {
	light: 'light',
	dark: 'dark',
};

export const REGISTRATION_STEP = {
	EMAIL_VERIFICATION: 'email_verification',
	BVN_VERIFICATION: 'bvn_verification',
	IDENTITY_VERIFICATION: 'identity_verification',
	CREATE_ACCOUNT: 'create_account',
};

export const QUERY_KEYS = {
	UserDetails: '@Query:User_Detials',
	Me: '@Query:Me',
	UserWallet: '@Query:USer_Wallet',
	Transactions: '@Query:Transactions',
	VirtualAccount: '@Query:Virtual_Account',
	GetConvertNetwork: '@Query:GetConvertNetwork',
	AirtimeNetwork: '@Query:AirtimeNetwork',
	AutoAirtimeConvertNetwork: '@Query:AutoAirtimeConvertNetwork',
	DataNetwork: '@Query:DataNetwork',
	DataNetworkPlans: '@Query:DataNetworkPlans',
	CableProviders: '@Query:GetCableProviders',
	CableProviderBouquet: '@Query:GetCableProviderBouquet',
	InternetProvider: '@Query:InternetProvider',
	InternetProviderBundles: '@Query:InternetProviderBundles',
	EducationProviders: '@Query:EducationProvider',
	EducationProviderBundles: '@Query:EducationProviderBundles',
	ElectricityProviders: '@Query:ElectricityProviders',
	KycLimits: '@Query:KycLimits',
	Get2FAToken: '@Query:Get2FAToken',
	GetPinResetToken: '@Query:Get2FAToken',
	UserBanks: '@Query:UserBanks',
	Banks: '@Query:Banks',
	VerifyBankAccount: '@Query:VerifyBankAccount',
	VerifyUser: '@Query:VerifyUser',
	Referrals: '@Query:Referrals',
	ValidateDiscountCode: '@Query:ValidateDiscountCode',
	AirtimeEPin: '@Query:AirtimeEPin',
	DataEPin: '@Query:DataEPin',
	BillEPin: '@Query:BillEPin',
	ValidateEPin: '@Query:ValidateEPin',
	Tickets: '@Query:Tickets',
	Ticket: '@Query:Ticket',
	NotificationSettings: '@Query:NotificationSettings',
	NotificationAlert: '@Query:NotificationAlert',
	VerificationState: '@Query:VerificationState',
	RecentTransactions: '@Query:RecentTransactions',
	Beneficiaries: '@Query:Beneficiaries',
};

export const HTTP_STATUS_CODE = {
	OK: 200,
	BadRequest: 400,
	Unauthorized: 401,
	Forbidden: 403,
	NotFound: 404,
	InternalServerError: 500,
	BadGateway: 502,
	ServiceUnavailable: 503,
};

export const TRANSACTION_STATUS = {
	PENDING: 'PENDING',
	APPROVED: 'APPROVED',
	FAILDED: 'FAILED',
	SUCCESSFUL: 'SUCCESSFUL',
};

export const ALERT_TYPE = {
	PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
	EMAIL_NOTIFICATION: 'EMAIL_NOTIFICATION',
	TOAST: 'TOAST',
	IN_APP: 'IN_APP',
};

export const BILL_TAB = {
	CablePayment: 'cable-payment',
	PowerBill: 'power-bill',
	Education: 'education',
	InternetSubscription: 'internet-subscription',
};

export const E_PINS = {
	Airtime: 'Airtime epin',
	InternetSubscription: 'Internet subscription',
	Cable: 'Cable',
	Power: 'Power',
	Education: 'Education',
};

export const SETTINGS_TAB = {
	Profile: 'profile',
	Security: 'security',
	Notification: 'Notification',
	BankInformation: 'bank-information',
	ManageLimit: 'manage-limit',
};

export const REFERRAL_HISTORY = {
	PendingReferral: 'pending',
	AllReferral: 'all',
};

export const WALLET_TOPUP = {
	WITH_BANK: 'Fund with bank',
	WITH_CARD: 'Fund with card',
};

export const WITHDRAWAL_TAB = {
	MY_BANK: 'My bank',
	ANOTHER_BANK: 'To another bank',
};

export const WITHDRAWAL_TYPE = {
	Default: 'DEFAULT-BANK',
	OtherBank: 'OTHER-BANK',
};

export const KYC_LEVEL = {
	LevelOne: 1,
	LevelTwo: 2,
	levelThree: 3,
};

export const API_ENDPOINTS = {
	User: '/user',
	Wallet: '/wallet',
	VirtualAccount: '/virtual-account',
	Funding: '/funding',
	Transactions: '/transaction',
	Airtime: '/airtime',
	InternationalAirtime: 'international-airtime',
	InternationalData: 'international-data',
	ConvertNetwork: '/convert-networks',
	AutoConvertNetwork: '/auto-convert-networks',
	AutoConvertAirtime: '/auto-convert-airtime',
	ConvertAirtime: '/convert-airtime',
	DataNetwork: '/data-networks',
	DataPlans: '/data-plans',
	DataTypes: '/data-types',
	DataSubscription: '/data-subscription',
	AirtimeNetwork: '/airtime-networks',
	Bill: '/bills',
	Kyc: '/kyc',
	BankAccount: '/bank-accounts',
	Institutions: '/banks',
	Withdraw: '/withdraw',
	Transfer: '/transfer',
	Coupon: '/coupon',
	IEPin: '/epin',
	Ticket: '/ticket',
	NotificationSettings: '/notification-settings',
	Notification: '/notification',
	Referral: '/referrals',
	Verification: '/verifications',
	Beneficiary: '/beneficiary-list',
	InternationalAirtimeData: '/international-airtime-data',
	EVouchers: '/e-vouchers',
	GiftCard: '/gift-cards',
	ESim: '/esim',
};

export const ENDPOINT_SUBPATH = {
	Me: '/me',
	InternetProvider: '/internet-providers',
	Purchase: '/purchase',
	ElectricityProvider: '/electricity-providers',
	ElectricityPayment: '/electricity-payment',
	CableProvider: '/cable-providers',
	ValidateBill: '/validate',
	CablePayment: '/cable-payment',
	InternetPayment: '/internet-payment',
	EducationProviders: '/education-providers',
	EducationPayment: '/education-payment',
	Settings: '/settings',
	Login: '/login',
	TwoFALogin: '/login/2fa',
	Verify: '/verify',
	SetPin: '/set-pin',
	GetPinResetToken: '/getResetPinToken',
	GetPasswordResetToken: '/getResetPasswordToken',
	Get2FAToken: '/get2FAToken',
	Update2FASetting: '/update-2fa-setting',
	ResendVerificationEmail: '/resend-verification',
	BvnVerification: '/verify-level-2',
	VerifyOthers: '/verify-other-user',
	ResetPassword: '/reset-password',
	ResetPin: '/reset-pin',
	ValidatePin: '/validate-pin',
	ValidateResetPinPassword: '/validate-reset-pin',
	RequestOtp: '/request-otp',
	VerifyOtp: '/verify-otp',
	Providers: '/providers',
	Products: '/products',
	ParseMsisdn: '/parse-msisdn',
};

export const FILTER_BY = {
	CreatedAt: '-createdAt',
	Amount: '-amount',
	Status: '-status',
};

export const SORT_BY_DAYS = {
	LAST_7_DAYS: 'LAST 7 DAYS',
	LAST_30_DAYS: 'LAST 30 DAYS',
	CUSTOM: 'CUSTOM',
};

export const RELATED_TRANSACTION = {
	BillPayment: 'BillPayment',
	Airtime: 'Airtime',
	DataSubscription: 'DataSubscription',
	AirtimeConvert: 'AirtimeConvert',
};

export const PRIORITY = {
	HIGH: 'HIGH',
	MEDIUM: 'MEDIUM',
	LOW: 'LOW',
};

export const FUND_WALLET_CHARGE = 1.5;

export const LANDING_PAGE_URL = process.env.REACT_APP_LANDING_PAGE as string;

export const TICKET_STATUS = {
	OPENED: 'OPENED',
	CLOSED: 'CLOSED',
};

export const TICKET_TYPE = {
	COMPLAINT: 'COMPLAINT',
	DISPUTE: 'DISPUTE',
};

export const TRANSFER_USSD = {
	Airtel: '*432*AIRTELNUMBER*AMOUNT#',
	MTN: `*777*Recipient's Number*Amount*PIN#`,
	Glo: `*131*recipients number*amount*pin#`,
};

export const NETWORK_TYPES = {
	Airtel: 'Airtel',
	MTN: 'MTN',
	Glo: 'Glo',
};

export const CHANGE_TRNSFER_PIN_USSD = {
	Airtel: 'PIN Old-Pin New-Pin to 432 (PIN 1234 4321 to 432.).',
	MTN: `*600*Oldpin*Newpin*Newpin#`,
	Glo: `*132*00000*[New PIN]*[New PIN]#`,
};

export const EPIN_SERVICES = {
	AIRTIME: 'AIRTIME',
	DATA: 'DATA',
	INTERNET: 'INTERNET',
	CABLE: 'CABLE',
	EDUCATION: 'EDUCATION',
	ELECTRICITY: 'ELECTRICITY',
};

export const TRANSACTION_SERVICE = {
	DATA_SUBSCRIPTION: 'DATA SUBSCRIPTION',
	AIRTIME_TOP_UP: 'AIRTIME TOP UP',
	AIRTIME_CONVERSION: 'AIRTIME CONVERSION',
	AIRTIME_AUTO_CONVERSION: 'AIRTIME AUTO CONVERSION',
	CABLE: 'CABLE',
	INTERNET: 'INTERNET',
	EDUCATION: 'EDUCATION',
	ELECTRICITY: 'ELECTRICITY',
	EPIN: 'EPIN',
	BETTING: 'BETTING',
	CARD_TOP_UP: 'CARD FUNDING',
	WITHDRAWAL: 'WITHDRAWAL',
	WALLET_TRANSFER: 'WALLET TRANSFER',
	REVERSAL: 'REVERSAL',
	REFERRAL_BONUS: 'REFERRAL_BONUS',
	INTERNATIONAL_DATA_SUBSCRIPTION: 'INTERNATIONAL DATA SUBSCRIPTION',
	INTERNATIONAL_AIRTIME_TOP_UP: 'INTERNATIONAL AIRTIME TOP UP',
	EVOUCHER: 'EVOUCHER',
	GIFT_CARD: 'GIFT_CARD',
	ESIM: 'ESIM',
};

export const PAYMENT_METHOD = {
	Interswitch: 'INTERSWITCH',
	Paystack: 'PAYSTACK',
	Monnify: 'MONNIFY',
};

export const COUPON_STATUS = {
	UNVERIFIED: 'UNVERIFIED',
	VERIFIED: 'VERIFIED',
	CANCELLED: 'CANCELLED',
	EXPIRED: 'EXPIRED',
};

export const COUPON_TYPE = {
	PERCENT: 'PERCENT',
	AMOUNT: 'AMOUNT',
};

export const LINKS = {
	Dashboard: '/dashboard',
	PayBill: '/services/bill',
	ConvertAirtime: '/services/convert-airtime',
	ManualConvertAirtime: '/services/convert-airtime/manual',
	InstantConvertAirtime: '/services/convert-airtime/instant',
	RequestConvertAirtimeOtp: '/services/convert-airtime/instant/request-otp',
	ConfirmConvertAirtimeOtp: '/services/convert-airtime/instant/confirm-otp',
	tradeCryto: '/trade-cryto',
	EPin: '/e-pin',
	GenerateData: '/e-pin/generate/data',
	GenerateAirtime: '/e-pin/generate/airtime',
	GenerateBill: '/e-pin/generate/bill',
	ViewBill: '/e-pin/view/bill',
	ViewData: '/e-pin/view/data',
	ViewEpinAirtime: '/e-pin/view/airtime',
	LoadEducation: '/e-pin/load/education',
	LoadPower: '/e-pin/load/power',
	LoadInternetSubscription: '/e-pin/load/internet-subscription',
	LoadCable: '/e-pin/load/cable',
	LoadAirtime: '/e-pin/load/airtime',
	LoadData: '/e-pin/load/data',
	services: '/services',
	Settings: '/settings',
	AddBank: '/settings/bank/add',
	BvnVerification: '/settings/verify/bvn',
	LevelTwoVerification: '/settings/verify/identity',
	ConfirmTwoFactorToken: '/settings/confirm/two-factor-auth-token',
	ValidateIdentityCard: '/settings/validate/identity-card',
	ResetPassword: '/settings/reset-password',
	ResetPin: '/settings/reset-pin',
	TransactionHistory: '/transaction-history',
	PendingTransaction: '/transaction-history/pending',
	PurchaseAirtime: '/services/purchase-airtime',
	PurchaseData: '/services/purchase-data',
	GetHelp: '/get-help',
	CableBill: '/services/bill/cable-payment',
	Referral: '/referral',
	REFERRAL_HISTORY: '/referral/history',
	Login: '/auth/login',
	ConfirmLoginOtp: '/auth/login/confirm-otp',
	VerifyAccount: '/auth/verify-email',
	RequestForgottenPasswordOtp: '/auth/forgotten-password/request-otp',
	ConfirmForgottenPasswordOtp: '/auth/forgotten-password/confirm-otp',
	AuthResetPassword: '/auth/forgotten-password/reset',
	Register: '/auth/register',
	WalletTopup: '/wallet/top-up',
	WalletWithdraw: '/wallet/withdraw',
	WalletTransfer: '/wallet/transfer',
	Notifications: '/notifications',
	SupportTicket: '/support-ticket',
	CreateSupportTicket: '/support-ticket/create',
	Message: '/support-ticket/message',
	FundBettingAccount: '/services/fund-betting-account',
	InternationalAirtimeData: '/services/international-airtime-and-data',
	PurchaseInternationalAirtimeData:
		'/services/international-airtime-and-data/:slug',
	ESim: '/services/e-sim',
	GiftCards: '/services/gift-cards',
	EVouchers: '/services/e-vouchers',
};

export const DRAWER_LINKS = {
	Dashboard: {
		name: 'Dashboard',
		path: LINKS.Dashboard,
	},
	Services: {
		name: 'Services',
		path: LINKS.services,
	},
	TransactionHistory: {
		name: 'Transaction history',
		path: LINKS.TransactionHistory,
	},
	IEPin: {
		name: 'E-pin',
		path: LINKS.EPin,
	},
	Settings: {
		name: 'Settings',
		path: LINKS.Settings,
	},
	GetHelp: {
		name: 'Get help/FAQs',
		path: LINKS.GetHelp,
	},
	Referral: {
		name: 'Referrals',
		path: LINKS.Referral,
	},
};

export const NOTIFICATION = {
	TRANSFER: 'transfer',
	PUSH_NOTIFICATION: 'push_notification',
	NEWSLETTERS: 'newsletter',
	TOPUP: 'topup',
};

export const BENEFICIARY_TYPE = {
	AIRTIME: 'AIRTIME',
	DATA: 'DATA',
	CABLE: 'CABLE',
	EDUCATION: 'EDUCATION',
	INTERNET: 'INTERNET',
	ELECTRICITY: 'ELECTRICITY',
};

export const SOCHITEL_LINKS = {
	OperatorLogo: (brandId: string) =>
		`https://media.sochitel.com/img/operators/${brandId}.png`,
	CountryFlag: (countryId: string) =>
		`https://media.sochitel.com/img/flags/${countryId}.png`,
};
