import React from 'react';
import { AuthLayout, LoginForm, Seo } from 'components';

const Login = () => {
	return (
		<AuthLayout>
			<Seo title={'Login'} />
			<LoginForm />
		</AuthLayout>
	);
};

export default Login;
