import React from 'react';
import { Layout, Seo } from 'components';

const NotFound = () => {
	return (
		<Layout>
			<Seo title={'Page Not Found'} />
			NotFound
		</Layout>
	);
};

export default NotFound;
