import React from 'react';
import { Box } from '@mui/material';
import { HEADER_HEIGHT } from 'utilities';
import List from '../nav-list';

const Sidebar = () => {
	return (
		<Box
			sx={{
				// position: 'sticky',
				top: '0px',
				paddingTop: '6rem',
				paddingBottom: '2rem',
				overflow: 'auto',
				// height: `calc(100vh - ${HEADER_HEIGHT})`,
				height: '100vh',
				// overflow: '-moz-scrollbars-none',
				scrollbarWidth: 'none',
				'&::::-webkit-scrollbar': {
					width: ' 0 !important',
					display: 'none',
				},
			}}
			component={'nav'}
		>
			<List />
		</Box>
	);
};

export default Sidebar;
