import { useImperativeHandle, forwardRef, useEffect } from 'react';
import { generateRandomNumber } from 'utilities';
import { useAppSelector } from 'store/hooks';

type Props = {
	onSuccess?: (res: any) => void;
};

interface IInitiateCheckout {
	amount: string;
}

const MonnifyCheckout = ({ onSuccess }: Props, ref) => {
	const user = useAppSelector((store) => store.auth.user);
	const apiKey = process.env.REACT_APP_MONIFY_APIKEY;
	const contractCode = process.env.REACT_APP_MONIFY_CONTRACT_CODE;

	useEffect(() => {
		const script = document.createElement('script');
		const body = document.getElementsByTagName('body')[0];
		script.src = 'https://sdk.monnify.com/plugin/monnify.js';
		script.type = 'text/javascript';

		body.appendChild(script);
	}, []);

	const initialize = ({ amount }: IInitiateCheckout) => {
		if (window && typeof window !== 'undefined') {
			const _window: any = { ...window };
			const MonnifySDK = _window.MonnifySDK;
			MonnifySDK.initialize({
				amount,
				currency: 'NGN',
				reference: generateRandomNumber({ length: 10 }),
				customerFullName: `${user?.firstname} ${user?.lastname}`,
				customerEmail: user?.email,
				apiKey,
				contractCode,
				paymentDescription: 'Wallet Top up',

				onLoadStart: () => {
					console.log('loading has started');
				},
				onLoadComplete: () => {
					console.log('SDK is UP');
				},

				onComplete: function (response) {
					//Implement what happens when the transaction is completed.
					console.log(response);
					if (response.status === 'SUCCESS') {
						typeof onSuccess === 'function' && onSuccess(response);
					}
				},
				onClose: function (data) {
					//Implement what should happen when the modal is closed here
					console.log(data);
				},
			});
		}
	};

	useImperativeHandle(
		ref,
		() => {
			return {
				initialize,
			};
		},
		// eslint-disable-next-line
		[user]
	);

	return null;
};

export default forwardRef(MonnifyCheckout);
