import React from 'react';
import { Box } from '@mui/material';
import {
	Layout,
	TwoColGrid,
	Seo,
	WalletTransferForm,
	WalletBalance,
	BackButton,
} from 'components';

const TransferPage = () => {
	return (
		<Layout>
			<Seo title={'Wallet Transfer'} />
			<BackButton name={'Back'} sx={{ marginBottom: ['1.5rem'] }} />
			<Box>
				<TwoColGrid rightAside={<WalletBalance isHideTopupBtn />}>
					<WalletTransferForm />
				</TwoColGrid>
			</Box>
		</Layout>
	);
};

export default TransferPage;
