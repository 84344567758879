import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import EpinItem from './epin-item';
import { LINKS, THEME_MODE } from 'utilities';
import { useAppSelector } from 'store/hooks';
import PurchaseAirtimeIcon from 'components/icons/purchase-airtime';
import PurchaseDataIcon from 'components/icons/purchase-data';
import NoteIcon from 'components/icons/note-icon';
import H5Typography from 'components/typography/h5-typography';

const ViewAllGenerateEPin = () => {
	const theme = useTheme();
	const { mode } = useAppSelector((store) => store.theme);
	const styles = useStyles(theme, mode);
	return (
		<Box>
			<Box style={styles.headerWrapper}>
				<H5Typography
					sx={{
						marginBottom: '6px',
					}}
					name='View all generated e-Pin'
				/>

				<Typography style={styles.text}>
					Click on each tab to conveniently review and manage your e-PINs.
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'grid',
					gap: '8px',
					gridTemplateColumns: {
						xs: '1fr',
						md: 'repeat(3, 1fr)',
					},
				}}
			>
				<EpinItem
					logo={PurchaseAirtimeIcon}
					title={'View airtime e-pins'}
					link={LINKS.ViewEpinAirtime}
				/>
				<EpinItem
					imageWidth={'25px'}
					logo={PurchaseDataIcon}
					title={'View data e-pins'}
					link={LINKS.ViewData}
				/>
				<EpinItem
					imageWidth={'20px'}
					logo={NoteIcon}
					title={'view bill e-pins'}
					link={LINKS.ViewBill}
				/>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any, mode: string) => ({
	headerWrapper: {
		marginBottom: theme.spacing(3),
	},
	title: {
		fontWeight: '600',
		// marginBottom: theme.spacing(4),
		color: mode === THEME_MODE.dark ? grey[300] : 'initial',
	},
	text: {
		color: mode === THEME_MODE.dark ? grey[300] : 'initial',
	},
});

export default ViewAllGenerateEPin;
