import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { LINKS, THEME_MODE } from 'utilities';

const LogoWithText = (props: BoxProps) => {
	const { mode } = useAppSelector((store) => store.theme);
	const navigate = useNavigate();
	const onClick = () => navigate(LINKS.Dashboard);

	return (
		<Box
			onClick={onClick}
			{...props}
			sx={{
				display: 'flex',
				alignItems: 'center',
				cursor: 'pointer',
				...props.sx,
				img: {
					width: '100%',
				},
			}}
		>
			{mode === THEME_MODE.dark ? (
				<img
					src={require('../../assets/images/airtimeflip-white.png')}
					alt={'app-logo'}
				/>
			) : (
				<img
					src={require('../../assets/images/appLogoWithText.png')}
					alt={'app-logo'}
				/>
			)}
		</Box>
	);
};

export default LogoWithText;
