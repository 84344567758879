import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { grey } from '@mui/material/colors';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import TextInput from '../form-components/text-input';
import Button from '../button';
import Select from '../form-components/select';
import { QUERY_KEYS, Bank, LINKS, BankAccount } from 'utilities';
import { useAppSelector } from 'store/hooks';
import Loader from '../loader';
import TextPlaceholder from '../partials/custom-text-input-holder';
import {
	useModal,
	useHandleError,
	useAlert,
	useVerifyBankAccount,
} from 'hooks';
import { banks, createBank, setDefaultBank } from 'api';

const SELECT_BANK = 'Select bank';

const BankAccountForm = () => {
	const theme = useTheme();
	const modal = useModal();
	const alert = useAlert();
	const handleError = useHandleError();
	const styles = useStyles(theme);
	const { token, user } = useAppSelector((store) => store.auth);
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const { verifyBankAccount, isVerifyingBankAccount, bankAccount } =
		useVerifyBankAccount();

	const { isLoading: isMakingBankDefault, mutate: mutateDefaultBank } =
		useMutation(setDefaultBank, {
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						alert({ message: res.message, type: 'error' });
					}
				}

				if (data && data.success) {
					queryClient.invalidateQueries(QUERY_KEYS.Me);
					modal.display({
						title: 'Create Bank',
						message: 'Bank create successfully',
						type: 'success',
						primaryButtonText: 'Close',
						onClickPrimaryButton: () => {
							modal.close();
							navigate(`${LINKS.Settings}?tab=bank-information`, {
								replace: true,
							});
						},
					});
				}
			},
		});

	const handleMakeBankDefault = (bank: string) => {
		mutateDefaultBank({ defaultBank: bank });
	};

	const { isLoading: isLoadingBanks, data: dataBanks } = useQuery(
		QUERY_KEYS.Banks,
		() =>
			banks({
				sort: 'name',
			}),
		{
			enabled: !!token,
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						alert({ message: res.message, type: 'error' });
					}
				}
			},
		}
	);

	const { isLoading: isCreatingBank, mutate: mutateCreateBank } = useMutation(
		createBank,
		{
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						alert({ message: res.message, type: 'error' });
					}
				}

				if (data && data.success) {
					resetForm();
					queryClient.invalidateQueries(QUERY_KEYS.UserBanks);
					handleMakeBankDefault(data.payload.id as string);
				}
			},
		}
	);

	const initialValues: BankAccount = {
		bankName: SELECT_BANK,
		code: '',
		accountName: '',
		accountNumber: '',
	};

	const {
		errors,
		touched,
		handleSubmit,
		values,
		resetForm,
		setFieldValue,
		handleChange,
	} = useFormik({
		initialValues,
		onSubmit: (values) => {
			if (bankAccount) {
				mutateCreateBank({
					...values,
					accountName: bankAccount.accountName as string,
				});
			}
		},
	});

	const { bankName, code, accountNumber } = values;

	const handleSelectBank = (bankName: string) => {
		const filterBank =
			dataBanks &&
			dataBanks.payload.find((bank: Bank) => bank.name === bankName);
		if (filterBank) {
			const code = filterBank.code as string;
			setFieldValue('code', code);
			setFieldValue('bankName', bankName);
		}
	};

	const handleVerifyAccount = () => {
		if (code) {
			if (!/\d{10}/.test(accountNumber)) {
				modal.display({
					title: 'Add Bank Account',
					message: 'The account number you provided is invalid',
					primaryButtonText: 'Close',
					onClickPrimaryButton: () => modal.close(),
				});
				return;
			}

			verifyBankAccount({
				accountNumber,
				bankCode: code,
			});
		}
	};

	return (
		<>
			{(isVerifyingBankAccount || isMakingBankDefault) && <Loader />}

			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Bank name
					</Typography>
					<Select
						fullWidth
						value={bankName}
						error={errors && touched.bankName && errors.bankName ? true : false}
						helpertext={errors && touched.bankName && errors.bankName}
						onChange={(e: any) => handleSelectBank(e.target.value)}
					>
						<MenuItem disabled value={SELECT_BANK}>
							{SELECT_BANK}
						</MenuItem>
						{isLoadingBanks ? (
							<MenuItem value={'loading'}>Loading...</MenuItem>
						) : dataBanks && dataBanks.payload.length > 0 ? (
							dataBanks.payload.map((bank: Bank) => (
								<MenuItem key={`${bank.name}-${bank.code}`} value={bank.name}>
									{bank.name}
								</MenuItem>
							))
						) : (
							<MenuItem>No Banks</MenuItem>
						)}
					</Select>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Account number
					</Typography>
					{bankName !== SELECT_BANK ? (
						<TextInput
							fullWidth
							error={
								errors && touched.accountNumber && errors.accountNumber
									? true
									: false
							}
							helperText={
								errors && touched.accountNumber && errors.accountNumber
							}
							disabled={bankName !== SELECT_BANK ? false : true}
							placeholder={'Type in your account number'}
							value={accountNumber}
							onChange={handleChange('accountNumber')}
						/>
					) : (
						<TextPlaceholder text={'Type in you account number'} />
					)}
				</Box>
				{bankAccount && (
					<Box>
						<Typography
							style={styles.label}
							component={'label'}
							variant={'body1'}
						>
							Account name
						</Typography>
						<TextPlaceholder text={bankAccount.accountName} />
					</Box>
				)}

				<Button
					loading={isCreatingBank}
					style={styles.btn}
					size={'large'}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						if (!bankAccount && accountNumber) {
							handleVerifyAccount();
							return;
						}
						handleSubmit();
					}}
				>
					{bankAccount ? 'Save account' : 'Confirm account'}
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
});

export default BankAccountForm;
