import React from 'react';
import {
	Box,
	TableCell,
	TableRow,
	TableCellProps,
	Typography,
} from '@mui/material';

interface TTableEmptyProps extends TableCellProps {
	text?: string;
}

const TableEmpty = ({ text, ...rest }: TTableEmptyProps) => {
	return (
		<TableRow>
			<TableCell {...rest}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Box
						sx={{
							maxWidth: '45px',
							img: {
								width: '100%',
							},
						}}
					>
						<img
							src={require('../../assets/icons/data.png')}
							alt={'empty data'}
						/>
					</Box>
					{text && <Typography variant={'body1'}>{text}</Typography>}
				</Box>
			</TableCell>
		</TableRow>
	);
};

export default TableEmpty;
