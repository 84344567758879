import React, { ReactNode } from 'react';
import {
	TextField,
	TextFieldProps,
	useTheme,
	Typography,
	Box,
} from '@mui/material';
import { useAppSelector } from 'store/hooks';
import { DARK_CARD_COLOR, THEME_MODE } from 'utilities';
import { grey } from '@mui/material/colors';

type Props = {
	isLoading?: boolean;
	labelText?: string | ReactNode;
};

const TextInput = (props: TextFieldProps & Props) => {
	const { mode } = useAppSelector((store) => store.theme);
	const theme = useTheme();
	const labelText = props?.labelText;
	const color = mode === THEME_MODE.dark ? grey['300'] : grey['800'];

	return (
		<Box>
			{labelText && (
				<Box
					sx={{
						marginBottom: theme.spacing(2),
					}}
				>
					{typeof labelText === 'string' ? (
						<Typography
							variant={'body1'}
							component={'label'}
							sx={{
								display: 'inline-block',
								fontWeight: '600',
							}}
						>
							{labelText}
						</Typography>
					) : (
						labelText
					)}
				</Box>
			)}

			<TextField
				{...props}
				sx={{
					'& input': {
						color: props.disabled
							? `${grey['400']} !important`
							: `${color} !important`,
					},
					'& .Mui-disabled': {
						// eslint-disable-next-line
						['-webkit-text-fill-color']: 'unset !important',
					},
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor:
							mode === THEME_MODE.dark ? `${grey[500]}` : `${grey[500]}`,
					},

					'& .MuiInputBase-root': {
						background:
							mode === THEME_MODE.dark
								? `${DARK_CARD_COLOR} !important`
								: 'initial',
					},
					...props.sx,
				}}
				disabled={props.disabled || props.isLoading}
			/>
		</Box>
	);
};

export default TextInput;
