import React from 'react';
import { Navigate } from 'react-router-dom';
import { Storage } from '../helpers';
import { STORAGE_KEYS } from '../constant';

interface Props {
	children: any;
}

const AuthGuard = ({ children }: Props) => {
	const token = Storage.getItem(STORAGE_KEYS.UserToken);

	if (token) {
		return <>{children}</>;
	}

	return <Navigate to={'/auth/login'} replace />;
};

export default AuthGuard;
