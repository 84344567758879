import React from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import {
	Layout,
	LoadAirtimePinForm,
	CircularProgress,
	Seo,
	BackButton,
} from 'components';
import { useAppSelector } from 'store/hooks';
import { ErrorBoundaryGuard, formatNumberToCurrency } from 'utilities';
import { useHandleError, useAlert } from 'hooks';
import { airtimeNetworks } from 'api';

const LoadAirtime = () => {
	const { search } = useLocation();
	const alert = useAlert();
	const handleError = useHandleError();
	const query = queryString.parse(search);
	const { token } = useAppSelector((store) => store.auth);

	const { isLoading, data: dataNetwork } = useQuery(
		'Load Airtime Network',
		() =>
			airtimeNetworks({
				_id: query.network,
			}),
		{
			enabled: !!(token && query && query.network),
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						alert({ message: response.message, type: 'error' });
					}
				}
			},
		}
	);

	return (
		<Layout>
			<Seo title={'Load Airtime E-Pin'} />
			<ErrorBoundaryGuard>
				{isLoading ? (
					<CircularProgress />
				) : (
					<Box
						sx={{
							maxWidth: '600px',
							display: 'grid',
							gridTemplateColumns: '1fr',
							gap: '15px',
						}}
					>
						<BackButton name={'E-Pin'} sx={{ marginBottom: ['1.5rem'] }} />
						<Typography sx={{ fontWeight: '600' }} variant={'h5'}>
							Airtime E-pin
						</Typography>
						<Box>
							<Typography>
								The e-pin you entered gives{' '}
								{query.amount && formatNumberToCurrency(query.amount as string)}{' '}
								worth of{' '}
								{dataNetwork && (
									<Box component={'span'} sx={{ fontWeight: '600' }}>
										{dataNetwork && dataNetwork.payload[0]?.name} Airtime.
									</Box>
								)}{' '}
								<br />
								Enter your phone number to load pin.
							</Typography>
						</Box>
						<LoadAirtimePinForm pin={query.pin as string} />
					</Box>
				)}
			</ErrorBoundaryGuard>
		</Layout>
	);
};

export default LoadAirtime;
