import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import {
	Layout,
	EPinTransactionTable,
	Pagination,
	BackButton,
} from 'components';
import { useAppSelector } from 'store/hooks';
import { QUERY_KEYS, EPIN_SERVICES, LINKS } from 'utilities';
import Seo from 'components/seo';
import { useAlert, useHandleError } from 'hooks';
import { ePins } from 'api';

const MAX_RECORDS = 20;

const ViewAirtime = () => {
	const {
		auth: { token, user },
	} = useAppSelector((store) => store);
	const navigate = useNavigate();
	const alert = useAlert();
	const handleError = useHandleError();
	const [count, setCount] = useState<number>(1);
	const [page, setPage] = useState<number>(1);
	const [total, setTotal] = useState<number>(0);
	const location = useLocation();
	const query = queryString.parse(location.search);

	useEffect(() => {
		if (query && query.page) {
			setPage(parseInt(query.page as string));
		}
	}, [query, query.page]);

	const { isLoading, data } = useQuery(
		[QUERY_KEYS.AirtimeEPin, page, user?.id],
		() =>
			ePins({
				limit: MAX_RECORDS,
				skip: (page - 1) * MAX_RECORDS,
				sort: '-createdAt',
				service: EPIN_SERVICES.AIRTIME,
				populate: 'transaction',
			}),

		{
			enabled: !!token,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				if (data && data.success) {
					const total = data.metadata && data.metadata.total;
					setTotal(Number(total));
					const count = Math.ceil(Number(total) / MAX_RECORDS);
					setCount(count);
				}
				if (error) {
					const res = handleError({
						error,
					});

					if (res?.message) alert({ message: res.message, type: 'error' });
				}
			},
		}
	);

	const handlePageChange = (page: number) => {
		if (page !== 1) {
			setPage(page);
			navigate(`${LINKS.ViewEpinAirtime}?page=${page}`);
		} else {
			navigate(LINKS.ViewEpinAirtime);
			setPage(page);
		}
	};

	return (
		<Layout>
			<Seo title={'Airtime E-Pin'} />
			<BackButton name={'E-Pin'} sx={{ marginBottom: ['1.5rem'] }} />
			<EPinTransactionTable
				tableTitle={'Airtime E-pins'}
				isLoading={isLoading}
				data={data && data.payload}
			/>
			{total > MAX_RECORDS && (
				<Pagination
					size={'large'}
					variant={'outlined'}
					shape={'rounded'}
					page={page}
					count={count}
					onChange={(e, number) => handlePageChange(number)}
				/>
			)}
		</Layout>
	);
};

export default ViewAirtime;
