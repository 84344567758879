import React, { ReactNode } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

type Props = {
	bgColor: string;
	text: string;
	icon: ReactNode;
	onClick?: () => void;
};

const TransactionRecordItem = ({ bgColor, text, icon, onClick }: Props) => {
	return (
		<Button
			onClick={onClick}
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				backgroundColor: bgColor,
				color: grey[50],
				minHeight: ['55px', '45px'],
				':hover': {
					backgroundColor: bgColor,
					opacity: '0.8',
				},
			}}
			fullWidth
			size={'large'}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: (theme) => theme.spacing(3),
				}}
			>
				<Box
					sx={{
						backgroundColor: 'rgba(250, 250, 250, 0.3)',
						padding: '5px',
						height: '25px',
						width: '25px',
						borderRadius: '50%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{icon}
				</Box>
				<Typography>{text}</Typography>
			</Box>
			<ChevronRight />
		</Button>
	);
};

export default TransactionRecordItem;
