import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { grey, red } from '@mui/material/colors';
import OtpInput from 'react-otp-input';
import Button from '../button';
import { ValidationSchema } from 'utilities';
import moduleStyles from './style.module.css';

type Props = {
	callback?: (values: string) => void;
	text?: string;
	isLoading?: boolean;
	numInputs?: number;
	isInputSecure?: boolean;
};

const OtpForm = ({
	callback,
	text,
	isLoading,
	numInputs = 6,
	isInputSecure = true,
}: Props) => {
	const theme = useTheme();
	const styles = useStyles(theme);

	const initialValues: { otp: string } = {
		otp: '',
	};

	const { values, handleChange, handleSubmit, errors, touched } = useFormik({
		initialValues,
		validationSchema: ValidationSchema.ValidateOTP,
		onSubmit: (values) => {
			typeof callback !== 'undefined' && callback(values.otp);
		},
	});

	const { otp } = values;

	return (
		<Box style={styles.form as any} component={'form'}>
			<Box>
				<Typography style={styles.label} component={'label'} variant={'body1'}>
					{text || 'Enter OTP'}
				</Typography>

				<Box
					sx={
						{
							// maxWidth: numInputs <= 4 ? '300px' : '480px',
						}
					}
				>
					<OtpInput
						errorStyle={errors && touched.otp && errors.otp ? true : false}
						focusStyle={styles.focusStyle}
						inputStyle={moduleStyles.otpInputStyle}
						value={otp}
						onChange={handleChange('otp')}
						isInputSecure={isInputSecure}
						numInputs={numInputs}
						separator={<Box style={styles.separator} />}
					/>
					{errors && touched.otp && errors.otp && (
						<Typography style={styles.errorText} variant={'body2'}>
							{errors.otp}
						</Typography>
					)}
				</Box>
			</Box>

			<Button
				loading={isLoading}
				size={'large'}
				style={styles.btn}
				onClick={(e: React.FormEvent<HTMLButtonElement>) => {
					e.preventDefault();
					handleSubmit();
				}}
			>
				Confirm
			</Button>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	separator: {
		width: '15px',
	},
	inputStyle: {
		height: '55px',
		width: '55px',
		fontSize: '14px',
		color: theme.palette.primary.main,
		borderRadius: theme.spacing(1),
		border: `1px solid ${theme.palette.primary.main}`,
		backgroundColor: 'unset !important',
	},
	focusStyle: {
		outlineColor: theme.palette.primary.main,
	},
	errorText: {
		color: red[800],
		marginTop: theme.spacing(1),
	},
});

export default OtpForm;
