import React, { useState, MouseEvent, useRef } from 'react';
import {
	Tab,
	Button,
	List,
	Popper,
	Box,
	useTheme,
	ListItem,
	Paper,
	ClickAwayListener,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import TabWrapper from './tab-wrapper';
import { BOX_SHADOW } from 'utilities';

type Props = {
	currentTab: string | number;
	handleChange: (e: any, value: number) => void;
};

const TABS = [
	'Cable payment',
	'Power bill',
	'Education',
	'Internet subscription',
];

const BillTab = ({ currentTab, handleChange }: Props) => {
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const buttonRef: any = useRef(null);

	const handleClick = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	return (
		<>
			<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
				<Box
					sx={{
						display: {
							md: 'none',
						},
						marginBottom: '2rem',
					}}
				>
					<Button
						size={'large'}
						ref={buttonRef}
						onClick={handleClick}
						variant={'outlined'}
						endIcon={<ArrowDropDown />}
					>
						Bill Service:{' '}
						<Box
							sx={{
								fontWeight: '600',
								color: theme.palette.secondary.main,
								marginLeft: theme.spacing(1),
							}}
							component={'span'}
						>
							{TABS[parseInt(currentTab as string)]}
						</Box>
					</Button>
					<Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
						<Paper
							sx={{
								boxShadow: BOX_SHADOW,
								padding: '0px 15px',
								marginTop: theme.spacing(2),
								width:
									buttonRef && buttonRef.current
										? buttonRef.current.clientWidth
										: '100%',
							}}
						>
							<List>
								{TABS.map((tab, index) => (
									<ListItem
										onClick={(e) => {
											setAnchorEl(null);
											handleChange(e, index);
										}}
										key={tab}
									>
										{tab}
									</ListItem>
								))}
							</List>
						</Paper>
					</Popper>
				</Box>
			</ClickAwayListener>

			<TabWrapper
				sx={{
					display: {
						xs: 'none',
						md: 'flex',
					},
				}}
				variant='scrollable'
				scrollButtons='auto'
				currentTab={currentTab}
				handleChange={handleChange}
			>
				<Tab label='Cable payment' />
				<Tab label='Power bill' />
				<Tab label='Education' />
				<Tab label='Internet subscription' />
			</TabWrapper>
		</>
	);
};

export default BillTab;
