import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Pages from 'pages';
import { LINKS } from 'utilities';

const Router = () => {
	return (
		<Routes>
			<Route path={'/'} element={<Pages.Initial />} />
			<Route path={'/auth'}>
				<Route path='login'>
					<Route path={''} element={<Pages.Auth.Login />} />
					<Route
						path={'confirm-otp'}
						element={<Pages.Auth.ConfirmLoginOtp />}
					/>
				</Route>
				<Route path='forgotten-password'>
					<Route
						path={'request-otp'}
						element={<Pages.Auth.RequestForgottenPasswordOtp />}
					/>
					<Route
						path={'confirm-otp'}
						element={<Pages.Auth.ConfirmForgottenPasswordOtp />}
					/>
					<Route path={'reset'} element={<Pages.Auth.AuthResetPassword />} />
				</Route>
				<Route path={'verify-email'} element={<Pages.Auth.VerifyAccount />} />
				<Route path={'register'} element={<Pages.Auth.SignUp />} />
			</Route>
			<Route path={LINKS.Dashboard} element={<Pages.Dashboard />} />
			<Route path={LINKS.Notifications} element={<Pages.Notifications />} />
			{/* Services */}
			<Route path={LINKS.services}>
				<Route path={''} element={<Pages.Services.Initial />} />
				<Route
					path={'purchase-airtime'}
					element={<Pages.Services.PurchaseAirtime />}
				/>
				<Route
					path={'fund-betting-account'}
					element={<Pages.Services.FundBettingAccount />}
				/>
				<Route path={'convert-airtime'}>
					<Route path={''} element={<Pages.Services.ConvertAirtime.Index />} />
					<Route
						path={'manual'}
						element={<Pages.Services.ConvertAirtime.ManualConvertAirtime />}
					/>
					<Route path={'instant'}>
						<Route
							path={''}
							element={<Pages.Services.ConvertAirtime.InstantConvertAirtime />}
						/>
						<Route
							path={'request-otp'}
							element={
								<Pages.Services.ConvertAirtime.RequestAirtimeConvertOtp />
							}
						/>
						<Route
							path={'confirm-otp'}
							element={
								<Pages.Services.ConvertAirtime.ConfirmAirtimeConvertOtp />
							}
						/>
					</Route>
				</Route>
				<Route
					path={'purchase-data'}
					element={<Pages.Services.PurchaseData />}
				/>
				<Route path={'bill'} element={<Pages.Services.Bills />} />
				<Route path={'international-airtime-and-data'}>
					<Route
						path={''}
						element={<Pages.Services.InternationalAirtimeData.Index />}
					/>
					<Route
						path={':slug'}
						element={<Pages.Services.InternationalAirtimeData.Purchase />}
					/>
				</Route>
				<Route path={'e-sim'} element={<Pages.Services.ESim />} />
				<Route path={'gift-cards'} element={<Pages.Services.GiftCards />} />
				<Route path={'e-vouchers'} element={<Pages.Services.EVoucher />} />
			</Route>
			<Route path='/transaction-history'>
				<Route path={''} element={<Pages.TransactionHistory.Initial />} />
				<Route
					path={'pending'}
					element={<Pages.TransactionHistory.PendingTransactions />}
				/>
			</Route>

			{/* Epin */}
			<Route path={LINKS.EPin}>
				<Route path={''} element={<Pages.EPin.Initial />} />
				<Route path={'generate'}>
					<Route path={'data'} element={<Pages.EPin.GenerateData />} />
					<Route path={'airtime'} element={<Pages.EPin.GenerateAirtime />} />
					<Route path={'bill'} element={<Pages.EPin.GenerateBills />} />
				</Route>
				<Route path={'view'}>
					<Route path={'airtime'} element={<Pages.EPin.ViewAirtime />} />
					<Route path={'bill'} element={<Pages.EPin.ViewBills />} />
					<Route path={'data'} element={<Pages.EPin.ViewData />} />
				</Route>
				<Route path='load'>
					<Route path={'education'} element={<Pages.EPin.LoadEducation />} />
					<Route path={'airtime'} element={<Pages.EPin.LoadAirtime />} />
					<Route path={'cable'} element={<Pages.EPin.LoadCable />} />
					<Route
						path={'internet-subscription'}
						element={<Pages.EPin.LoadInternetSubscription />}
					/>
					<Route path={'power'} element={<Pages.EPin.LoadPower />} />
					<Route path={'data'} element={<Pages.EPin.LoadData />} />
				</Route>
			</Route>
			{/* Settings */}
			<Route path={LINKS.Settings}>
				<Route path={''} element={<Pages.Settings.Initial />} />
				<Route
					path={'reset-password'}
					element={<Pages.Settings.ResetPassword />}
				/>
				<Route path={'verify'}>
					<Route path={'bvn'} element={<Pages.Settings.BvnVerification />} />
					<Route
						path={'identity'}
						element={<Pages.Settings.LevelTwoVerification />}
					/>
					<Route
						path={'id_card'}
						element={<Pages.Settings.BvnVerification />}
					/>
				</Route>
				<Route path={'reset-pin'} element={<Pages.Settings.ResetPin />} />
				<Route path={'bank/add'} element={<Pages.Settings.AddBank />} />
				<Route
					path={'confirm/two-factor-auth-token'}
					element={<Pages.Settings.ConfirmTwoFactorAuthToken />}
				/>
				<Route
					path={'validate/identity-card'}
					element={<Pages.Settings.ValidateIdentityCard />}
				/>
			</Route>

			<Route path={LINKS.GetHelp} element={<Pages.GetHelpOrFaqs />} />
			<Route path={LINKS.Referral} element={<Pages.Referral />} />
			<Route
				path={LINKS.REFERRAL_HISTORY}
				element={<Pages.REFERRAL_HISTORY />}
			/>
			<Route path='/support-ticket'>
				<Route path={''} element={<Pages.SupportTicket />} />
				<Route path={'create'} element={<Pages.CreateSupportTicket />} />
				<Route path={'message/:id'} element={<Pages.Message />} />
			</Route>
			<Route path={'/wallet'}>
				<Route path={'top-up'} element={<Pages.Wallet.TopupWallet />} />
				<Route path={'withdraw'} element={<Pages.Wallet.Withdraw />} />
				<Route path={'transfer'} element={<Pages.Wallet.Transfer />} />
			</Route>
			<Route path={'*'} element={<Pages.NotFound />} />
		</Routes>
	);
};

export default Router;
