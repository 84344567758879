import React, { ReactNode } from 'react';
import { Tabs, useTheme, TabsProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useAppSelector } from 'store/hooks';
import { DARK_BACKGROUND_COLOR, LIGHT_GRAY, THEME_MODE } from 'utilities';

interface Props extends TabsProps {
	currentTab: string | number;
	handleChange: (e: any, value: number) => void;
	children: ReactNode;
}

const TabWrapper = ({ currentTab, handleChange, children, ...rest }: Props) => {
	const theme = useTheme();
	const { mode } = useAppSelector((store) => store.theme);
	return (
		<Tabs
			style={{
				overflow: 'auto !important',
			}}
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				borderBottom: `1px solid ${LIGHT_GRAY}`,
				width: '100%',
				position: 'sticky',
				top: '5rem',

				backgroundColor:
					mode === THEME_MODE.dark ? DARK_BACKGROUND_COLOR : grey[50],
				zIndex: '8',
				marginBottom: theme.spacing(4),
				'& .MuiTabs-scroller': {
					overflow: 'auto',
				},
				'& .MuiButtonBase-root': {
					flex: 1,
					color:
						mode === THEME_MODE.dark ? grey[300] : theme.palette.primary.main,
					whiteSpace: 'nowrap',
				},
				'& .MuiButtonBase-root.Mui-selected': {
					color: theme.palette.secondary.main,
					fontWeight: '600',
				},
				'& .MuiTabs-indicator': {
					backgroundColor: theme.palette.secondary.main,
				},
				...rest.sx,
			}}
			value={currentTab}
			onChange={handleChange}
		>
			{children}
		</Tabs>
	);
};

export default TabWrapper;
