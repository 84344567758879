import React, { useEffect, useState } from 'react';
import { grey } from '@mui/material/colors';
import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Button from '../button';
import Select from '../form-components/select';
import { ENDPOINT_SUBPATH, ValidationSchema } from 'utilities';
import { useAppSelector } from 'store/hooks';
import {
	API_ENDPOINTS,
	IProvider,
	ICablePlan,
	AvailablePricingOption,
	QUERY_KEYS,
	EPIN_SERVICES,
	formatNumberToCurrency,
} from 'utilities';
import {
	useAlert,
	useVerifyPin,
	useModalAlert,
	useHandleError,
	useCheckKycLevelTwo,
} from 'hooks';
import { billBundles, billProviders, generateEPin } from 'api';
import CopyPin from 'components/copy-items';

const SELECT_TV_PROVIDER = 'Select tv provider';
const SELECT_PLAN = 'Select subscription plan';
const SELECT_DURATION = 'Select subscription duration';

const DAILY_WEEKLY = ['weekly', 'daily'];

interface Props {
	enableRequest?: boolean;
}

const GenerateCablePaymentForm = ({ enableRequest }: Props) => {
	const verifyPin = useVerifyPin();
	const handleError = useHandleError();
	const modal = useModalAlert();
	const alert = useAlert();
	const theme = useTheme();
	const styles = useStyles(theme);
	const queryClient = useQueryClient();
	const checkKycLevel = useCheckKycLevelTwo();

	const [isLoadCablePlan, setLoadCablePlan] = useState<boolean>(false);
	const [selectedPlan, setSelectedPlan] = useState<null | ICablePlan>(null);

	const { token } = useAppSelector((store) => store.auth);

	// Load TV Provider

	const { isLoading: isLoadingCableProvider, data: dataCableProviders } =
		useQuery(
			['Generate-Cable-providers'],
			() =>
				billProviders(`${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.CableProvider}`),
			{
				enabled: !!(token && enableRequest),
				onSettled: (data, error) => {
					if (error) {
						const response = handleError({ error });
						if (response?.message) {
							alert({
								message: response.message,
								type: 'error',
							});
						}
					}
				},
			}
		);

	const initialValues = {
		service_type: SELECT_TV_PROVIDER,
		product_code: SELECT_PLAN,
		price: 0,
		monthsPaidFor: SELECT_DURATION,
	};

	const {
		handleChange,
		handleSubmit,
		errors,
		touched,
		setFieldValue,
		values,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema: ValidationSchema.GenerateCablePin,
		onSubmit: (values) => {
			const data = {
				service: EPIN_SERVICES.CABLE,
				pin_data: {
					service_type: values.service_type,
					product_code: values.product_code,
					price: values.price,
					monthsPaidFor: parseInt(values.monthsPaidFor),
				},
			};

			if (checkKycLevel()) {
				verifyPin({
					title: 'Cable Subscription E-Pin',
					message: `Verify transaction pin to confirm E-Pin`,
					callback: () => {
						verifyPin(null);
						mutateGenerateEPin(data);
					},
				});
			}
		},
	});

	const { service_type, product_code, monthsPaidFor } = values;

	// Load Cable Plan
	const { isLoading: isLoadingCablePlan, data: dataCablePlans } = useQuery(
		['Generate-Cable-plans'],
		() =>
			billBundles({
				url: `${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.CableProvider}`,
				params: {
					provider: service_type,
				},
			}),
		{
			enabled: !!(
				token &&
				isLoadCablePlan &&
				service_type !== SELECT_TV_PROVIDER
			),
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				setLoadCablePlan(false);
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						alert({
							message: response.message,
							type: 'error',
						});
					}
				}
			},
		}
	);

	// Generate IEPin

	const { isLoading: isGeneratingEPin, mutate: mutateGenerateEPin } =
		useMutation(generateEPin, {
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						alert({ message: response.message, type: 'error' });
					}
				}

				if (data && data.success) {
					resetForm();
					queryClient.invalidateQueries(QUERY_KEYS.UserWallet);
					queryClient.invalidateQueries(QUERY_KEYS.Transactions);
					queryClient.invalidateQueries([QUERY_KEYS.RecentTransactions]);
					const pin = data.payload.pin;
					/* 
						!TODO
						*Set data pin
						pin: data.payload.pin,

					*/
					modal({
						title: 'Cable Subscription E-Pin',
						message: data.message,
						type: 'success',
						children: <CopyPin text={pin} />,
						primaryButtonText: 'Generate another',
						secondaryButtonText: 'Close',
						onClickPrimaryButton: () => {
							modal(null);
						},
						onClickSecondaryButton: () => {
							modal(null);
						},
					});
				}
			},
		});

	// Load Cable plans
	useEffect(() => {
		if (service_type !== SELECT_TV_PROVIDER) {
			// Reset Select Field
			setFieldValue('product_code', SELECT_PLAN);
			setFieldValue('monthsPaidFor', SELECT_DURATION);

			// Enable load plan
			setLoadCablePlan(true);
		}
	}, [service_type, setFieldValue]);

	useEffect(() => {
		if (product_code !== SELECT_PLAN) {
			setFieldValue('monthsPaidFor', SELECT_DURATION);
			if (dataCablePlans) {
				const _selectedPlan = dataCablePlans.payload.find(
					(cablePlan: ICablePlan) => cablePlan.code === product_code
				);
				if (_selectedPlan) {
					setSelectedPlan(_selectedPlan);
				}
			}
		}
	}, [product_code, dataCablePlans, setFieldValue]);

	useEffect(() => {
		if (monthsPaidFor !== SELECT_DURATION && selectedPlan) {
			const _filterOption = selectedPlan.availablePricingOptions.find(
				(option: AvailablePricingOption) =>
					option.monthsPaidFor === parseInt(monthsPaidFor)
			);

			if (_filterOption) {
				setFieldValue('price', _filterOption?.price);
			}
		}
	}, [monthsPaidFor, setFieldValue, selectedPlan]);

	return (
		<>
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						TV service
					</Typography>
					<Select
						fullWidth
						error={touched.service_type && errors.service_type ? true : false}
						helpertext={touched.service_type && errors.service_type}
						value={service_type}
						onChange={handleChange('service_type') as never}
					>
						<MenuItem disabled value={SELECT_TV_PROVIDER}>
							{SELECT_TV_PROVIDER}
						</MenuItem>
						{isLoadingCableProvider ? (
							<MenuItem disabled value={'loading'}>
								Loading...
							</MenuItem>
						) : dataCableProviders && dataCableProviders.payload ? (
							dataCableProviders.payload.map((cableProvider: IProvider) => (
								<MenuItem
									key={cableProvider.productid}
									value={cableProvider.service_type}
								>
									{cableProvider.name}
								</MenuItem>
							))
						) : (
							<MenuItem>No Tv provider</MenuItem>
						)}
					</Select>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Subscription plan
					</Typography>
					<Select
						fullWidth
						error={touched.product_code && errors.product_code ? true : false}
						helpertext={touched.product_code && errors.product_code}
						value={product_code}
						onChange={handleChange('product_code') as never}
					>
						<MenuItem disabled value={SELECT_PLAN}>
							{SELECT_PLAN}
						</MenuItem>
						{isLoadingCablePlan ? (
							<MenuItem disabled value={'loading'}>
								Loading...
							</MenuItem>
						) : dataCablePlans && dataCablePlans.payload ? (
							dataCablePlans.payload.map((cablePlan: ICablePlan) => (
								<MenuItem key={cablePlan.code} value={cablePlan.code}>
									{cablePlan.name}
								</MenuItem>
							))
						) : (
							<MenuItem disabled>
								{service_type === SELECT_TV_PROVIDER
									? 'Select a TV provider'
									: 'No Cable provider'}
							</MenuItem>
						)}
					</Select>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						No. of month/week
					</Typography>
					<Select
						fullWidth
						error={touched.monthsPaidFor && errors.monthsPaidFor ? true : false}
						helpertext={touched.monthsPaidFor && errors.monthsPaidFor}
						value={monthsPaidFor}
						onChange={handleChange('monthsPaidFor') as never}
					>
						<MenuItem disabled value={SELECT_DURATION}>
							{SELECT_DURATION}
						</MenuItem>
						{selectedPlan ? (
							selectedPlan.availablePricingOptions.map(
								(option: AvailablePricingOption, key: number) => {
									let packageDuration = '';
									DAILY_WEEKLY.forEach((duration) => {
										const regExp = new RegExp(duration, 'ig');

										if (regExp.test(selectedPlan.name)) {
											packageDuration = duration;
										}
									});

									return (
										<MenuItem key={key} value={option.monthsPaidFor}>
											{packageDuration
												? `${packageDuration}(${option.monthsPaidFor})`
												: `monthly (${option.monthsPaidFor})`}{' '}
											{'  '} for {'  '}
											{formatNumberToCurrency(option.price)}
										</MenuItem>
									);
								}
							)
						) : (
							<MenuItem disabled>Select a cable plan</MenuItem>
						)}
					</Select>
				</Box>

				<Button
					loading={isGeneratingEPin}
					size={'large'}
					style={styles.btn}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Generate e-Pin
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default GenerateCablePaymentForm;
