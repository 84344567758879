import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
	Button,
	Box,
	Typography,
	CircularProgress,
	ListItem,
	List,
	IconButton,
	useTheme,
} from '@mui/material';
import { Delete, Close } from '@mui/icons-material';
import { grey, red } from '@mui/material/colors';
import { useAlert, useModal, useQueryBeneficiaries } from 'hooks';
import { BENEFICIARY_TYPE, PRIMARY_COLOR, QUERY_KEYS } from 'utilities';
import { deleteBeneficiary } from 'api';

interface ISelectBeneficiary {
	type: string;
	onSelect?: (beneficiary: any) => void;
}

const SelectBeneficiary: React.FC<ISelectBeneficiary> = ({
	type,
	onSelect,
}) => {
	const queryClient = useQueryClient();
	const alert = useAlert();
	const theme = useTheme();
	const [isDisplay, setDisplay] = useState<boolean>(false);

	const { isLoadingBeneficiaries, beneficiaries } = useQueryBeneficiaries({
		queryKey: type,
	});

	const closeModal = () => setDisplay(false);

	const handleSelect = (beneficiary) => {
		typeof onSelect === 'function' && onSelect(beneficiary);
		closeModal();
	};

	const { mutate, isLoading } = useMutation(deleteBeneficiary, {
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.Beneficiaries, type]);
			alert({ message: 'Beneficiary deleted', type: 'success' });
		},
	});

	const handleDelete = (id: string) => {
		mutate(id);
	};

	const modal = (
		<Box
			sx={{
				position: 'fixed',
				top: '0px',
				left: '0px',
				height: '100vh',
				width: '100%',
				backgroundColor: 'rgba(40, 83, 107, 0.7)',
				zIndex: theme.zIndex.modal,
				overflow: 'auto',
				padding: '15px',
			}}
		>
			<Box
				sx={{
					maxWidth: '480px',
					margin: '10rem auto',
					width: '100%',
					backgroundColor: 'background.paper',
					padding: { xs: '15px 20px', sm: '2rem', md: '3.5rem' },
					borderRadius: '5px',
					position: 'relative',
				}}
			>
				<IconButton
					onClick={closeModal}
					size={'small'}
					sx={{
						position: 'absolute',
						top: '-45px',
						right: '0px',
						backgroundColor: `${grey['400']} !important`,
						border: `1.5px solid ${grey['50']}`,
						color: theme.palette.primary.main,
					}}
				>
					<Close />
				</IconButton>

				<Box>
					{isLoadingBeneficiaries ? (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						beneficiaries && (
							<>
								{beneficiaries.length > 0 ? (
									<Box>
										<Typography
											sx={{ fontWeight: 'bold', marginBottom: '10px' }}
										>
											Select Beneficiary
										</Typography>
										<List
											sx={{
												display: 'grid',
												gap: '6px',
											}}
										>
											{beneficiaries.map(
												(beneficiary: { [key: string]: any }) => {
													const transactionType =
														beneficiary.record.transactionType;
													const type = beneficiary.type;
													const provider =
														beneficiary.record.provider &&
														(beneficiary.record.provider?.name ||
															beneficiary.record.provider?.shortname);

													let display = '';

													switch (type) {
														case BENEFICIARY_TYPE.AIRTIME:
															display = beneficiary.record?.phone_number;
															break;

														case BENEFICIARY_TYPE.DATA:
															display = beneficiary.record?.number;
															break;

														case BENEFICIARY_TYPE.ELECTRICITY:
															display = beneficiary.record?.meter_number;
															break;

														case BENEFICIARY_TYPE.CABLE:
															display = beneficiary.record?.smartcard_number;
															break;

														default:
															break;
													}

													return (
														<ListItem
															onClick={() => handleSelect(beneficiary)}
															sx={{
																border: `1px solid ${PRIMARY_COLOR}`,
																borderRadius: '6px',
																cursor: 'pointer',
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'space-between',
																padding: '4px',
																paddingLeft: '8px',
															}}
															key={beneficiary.id}
														>
															<Box>
																<span>
																	{transactionType} -{' '}
																	{display && `(${display})`}
																</span>
																<span>{provider}</span>
															</Box>
															<IconButton
																size={'small'}
																sx={{
																	color: red['600'],
																}}
																onClick={(e) => {
																	e.stopPropagation();
																	handleDelete(beneficiary.id);
																}}
															>
																<Delete />
															</IconButton>
														</ListItem>
													);
												}
											)}
										</List>
									</Box>
								) : (
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Typography>No available Beneficiary</Typography>
									</Box>
								)}
							</>
						)
					)}
				</Box>
			</Box>
		</Box>
	);

	return (
		<>
			{isDisplay && modal}
			<Button onClick={() => setDisplay(true)}>Select Beneficiary</Button>
		</>
	);
};

export default SelectBeneficiary;
