import React from 'react';

type Props = {
	color?: string;
	size?: number;
	innerColor?: string;
};

const IEPin
 = ({ size, color, innerColor }: Props) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M21.8983 24H2.10179C1.7462 24 1.45801 23.7118 1.45801 23.3562V0.643781C1.45796 0.288234 1.7462 0 2.10179 0H21.8983C22.2539 0 22.5421 0.288234 22.5421 0.643781V12.4721C22.5421 12.8277 22.2539 13.1159 21.8983 13.1159C21.5428 13.1159 21.2545 12.8277 21.2545 12.4721V1.28761H2.74557V22.7124H21.2545V16.3778C21.2545 16.0222 21.5427 15.734 21.8983 15.734C22.2538 15.734 22.5421 16.0223 22.5421 16.3778V23.3562C22.5422 23.7118 22.2539 24 21.8983 24Z'
				fill={color}
			/>
			<path
				d='M19.0816 3.15308H10.0684V5.70687H19.0816V3.15308Z'
				fill={innerColor || '#DCE3E7'}
			/>
			<path
				d='M19.0819 6.35059H10.0686C9.71299 6.35059 9.4248 6.06236 9.4248 5.70681V3.15306C9.4248 2.79746 9.71304 2.50928 10.0686 2.50928H19.0819C19.4374 2.50928 19.7256 2.79751 19.7256 3.15306V5.70686C19.7256 6.06236 19.4374 6.35059 19.0819 6.35059ZM10.7124 5.06298H18.438V3.79689H10.7124V5.06298Z'
				fill={color}
			/>
			<path
				d='M19.0816 8.19946H10.0684V10.7533H19.0816V8.19946Z'
				fill={innerColor || '#DCE3E7'}
			/>
			<path
				d='M19.0819 11.3973H10.0686C9.71299 11.3973 9.4248 11.109 9.4248 10.7535V8.19969C9.4248 7.8441 9.71304 7.55591 10.0686 7.55591H19.0819C19.4374 7.55591 19.7256 7.84414 19.7256 8.19969V10.7535C19.7256 11.109 19.4374 11.3973 19.0819 11.3973ZM10.7124 10.1097H18.438V8.84347H10.7124V10.1097Z'
				fill={color}
			/>
			<path d='M19.0816 13.2462H10.0684V15.8H19.0816V13.2462Z' fill='#DCE3E7' />
			<path
				d='M19.0819 16.444H10.0686C9.71299 16.444 9.4248 16.1558 9.4248 15.8002V13.2464C9.4248 12.8908 9.71304 12.6027 10.0686 12.6027H19.0819C19.4374 12.6027 19.7256 12.8909 19.7256 13.2464V15.8002C19.7256 16.1558 19.4374 16.444 19.0819 16.444ZM10.7124 15.1564H18.438V13.8902H10.7124V15.1564Z'
				fill={color}
			/>
			<path
				d='M19.0819 21.4907H10.0686C9.71299 21.4907 9.4248 21.2025 9.4248 20.8469C9.4248 20.4914 9.71304 20.2032 10.0686 20.2032H18.438V18.937H10.0686C9.71299 18.937 9.4248 18.6487 9.4248 18.2932C9.4248 17.9376 9.71304 17.6494 10.0686 17.6494H19.0819C19.4374 17.6494 19.7256 17.9376 19.7256 18.2932V20.847C19.7256 21.2025 19.4374 21.4907 19.0819 21.4907Z'
				fill={color}
			/>
			<path
				d='M7.49341 6.35059H4.9182C4.5626 6.35059 4.27441 6.06236 4.27441 5.70681V3.15306C4.27441 2.79746 4.56265 2.50928 4.9182 2.50928H7.49341C7.84901 2.50928 8.1372 2.79751 8.1372 3.15306V5.70686C8.1372 6.06236 7.84896 6.35059 7.49341 6.35059ZM5.56198 5.06298H6.84959V3.79689H5.56198V5.06298Z'
				fill={color}
			/>
			<path
				d='M7.49341 11.3973H4.9182C4.5626 11.3973 4.27441 11.109 4.27441 10.7535V8.19969C4.27441 7.8441 4.56265 7.55591 4.9182 7.55591H7.49341C7.84901 7.55591 8.1372 7.84414 8.1372 8.19969V10.7535C8.1372 11.109 7.84896 11.3973 7.49341 11.3973ZM5.56198 10.1097H6.84959V8.84347H5.56198V10.1097Z'
				fill={color}
			/>
			<path
				d='M7.49341 16.444H4.9182C4.5626 16.444 4.27441 16.1558 4.27441 15.8002V13.2464C4.27441 12.8908 4.56265 12.6027 4.9182 12.6027H7.49341C7.84901 12.6027 8.1372 12.8909 8.1372 13.2464V15.8002C8.1372 16.1558 7.84896 16.444 7.49341 16.444ZM5.56198 15.1564H6.84959V13.8902H5.56198V15.1564Z'
				fill={color}
			/>
			<path
				d='M7.49319 18.2931H4.91797V20.8469H7.49319V18.2931Z'
				fill={innerColor || '#DCE3E7'}
			/>
			<path
				d='M7.49341 21.4908H4.9182C4.5626 21.4908 4.27441 21.2025 4.27441 20.847V18.2932C4.27441 17.9376 4.56265 17.6494 4.9182 17.6494H7.49341C7.84901 17.6494 8.1372 17.9376 8.1372 18.2932V20.847C8.1372 21.2025 7.84896 21.4908 7.49341 21.4908ZM5.56198 20.2032H6.84959V18.937H5.56198V20.2032Z'
				fill={color}
			/>
		</svg>
	);
};

export default IEPin
;
