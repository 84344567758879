import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STORAGE_KEYS, THEME_MODE, Storage } from 'utilities';

export type ThemeState = {
	mode: string;
};

// Define the initial state using that type
const initialState: ThemeState = {
	mode:
		window !== undefined && Storage.getItem(STORAGE_KEYS.themeMode)
			? Storage.getItem(STORAGE_KEYS.themeMode)
			: THEME_MODE.light,
};

export const counterSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		setTheme: (state, action: PayloadAction<string>) => {
			Storage.saveItem(STORAGE_KEYS.themeMode, action.payload);
			state.mode = action.payload;
		},
	},
});

export const { setTheme } = counterSlice.actions;

export default counterSlice.reducer;
