import React, { useState } from 'react';
import { useTheme, Typography, Box } from '@mui/material';
import AccordionWrapper from '../accordion';
import { FAQS } from 'utilities';
import { useAppSelector } from 'store/hooks';

const Faqs = () => {
	const theme = useTheme();
	const { mode } = useAppSelector((store) => store.theme);
	const styles = useStyles(theme, mode);

	const [selectedPanel, setSelectPanel] = useState<string>('');

	const handleChange = (panel: string) => {
		selectedPanel === panel ? setSelectPanel('') : setSelectPanel(panel);
	};

	return (
		<Box sx={{ flex: 7 }}>
			<Typography
				sx={{ marginBottom: theme.spacing(3) }}
				style={styles.title}
				variant={'h5'}
			>
				Fequently asked questions
			</Typography>
			{FAQS.slice(0, FAQS.length - 1).map((data, index) => (
				<AccordionWrapper
					key={data.question}
					selected={selectedPanel}
					accordion={data.question}
					handleChange={() => handleChange(data.question)}
					summary={
						<>
							<Typography style={styles.numberText}>0{index + 1}</Typography>
							<Typography style={styles.title}>{data.question}</Typography>
						</>
					}
				>
					<Typography variant={'body1'}>{data.answer}</Typography>
				</AccordionWrapper>
			))}
		</Box>
	);
};

const useStyles = (theme: any, mode: string) => ({
	numberText: {
		marginRight: theme.spacing(3),
		fontWeight: '600',
	},
	title: {
		fontWeight: '600',
		// color: theme.palette.primary.main,
	},
	textWrapper: {
		borderLeft: `3px solid ${theme.palette.secondary.main}`,
		paddingLeft: theme.spacing(3),
		margin: `${theme.spacing(4)} 0px`,
		color: theme.palette.primary.main,
	},
});

export default Faqs;
