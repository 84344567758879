import React, { CSSProperties, ReactNode } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Typography, useTheme, List } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import {
	formatNumberToCurrency,
	DANGER_COLOR,
	SUCCESS_COLOR,
	PRIMARY_COLOR,
	KYC_LEVEL,
	LINKS,
} from 'utilities';
import { useModalAlert } from 'hooks';

type LimitProps = {
	amount: number;
	text: string;
};

type Props = {
	isActive?: boolean;
	disabled?: boolean;
	level: number;
	children: ReactNode;
	data: { [key: string]: any };
	btnText?: string;
	button?: any;
	productCode?: string;
	className?: string;
};

const LimitDetails = ({ amount, text }: LimitProps) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	return (
		<Typography variant={'body1'}>
			<Box style={styles.labelText} component={'span'}>
				{text}
			</Box>
			<Box style={styles.amountText} component={'span'}>
				{amount && formatNumberToCurrency(amount)}
			</Box>
		</Typography>
	);
};

const ManageLimitItem = ({
	isActive = false,
	level,
	children,
	data,
	btnText = 'Upgrade',
	disabled,
	className,
}: Props) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const navigate = useNavigate();
	const { pathname, search } = useLocation();
	const modal = useModalAlert();

	const redirectLink = `${pathname}${search}`;

	const handleUpgrade = () => {
		modal(null);

		if (level === KYC_LEVEL.levelThree) {
			// widgetOkra();
			navigate(`${LINKS.BvnVerification}?redirect=${redirectLink}`, {
				state: {
					redirect: redirectLink,
				},
			});
			return;
		}

		if (level === KYC_LEVEL.LevelTwo) {
			navigate(`${LINKS.LevelTwoVerification}?redirect=${redirectLink}`, {
				state: {
					redirect: redirectLink,
				},
			});
		}
	};

	return (
		<>
			<Box
				sx={{
					minHeight: {
						xs: '400px',
						md: '480px',
					},
				}}
				style={styles.main as CSSProperties}
			>
				<Box style={styles.topContent}>
					<Box
						sx={{
							backgroundColor: isActive
								? '#FCE2C0 !important'
								: '#D7E8F9 !important',
							width: '100%',
							padding: ['8px 15px', '8px 20px'],
							userSelect: 'none',
							borderRadius: '20px',
							color: `${PRIMARY_COLOR} !important`,
						}}
						// style={styles.limitBox}
					>
						Level {level}
					</Box>
					<Box>
						<Typography variant={'body1'} style={styles.boldText}>
							Account Limit
						</Typography>
						<Box style={styles.dGrid}>
							<LimitDetails
								text={'Daily limit'}
								amount={data && data.dailyLimit}
							/>
							<LimitDetails
								text={'Weekly limit'}
								amount={data && data.weeklyLimit}
							/>
							<LimitDetails
								text={'Monthly limit'}
								amount={data && data.monthlyLimit}
							/>
						</Box>
					</Box>
					<List style={styles.children}>{children}</List>
				</Box>

				<Button
					className={className}
					onClick={() => handleUpgrade()}
					disabled={disabled}
					startIcon={isActive && <CheckCircle />}
					size={'large'}
					style={styles.upgradeButton as CSSProperties}
					sx={{
						backgroundColor: isActive
							? '#D3FFE2 !important'
							: `${theme.palette.secondary.main} !important`,
						color: isActive ? SUCCESS_COLOR : grey[50],
					}}
				>
					{btnText}
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	main: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		gap: '2rem',
	},
	boldText: {
		fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	topContent: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '2rem',
	},
	dGrid: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '8px',
	},
	labelText: {
		color: theme.palette.secondary.main,
		marginRight: theme.spacing(2),
	},
	amountText: {
		fontWeight: '600',
	},
	limitButton: {
		fontWeight: '600',
		borderRadius: '2rem',
		maxWidth: '85%',
	},
	upgradeButton: {
		borderRadius: '0px',
		paddingTop: '18px',
		paddingBottom: '18px',
		fontWeight: '600',
	},
	children: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '1rem',
		padding: '0px',
	},
	title: {
		marginBottom: theme.spacing(2),
	},
	verifyButton: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	cancelButton: {
		backgroundColor: DANGER_COLOR,
		color: grey[50],
		fontWeight: '600',
	},
});

export default ManageLimitItem;
