import { useState } from 'react';
import { validateBillCard } from 'api';
import useHandleError from './useHandleError';
import useToastAlert from './useToastAlert';
import { IVerifiedBill, VerifyBillPayload } from 'utilities';

const useVerifyBillAccount = () => {
	const alert = useToastAlert();
	const handleError = useHandleError();
	const [billAccount, setBillAccount] = useState<null | IVerifiedBill>(null);
	const [isVerifyingBillAccount, setVerifyingBill] = useState<boolean>(false);

	const verifyBillAccount = async ({
		service_type,
		account_number,
	}: VerifyBillPayload) => {
		console.log(account_number);
		if (!account_number) {
			alert({ message: 'Enter bill number', type: 'info' });
		}
		// const account_number$ = account_number && account_number.trim();

		let payload: VerifyBillPayload = {
			service_type,
			account_number: `${account_number}`,
		};

		// payload.account_number = account_number$;
		setVerifyingBill(true);
		try {
			const data = await validateBillCard(payload);
			if (data && data.success) {
				const bill = data.payload;
				if ('payload' in bill) {
					setBillAccount(bill.payload);
				} else {
					setBillAccount(bill);
				}
			}
		} catch (error) {
			const response = handleError({ error });
			if (response?.message)
				alert({ message: response.message, type: 'error' });
		}
		setVerifyingBill(false);
	};

	const clearBillAccount = () => setBillAccount(null);

	return {
		verifyBillAccount,
		isVerifyingBillAccount,
		billAccount,
		clearBillAccount,
	};
};

export default useVerifyBillAccount;
