import React from 'react';
import {
	Box,
	useTheme,
	Card,
	styled,
	Typography,
	IconButton,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { Close, Info } from '@mui/icons-material';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setNotificationAlert } from 'store/notification';
import { ALERT_TYPE, removeHtmlTags, truncateText } from 'utilities';

const ToastAlert = () => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const { alert } = useAppSelector((store) => store.notification);

	const closeAlert = () => dispatch(setNotificationAlert(null));

	if (alert && alert.type === ALERT_TYPE.TOAST) {
		const regExp = new RegExp('down', 'ig');

		const isDown = regExp.test(alert.message);

		return (
			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<AlertContainer bgColor={isDown ? red['700'] : green['700']}>
					<Box
						sx={{
							position: 'relative',
							display: 'flex',
							// alignItems: 'center',
							gap: '10px',
						}}
					>
						<Info sx={{ color: 'white' }} />
						<Box>
							<Typography
								sx={{ fontWeight: 'bold', color: 'white' }}
								variant={'body1'}
							>
								{alert?.subject}
							</Typography>
							<Box
								sx={{
									color: 'white !important',
									fontSize: '13px',
									'& p': {
										margin: '0px !important',
										fontSize: '13px',
									},
								}}
							>
								<div
									style={{
										margin: '0px !important',
										color: 'white !important',
									}}
									dangerouslySetInnerHTML={{
										__html: truncateText(removeHtmlTags(alert?.message), 50),
									}}
								/>
							</Box>
						</Box>
						<IconButton
							onClick={closeAlert}
							size={'small'}
							sx={{
								position: 'absolute',
								right: '-8px',
								top: '-8px',
								color: 'white',
							}}
						>
							<Close
								sx={{
									fontSize: '16px',
								}}
							/>
						</IconButton>
					</Box>
				</AlertContainer>
			</Box>
		);
	}

	return null;
};

const AlertContainer = styled(Card)<{ bgColor?: string }>(
	({ theme, bgColor }) => ({
		boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
		backgroundColor: bgColor,
		padding: theme.spacing(3),
		borderRadius: theme.spacing(2),
		maxWidth: '320px',
		width: '100%',
		marginBottom: theme.spacing(3),
	})
);

export default ToastAlert;
