import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { Box, FormHelperText, Typography, useTheme } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { DARK_CARD_COLOR, THEME_MODE } from 'utilities';
import { useAppSelector } from 'store/hooks';

interface IPhoneTextField extends PhoneInputProps {
	value: string;
	onChange?: (value: string) => void;
	country?: string;
	error?: boolean;
	helperText?: string | boolean;
	labelText?: string;
}

const PhoneTextField = ({
	value,
	onChange,
	country = 'ng',
	helperText,
	error,
	labelText,
	...rest
}: IPhoneTextField) => {
	const theme = useTheme();
	const mode = useAppSelector((store) => store.theme.mode);

	return (
		<Box>
			{labelText && (
				<Typography
					variant={'body1'}
					component={'label'}
					sx={{
						marginBottom: theme.spacing(2),
						display: 'inline-block',
						fontWeight: '600',
					}}
				>
					{labelText}
				</Typography>
			)}
			<Box
				sx={{
					'.special-label': {
						display: 'none !important',
					},
					'.form-control': {
						width: '100% !important',
						paddingTop: '16.5px',
						paddingBottom: '16.5px',
						color: mode === THEME_MODE.dark ? 'white' : grey['600'],
						borderColor: error
							? `${red['700']} !important`
							: mode === THEME_MODE.dark
							? `${grey['500']} !important`
							: `${grey[500]}`,
						outlineColor: error
							? `${red['700']}`
							: mode === THEME_MODE.dark
							? `${grey['500']}`
							: `${grey[500]}`,
						background:
							mode === THEME_MODE.dark
								? `${DARK_CARD_COLOR} !important`
								: 'initial',
					},
					'.form-control:focus': {
						borderColor: error
							? `${red['700']} !important`
							: `${theme.palette.primary.main} !important`,
						outlineColor: error
							? `${red['700']} !important`
							: `${theme.palette.primary.main} !important`,
					},
					'.form-control:hover': {
						borderColor:
							mode === THEME_MODE.dark
								? `white !important`
								: theme.palette.primary.main,
					},
				}}
			>
				<PhoneInput
					containerStyle={{
						padding: '0px',
						width: '100%',
					}}
					{...rest}
					country={country}
					value={value}
					onChange={onChange}
				/>
				{helperText && (
					<FormHelperText
						sx={{
							margin: '3px 14px 0px',
						}}
						error={error}
					>
						{helperText}
					</FormHelperText>
				)}
			</Box>
		</Box>
	);
};

export default PhoneTextField;
