import React, { ChangeEvent, useEffect, useState } from 'react';
import {
	Box,
	InputAdornment,
	Typography,
	useTheme,
	// InputAdornment,
} from '@mui/material';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { grey } from '@mui/material/colors';
import QRCode from 'react-qr-code';
import Button from '../button';
import { purchaseESim, eSimProducts, eSimProviders, IESimPurchase } from 'api';
import {
	QUERY_KEYS,
	IProvider,
	Product,
	formatNumberToCurrency,
	THEME_MODE,
	LIGHT_GRAY,
	capitalize,
	SOCHITEL_LINKS,
	calculateDiscountedAmount,
} from 'utilities';
import { setTransactionBreakdown } from 'store/transaction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
	useModalAlert,
	useHandleError,
	useVerifyPin,
	useVerifyCoupon,
	useAlert,
} from 'hooks';
import CopyToClipboard from 'components/copy-to-clipboard';
import CustomSelect from 'components/form-components/custom-select';
import CustomSelectWithImage from 'components/form-components/custom-select-with-image';
import TextInput from 'components/form-components/text-input';

const SELECT_PROVIDER = 'Select provider';
const SELECT_PACKAGE = 'Select package';

interface IESimPin {
	pin?: {
		number: string;
		serial: string;
		instructions: string;
	};
}

const MAX_VALUE_LENGTH = 30;

const ESimPin = ({ pin }: IESimPin) => {
	const theme = useTheme();
	const mode = useAppSelector((store) => store.theme.mode);
	const isDark = mode === THEME_MODE.dark;

	if (pin) {
		return (
			<Box
				sx={{
					display: 'grid',
					gap: '4px',
				}}
			>
				<Typography>E-Sim Details</Typography>
				{Object.keys(pin).map((pinKey) => {
					const value = pin[pinKey];
					const hasLargerValueLength = value.length > MAX_VALUE_LENGTH;

					const urlRegex = /(https?:\/\/[^ ]*)/;
					const matchUrl = value.match(urlRegex) as any;

					const url = matchUrl ? matchUrl[0] : '';
					const splitPinValue = url && value.split(url);

					return (
						<Box
							sx={{
								display: 'flex',
								backgroundColor: isDark
									? theme.palette.background.paper
									: LIGHT_GRAY,
								padding: '6px 10px',
								alignItems: hasLargerValueLength ? 'flex-start' : 'center',
								justifyContent: 'space-between',
								gap: hasLargerValueLength ? '6' : '15px',

								flexDirection: hasLargerValueLength ? 'column' : 'row',
							}}
							key={pinKey}
						>
							<Typography>{capitalize(pinKey)}</Typography>
							{url && Array.isArray(splitPinValue) ? (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: '6px',
										alignItems: 'center',
									}}
								>
									<Typography sx={{ textAlign: 'center' }}>
										{splitPinValue[0]}
									</Typography>
									<QRCode size={200} value={url} />
									<Typography sx={{ textAlign: 'center' }}>
										{splitPinValue[1]}
									</Typography>
								</Box>
							) : (
								<Box
									sx={{
										display: 'flex',
										gap: '4px',
										alignItems: 'center',
									}}
								>
									<Typography
										sx={{
											flexWrap: 'wrap',
											maxWidth: '320px',
										}}
									>
										{pin[pinKey] || `No available ${pinKey}`}
									</Typography>
									{pinKey === 'number' && (
										<CopyToClipboard text={pin[pinKey]} />
									)}
								</Box>
							)}
						</Box>
					);
				})}
			</Box>
		);
	}

	return null;
};

const ESimForm = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const handleError = useHandleError();
	const modal = useModalAlert();
	const verifyPin = useVerifyPin();
	const alert = useAlert();
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	// Product State

	const [isLoadingDataProducts, setLoadingDataProducts] =
		useState<boolean>(false);

	const [products, setProducts] = useState<Product[] | null>(null);

	const [selectedProduct, setSelectedProduct] = useState<null | Product>(null);

	const isProductPriceRanged = selectedProduct?.priceType === 'range';

	const { coupon, clearCoupon, verifyCoupon, isVerifyingCoupon } =
		useVerifyCoupon();

	useEffect(
		() => {
			if (coupon) {
				dispatch(
					setTransactionBreakdown({
						coupon,
					})
				);
			}
		},
		// eslint-disable-next-line
		[coupon]
	);

	const validationSchema = yup.object().shape({
		operatorId: yup
			.string()
			.notOneOf([SELECT_PROVIDER], SELECT_PROVIDER)
			.required('Network provider is required'),
		productId: yup
			.string()
			.notOneOf([SELECT_PACKAGE], SELECT_PACKAGE)
			.required('Network package is required'),
		amount: yup
			.number()
			.positive('Amount cannot be negative')
			.required('Amount is required'),
	});

	const initialValues = {
		product: '',
		productId: SELECT_PACKAGE,
		operatorId: SELECT_PROVIDER,
		amount: '',
		discount_code: '',
	};

	const { isLoading: isPurchasingESim, mutate: mutatePurchaseESim } =
		useMutation(purchaseESim, {
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						modal({
							title: 'E-Sim Purchase',
							message: response.message,
							hasCloseButton: true,
							type: 'error',
							primaryButtonText: 'Close',
							onClickPrimaryButton: () => modal(null),
						});
					}
				}
				if (data && data.success) {
					queryClient.invalidateQueries([QUERY_KEYS.UserWallet]);
					dispatch(setTransactionBreakdown(null));

					const payload = data.payload;

					resetForm();
					modal({
						title: 'E-Sim Purchase',
						message: `${payload?.operator} of ${
							payload?.product
						} for ${formatNumberToCurrency(payload.amount)} is successful!`,
						type: 'success',
						hasCloseButton: true,
						children: <ESimPin pin={payload.pin} />,
						primaryButtonText: 'Purchase again',
						secondaryButtonText: 'Close',
						onClickPrimaryButton: () => {
							setSelectedProduct(null);
							modal(null);
						},
						onClickSecondaryButton: () => {
							setSelectedProduct(null);
							modal(null);
						},
					});
				}
			},
		});

	const {
		errors,
		values,
		touched,
		handleSubmit,
		setFieldValue,
		resetForm,
		setFieldError,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			const data: { [key: string]: any } = {
				productId: parseFloat(values.productId),
				operatorId: parseFloat(values.operatorId),
				product: values.product,
				// amount: values.amount,
			};

			let amount = parseFloat(values.amount) || 0;

			const price = selectedProduct?.price;

			const currency = selectedProduct?.currency?.operator;

			if (selectedProduct) {
				if (isProductPriceRanged) {
					const minAmount = parseFloat(`${price?.min.operator}`);
					const maxAmount = parseFloat(`${price?.max.operator}`);

					if (amount < minAmount) {
						setFieldError(
							'amount',
							`Amount must be more than ${formatNumberToCurrency(
								minAmount,
								currency
							)}`
						);
						return;
					}
					if (amount > maxAmount) {
						setFieldError(
							'amount',
							`Amount must be less than ${formatNumberToCurrency(
								maxAmount,
								currency
							)}`
						);
						return;
					}

					data.amount = selectedProduct.conversion_rate * amount;
				} else {
					data.amount = amount;
				}
			}

			if (values.discount_code) {
				if (!coupon) {
					return alert({ message: 'Apply discount code', type: 'info' });
				}

				if (coupon && coupon.code !== values.discount_code) {
					return alert({ message: 'Invalid coupon code', type: 'error' });
				}

				data.discount_code = values.discount_code;
				amount = calculateDiscountedAmount({ coupon, amount });
			}

			const callback = () => {
				verifyPin(null);
				mutatePurchaseESim(data as IESimPurchase);
			};
			verifyPin({
				title: 'E-Sim Purchase',
				message: `Verify transaction pin to confirm the purchase of ${formatNumberToCurrency(
					amount,
					currency
				)} worth of ${data.product} e-sim`,
				callback,
			});

			// if (checkKycLevel()) {
			// }
		},
	});

	const { amount, discount_code, operatorId, productId } = values;

	const onChangeCode = (code: string) => {
		clearCoupon();
		setFieldValue('discount_code', code);
	};

	const handleApplyCode = () => {
		if (discount_code) {
			verifyCoupon(discount_code);

			return;
		}

		alert({ message: 'Enter discount code', type: 'info' });
	};

	const { data: dataESimProviders, isLoading: isLoadingESimProviders } =
		useQuery(['e-sim-providers'], eSimProviders);

	// Airtime products
	const getESimProducts = async (providerId: string) => {
		try {
			setLoadingDataProducts(true);
			const response = await eSimProducts(providerId);
			if (response.payload) setProducts(response.payload);
		} catch (error) {
			const response = handleError({ error });
			if (response?.message) {
				alert({
					message: response.message,
					type: 'error',
				});
			}
		} finally {
			setLoadingDataProducts(false);
		}
	};

	// Handlee Select Provider
	const handleSelectProvider = async (operatorId: string) => {
		if (
			dataESimProviders &&
			dataESimProviders.payload &&
			Array.isArray(dataESimProviders.payload)
		) {
			const provider = dataESimProviders.payload.find(
				(provider: IProvider) => provider.id === operatorId
			);
			if (provider) {
				setFieldValue('operatorId', operatorId);
			}
		}
		setFieldValue('productId', SELECT_PACKAGE);
		setFieldValue('amount', '');
		dispatch(
			setTransactionBreakdown({
				serviceCost: 0,
			})
		);
		await getESimProducts(operatorId); // Call products
	};

	const handleSelectProduct = (productId: string): void => {
		dispatch(setTransactionBreakdown(null)); // Clear transaction breakdown
		if (products) {
			const foundProduct = products.find((product) => product.id === productId);
			if (foundProduct) {
				setSelectedProduct(foundProduct);
				setFieldValue('productId', foundProduct.id);
				setFieldValue('product', foundProduct.name);

				if (foundProduct) {
					setSelectedProduct(foundProduct);
					setFieldValue('productId', foundProduct.id);
					setFieldValue('product', foundProduct.name);

					if (foundProduct.priceType === 'fixed') {
						const amount = foundProduct.price.user;
						setFieldValue('amount', amount);
						dispatch(
							setTransactionBreakdown({
								serviceCost: parseFloat(amount),
								currency: foundProduct.currency.user,
							})
						);
					}
				}
			}
		}
	};

	return (
		<Box style={styles.form as any} component={'form'}>
			<CustomSelectWithImage
				isLoading={isLoadingESimProviders}
				disabled={isLoadingESimProviders}
				labelText={SELECT_PROVIDER}
				placeholder={SELECT_PROVIDER}
				error={touched.operatorId && Boolean(errors.operatorId)}
				helpertext={touched.operatorId && errors.operatorId}
				logo={SOCHITEL_LINKS.CountryFlag}
				options={
					dataESimProviders &&
					dataESimProviders.payload &&
					Array.isArray(dataESimProviders.payload)
						? [
								...dataESimProviders.payload.map((data) => ({
									label: data.name,
									value: data.id,
									imageRef: data.country?.id?.toString(),
								})),
						  ]
						: null
				}
				value={operatorId}
				onChange={(value) => handleSelectProvider(value)}
			/>

			<CustomSelect
				disabled={operatorId === SELECT_PROVIDER || isLoadingDataProducts}
				// labelText={SELECT_PACKAGE}

				labelText={
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Typography
							sx={{
								display: 'inline-block',
								fontWeight: '600',
							}}
						>
							{SELECT_PACKAGE}
						</Typography>

						{selectedProduct && selectedProduct?.price?.user && amount && (
							<Typography
								sx={{
									display: ['inline-block', 'inline-block', 'none'],
								}}
							>
								{formatNumberToCurrency(
									parseFloat(selectedProduct?.price?.user).toFixed(2)
								)}
							</Typography>
						)}
					</Box>
				}
				isLoading={isLoadingDataProducts}
				placeholder={SELECT_PACKAGE}
				error={touched.productId && Boolean(errors.productId)}
				helpertext={touched.productId && errors.productId}
				options={
					products
						? [
								{ label: SELECT_PACKAGE, value: '' },
								...products.map((data) => {
									const operator = `${data.currency.operator}${data.price.operator}`;
									const user = data.price.user;
									return {
										label: `${
											data.name
										} - ${operator} (${formatNumberToCurrency(
											user,
											data.currency.user
										)})`,
										value: data.id,
									};
								}),
						  ]
						: null
				}
				value={productId}
				onChange={(value) => handleSelectProduct(value)}
			/>

			<TextInput
				labelText={'	Discount code [optional]'}
				disabled={isVerifyingCoupon}
				value={discount_code}
				onChange={(e: ChangeEvent<HTMLInputElement>) =>
					onChangeCode(e.target.value)
				}
				fullWidth
				placeholder={'Type in discount code here'}
				InputProps={{
					endAdornment: (
						<InputAdornment position='start'>
							<Button
								loading={isVerifyingCoupon}
								onClick={handleApplyCode}
								disableRipple
								style={styles.applyBtn}
							>
								Apply
							</Button>
						</InputAdornment>
					),
				}}
			/>

			<Button
				loading={isPurchasingESim}
				onClick={(e: React.FormEvent<HTMLButtonElement>) => {
					e.preventDefault();
					handleSubmit();
				}}
				size={'large'}
				style={styles.btn}
			>
				Purchase
			</Button>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default ESimForm;
