import React from 'react';

type Props = {
	color?: string;
	size?: number;
	innerColor?: string;
};

const GetHelp = ({ size, color, innerColor }: Props) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M18.7811 21.5564V21.0556C18.7811 17.3261 15.7296 14.2745 12.0001 14.2745C8.27051 14.2745 5.21899 17.3261 5.21899 21.0556V21.5564C5.21899 22.5261 6.00504 23.3122 6.97474 23.3122H17.0254C17.9951 23.3122 18.7811 22.526 18.7811 21.5564Z'
				fill={innerColor || '#DCE3E7'}
			/>
			<path
				d='M17.0253 24H6.97451C5.62713 24 4.53101 22.9038 4.53101 21.5564V21.0556C4.53101 16.9372 7.88154 13.5867 11.9999 13.5867C16.1183 13.5867 19.4689 16.9372 19.4689 21.0556V21.5564C19.4689 22.9038 18.3727 24 17.0253 24ZM11.9999 14.9623C8.64007 14.9623 5.90665 17.6957 5.90665 21.0555V21.5564C5.90665 22.1452 6.38571 22.6243 6.97451 22.6243H17.0253C17.6142 22.6243 18.0932 22.1452 18.0932 21.5564V21.0555C18.0932 17.6957 15.3598 14.9623 11.9999 14.9623Z'
				fill={color}
			/>
			<path
				d='M20.5257 8.52577H19.1501C19.1501 4.58316 15.9425 1.37564 12 1.37564C8.05752 1.37564 4.85 4.58316 4.85 8.52577H3.47437C3.47437 3.82462 7.29889 0 12 0C16.7012 0 20.5257 3.82462 20.5257 8.52577Z'
				fill={color}
			/>
			<path
				d='M12 14.724C8.667 14.724 5.95532 12.0123 5.95532 8.67924C5.95532 5.34619 8.667 2.63452 12 2.63452C15.3331 2.63452 18.0448 5.34619 18.0448 8.67924C18.0448 12.0124 15.3331 14.724 12 14.724ZM12 4.01021C9.42548 4.01021 7.33096 6.10477 7.33096 8.67929C7.33096 11.2538 9.42543 13.3484 12 13.3484C14.5747 13.3484 16.6691 11.2538 16.6691 8.67929C16.6691 6.10473 14.5746 4.01021 12 4.01021Z'
				fill={color}
			/>
			<path
				d='M6.60744 7.64034C6.60744 7.31198 6.44066 7.00453 6.16227 6.83034C5.87399 6.65002 5.534 6.54553 5.17077 6.54553C4.13647 6.54553 3.29028 7.39177 3.29028 8.42602V9.68747C3.29028 10.7218 4.13652 11.568 5.17077 11.568C5.534 11.568 5.87399 11.4635 6.16227 11.2831C6.44061 11.109 6.60744 10.8015 6.60744 10.4731V7.64034Z'
				fill={innerColor || '#DCE3E7'}
			/>
			<path
				d='M5.17087 12.2559C3.75473 12.2559 2.60254 11.1037 2.60254 9.68757V8.42611C2.60254 7.00997 3.75473 5.85779 5.17087 5.85779C5.65091 5.85779 6.11994 5.99246 6.52724 6.24737C7.00818 6.54821 7.29538 7.06899 7.29538 7.6404V10.4733C7.29538 11.0448 7.00813 11.5655 6.5271 11.8665C6.1199 12.1211 5.65082 12.2559 5.17087 12.2559ZM5.17087 7.23338C4.51316 7.23338 3.97818 7.76846 3.97818 8.42607V9.68752C3.97818 10.3452 4.51316 10.8802 5.17087 10.8802C5.39254 10.8802 5.60919 10.818 5.79749 10.7001C5.87296 10.6529 5.91969 10.566 5.91969 10.4732V7.64025C5.91969 7.5474 5.87286 7.46054 5.79758 7.41343C5.60919 7.29563 5.39244 7.23338 5.17087 7.23338Z'
				fill={color}
			/>
			<path
				d='M17.3923 7.64034C17.3923 7.31198 17.5591 7.00453 17.8375 6.83034C18.1258 6.65002 18.4658 6.54553 18.829 6.54553C19.8633 6.54553 20.7095 7.39177 20.7095 8.42602V9.68747C20.7095 10.7218 19.8633 11.568 18.829 11.568C18.4658 11.568 18.1258 11.4635 17.8375 11.2831C17.5592 11.109 17.3923 10.8015 17.3923 10.4731V7.64034Z'
				fill={innerColor || '#DCE3E7'}
			/>
			<path
				d='M18.8291 12.256C18.3491 12.256 17.88 12.1213 17.4727 11.8664C16.9918 11.5656 16.7046 11.0448 16.7046 10.4734V7.64044C16.7046 7.06899 16.9918 6.54816 17.4729 6.24732C17.8801 5.99246 18.3491 5.85779 18.8291 5.85779C20.2452 5.85779 21.3974 7.00998 21.3974 8.42612V9.68757C21.3974 11.1038 20.2453 12.256 18.8291 12.256ZM18.8291 7.23348C18.6074 7.23348 18.3908 7.29573 18.2025 7.41357C18.127 7.46077 18.0803 7.54768 18.0803 7.64049V10.4734C18.0803 10.5662 18.1271 10.6531 18.2024 10.7003C18.3908 10.8181 18.6075 10.8804 18.8292 10.8804C19.4869 10.8804 20.0218 10.3453 20.0218 9.68771V8.42626C20.0218 7.76846 19.4868 7.23348 18.8291 7.23348Z'
				fill={color}
			/>
			<path
				d='M17.3192 11.4807H12.4356C12.0558 11.4807 11.7478 11.1727 11.7478 10.7928C11.7478 10.4129 12.0558 10.105 12.4356 10.105H17.3192C17.699 10.105 18.007 10.4129 18.007 10.7928C18.007 11.1727 17.699 11.4807 17.3192 11.4807Z'
				fill={color}
			/>
		</svg>
	);
};

export default GetHelp;
