import React from 'react';
import { Box } from '@mui/material';
import NotificationItem from './notification-item';
import { useAppSelector } from 'store/hooks';
import { NOTIFICATION } from 'utilities';

const Notification = () => {
	const { settings } = useAppSelector((store) => store.notification);
	return (
		<Box
			sx={{
				maxWidth: '540px',
				display: 'grid',
				gridTemplateColumns: '1fr',
				gap: '30px',
			}}
		>
			<NotificationItem
				active={Boolean(settings?.transfer)}
				title={'Transactions'}
				type={NOTIFICATION.TRANSFER}
				description={
					'Do you want to keep recieving withdrawal and transfer notifications'
				}
			/>
			<NotificationItem
				active={Boolean(settings?.newsletter)}
				title={'Newsletter'}
				type={NOTIFICATION.NEWSLETTERS}
				description={
					'Do you want to keep recieving promotions and other information notifications'
				}
			/>
			<NotificationItem
				active={Boolean(settings?.topup)}
				title={'Topups'}
				type={NOTIFICATION.TOPUP}
				description={
					'Do you want to keep recieving airtime and data notifications'
				}
			/>
			<NotificationItem
				active={Boolean(settings?.push_notification)}
				type={NOTIFICATION.PUSH_NOTIFICATION}
				title={'Push notification'}
				description={'Do you want to recieve push notifications'}
			/>
		</Box>
	);
};

export default Notification;
