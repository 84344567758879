import React, { useState, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import {
	GenerateBillTab,
	Layout,
	EPinTransactionTable,
	Pagination,
	Seo,
	BackButton,
} from 'components';
import { BILL_TAB, QUERY_KEYS, EPIN_SERVICES, LINKS } from 'utilities';
import { useAppSelector } from 'store/hooks';
import { useAlert, useHandleError } from 'hooks';
import { ePins } from 'api';

const MAX_RECORDS = 20;

const ViewBills = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const handleError = useHandleError();
	const alert = useAlert();

	const {
		auth: { token, user },
	} = useAppSelector((store) => store);

	const [count, setCount] = useState<number>(1);
	const [page, setPage] = useState<number>(1);
	const [total, setTotal] = useState<number>(0);
	const query = queryString.parse(location.search);

	const { tab } = queryString.parse(location.search);
	const [currentTab, setCurrentTab] = useState<number>(0);

	const switchNavigation = (value: number) => {
		switch (value) {
			case 1:
				return navigate(`${LINKS.ViewBill}?tab=${BILL_TAB.PowerBill}`);

			case 2:
				return navigate(`${LINKS.ViewBill}?tab=${BILL_TAB.Education}`);
			case 3:
				return navigate(
					`${LINKS.ViewBill}?tab=${BILL_TAB.InternetSubscription}`
				);

			default:
				navigate(`${LINKS.ViewBill}`);
		}
	};

	const switchNavigationPage = (page: number) => {
		switch (currentTab) {
			case 1:
				return navigate(
					`${LINKS.ViewBill}?tab=${BILL_TAB.PowerBill}&page=${page}`
				);

			case 2:
				return navigate(
					`${LINKS.ViewBill}?tab=${BILL_TAB.Education}&page=${page}`
				);
			case 3:
				return navigate(
					`${LINKS.ViewBill}?tab=${BILL_TAB.InternetSubscription}&page=${page}`
				);

			default:
				navigate(`${LINKS.ViewBill}?page=${page}`);
		}
	};

	const handleChangeTab = (e: any, value: number) => {
		switchNavigation(value);
		setCurrentTab(value);
	};

	useEffect(() => {
		switch (tab) {
			case BILL_TAB.PowerBill:
				return setCurrentTab(1);

			case BILL_TAB.Education:
				return setCurrentTab(2);

			case BILL_TAB.InternetSubscription:
				return setCurrentTab(3);

			default:
				setCurrentTab(0);
		}
	}, [tab]);

	useEffect(() => {
		if (query && query.page) {
			setPage(parseInt(query.page as string));
		}
	}, [query, query.page]);

	const theme = useTheme();

	const service =
		currentTab === 1
			? EPIN_SERVICES.ELECTRICITY
			: currentTab === 2
			? EPIN_SERVICES.EDUCATION
			: currentTab === 3
			? EPIN_SERVICES.INTERNET
			: EPIN_SERVICES.CABLE;

	const { isLoading, data } = useQuery(
		[QUERY_KEYS.BillEPin, page, currentTab, user?.id],
		() =>
			ePins({
				limit: MAX_RECORDS,
				skip: (page - 1) * MAX_RECORDS,
				sort: '-createdAt',
				service,
				populate: 'transaction',
			}),

		{
			enabled: !!token,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				if (data && data.success) {
					const total = data.metadata && data.metadata.total;
					setTotal(Number(total));
					const count = Math.ceil(Number(total) / MAX_RECORDS);
					setCount(count);
				}
				if (error) {
					const res = handleError({
						error,
					});

					if (res?.message) alert({ message: res.message, type: 'error' });
				}
			},
		}
	);

	const handlePageChange = (page: number) => {
		if (page !== 1) {
			setPage(page);
			switchNavigationPage(page);
		} else {
			switchNavigation(currentTab);
			setPage(page);
		}
	};

	const title =
		currentTab === 1
			? 'Electricity E-Pin'
			: currentTab === 2
			? 'Education E-Pin'
			: currentTab === 3
			? 'Internet Subscription E-Pin'
			: 'Cable E-Pin';

	return (
		<Layout>
			<Seo title={title} />
			<BackButton name={'E-Pin'} sx={{ marginBottom: ['1.5rem'] }} />
			<GenerateBillTab currentTab={currentTab} handleChange={handleChangeTab} />

			<Box hidden={currentTab !== 0}>
				<EPinTransactionTable
					data={data && data.payload}
					tableTitle={'Cable  bill E-pins'}
					isLoading={isLoading}
				/>
			</Box>

			<Box hidden={currentTab !== 1}>
				<EPinTransactionTable
					data={data && data.payload}
					tableTitle={'Power  bill E-pins'}
					isLoading={isLoading}
				/>
			</Box>
			<Box hidden={currentTab !== 2}>
				<EPinTransactionTable
					data={data && data.payload}
					tableTitle={'Education  bill E-pins'}
					isLoading={isLoading}
				/>
			</Box>
			<Box hidden={currentTab !== 3}>
				<EPinTransactionTable
					tableTitle={'Internet subscription  bill E-pins'}
					isLoading={isLoading}
					data={data && data.payload}
				/>
			</Box>
			{total > MAX_RECORDS && (
				<Pagination
					sx={{ marginTop: theme.spacing(3) }}
					size={'large'}
					variant={'outlined'}
					shape={'rounded'}
					page={page}
					count={count}
					onChange={(e, number) => handlePageChange(number)}
				/>
			)}
		</Layout>
	);
};

export default ViewBills;
