import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Link from '../../link';
import { LIGHT_GRAY, THEME_MODE } from 'utilities';
import { useAppSelector } from 'store/hooks';
import { grey } from '@mui/material/colors';

type Props = {
	data: {
		img: string;
		title: string;
		description: string;
		link: string;
		type?: string;
	};
	onClick?: (type: string) => void;
};

const WalletOperationItem = ({
	data: { img, title, description, link, type },
	onClick,
}: Props) => {
	const theme = useTheme();
	const { mode } = useAppSelector((store) => store.theme);
	const styles = useStyles(theme, mode);
	return (
		<Link
			onClick={(e) => {
				if (type === 'fund-wallet') {
					e.preventDefault();
					typeof onClick === 'function' && onClick('fund-wallet');
				}
			}}
			to={link}
		>
			<Box
				sx={{
					backgroundImage: `url(${require('assets/images/stripBg.png')})`,
					backgroundColor:
						mode === THEME_MODE.dark
							? theme.palette.background.paper
							: LIGHT_GRAY,
				}}
				style={styles.container}
			>
				<Box
					sx={{
						width: '30px',
						margin: 'auto',
						img: {
							width: '100%',
							display: 'block',
						},
					}}
				>
					<img src={img} alt={title} />
				</Box>
				<Typography style={styles.title as any} variant={'body1'}>
					{title}
				</Typography>
				<Typography style={styles.text as any} variant={'body1'}>
					{description}
				</Typography>
			</Box>
		</Link>
	);
};

const useStyles = (theme: any, mode: string) => ({
	container: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '10px',
		padding: theme.spacing(3, 4),
		borderRadius: '10px',
		// backgroundColor: LIGHT_GRAY,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	},
	title: {
		fontWeight: '600',
		textAlign: 'center',
		color: mode === THEME_MODE.dark ? grey['100'] : theme.palette.primary.main,
	},
	text: {
		textAlign: 'center',
		color: mode === THEME_MODE.dark ? grey['300'] : theme.palette.primary.main,
	},
});

export default WalletOperationItem;
