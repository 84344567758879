import { useState } from 'react';
import { verifyBankAccount as verifyBankAccountApi } from 'api';
import useHandleError from './useHandleError';
import useToastAlert from './useToastAlert';
import { VerifyAccountPayload } from 'utilities';

const useVerifyBankAccount = () => {
	const alert = useToastAlert();
	const handleError = useHandleError();
	const [bankAccount, setBankAccount] = useState<null | { [key: string]: any }>(
		null
	);
	const [isVerifyingBankAccount, setVerifyingBankAccount] =
		useState<boolean>(false);

	const verifyBankAccount = async (payload: VerifyAccountPayload) => {
		let payload$ = {
			bankCode: payload.bankCode,
		} as VerifyAccountPayload;

		const accountNumber = payload.accountNumber.trim();

		if (!accountNumber) {
			alert({ message: 'Enter Bank account number', type: 'info' });
		}

		payload$.accountNumber = payload.accountNumber;

		setVerifyingBankAccount(true);
		try {
			const data = await verifyBankAccountApi(payload$);
			if (data && data.success) {
				const verifiedAccount = data.payload;
				setBankAccount(verifiedAccount);
			}
		} catch (error) {
			const response = handleError({ error });
			if (response?.message)
				alert({ message: response.message, type: 'error' });
		}
		setVerifyingBankAccount(false);
	};

	const clearVerifyBankAccount = () => setBankAccount(null);

	return {
		verifyBankAccount,
		isVerifyingBankAccount,
		bankAccount,
		clearVerifyBankAccount,
	};
};

export default useVerifyBankAccount;
