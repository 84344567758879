import React, { useEffect, useState } from 'react';
import {
	Box,
	MenuItem,
	Typography,
	useTheme,
	InputAdornment,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { grey } from '@mui/material/colors';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import TextInput from '../form-components/text-input';
import Button from '../button';
import Select from '../form-components/select';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
	IBill,
	QUERY_KEYS,
	API_ENDPOINTS,
	TICKET_TYPE,
	DANGER_COLOR,
	SUCCESS_COLOR,
	LINKS,
	ValidationSchema,
	ENDPOINT_SUBPATH,
	IProvider,
	calculateFinalAmount,
	Coupon,
	formatNumberToCurrency,
	TRANSACTION_SERVICE,
	BENEFICIARY_TYPE,
} from 'utilities';
import Loader from '../loader';
import TextHolder from '../partials/custom-text-input-holder';
import CheckBox from 'components/form-components/check-box';
import {
	useVerifyCoupon,
	useHandleError,
	useModalAlert,
	useVerifyPin,
	useAlert,
	useVerifyBillAccount,
	useCheckKycLevelTwo,
	useCreateBeneficiary,
} from 'hooks';
import { billPayment, billProviders } from 'api';
import { setTransactionBreakdown } from 'store/transaction';
import CopyItem from 'components/copy-items';
import SelectBeneficiary from 'components/beneficiary/select-beneficiary';

type Props = {
	enableRequest?: boolean;
};

const SELECT_ELECTRICITY_PROVIDER = 'Select electricity provider';

const PowerbillForm = ({ enableRequest = false }: Props) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { search } = useLocation();
	const handleError = useHandleError();
	const dispatch = useAppDispatch();
	const modal = useModalAlert();
	const alert = useAlert();
	const verifyPin = useVerifyPin();
	const checkKycLevel = useCheckKycLevelTwo();
	const { token } = useAppSelector((store) => store.auth);
	const { isVerifyingCoupon, coupon, verifyCoupon } = useVerifyCoupon();
	const {
		isVerifyingBillAccount,
		billAccount,
		verifyBillAccount,
		clearBillAccount,
	} = useVerifyBillAccount();
	const [selectedProvider, setSelectedProvider] = useState<IProvider | null>(
		null
	);

	const [isSaveBeneficiary, setSaveBeneficiary] = useState<boolean>(false);
	const [beneficiary, setBeneficiary] = useState<null | { [key: string]: any }>(
		null
	);

	const { createBeneficiary } = useCreateBeneficiary(() => {
		setBeneficiary(null);
	});

	// Clear State
	const clearState = () => {
		clearBillAccount();
		modal(null);
		resetForm();
	};

	// Payment mutation
	const { isLoading: isMakingPayment, mutate: mutateMakePayment } = useMutation(
		billPayment,
		{
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						modal({
							message: res.message,
							title: 'Power Bill Payment Message',
							secondaryButtonText: 'Close',
							type: 'error',
							onClickSecondaryButton: () => modal(null),
							primaryButtonText: 'Contact Support',
							onClickPrimaryButton: () => {
								modal(null);
								navigate(
									`${LINKS.CreateSupportTicket}?_type=${TICKET_TYPE.COMPLAINT}`
								);
							},
						});
					}
				}

				/* 
					!TODO
						*pin: data.payload.electricity_token.token,
						*copyTextDescription: `Electricity token number - ${data.payload.electricity_token.unit} units`,

				*/

				if (data && data.success) {
					queryClient.invalidateQueries(QUERY_KEYS.UserWallet);
					if (isSaveBeneficiary)
						createBeneficiary({
							type: BENEFICIARY_TYPE.ELECTRICITY,
							record: beneficiary,
						});

					const token = data.payload.electricity_token.token;
					const amount = data.payload.amount;

					modal({
						message: `Power bill payment of ${
							selectedProvider?.name
						} for ${formatNumberToCurrency(amount)} to ${
							billAccount?.user.accountNumber
						} is successfully`,
						title: 'Power Bill Payment Message',
						primaryButtonText: 'Make another payment',
						secondaryButtonText: 'Close',
						type: 'success',
						children: (
							<CopyItem text={token} textDescription={'Electricity token'} />
						),
						callback: clearState,
						onClickPrimaryButton: () => {
							clearState();
						},
						onClickSecondaryButton: () => clearState(),
					});
				}
			},
		}
	);

	// Power Payment Details

	const initialValues: IBill = {
		service_type: SELECT_ELECTRICITY_PROVIDER,
		amount: '',
		meter_number: '',
		discount_code: '',
	};

	// Load Electriciry Provider
	const {
		isLoading: isLoadingElectricityProviders,
		data: dataElectricityProviders,
	} = useQuery(
		QUERY_KEYS.ElectricityProviders,
		() =>
			billProviders(
				`${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.ElectricityProvider}`
			),
		{
			enabled: !!(token && enableRequest),
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						alert({ message: res.message, type: 'error' });
					}
				}
			},
		}
	);

	// Formik Init

	const {
		handleSubmit,
		errors,
		values,
		setFieldValue,
		touched,
		resetForm,
		handleChange,
	} = useFormik({
		initialValues,
		validationSchema: ValidationSchema.PowerBill,
		onSubmit: (values) => {
			let data = {
				service_type: values.service_type,
				meter_number: values.meter_number,
				amount: Number(values.amount),
			} as IBill;

			let amount = values.amount;

			if (values.discount_code) {
				if (!coupon)
					return alert({ message: 'Verify discount code', type: 'info' });

				if (coupon && coupon.code !== values.discount_code)
					return alert({
						message: 'Incorrect coupon code, check and reconfirm again',
						type: 'info',
					});

				data.discount_code = values.discount_code;
				amount = calculateFinalAmount({
					coupon: coupon as Coupon,
					amount: Number(amount),
				});
			}

			if (values.meter_number) {
				/* Check bill Details */
				if (!billAccount)
					return alert({
						message: 'Electricity meter card not verified',
						type: 'info',
					});

				if (
					billAccount &&
					billAccount.user.accountNumber !== values.meter_number
				)
					return alert({
						message:
							'Verified bill meter number does not match provider meter number',
						type: 'info',
					});
			}

			if (isSaveBeneficiary) {
				setBeneficiary({
					meter_number: values.meter_number,
					provider:
						dataElectricityProviders &&
						dataElectricityProviders.payload.find(
							(provider) => provider.id === values.service_type
						),
					transactionType: TRANSACTION_SERVICE.ELECTRICITY,
				});
			}

			const url = `${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.ElectricityPayment}`;

			const makePayment = () => {
				verifyPin(null);
				mutateMakePayment({ data, url });
			};

			if (checkKycLevel()) {
				verifyPin({
					title: 'Power Bill',
					message: `Verify transaction pin to confirm the payment of ${
						selectedProvider?.name
					} for ${formatNumberToCurrency(amount as string)} to ${
						values.meter_number
					} `,
					callback: makePayment,
				});
			}
		},
	});

	const { service_type, amount, meter_number, discount_code } = values;

	useEffect(
		() => {
			if (coupon) {
				dispatch(
					setTransactionBreakdown({
						coupon,
					})
				);
			}

			if (search) {
				clearState();
			}
		},
		//eslint-disable-next-line
		[coupon, search]
	);

	const handleChangeAmount = (value: string) => {
		setFieldValue('amount', value);
		dispatch(setTransactionBreakdown({ serviceCost: Number(value) }));
	};

	const handleVerifyBillAccount = () => {
		let errors: string[] = [];
		if (service_type === SELECT_ELECTRICITY_PROVIDER)
			errors = [...errors, SELECT_ELECTRICITY_PROVIDER];
		if (!meter_number) errors = [...errors, 'Enter meter number'];

		if (errors.length > 0)
			return alert({ message: errors.join(', '), type: 'info' });

		errors = [];

		console.log(meter_number);

		verifyBillAccount({
			service_type: service_type as string,
			account_number: meter_number,
		});
	};

	const handleSelectProvider = (value: string) => {
		setFieldValue('service_type', value);
		const provider =
			dataElectricityProviders &&
			dataElectricityProviders.payload.find(
				(provider: IProvider) => provider.type === value
			);
		if (provider) {
			setSelectedProvider(provider);
		}
	};

	const handleSelectBeneficiary = (beneficiary: { [key: string]: any }) => {
		const meter_number = beneficiary.record.meter_number;
		const provider = beneficiary.record.provider;
		setFieldValue('meter_number', meter_number);
		setFieldValue('service_type', provider.service_type);
	};

	return (
		<>
			{(isVerifyingCoupon || isVerifyingBillAccount) && <Loader />}
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Power provider
					</Typography>
					<Select
						fullWidth
						error={touched.service_type && errors.service_type ? true : false}
						helpertext={touched.service_type && errors.service_type}
						value={service_type}
						onChange={(e) => handleSelectProvider(e.target.value as string)}
					>
						<MenuItem disabled value={SELECT_ELECTRICITY_PROVIDER}>
							{isLoadingElectricityProviders
								? 'Loading...'
								: dataElectricityProviders &&
								  dataElectricityProviders.payload.length === 0
								? 'No available provider'
								: SELECT_ELECTRICITY_PROVIDER}
						</MenuItem>
						{dataElectricityProviders &&
							dataElectricityProviders.payload.length > 0 &&
							dataElectricityProviders.payload.map(
								(provider: IProvider, key: number) => (
									<MenuItem value={provider.type} key={key}>
										{provider.name}
									</MenuItem>
								)
							)}
					</Select>
				</Box>
				<Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginBottom: '6px',
						}}
					>
						<Typography
							sx={{
								marginBottom: '0px !important',
							}}
							style={styles.label}
							component={'label'}
							variant={'body1'}
						>
							Meter number
						</Typography>
						<SelectBeneficiary
							onSelect={handleSelectBeneficiary}
							type={BENEFICIARY_TYPE.CABLE}
						/>
					</Box>
					{service_type && service_type !== SELECT_ELECTRICITY_PROVIDER ? (
						<TextInput
							fullWidth
							error={touched.meter_number && errors.meter_number ? true : false}
							helperText={touched.meter_number && errors.meter_number}
							placeholder={'Type in meter number here'}
							value={meter_number}
							onChange={handleChange('meter_number')}
						/>
					) : (
						<TextHolder text={'Type in meter number here'} />
					)}
				</Box>
				{billAccount && (
					<>
						<Box>
							<Typography
								style={styles.label}
								component={'label'}
								variant={'body1'}
							>
								Meter Account Name
							</Typography>
							<TextHolder text={billAccount.user.name} />
						</Box>
						<Box>
							<Typography
								style={styles.label}
								component={'label'}
								variant={'body1'}
							>
								Amount
							</Typography>
							<TextInput
								fullWidth
								error={touched.amount && errors.amount ? true : false}
								helperText={touched.amount && errors.amount}
								placeholder={'Type in amount'}
								type={'number'}
								value={amount}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const value = e.target.value;
									handleChangeAmount(value);
								}}
							/>
						</Box>

						<Box>
							<Typography
								style={styles.label}
								component={'label'}
								variant={'body1'}
							>
								Discount code [optional]
							</Typography>
							<TextInput
								fullWidth
								error={
									touched.discount_code && errors.discount_code ? true : false
								}
								helperText={touched.discount_code && errors.discount_code}
								placeholder={'Type in discount code here'}
								value={discount_code}
								onChange={handleChange('discount_code')}
								InputProps={{
									endAdornment: (
										<InputAdornment position='start'>
											<Button
												style={styles.applyBtn}
												onClick={() => verifyCoupon(discount_code as string)}
											>
												Apply
											</Button>
										</InputAdornment>
									),
								}}
							/>
						</Box>
					</>
				)}

				<Box>
					<CheckBox
						label={'Save meter number for later'}
						value={isSaveBeneficiary}
						onChange={() => setSaveBeneficiary(!isSaveBeneficiary)}
					/>
				</Box>

				<Button
					loading={isMakingPayment}
					size={'large'}
					type={'submit'}
					style={styles.btn}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						if (billAccount) {
							handleSubmit();
						}

						handleVerifyBillAccount();
					}}
				>
					{billAccount ? 'Purchase' : 'Verify bill account'}
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
	verifyWrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(3),
		justifyContent: 'space-between',
		border: `1px solid ${theme.palette.primary.main}`,
		padding: theme.spacing(2),
		borderRadius: theme.spacing(1),
	},
	verifyIcon: {
		color: SUCCESS_COLOR,
		fontSize: '32px',
	},
	closeButton: {
		color: DANGER_COLOR,
	},
});

export default PowerbillForm;
