import React, { useRef, useCallback, ChangeEvent } from 'react';
import { ButtonProps, Button } from '@mui/material';
import { useAlert } from 'hooks';

interface Props extends ButtonProps {
	setFile?: (file?: any, preview?: any) => void;
}

const UploadImageButton = ({ setFile, children, ...rest }: Props) => {
	const alert = useAlert();
	const inputEl = useRef<HTMLInputElement>(null);

	const onClick = useCallback(
		() => {
			typeof setFile !== 'undefined' && setFile();
			if (inputEl.current) {
				inputEl.current.value = '';
				inputEl.current.click();
			}
		},
		// eslint-disable-next-line
		[]
	);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.target.files) {
			let reader = new FileReader();
			let file = e.target.files[0];
			if (file && file.size && file.size > 1048576) {
				alert({ message: 'Image Size must not exceed 1MB', type: 'info' });
				return;
			}
			if (file) {
				reader.readAsDataURL(file);
			}
			reader.onloadend = () => {
				typeof setFile !== 'undefined' && setFile(file, reader.result);

				if (inputEl.current) {
					inputEl.current.value = '';
				}
			};
		}
	};

	return (
		<Button onClick={() => onClick()} {...rest}>
			<input
				onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
				ref={inputEl}
				type={'file'}
				style={{ display: 'none' }}
				accept={'image/png, image/gif, image/jpeg, image/jpg'}
			/>
			{children}
		</Button>
	);
};

export default UploadImageButton;
