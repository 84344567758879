import React from 'react';
import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { grey } from '@mui/material/colors';
import TextInput from '../form-components/text-input';
import Button from '../button';
import Select from '../form-components/select';
import {
	useAlert,
	useHandleError,
	useVerifyPin,
	useModalAlert,
	useCheckKycLevelTwo,
} from 'hooks';
import {
	QUERY_KEYS,
	API_ENDPOINTS,
	EPIN_SERVICES,
	ValidationSchema,
	ENDPOINT_SUBPATH,
	IProvider,
} from 'utilities';
import { useAppSelector } from 'store/hooks';
import { billProviders, generateEPin } from 'api';
import CopyItem from 'components/copy-items';

const SELECCT_PROVIDER = 'Select electricity provider';

interface Props {
	enableRequest: boolean;
}

const GeneratePowerBillForm = ({ enableRequest }: Props) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const alert = useAlert();
	const verifyPin = useVerifyPin();
	const handleError = useHandleError();
	const modal = useModalAlert();
	const queryClient = useQueryClient();
	const checkKycLevel = useCheckKycLevelTwo();

	const { token } = useAppSelector((store) => store.auth);

	const initialValues = {
		amount: '',
		service_type: SELECCT_PROVIDER,
	};

	const { handleChange, handleSubmit, errors, touched, values, resetForm } =
		useFormik({
			initialValues,
			validationSchema: ValidationSchema.GenerateElectricityEPin,
			onSubmit: (values) => {
				const data = {
					service: EPIN_SERVICES.ELECTRICITY,
					pin_data: {
						service_type: values.service_type,
						amount: parseFloat(values.amount),
					},
				};

				if (checkKycLevel()) {
					verifyPin({
						title: 'Power E-Pin',
						message: 'Enter transaction pin to Generate E-Pin',
						callback: () => {
							verifyPin(null);
							mutateGenerateEPin(data);
						},
					});
				}
			},
		});

	const { amount, service_type } = values;

	const { isLoading: isGeneratingEPin, mutate: mutateGenerateEPin } =
		useMutation(generateEPin, {
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						alert({ message: response.message, type: 'error' });
					}
				}

				if (data && data.success) {
					queryClient.invalidateQueries(QUERY_KEYS.UserWallet);
					queryClient.invalidateQueries(QUERY_KEYS.Transactions);
					queryClient.invalidateQueries([QUERY_KEYS.RecentTransactions]);
					resetForm();
					modal({
						title: 'Electricity EPin',
						message: data.message,
						type: 'success',
						children: <CopyItem text={data.payload.pin} />,
						primaryButtonText: 'Generate Another',
						secondaryButtonText: 'Close',
						onClickPrimaryButton: () => {
							modal(null);
						},
						onClickSecondaryButton: () => modal(null),
					});
				}
			},
		});

	const {
		isLoading: isLoadingElectricityProvider,
		data: dataElectricityProviders,
	} = useQuery(
		[QUERY_KEYS.ElectricityProviders],
		() =>
			billProviders(
				`${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.ElectricityProvider}`
			),
		{
			enabled: !!token,
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						alert({ message: response.message, type: 'error' });
					}
				}
			},
		}
	);

	return (
		<>
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Power provider
					</Typography>
					<Select
						fullWidth
						error={touched.service_type && errors.service_type ? true : false}
						helpertext={touched.service_type && errors.service_type}
						value={service_type}
						onChange={handleChange('service_type') as never}
					>
						<MenuItem disabled value={SELECCT_PROVIDER}>
							{SELECCT_PROVIDER}
						</MenuItem>
						{isLoadingElectricityProvider ? (
							<MenuItem disabled value={'loading...'}>
								Loading...
							</MenuItem>
						) : dataElectricityProviders &&
						  dataElectricityProviders.payload.length > 0 ? (
							dataElectricityProviders.payload.map((electricity: IProvider) => (
								<MenuItem value={electricity.type} key={electricity.type}>
									{electricity.name}
								</MenuItem>
							))
						) : (
							<MenuItem disabled value={'No network provider'}>
								No network provider
							</MenuItem>
						)}
					</Select>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Amount
					</Typography>
					<TextInput
						fullWidth
						error={touched.amount && errors.amount ? true : false}
						helperText={touched.amount && errors.amount}
						placeholder={'Type in amount'}
						type={'number'}
						value={amount}
						onChange={handleChange('amount')}
					/>
				</Box>

				<Button
					loading={isGeneratingEPin}
					size={'large'}
					style={styles.btn}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Generate E-Pin
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default GeneratePowerBillForm;
