import React from 'react';
import { Box, Typography } from '@mui/material';
import Button from '../button';
import { grey } from '@mui/material/colors';

type Props = {
	alertType: 'success' | 'failed';
	successType: 'green' | 'primary';
	title: string;
	message: string;
	btnText: string;
	handlePress: () => void;
};

const Alert = ({
	alertType,
	successType,
	title,
	message,
	btnText,
	handlePress,
}: Props) => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				gap: '20px',
			}}
		>
			<Box
				sx={{
					maxWidth: '80px',
					img: {
						width: '100%',
					},
				}}
			>
				{alertType ? (
					<>
						{successType === 'green' ? (
							<img
								src={require('../../assets/icons/check-green.png')}
								alt={'success-green'}
							/>
						) : (
							<img
								src={require('../../assets/icons/check-primary.png')}
								alt={'success-green'}
							/>
						)}
					</>
				) : (
					<img
						src={require('../../assets/icons/failed.png')}
						alt={'success-green'}
					/>
				)}
			</Box>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<Typography
					sx={{ fontWeight: '600', fontSize: '20px' }}
					variant={'body1'}
				>
					{title}
				</Typography>
				<Typography variant={'body1'}>{message}</Typography>
			</Box>
			<Button
				onClick={() => {
					typeof handlePress !== 'undefined' && handlePress();
				}}
				fullWidth
				size={'large'}
				sx={{
					backgroundColor: (theme) => theme.palette.secondary.main,
					color: grey[50],
					fontWeight: '600',
					':hover': {
						backgroundColor: (theme) => theme.palette.secondary.main,
					},
				}}
			>
				{btnText}
			</Button>
		</Box>
	);
};

export default Alert;
