import React from 'react';
import { useParams } from 'react-router-dom';
import {
	Layout,
	Seo,
	BackButton,
	TwoColGrid,
	H5Typography,
	WalletWithBreakdown,
	InternationalAirtimeForm,
	InternationalDataForm,
} from 'components';
import { THEME_MODE, capitalize } from 'utilities';
import { grey } from '@mui/material/colors';

enum TYPES {
	Data = 'data',
	Airtime = 'airtime',
}

const InternationalAirtimeData = () => {
	const params = useParams();

	const slug = params?.slug;
	const PAGE = `International ${capitalize(slug || '')}`;

	return (
		<Layout>
			<Seo title={PAGE} />
			<TwoColGrid
				rightAside={<WalletWithBreakdown top={'6rem'} isStickySidebar />}
			>
				<BackButton name={'Back'} sx={{ marginBottom: ['2rem'] }} />
				<H5Typography name={PAGE} />
				{slug && (
					<>
						{slug === TYPES.Airtime && <InternationalAirtimeForm />}
						{slug === TYPES.Data && <InternationalDataForm />}
					</>
				)}
			</TwoColGrid>
		</Layout>
	);
};

const useStyles = (theme: any, mode?: any) => ({
	title: {
		marginBottom: theme.spacing(3),
		fontWeight: '600',
		color: mode === THEME_MODE.dark ? grey[300] : 'inherit',
	},
});

export default InternationalAirtimeData;
