import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalAlert, IVerifyPinMModal, PushNotification } from 'utilities';

// Define the initial state using that type
type InitialStateTypes = {
	isToggleDrawer: boolean;
	isDisplayPinModal: boolean;
	isDisplayPinInfo: boolean;
	pushNotification: PushNotification | null;
	isDisplayLogoutModal: boolean;
	modalAlert: IModalAlert | null;
	verifyPinModal: IVerifyPinMModal | null;
};

const initialState: InitialStateTypes = {
	isToggleDrawer: false,
	isDisplayPinModal: false,
	isDisplayPinInfo: false,
	pushNotification: null,
	isDisplayLogoutModal: false,
	modalAlert: null,
	verifyPinModal: null,
};

export const appSlice = createSlice({
	name: 'appState',
	initialState,
	reducers: {
		setToggleDrawer: (state, action: PayloadAction<boolean>) => {
			state.isToggleDrawer = action.payload;
		},
		setDisplayPinModal: (state, action: PayloadAction<boolean>) => {
			state.isDisplayPinModal = action.payload;
		},
		setDisplayLogoutModal: (state, action: PayloadAction<boolean>) => {
			state.isDisplayLogoutModal = action.payload;
		},
		setDisplayPinInfo: (state, action: PayloadAction<boolean>) => {
			state.isDisplayPinInfo = action.payload;
		},

		setModalAlert: (state, action: PayloadAction<IModalAlert | null>) => {
			const alert = action.payload;
			if (alert) {
				state.modalAlert = alert;
			} else {
				state.modalAlert = null;
			}
		},

		setVerifyPinModal: (
			state,
			action: PayloadAction<IVerifyPinMModal | null>
		) => {
			const verifyPinModal = action.payload;
			if (verifyPinModal) {
				state.verifyPinModal = verifyPinModal;
			} else {
				state.verifyPinModal = null;
			}
		},

		setPushNotification: (
			state,
			action: PayloadAction<PushNotification | null>
		) => {
			state.pushNotification = action.payload;
		},
	},
});

export const {
	setToggleDrawer,
	setDisplayPinModal,
	setDisplayPinInfo,
	setPushNotification,
	setDisplayLogoutModal,
	setModalAlert,
	setVerifyPinModal,
} = appSlice.actions;

export default appSlice.reducer;
