import axios from 'axios';
import {
	API_ENDPOINTS,
	SignupPayload,
	LoginPayload,
	VerifyEmailPayload,
	DataResponse,
	ENDPOINT_SUBPATH,
	IUser,
	Configs,
	IReferral,
	IResetPinPassword,
	IKeyLevelPayload,
} from 'utilities';
import { apiRequest } from '../config';

export const login = async (
	data: LoginPayload
): Promise<
	DataResponse<{ user: IUser; token: string; twoFactorAuth: boolean }>
> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.Login}`,
		data,
		method: 'POST',
	});

export const twoFALogin = async (
	data: LoginPayload
): Promise<DataResponse<{ user: IUser; token: string }>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.TwoFALogin}`,
		data,
		method: 'POST',
	});

export const register = async (
	data: SignupPayload
): Promise<DataResponse<IUser>> =>
	apiRequest({ url: `${API_ENDPOINTS.User}`, data, method: 'POST' });

export const verifyEmail = async (data: VerifyEmailPayload) =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.Verify}`,
		data,
		method: 'PUT',
	});

export const verifyBvn = async (data: {
	bvn: string;
}): Promise<DataResponse<IUser>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.BvnVerification}`,
		data,
		method: 'PUT',
	});

export const resendVerificationEmail = async (data: {
	email: string;
}): Promise<any> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.ResendVerificationEmail}`,
		data,
		method: 'PUT',
	});

export const me = async (): Promise<DataResponse<IUser[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.Me}`,
		method: 'GET',
		params: {
			populate: 'manager',
		},
	});

export const verifyUser = async (params: {
	[key: string]: any;
}): Promise<DataResponse<IUser[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.VerifyOthers}`,
		method: 'GET',
		params,
	});

export const updateSettings = async (data: {
	[key: string]: any;
}): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.Settings}`,
		data,
		method: 'PUT',
	});

export const setPin = async (data: {
	[key: string]: any;
}): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.SetPin}`,
		method: 'POST',
		data,
	});

export const resetPassword = async ({
	data,
	id,
}: {
	data: { [key: string]: any };
	id: string;
}): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.ResetPassword}/${id}`,
		data,
		method: 'PUT',
	});

export const resetPin = async ({
	data,
	id,
}: {
	data: any;
	id: string;
}): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.ResetPin}/${id}`,
		data,
		method: 'PUT',
	});

export const resetPinToken = async (): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.GetPinResetToken}`,
		method: 'GET',
	});

export const resetPasswordToken = async (params: {
	email: string;
}): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.GetPasswordResetToken}`,
		method: 'GET',
		params,
	});

export const twoFAToken = async (): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.Get2FAToken}`,
		method: 'GET',
	});

export const set2FASettings = async ({
	id,
	data,
}: {
	id: string;
	data: { [key: string]: any };
}): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.Update2FASetting}/${id}`,
		method: 'PUT',
		data,
	});

export const validatePin = async (data: { pin: string }): Promise<any> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.ValidatePin}`,
		method: 'POST',
		data,
	});

export const validateResetPinOrPassword = async ({
	data,
	id,
}: {
	data: IResetPinPassword;
	id;
}): Promise<any> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}${ENDPOINT_SUBPATH.ValidateResetPinPassword}/${id}`,
		method: 'POST',
		data,
	});

export const uploadProfileImage = async ({
	data,
	token,
}: {
	data: any;
	token: string;
}) => {
	const res = await axios.post(
		`${Configs.BASE_URL}/${API_ENDPOINTS.User}/upload-display-picture`,
		data,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	return res.data;
};

export const kycLimits = async (): Promise<DataResponse<any>> =>
	apiRequest({ url: API_ENDPOINTS.Kyc, method: 'GET' });

export const referrals = async (params?: {
	[key: string]: any;
}): Promise<DataResponse<IReferral[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.Referral}${ENDPOINT_SUBPATH.Me}`,
		method: 'GET',
		params,
	});

export const requestDeleteAccountToken = async (): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}/get-deletion-token`,
		method: 'GET',
	});

export const deleteAccount = async (
	data: Record<string, any>
): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}`,
		method: 'PATCH',
		data,
	});

export const kycLevelTwoUpdate = async (
	data: Partial<IKeyLevelPayload>
): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}/verify-level-2`,
		method: 'PUT',
		data,
	});

export const kycLevelThreeUpdate = async (
	data: Partial<IKeyLevelPayload>
): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}/verify-level-3`,
		method: 'PUT',
		data,
	});

export const checkVerificationState = async (
	params: Record<string, any>
): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.Verification}/me`,
		method: 'GET',
		params,
	});

// /email-extensions

export const emailExtensions = async (): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}/email-extensions`,
		method: 'GET',
	});
