import {
	API_ENDPOINTS,
	ITicket,
	DataResponse,
	ReplyTicket,
	ENDPOINT_SUBPATH,
} from 'utilities';
import { apiRequest } from '../config';

export const createTicket = async (
	data: ITicket
): Promise<DataResponse<ITicket>> =>
	apiRequest({
		url: API_ENDPOINTS.Ticket,
		method: 'POST',
		data,
	});

export const replyTicket = async ({
	data,
	code,
}: {
	data: ReplyTicket;
	code: string;
}): Promise<DataResponse<any>> =>
	apiRequest({
		url: `${API_ENDPOINTS.Ticket}/reply/${code}`,
		method: 'PUT',
		data,
	});

export const tickets = async (params?: {
	[key: string]: any;
}): Promise<DataResponse<ITicket[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.Ticket}${ENDPOINT_SUBPATH.Me}`,
		method: 'GET',
		params,
	});
