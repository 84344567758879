import React, { useRef } from 'react';
import moment from 'moment';
import {
	ITransaction,
	TRANSACTION_SERVICE,
	checkAmount,
	formatNumberToCurrency,
} from 'utilities';
import AppTable from './components/table';
import TransactionDetails from 'components/transaction-details';

interface Props {
	data: ITransaction[] | null;
	isLoading?: boolean;
}

const AutoAirtimeConvertsTable = ({ data, isLoading }: Props) => {
	const transactionDetailsRef = useRef<any>(null);
	const handleRowClick = (transaction: ITransaction) => {
		if (transactionDetailsRef.current)
			transactionDetailsRef.current.display(transaction);
	};
	return (
		<>
			<TransactionDetails
				transactionType={TRANSACTION_SERVICE.AIRTIME_AUTO_CONVERSION}
				ref={transactionDetailsRef}
			/>
			<AppTable
				canClickRow
				onRowClick={handleRowClick}
				numberOfColumns={6}
				isLoading={isLoading}
				header={[
					'Network',
					'Phone Number',
					'Amount',
					'Return Amount',
					'Number of share',
					'Created At',
				]}
				body={
					data &&
					data.map((value) => {
						const network =
							(typeof value.network === 'object' && value.network.name) || '';

						const createdAt =
							value.transactions &&
							Array.isArray(value.transactions) &&
							value.transactions[0]?.createdAt;

						const totalAmount =
							typeof value?.totalAmount === 'object'
								? value.totalAmount?.$numberDecimal
								: '';
						const totalReturnAmount =
							typeof value?.totalReturnAmount === 'object'
								? value.totalReturnAmount?.$numberDecimal
								: '';

						return {
							data: [
								network,
								value?.phone_number,
								formatNumberToCurrency(checkAmount(`${totalAmount}`)),
								formatNumberToCurrency(checkAmount(`${totalReturnAmount}`)),
								value?.count,
								createdAt ? moment(`${createdAt}`).format('ll') : '',
							],
							rawData: value,
						};
					})
				}
				emptyText={'No auto airtime convert transactions'}
			/>
		</>
	);
};

export default AutoAirtimeConvertsTable;
