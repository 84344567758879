import React from 'react';
import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';
import TextInput from '../form-components/text-input';
import { grey } from '@mui/material/colors';
import { ITicket, PRIORITY, LINKS, TICKET_TYPE } from 'utilities';
import Button from '../button';
import { createTicket } from 'api';
import { useHandleError, useModalAlert } from 'hooks';
import Select from '../form-components/select';

const SELECT_PRIORITY = 'Select priority';

interface Props {
	onCreateComplain: () => void;
}

const ComplainForm = ({ onCreateComplain }: Props) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const styles = useStyles(theme);
	const modal = useModalAlert();
	const handleError = useHandleError();

	const { search } = useLocation();
	const urlQuery = queryString.parse(search);

	const validationSchema = yup.object().shape({
		message: yup.string().required('Enter dispute message'),
		subject: yup.string().required('Enter dispute subject'),
		priority: yup
			.string()
			.notOneOf([SELECT_PRIORITY], SELECT_PRIORITY)
			.required('Select dispute priority'),
	});

	// Cable Payment Mutation
	const { isLoading: isCreatingDispute, mutate: mutateCreateTicket } =
		useMutation(createTicket, {
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						modal({
							message: res.message,
							title: 'Create Dispute',
							primaryButtonText: 'Close',
							type: 'error',
							onClickPrimaryButton: () => modal(null),
						});
					}
				}

				if (data && data.success) {
					resetForm();
					typeof onCreateComplain !== 'undefined' && onCreateComplain();
					modal({
						message: data.message,
						title: 'Create Dispute',
						primaryButtonText: 'Go to Dashboard',
						type: 'success',
						onClickPrimaryButton: () => {
							modal(null);
							navigate(LINKS.Dashboard);
						},
					});
				}
			},
		});

	const initialValues: ITicket = {
		priority: SELECT_PRIORITY,
		subject: '',
		message: '',
		type: TICKET_TYPE.COMPLAINT,
	};

	// Cable Payment Formik initialization

	const { values, handleSubmit, errors, touched, handleChange, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: (values) => {
				if (!urlQuery._transaction && !urlQuery._type) {
					modal({
						message: `Something went wrong, unable to create dispute`,
						title: 'Create Dispute',
						primaryButtonText: 'Go to Dashboard',
						type: 'error',
						onClickPrimaryButton: () => {
							modal(null);
							navigate(LINKS.Dashboard);
						},
					});
				} else {
					mutateCreateTicket(values);
				}
			},
		});

	const { priority, subject, message } = values;

	return (
		<>
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Priority
					</Typography>
					<Select
						fullWidth
						error={touched.priority && errors.priority ? true : false}
						// helpertext={touched.priority && errors.priority}
						value={priority}
						onChange={handleChange('priority') as never}
					>
						<MenuItem disabled value={SELECT_PRIORITY}>
							{SELECT_PRIORITY}
						</MenuItem>
						{Object.values(PRIORITY).map((value) => (
							<MenuItem key={value} value={value}>
								{value}
							</MenuItem>
						))}
					</Select>
				</Box>

				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Subject
					</Typography>
					<TextInput
						fullWidth
						error={touched.subject && errors.subject ? true : false}
						helperText={touched.subject && errors.subject}
						placeholder={'Type in subject'}
						value={subject}
						onChange={handleChange('subject')}
					/>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Message
					</Typography>
					<TextInput
						fullWidth
						multiline
						error={touched.message && errors.message ? true : false}
						helperText={touched.message && errors.message}
						placeholder={'Type in your message'}
						rows={4}
						value={message}
						onChange={handleChange('message')}
					/>
				</Box>

				<Button
					loading={isCreatingDispute}
					type={'submit'}
					size={'large'}
					style={styles.btn}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Submit
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
		maxWidth: '480px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default ComplainForm;
