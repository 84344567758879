import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import {
	Layout,
	SettingsTab,
	Profile,
	Security,
	Notification,
	BankInformation,
	ManageLimits,
} from 'components';
import { SETTINGS_TAB, LINKS } from 'utilities';

const Settings = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const { tab } = queryString.parse(location.search);
	const [currentTab, setCurrentTab] = useState<number>(0);

	const handleChangeTab = (e: any, value: number) => {
		switch (value) {
			case 1:
				return navigate(`${LINKS.Settings}?tab=${SETTINGS_TAB.Security}`);

			case 2:
				return navigate(`${LINKS.Settings}?tab=${SETTINGS_TAB.Notification}`);
			case 3:
				return navigate(
					`${LINKS.Settings}?tab=${SETTINGS_TAB.BankInformation}`
				);
			case 4:
				return navigate(`${LINKS.Settings}?tab=${SETTINGS_TAB.ManageLimit}`);

			default:
				navigate(`${LINKS.Settings}`);
		}
		setCurrentTab(value);
	};

	useEffect(() => {
		switch (tab) {
			case SETTINGS_TAB.Security:
				return setCurrentTab(1);

			case SETTINGS_TAB.Notification:
				return setCurrentTab(2);

			case SETTINGS_TAB.BankInformation:
				return setCurrentTab(3);
			case SETTINGS_TAB.ManageLimit:
				return setCurrentTab(4);

			default:
				setCurrentTab(0);
		}
	}, [tab]);

	return (
		<Layout>
			<SettingsTab currentTab={currentTab} handleChange={handleChangeTab} />
			{currentTab === 0 && <Profile />}
			{currentTab === 1 && <Security />}
			{currentTab === 2 && <Notification />}
			{currentTab === 3 && (
				<BankInformation
					handleChangeTab={(value) => handleChangeTab(null, value)}
				/>
			)}
			{currentTab === 4 && <ManageLimits />}
		</Layout>
	);
};

export default Settings;
