import React, { useState } from 'react';
import { Box } from '@mui/material';
import {
	Layout,
	TwoColGrid,
	WalletBalance,
	// WalletWithdrawTab,
	Seo,
	WalletWithdrawForm,
	BackButton,
} from 'components';
import { WITHDRAWAL_TYPE } from 'utilities';

const Withdraw = () => {
	const [currentTab, setCurrentTab] = useState<number>(0);
	const title =
		currentTab === 0
			? 'Wallet withdraw to bank'
			: 'Wallet withraw to another bank';
	// const handleChangeTab = (e: HTMLFormElement, value: number) =>
	// 	setCurrentTab(value);

	return (
		<Layout>
			<Seo title={title} />
			<BackButton name={'Back'} sx={{ marginBottom: ['1.5rem'] }} />
			<Box>
				<TwoColGrid rightAside={<WalletBalance isHideTopupBtn />}>
					<WalletWithdrawForm withdrawType={WITHDRAWAL_TYPE.Default} />
				</TwoColGrid>
			</Box>
		</Layout>
	);
};

export default Withdraw;
