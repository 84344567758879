import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

type Props = {
	title: string;
	description: string;
	button: ReactNode;
};

const SecurityItem = ({ title, description, button }: Props) => {
	return (
		<Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: '6px' }}>
			<Typography sx={{ fontWeight: '600' }} variant={'body1'}>
				{title}
			</Typography>
			<Typography sx={{ marginBottom: '15px' }} variant={'body1'}>
				{description}
			</Typography>
			{button}
		</Box>
	);
};

export default SecurityItem;
