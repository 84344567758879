import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	Coupon,
	ITransaction,
	IFilterTransaction,
	TRANSACTION_SERVICE,
} from 'utilities';

// Define the initial state using that type
type InitialStateTypes = {
	transactions: ITransaction[] | null;
	transactionBreakdown: TransactionBreakdown | null;
	metadata: {
		total: number;
		limit: number;
		count: number;
		skip: number;
		page: number;
		sort: any;
	} | null;
	isLoading: boolean;
	filterTransactionBy: IFilterTransaction;
};

interface TransactionBreakdown {
	coupon?: Coupon | null;
	serviceCost?: number;
	charge?: string;
	currency?: string;
}

const initialState: InitialStateTypes = {
	transactions: null,
	metadata: null,
	isLoading: true,
	transactionBreakdown: {
		coupon: null,
		serviceCost: 0,
		charge: '',
		currency: 'NGN',
	},
	filterTransactionBy: {
		date: '',
		service: TRANSACTION_SERVICE.DATA_SUBSCRIPTION,
		status: '',
	},
};

export const appSlice = createSlice({
	name: 'transactionState',
	initialState,
	reducers: {
		setTransactions: (state, action: PayloadAction<any>) => {
			state.transactions = action.payload.payload;
			state.metadata = action.payload.metadata;
			state.isLoading = false;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setTransactionBreakdown: (
			state,
			action: PayloadAction<TransactionBreakdown | null>
		) => {
			if (action.payload) {
				if (
					state.transactionBreakdown &&
					Object.keys(state.transactionBreakdown).length > 0
				) {
					state.transactionBreakdown = {
						...state.transactionBreakdown,
						...action.payload,
					};
				} else {
					state.transactionBreakdown = action.payload;
				}
			} else {
				state.transactionBreakdown = null;
			}
		},
		setTransactionFilter: (
			state,
			action: PayloadAction<IFilterTransaction>
		) => {
			if (
				state.filterTransactionBy &&
				Object.keys(state.filterTransactionBy).length > 0
			) {
				state.filterTransactionBy = {
					...state.filterTransactionBy,
					...action.payload,
				};
			}
		},
	},
});

export const {
	setTransactions,
	setLoading,
	setTransactionBreakdown,
	setTransactionFilter,
} = appSlice.actions;

export default appSlice.reducer;
