import { useState } from 'react';
import { tickets } from 'api';
import useHandleError from './useHandleError';
import useToastAlert from './useToastAlert';
import { ITicket } from 'utilities';

const useSearchDispute = () => {
	const alert = useToastAlert();
	const handleError = useHandleError();
	const [dispute, setDispute] = useState<null | ITicket>(null);
	const [isSearchingDispute, setSearchingDispute] = useState<boolean>(false);

	const searchDispute = async (related_transaction: string) => {
		setSearchingDispute(true);
		try {
			const data = await tickets({ related_transaction });
			if (data && data.success) {
				const dispute = data.payload[0];
				setDispute(dispute);
			}
		} catch (error) {
			const response = handleError({ error });
			if (response?.message)
				alert({ message: response.message, type: 'error' });
		}
		setSearchingDispute(false);
	};

	return {
		searchDispute,
		isSearchingDispute,
		dispute,
	};
};

export default useSearchDispute;
