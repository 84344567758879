import { useMutation, useQuery } from 'react-query';
import { createBeneficiary, beneficiaries } from 'api';
import { QUERY_KEYS } from 'utilities';

export const useCreateBeneficiary = (callback?: () => void) => {
	const { mutate } = useMutation(createBeneficiary, {
		onSettled: (data, error) => {
			if (data) {
				if (data && data.success) {
					typeof callback === 'function' && callback();
				}
			}
		},
	});

	return { createBeneficiary: mutate };
};

export const useQueryBeneficiaries = (params?: { queryKey?: string }) => {
	const queryKey = params?.queryKey;

	const { data, isLoading } = useQuery(
		[QUERY_KEYS.Beneficiaries, queryKey],
		() =>
			beneficiaries({
				sort: '-createdAt',
				type: queryKey,
			}),
		{
			enabled: !!queryKey,
			refetchOnWindowFocus: false,
		}
	);

	return {
		beneficiaries: data && data.payload,
		isLoadingBeneficiaries: isLoading,
	};
};
