import React, { useState } from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { AuthLayout, OtpForm, Seo } from 'components';
import { validateResetPinOrPassword } from 'api';
import { useHandleError, useAlert, useModal } from 'hooks';
import { LINKS } from 'utilities';

const ConfirmOtp = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const handleError = useHandleError();
	const alert = useAlert();
	const modal = useModal();
	const navigate = useNavigate();
	const { search, state } = useLocation();
	const parseUrl: Record<string, any> = queryString.parse(search);
	const [isValidatingOtp, setVerifyingOtp] = useState<boolean>(false);

	const ref = parseUrl.ref || state.ref;

	const handleConfirmOtp = async (otp: string) => {
		setVerifyingOtp(true);
		try {
			const response = await validateResetPinOrPassword({
				id: ref,
				data: {
					type: 'RESET_PASSWORD',
					otp: parseInt(otp),
				},
			});
			if (response.success) {
				setVerifyingOtp(false);
				modal.display({
					type: 'success',
					title: 'Reset Password',
					message:
						'OTP has been verify successfully, proceed to reset password',
					primaryButtonText: 'Proceed',
					onClickPrimaryButton: () => {
						modal.close();
						navigate(`${LINKS.AuthResetPassword}?ref=${ref}&access=${otp}`, {
							state: {
								ref,
								otp,
							},
						});
					},
				});
			}
		} catch (error) {
			const response = handleError({ error });
			setVerifyingOtp(false);

			if (response?.message) {
				alert({ message: response.message, type: 'error' });
			}
		}
	};

	return (
		<AuthLayout>
			<Seo title={'Confirm forgotten password OTP'} />
			<Box sx={{ marginBottom: theme.spacing(4) }}>
				<Typography style={styles.title} variant={'h5'}>
					Confirm OTP
				</Typography>
				<Typography variant={'body1'}>
					Confirm the otp send to your email address and we'll send you and OTP
					to reset password.
				</Typography>
			</Box>
			<OtpForm
				isLoading={isValidatingOtp}
				callback={(token: string) => handleConfirmOtp(token)}
			/>
		</AuthLayout>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
});

export default ConfirmOtp;
