import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import BackButton from 'components/button/back-button';

type Props = {
	children: ReactNode;
	rightAside?: any;
	canGoBack?: boolean;
};

const TwoColLayout = ({ children, rightAside, canGoBack = false }: Props) => {
	return (
		<Box>
			{canGoBack && (
				<Box
					sx={{
						marginBottom: '2rem',
						display: ['block', 'block', 'none'],
					}}
				>
					<BackButton />
				</Box>
			)}
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: { md: '5.5fr 4.5fr' },
					gap: ['0rem', '0px', '1.5rem', '3rem'],
				}}
			>
				<Box>{children}</Box>
				<Box
					sx={{
						display: ['none', 'none', 'block'],
					}}
				>
					{rightAside}
				</Box>
			</Box>
		</Box>
	);
};

export default TwoColLayout;
