import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import {
	Layout,
	LoadCablePinForm,
	CircularProgress,
	Seo,
	BackButton,
} from 'components';
import {
	formatNumberToCurrency,
	ErrorBoundaryGuard,
	ENDPOINT_SUBPATH,
} from 'utilities';
import { useAppSelector } from 'store/hooks';
import { API_ENDPOINTS } from 'utilities';
import { useAlert, useHandleError } from 'hooks';
import { billBundles } from 'api';

const LoadCable = () => {
	const handleError = useHandleError();
	const alert = useAlert();
	const { search } = useLocation();
	const query = queryString.parse(search);

	const [isLoading, setLoading] = useState<boolean>(true);
	const { token } = useAppSelector((store) => store.auth);

	const { data: dataDataBundles } = useQuery(
		'Load Cable Network',
		() =>
			billBundles({
				url: `${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.CableProvider}`,
				params: { provider: query.service_type as string },
			}),
		{
			enabled: !!(token && query && query.service_type),
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				setLoading(false);
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						alert({ message: response.message, type: 'error' });
					}
				}
			},
		}
	);

	return (
		<Layout>
			<Seo title={'Load Cable E-Pin'} />
			<ErrorBoundaryGuard>
				{isLoading ? (
					<CircularProgress />
				) : (
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: '1fr',
							gap: '15px',
							maxWidth: '600px',
						}}
					>
						<BackButton name={'E-Pin'} sx={{ marginBottom: ['1.5rem'] }} />
						<Typography sx={{ fontWeight: '600' }} variant={'h5'}>
							Cable E-pin
						</Typography>
						<Typography>
							The e-pin you entered gives{' '}
							{query.amount && formatNumberToCurrency(query.amount as string)}{' '}
							worth of{' '}
							<Box component={'span'} sx={{ fontWeight: '600' }}>
								{dataDataBundles && dataDataBundles.payload[0]?.name}.
							</Box>{' '}
							Enter your smart card number to load pin.
						</Typography>
						<LoadCablePinForm
							serviceType={query.service_type as string}
							pin={query.pin as string}
						/>
					</Box>
				)}
			</ErrorBoundaryGuard>
		</Layout>
	);
};

export default LoadCable;
