import React, { ReactNode } from 'react';
import { Box, Container } from '@mui/material';
import Header from './header';
import Sidebar from '../sidebar';
import Drawer from './drawer';
import { AuthGuard } from 'utilities';

type Props = {
	children: ReactNode;
};

const Layout = ({ children }: Props) => {
	return (
		<AuthGuard>
			<Drawer />
			<Header />
			<Container
				sx={{
					maxWidth: '1300px !important',
					margin: '6rem auto 2rem',
					position: 'relative',
				}}
			>
				<Box
					sx={{
						position: 'fixed',
						top: '0px',
						width: '24%',
						maxWidth: '320px',
						display: {
							xs: 'none',
							md: 'block',
						},
					}}
				>
					<Sidebar />
				</Box>
				<Box
					sx={{
						width: { xs: '100%', md: '70%' },
						float: 'right',
						paddingBottom: '2rem',
					}}
				>
					{children}
				</Box>
			</Container>
		</AuthGuard>
	);
};

export default Layout;
