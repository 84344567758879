import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import OtpInput from 'react-otp-input';
import { grey, red } from '@mui/material/colors';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import Button from '../button';
import { ValidationSchema } from 'utilities';
import { useAlert, useHandleError } from 'hooks';
import { validatePin } from 'api';
import moduleStyles from './style.module.css';

type Props = {
	callback?: (pin?: string) => void;
	text?: string;
};

const VerifyPinForm = ({ callback, text }: Props) => {
	const alert = useAlert();
	const handleError = useHandleError();
	const theme = useTheme();
	const styles = useStyles(theme);

	const [pinValue, setPinValue] = useState<string>('');

	const initialValues: { pin: string } = {
		pin: '',
	};

	const { mutate: mutateValidatePin, isLoading } = useMutation(validatePin, {
		onSettled: (data, error) => {
			if (data && data.success) {
				typeof callback !== 'undefined' && callback(pinValue);
				resetForm();
			}

			if (error) {
				const response = handleError({
					error,
				});

				if (response?.message) {
					alert({
						message: response.message,
						type: 'error',
					});
				}
			}
		},
	});

	const { values, handleSubmit, errors, touched, resetForm, setFieldValue } =
		useFormik({
			initialValues,
			validationSchema: ValidationSchema.ValidatePin,
			onSubmit: (values) => {
				mutateValidatePin(values);
			},
		});

	const { pin } = values;

	return (
		<Box style={styles.form as any} component={'form'}>
			<Box>
				<Typography style={styles.label} component={'label'} variant={'body1'}>
					{text || 'Specify Pin'}
				</Typography>

				<OtpInput
					value={pin}
					errorStyle={errors && touched.pin && errors.pin ? true : false}
					focusStyle={styles.focusStyle}
					inputStyle={moduleStyles.otpInputStyle}
					onChange={(pin) => {
						setPinValue(pin);
						setFieldValue('pin', pin);
					}}
					isInputSecure
					numInputs={4}
					separator={<Box style={styles.separator} />}
				/>
				{errors && touched.pin && errors.pin && (
					<Typography style={styles.errorText} variant={'body2'}>
						{errors.pin}
					</Typography>
				)}
			</Box>

			<Button
				loading={isLoading}
				size={'large'}
				style={styles.btn}
				onClick={(e: React.FormEvent<HTMLButtonElement>) => {
					e.preventDefault();
					handleSubmit();
				}}
			>
				Confirm
			</Button>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	separator: {
		width: '15px',
	},
	inputStyle: {
		height: '55px',
		width: '55px',
		fontSize: '14px',
		color: theme.palette.primary.main,
		borderRadius: theme.spacing(1),
		border: `1px solid ${theme.palette.primary.main}`,
	},
	focusStyle: {
		outlineColor: theme.palette.primary.main,
	},
	errorText: {
		color: red[800],
		marginTop: theme.spacing(1),
	},
});

export default VerifyPinForm;
