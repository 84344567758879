import React, { useState } from 'react';
import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { grey } from '@mui/material/colors';
import TextInput from '../form-components/text-input';
import Button from '../button';
import { useFormik } from 'formik';
import Select from '../form-components/select';
import {
	QUERY_KEYS,
	EPIN_SERVICES,
	API_ENDPOINTS,
	ENDPOINT_SUBPATH,
	IProvider,
	Bundle,
	ValidationSchema,
} from 'utilities';
import { useAppSelector } from 'store/hooks';
import { formatNumberToCurrency } from 'utilities';
import TextHolder from '../partials/custom-text-input-holder';
import {
	useAlert,
	useHandleError,
	useVerifyPin,
	useModalAlert,
	useCheckKycLevelTwo,
} from 'hooks';
import { billBundles, billProviders, generateEPin } from 'api';
import CopyItem from 'components/copy-items';

type Props = {
	setServiceCost?: (value: number) => void;
	setDiscount?: (value: number) => void;
	enableRequest?: boolean;
};

interface InitialValues {
	service_type: string;
	numberOfPins: string;
	price: string;
}

const SELECT_SERVICE_TYPE = 'Select Exam';
const SELECT_EXAM_BUNDLE = 'Select Exam Bundle';

const GenerateEducationEPinForm = ({
	setServiceCost,
	enableRequest,
}: Props) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const { token } = useAppSelector((store) => store.auth);
	const alert = useAlert();
	const verifyPin = useVerifyPin();
	const modal = useModalAlert();
	const handleError = useHandleError();
	const queryClient = useQueryClient();
	const checkKycLevel = useCheckKycLevelTwo();

	const [isLoadingEductionBundles, setLoadingEducationBundles] =
		useState<boolean>(false);
	const [dataDataEducationBundles, setDataEducationBundles] = useState<
		Bundle[] | null
	>(null);

	// Education State
	const initialValues: InitialValues = {
		service_type: SELECT_SERVICE_TYPE,
		numberOfPins: '',
		price: SELECT_EXAM_BUNDLE,
	};

	// Education Bill Payment
	const { isLoading: isMakingPayment, mutate: mutateGenerateEPin } =
		useMutation(generateEPin, {
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						modal({
							message: res.message,
							title: 'Education IEPin',
							primaryButtonText: 'Close',
							type: 'error',
							onClickPrimaryButton: () => modal(null),
						});
					}
				}

				if (data && data.success) {
					queryClient.invalidateQueries(QUERY_KEYS.UserWallet);
					queryClient.invalidateQueries(QUERY_KEYS.Transactions);
					queryClient.invalidateQueries([QUERY_KEYS.RecentTransactions]);
					resetForm();
					modal({
						message: data.message,
						title: 'Education Epin',
						primaryButtonText: 'Generate another',
						secondaryButtonText: 'Close',
						children: <CopyItem text={data.payload.pin} />,
						type: 'success',
						onClickPrimaryButton: () => {
							modal(null);
						},
						onClickSecondaryButton: () => modal(null),
					});
				}
			},
		});

	const {
		handleSubmit,
		errors,
		touched,
		setFieldValue,
		values,
		handleChange,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema: ValidationSchema.GenerateEducationEPin,
		onSubmit: (values) => {
			const data = {
				service: EPIN_SERVICES.EDUCATION,
				pin_data: {
					service_type: values.service_type,
					price: values.price,
					numberOfPins: parseInt(values.numberOfPins),
				},
			};

			if (checkKycLevel()) {
				verifyPin({
					title: 'Education E-Pin',
					message: 'Enter transaction Pin to generate Education pin',
					callback: () => {
						verifyPin(null);
						mutateGenerateEPin(data);
					},
				});
			}
		},
	});

	const { service_type, numberOfPins, price } = values;

	// Load Education Provider
	const {
		isLoading: isLoadingEducationProviders,
		data: dataEducationProviders,
	} = useQuery(
		QUERY_KEYS.EducationProviders,
		() =>
			billProviders(
				`${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.EducationProviders}`
			),
		{
			enabled: !!(token && enableRequest),
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						alert({ message: res.message, type: 'error' });
					}
				}
			},
		}
	);

	const loadEducationBundles = async (provider: string) => {
		const url = `${API_ENDPOINTS.Bill}${ENDPOINT_SUBPATH.EducationProviders}`;
		setLoadingEducationBundles(true);
		try {
			const data = await billBundles({
				url,
				params: {
					provider,
				},
			});

			if (data && data.success) {
				setDataEducationBundles(data.payload);
			}
		} catch (error) {}
		setLoadingEducationBundles(false);
	};

	const handleSelectEducationProvider = (value: string) => {
		setFieldValue('service_type', value);
		loadEducationBundles(value);
	};

	return (
		<Box>
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Exam
					</Typography>
					<Select
						fullWidth
						error={touched.service_type && errors.service_type ? true : false}
						helpertext={touched.service_type && errors.service_type}
						value={service_type}
						onChange={(e) =>
							handleSelectEducationProvider(e.target.value as string)
						}
					>
						<MenuItem value={SELECT_SERVICE_TYPE}>
							{isLoadingEducationProviders
								? 'Loading...'
								: dataEducationProviders &&
								  dataEducationProviders.payload.length === 0
								? 'No available Exam provider'
								: SELECT_SERVICE_TYPE}
						</MenuItem>
						{dataEducationProviders &&
							dataEducationProviders.payload.length > 0 &&
							dataEducationProviders.payload.map(
								(provider: IProvider, key: number) => (
									<MenuItem value={provider.service_type} key={key}>
										{provider.name}
									</MenuItem>
								)
							)}
					</Select>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Exam bundle
					</Typography>
					{service_type === SELECT_SERVICE_TYPE ? (
						<TextHolder text={'Select exam bundle'} isSelectable />
					) : (
						<Select
							fullWidth
							error={touched.price && errors.price ? true : false}
							helpertext={touched.price && errors.price}
							value={price}
							onChange={handleChange('price') as never}
						>
							<MenuItem disabled value={SELECT_EXAM_BUNDLE}>
								{isLoadingEductionBundles
									? 'Loading...'
									: dataDataEducationBundles &&
									  dataDataEducationBundles.length === 0
									? 'No available bundle'
									: 'Select exam bundle'}
							</MenuItem>
							{dataDataEducationBundles &&
								dataDataEducationBundles.length > 0 &&
								dataDataEducationBundles.map((bundle: Bundle, key: number) => (
									<MenuItem value={bundle.amount} key={key}>
										{`${bundle.description} - ${formatNumberToCurrency(
											bundle.amount
										)}`}
									</MenuItem>
								))}
						</Select>
					)}
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Number of pins
					</Typography>
					{price === SELECT_EXAM_BUNDLE ? (
						<TextHolder text={'Enter number of pins'} />
					) : (
						<TextInput
							error={touched.numberOfPins && errors.numberOfPins ? true : false}
							helperText={touched.numberOfPins && errors.numberOfPins}
							type={'number'}
							fullWidth
							placeholder={'Enter number of pins'}
							value={numberOfPins}
							onChange={handleChange('numberOfPins')}
						/>
					)}
				</Box>

				<Button
					loading={isMakingPayment}
					type={'submit'}
					style={styles.btn}
					size={'large'}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Generate e-Pin
				</Button>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default GenerateEducationEPinForm;
