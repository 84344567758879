import React from 'react';

type Props = {
	color?: string;
	size?: number;
	innerColor?: string;
};

const Services = ({ size, color, innerColor }: Props) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 24 24'
			fill={color}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M9.21524 24C9.1348 24 9.05352 23.9853 8.97529 23.955C8.70107 23.8485 8.52979 23.5739 8.55477 23.2808L9.23549 15.2852H2.25219C2.03432 15.2852 1.83036 15.1782 1.70657 14.9989C1.58277 14.8196 1.55493 14.591 1.63213 14.3873L6.91991 0.428203C7.01755 0.170484 7.2644 0 7.53993 0H18.4423C18.6646 0 18.872 0.111375 18.9948 0.296625C19.1177 0.481875 19.1396 0.716391 19.053 0.921047L16.7374 6.4012H21.7476C22.0003 6.4012 22.2309 6.54483 22.3425 6.77152C22.4542 6.9982 22.4271 7.26867 22.2729 7.46883L19.9845 10.4401C19.7611 10.7303 19.3448 10.7843 19.0547 10.5609C18.7645 10.3374 18.7105 9.92114 18.9339 9.63103L20.4001 7.72734H15.7374C15.5151 7.72734 15.3077 7.61597 15.1848 7.43072C15.062 7.24547 15.0401 7.01095 15.1266 6.8063L17.4423 1.32605H7.99785L3.21233 13.9591H9.95737C10.143 13.9591 10.3201 14.037 10.4457 14.1736C10.5713 14.3103 10.6338 14.4934 10.618 14.6783L10.0676 21.1438L15.7846 13.7205C16.0081 13.4303 16.4243 13.3764 16.7144 13.5997C17.0046 13.8232 17.0586 14.2395 16.8352 14.5296L9.74066 23.7415C9.61237 23.9081 9.41657 24 9.21524 24Z'
				fill={color}
			/>
			<path
				d='M12.845 11.97H6.09302L9.37155 3.31519H14.3935L11.6886 9.71644H16.255L12.1661 15.8447L12.845 11.97Z'
				fill={innerColor || '#DCE3E7'}
			/>
			<path
				d='M12.1658 16.5078C12.0833 16.5078 11.9999 16.4924 11.9195 16.4602C11.6284 16.3436 11.4588 16.0391 11.5129 15.7302L12.0556 12.633H6.09301C5.87514 12.633 5.67118 12.526 5.54739 12.3467C5.42359 12.1674 5.39575 11.9388 5.47295 11.7351L8.75138 3.0803C8.84898 2.82258 9.09582 2.6521 9.37145 2.6521H14.3934C14.6157 2.6521 14.8231 2.76347 14.946 2.94872C15.0688 3.13397 15.0907 3.36849 15.0041 3.57315L12.6885 9.0533H16.2549C16.4995 9.0533 16.7243 9.18797 16.8395 9.4036C16.9549 9.61922 16.9422 9.88093 16.8064 10.0843L12.7174 16.2126C12.5914 16.4015 12.3821 16.5078 12.1658 16.5078ZM7.05306 11.307H12.8449C13.0408 11.307 13.2266 11.3935 13.3526 11.5435C13.4785 11.6935 13.5318 11.8916 13.498 12.0844L13.3627 12.8565L15.0155 10.3794H11.6886C11.4662 10.3794 11.2588 10.2681 11.136 10.0828C11.0132 9.89757 10.9914 9.66305 11.0779 9.45839L13.3935 3.97824H9.82932L7.05306 11.307Z'
				fill={color}
			/>
		</svg>
	);
};

export default Services;
