import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { Delete } from '@mui/icons-material';
import SecurityItem from './security-item';
import Button from '../button';
import { DANGER_COLOR, LINKS } from 'utilities';
import { useAppSelector } from 'store/hooks';
import TwoFactorAuth from './two-factor-auth';
import { useHandleError, useAlert, useModalAlert, useLogoutUser } from 'hooks';
import {
	deleteAccount,
	requestDeleteAccountToken,
	resetPasswordToken,
	resetPinToken,
} from 'api';
import { useMutation } from 'react-query';
import Loader from 'components/loader';
import OtpForm from 'components/forms/otp-form';

const Security = () => {
	const theme = useTheme();
	const { pathname, search } = useLocation();
	const alert = useAlert();
	const handleError = useHandleError();
	const modal = useModalAlert();
	const styles = useStyles(theme);
	const navigate = useNavigate();
	const { user } = useAppSelector((store) => store.auth);
	const logout = useLogoutUser();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isRequestingDeleteAccountOtp, setRequestingDeleteAccountOtp] =
		useState<boolean>(false);

	const [isRequestingResetPinToken, setRequestingResetPinToken] =
		useState<boolean>(false);

	const handleSendPinToken = async () => {
		setRequestingResetPinToken(true);
		try {
			const data = await resetPinToken();
			if (data && data.success)
				alert({ message: data.message, type: 'success' });
			const redirect = `${pathname}${search}`;
			navigate(`${LINKS.ResetPin}?redirect=${redirect}`);
		} catch (error) {
			const response = handleError({ error });
			if (response?.message)
				alert({ message: response.message, type: 'error' });
		}
		setRequestingResetPinToken(false);
	};

	const handleSendPasswordToken = async () => {
		setLoading(true);
		try {
			const data = await resetPasswordToken({
				email: user?.email as string,
			});
			if (data && data.success)
				alert({ message: data.message, type: 'success' });
			const redirect = `${pathname}${search}`;
			navigate(`${LINKS.ResetPassword}?redirect=${redirect}`);
		} catch (error) {
			const response = handleError({ error });
			if (response?.message)
				alert({ message: response.message, type: 'error' });
		}
		setLoading(false);
	};

	const { isLoading: isLoadingDeleteAccount, mutate: mutateDeleteAccount } =
		useMutation(deleteAccount, {
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message)
						alert({ message: response.message, type: 'error' });

					return;
				}

				modal(null);
				alert({ message: 'Account deleted successfully!', type: 'success' });
				logout();
			},
		});

	const handleDeleteAccount = (code: string) => {
		mutateDeleteAccount({ code });
	};

	// Request Delete account token
	const handleRequestDeleteToken = async () => {
		setRequestingDeleteAccountOtp(true);
		try {
			const response = await requestDeleteAccountToken();
			setRequestingDeleteAccountOtp(false);

			if (response.success) {
				modal({
					title: 'Delete Account',
					message: 'Confirm the OTP sent to your email to delete account',
					children: <OtpForm callback={(otp) => handleDeleteAccount(otp)} />,
					hasCloseButton: true,
				});
			}
		} catch (error) {
			const response = handleError({ error });
			setRequestingDeleteAccountOtp(false);

			if (response?.message)
				alert({ message: response.message, type: 'error' });
		}
	};

	const onClickDeleteButton = () => {
		modal({
			title: 'Delete Account',
			message:
				'To delete account we will send you an otp to your email register to Airtimeflip',
			type: 'pending',
			primaryButtonText: 'send OTP',
			secondaryButtonText: 'Cancel',
			onClickPrimaryButton: () => {
				handleRequestDeleteToken();
				modal(null);
			},
			onClickSecondaryButton: () => {
				modal(null);
			},
		});
	};
	return (
		<>
			{(isLoadingDeleteAccount || isRequestingDeleteAccountOtp) && <Loader />}
			<Box style={styles.container} sx={{ maxWidth: '400px' }}>
				{/* {user && user?.kycLevel === 2 && !user.bvnVerified && (
					<SecurityItem
						title={'BVN Verification'}
						description={'Bank Verification number has not been verified.'}
						button={
							<Button
								// onClick={() => navigate(LINKS.BvnVerification)}
								onClick={navigateVerifyBvn}
								startIcon={<VerifiedUser />}
								style={styles.btn}
								size={'large'}
							>
								Verify BVN
							</Button>
						}
						// button={<VerifyBvnButton />}
					/>
				)} */}
				<SecurityItem
					title={'Two Factor Authentication'}
					description={
						'Two factor authentication is an extra layer of security where we email you a code to verify it’s you before you login.'
					}
					button={<TwoFactorAuth />}
				/>
				<SecurityItem
					title={'Reset password'}
					description={'Forgot your account password? You can reset it now!'}
					button={
						<Button
							onClick={handleSendPasswordToken}
							loading={isLoading}
							style={styles.btn}
							size={'large'}
						>
							Reset password
						</Button>
					}
				/>
				<SecurityItem
					title={'Reset pin'}
					description={'Forgot your 4 digit pin? You can reset it now!'}
					button={
						<Button
							onClick={handleSendPinToken}
							loading={isRequestingResetPinToken}
							style={styles.btn}
							size={'large'}
						>
							Reset pin
						</Button>
					}
				/>
				<SecurityItem
					title={'Delete account'}
					description={'Delete your account and all associated details'}
					button={
						<Button
							onClick={onClickDeleteButton}
							loading={isLoadingDeleteAccount}
							startIcon={<Delete />}
							style={styles.deleteBtn}
							size={'large'}
						>
							Delete account
						</Button>
					}
				/>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	container: {
		display: 'grid',
		gridTemplateColumn: '1fr',
		gap: '30px',
	},
	activateBtn: {
		borderTopRightRadius: '0px',
		borderBottomRightRadius: '0px',
		borderRight: '0px',
		// backgroundColor: '#FCE2C0',
	},
	deactivateBtn: {
		borderTopLeftRadius: '0px',
		borderBottomLeftRadius: '0px',
		borderLeft: '0px',
	},
	btn: {
		fontSize: '14px',
		maxWidth: '180px',
		fontWeight: '600',
		color: theme.palette.secondary.main,
		border: `1px solid ${theme.palette.secondary.main}`,
	},
	deleteBtn: {
		fontSize: '14px',
		maxWidth: '180px',
		fontWeight: '600',
		color: DANGER_COLOR,
		border: `1px solid ${DANGER_COLOR}`,
	},
	verticalLine: {
		backgroundColor: theme.palette.secondary.main,
		width: '2px',
		height: '100%',
	},
});

export default Security;
