import React, { useState, MouseEvent } from 'react';
import {
	Button,
	useTheme,
	ClickAwayListener,
	Popper,
	Paper,
	Avatar,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { useAppSelector } from 'store/hooks';
import { THEME_MODE, LIGHT_GRAY, extractUserName, IUser } from 'utilities';
import AuthList from '../nav-list/auth-list';

const UserAvatarButton = () => {
	const {
		theme: { mode },
		auth: { user },
	} = useAppSelector((store) => store);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const theme = useTheme();
	const styles = useStyles(theme, mode);
	return (
		<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
			<div>
				<Button
					onClick={handleClick}
					style={styles.button}
					endIcon={<ArrowDropDown />}
					startIcon={
						<Avatar src={user?.photoUrl || ''} style={styles.avatar} />
					}
				>
					{extractUserName(user as IUser)}
				</Button>
				<Popper
					sx={{ zIndex: theme.zIndex.appBar + 10 }}
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
				>
					<Paper
						sx={{
							bgcolor: 'background.paper',
							marginTop: '15px',
							width: '200px',
							padding: '15px 0px',
						}}
					>
						<AuthList closePopper={() => setAnchorEl(null)} />
					</Paper>
				</Popper>
			</div>
		</ClickAwayListener>
	);
};

const useStyles = (theme: any, mode: string) => ({
	button: {
		border: `1px solid ${
			mode === THEME_MODE.dark ? grey[300] : theme.palette.primary.main
		}`,
		color: mode === THEME_MODE.dark ? grey[300] : theme.palette.primary.main,
		overflow: 'hidden',
		// minWidth: '180px',
		padding: '8px',
		borderRadius: '5px',
		fontWeight: '600',
	},
	avatar: {
		height: '25px',
		width: '25px',
		borderRadius: theme.spacing(1),
		backgroundColor: LIGHT_GRAY,
	},
});

export default UserAvatarButton;
