import React, { useState } from 'react';
import { Box } from '@mui/material';
import {
	Layout,
	Seo,
	TwoColGrid,
	VirtualAccount,
	WalletBalance,
	WalletTopupForm,
	WalletTopupTab,
	BillBreakdown,
	BackButton,
	// WalletWithBreakdown,
} from 'components';
import { useAppDispatch } from 'store/hooks';
import { setTransactionBreakdown } from 'store/transaction';

const WalletTopupPage = () => {
	const dispatch = useAppDispatch();
	const [currentTab, setCurrentTab] = useState<number>(0);
	const title =
		currentTab === 0 ? 'Fund wallet with bank' : 'Fund wallet with card';
	const handleChangeTab = (e: HTMLFormElement, value: number) => {
		dispatch(setTransactionBreakdown(null));
		setCurrentTab(value);
	};

	return (
		<Layout>
			<Seo title={title} />
			<BackButton name={'Back'} sx={{ marginBottom: ['1.5rem'] }} />
			<Box>
				<WalletTopupTab
					currentTab={currentTab}
					handleChange={handleChangeTab}
				/>
				<TwoColGrid
					rightAside={
						<Box
							sx={{
								display: 'grid',
								gap: '20px',
							}}
						>
							{/* <WalletWithBreakdown isStickySidebar /> */}
							<WalletBalance isHideTopupBtn isSticky top={'10rem'} />
							{currentTab === 1 && <BillBreakdown />}
						</Box>
					}
				>
					<Box hidden={currentTab !== 0}>
						<VirtualAccount />
					</Box>
					<Box hidden={currentTab !== 1}>
						<WalletTopupForm />
					</Box>
				</TwoColGrid>
			</Box>
		</Layout>
	);
};

export default WalletTopupPage;
