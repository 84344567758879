import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useAlert } from 'hooks';

interface Props {
	label: string;
	value: string;
	hasCopy?: boolean;
}

const TransactionItem: React.FC<Props> = ({
	label,
	value,
	hasCopy = false,
}) => {
	const alert = useAlert();
	const onCopy = () =>
		alert({ message: `${value} copied to clipboard`, type: 'success' });
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				gap: (theme) => theme.spacing(2),
			}}
		>
			<Typography sx={{ fontWeight: 'bold' }} variant={'body1'}>
				{label}:
			</Typography>
			<Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
				<Typography
					sx={{
						textTransform: 'capitalize',
					}}
					variant={'body1'}
				>
					{value}
				</Typography>
				{hasCopy && (
					<CopyToClipboard text={value} onCopy={onCopy}>
						<IconButton>
							<ContentCopy sx={{ fontSize: '16px' }} />
						</IconButton>
					</CopyToClipboard>
				)}
			</Box>
		</Box>
	);
};

export default TransactionItem;
