import React from 'react';
import { AuthLayout, ConfirmLoginOtpForm } from 'components';

const ConfirmLoginOtp = () => {
	return (
		<AuthLayout>
			<ConfirmLoginOtpForm />
		</AuthLayout>
	);
};

export default ConfirmLoginOtp;
