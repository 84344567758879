import {
	getMessaging,
	getToken as firebaseGetToken,
	onMessage,
} from 'firebase/messaging';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
	apiKey: 'AIzaSyAQiPqmIGfAgZI2EmAJ4iDtnNtTxcoCZag',
	authDomain: 'airtimeflip-aafa9.firebaseapp.com',
	projectId: 'airtimeflip-aafa9',
	storageBucket: 'airtimeflip-aafa9.appspot.com',
	messagingSenderId: '734091444995',
	appId: '1:734091444995:web:9dbbec862b48e2aa321f36',
	measurementId: 'G-48WBF6ZVW4',
};

export const firebaseApp = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const getToken = async () => {
	try {
		const token = await firebaseGetToken(messaging, {
			vapidKey:
				'BIMTJVNBmIXx3LtMLJDldePrDaySVWDXiSKq14cEJbyEK0t-KxYy3rtUhwP7dj9KE7WKklZu_rNAM9fm4KRl61c',
		});

		if (token) {
			console.log('current token for client: ', token);
		} else {
			console.log(
				'No registration token available. Request permission to generate one.'
			);
		}
	} catch (error) {
		console.log('An error occurred while retrieving token. ', error);
	}
};

export const onMessageListener = () =>
	new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			resolve(payload);
		});
	});

// export default firebase;
