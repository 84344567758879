import React from 'react';

type Props = {
	color?: string;
	width?: number;
	height?: number;
	innerColor?: string;
};

const VerifiedIcon = ({ width, height, color, innerColor }: Props) => {
	return (
		<svg
			width={width || '13'}
			height={height || '16'}
			viewBox='0 0 13 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6.5 0L5.14714e-05 2.90909V7.27273C-0.00656274 9.24128 0.624447 11.1583 1.79742 12.7332C2.97038 14.3082 4.62135 15.455 6.5 16C8.37865 15.455 10.0296 14.3082 11.2026 12.7332C12.3756 11.1583 13.0066 9.24128 12.9999 7.27273V2.90909L6.5 0ZM5.05543 11.6364L2.1663 8.72727L3.18464 7.7019L5.05543 9.5783L9.81415 4.78549L10.8325 5.81818L5.05543 11.6364Z'
				fill={color || '#28536B'}
			/>
		</svg>
	);
};

export default VerifiedIcon;
