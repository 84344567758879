import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Button from '../../button';
import {
	LIGHT_GRAY,
	ErrorBoundaryGuard,
	formatNumberToCurrency,
	THEME_MODE,
} from 'utilities';
import Image from '../../image';
import { useAppSelector } from 'store/hooks';
import { grey } from '@mui/material/colors';

type Props = {
	isHideTopupBtn?: boolean;
	isSticky?: boolean;
	top?: string;
};

const WalletBalance = ({ isHideTopupBtn, isSticky, top }: Props) => {
	const theme = useTheme();
	const { isLoading, wallet } = useAppSelector((store) => store.walletState);
	const mode = useAppSelector((store) => store.theme.mode);
	const isDark = mode === THEME_MODE.dark;
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				position: isSticky ? 'sticky' : 'initial',
				top: top || '6rem',
				gap: '15px',
				backgroundColor: isDark ? theme.palette.background.paper : LIGHT_GRAY,
				padding: '20px 15px',
				borderRadius: '5px',
			}}
		>
			<Typography
				sx={{ color: isDark ? grey['100'] : theme.palette.primary.main }}
				variant={'body1'}
			>
				Wallet balance
			</Typography>
			<ErrorBoundaryGuard errorMessage={'Wallet balance error'}>
				<Typography
					sx={{
						fontWeight: '300',
						fontSize: '28px',
						color: isDark ? grey['100'] : theme.palette.primary.main,
					}}
					variant={'h4'}
				>
					{isLoading
						? '---'
						: wallet &&
						  wallet.balance &&
						  formatNumberToCurrency(
								typeof wallet.balance === 'string'
									? wallet.balance
									: wallet.balance.$numberDecimal
						  )}
				</Typography>
			</ErrorBoundaryGuard>
			{!isHideTopupBtn && (
				<Button
					sx={{
						fontWeight: '600',
						color: isDark ? grey['100'] : theme.palette.primary.main,
					}}
					startIcon={
						<Image
							sx={{
								width: '20px',
								img: {
									width: '100%',
									display: 'block',
								},
							}}
							src={require('assets/icons/fund-wallet.png')}
							alt={'fund wallet'}
						/>
					}
				>
					Top wallet
				</Button>
			)}
		</Box>
	);
};

export default WalletBalance;
