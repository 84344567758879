import React, { useRef } from 'react';
import moment from 'moment';
import { ITransaction, checkAmount, formatNumberToCurrency } from 'utilities';
import AppTable from './components/table';
import TransactionDetails from 'components/transaction-details';

interface Props {
	data: ITransaction[] | null;
	isLoading?: boolean;
}

const BettingFundingsTable = ({ data, isLoading }: Props) => {
	const transactionDetailsRef = useRef<any>(null);
	const handleRowClick = (transaction: ITransaction) => {
		if (transactionDetailsRef.current)
			transactionDetailsRef.current.display(transaction);
	};
	return (
		<>
			<TransactionDetails ref={transactionDetailsRef} />
			<AppTable
				canClickRow
				onRowClick={handleRowClick}
				numberOfColumns={7}
				isLoading={isLoading}
				header={[
					'Reference',
					'Provider',
					'Customer ID',
					'Amount',
					'Created At',
					'Status',
				]}
				body={
					data &&
					data.map((value) => {
						return {
							data: [
								value.reference,
								value?.name,
								value?.customerId,

								formatNumberToCurrency(checkAmount(`${value?.amount}`)),
								moment(value.createdAt).format('ll'),
								value.status,
							],
							rawData: value,
						};
					})
				}
				emptyText={'No withdrawal transaction'}
			/>
		</>
	);
};

export default BettingFundingsTable;
