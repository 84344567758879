import React from 'react';
import { Box, useTheme } from '@mui/material';
import * as yup from 'yup';
import { grey } from '@mui/material/colors';
import { useFormik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import TextInput from '../form-components/text-input';
import { useAppSelector } from 'store/hooks';
import {
	THEME_MODE,
	TicketReply,
	ITicket,
	QUERY_KEYS,
	TICKET_STATUS,
} from 'utilities';
import Button from '../button';
import { useModalAlert, useHandleError } from 'hooks';
import { replyTicket } from 'api';

interface Props {
	ticket: ITicket | null;
}

const ReplyTicketForm = ({ ticket }: Props) => {
	const { mode } = useAppSelector((store) => store.theme);
	const theme = useTheme();
	const handleError = useHandleError();
	const modal = useModalAlert();
	const queryClient = useQueryClient();

	const validationSchema = yup.object().shape({
		reply: yup.string().required('Enter reply message'),
	});

	const initialValues: TicketReply = {
		reply_type: 'User',
		reply: '',
	};

	const { isLoading, mutate } = useMutation(replyTicket, {
		onSettled: (data, error) => {
			if (error) {
				const response = handleError({ error });
				if (response?.message) {
					modal({
						title: 'Reply Ticket',
						message: response.message,
						type: 'error',
						primaryButtonText: 'Close',
						onClickPrimaryButton: () => modal(null),
					});
				}
			}

			if (data && data.success) {
				resetForm();
				queryClient.invalidateQueries(QUERY_KEYS.Ticket);
				queryClient.invalidateQueries(ticket?.id);
			}
		},
	});

	const { errors, touched, handleChange, handleSubmit, values, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: (values) => {
				mutate({
					data: values,
					code: ticket?.code as string,
				});
			},
		});

	const isDisabled =
		ticket && ticket.status === TICKET_STATUS.CLOSED ? true : false;

	return (
		<>
			<Box
				sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: '8px' }}
				component={'form'}
			>
				<TextInput
					disabled={isDisabled}
					placeholder={'Type in reply'}
					sx={{
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: mode === THEME_MODE.light ? grey['300'] : grey[50],
						},
						'&.MuiFormControl-root': {
							backgroundColor: 'white',
						},
						'& .Mui-focused': {
							outline: 'none !important',
						},
					}}
					multiline
					rows={2}
					fullWidth
					error={touched.reply && errors.reply ? true : false}
					helperText={touched.reply && errors.reply}
					value={values.reply}
					onChange={handleChange('reply')}
				/>
				<Button
					loading={isLoading}
					disabled={isDisabled}
					sx={{
						backgroundColor: `${theme.palette.secondary.main} !important`,
						color: grey['50'],
						minWidth: '160px',
						justifySelf: 'flex-start',
					}}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Submit
				</Button>
			</Box>
		</>
	);
};

export default ReplyTicketForm;
