import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme, InputAdornment } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useFormik } from 'formik';
import TextInput from '../form-components/text-input';
import Button from '../button';
import { useAppSelector } from 'store/hooks';
import { ValidationSchema } from 'utilities';

const ProfileForm = () => {
	const theme = useTheme();
	const styles = useStyles(theme);

	const { user } = useAppSelector((store) => store.auth);

	const [editField, setEditField] = useState<{ [key: string]: boolean }>({
		isEditFirstname: false,
		isEditLastname: false,
		isEditEmail: false,
		isEditPhone: false,
		isEditUsername: false,
	});

	const { isEditPhone, isEditUsername } = editField;

	const handleEditField = ({
		field,
		status,
	}: {
		field: string;
		status: boolean;
	}) =>
		setEditField({
			...editField,
			[field]: status,
		});

	const initialValues = {
		firstname: '',
		lastname: '',
		email: '',
		phone: '',
		username: '',
	};

	const { setValues, values, handleChange } = useFormik({
		initialValues,
		validationSchema: ValidationSchema.ProfileDetail,
		onSubmit: (values) => {
			console.log(values);
		},
	});

	const { firstname, lastname, email, phone, username } = values;

	useEffect(
		() => {
			if (user) {
				setValues({
					firstname: user.firstname,
					lastname: user.lastname,
					phone: user.phone,
					email: user.email,
					username: user.username,
				});
			}
		},
		// eslint-disable-next-line
		[user]
	);

	return (
		<>
			<Box style={styles.form as any} component={'form'}>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: {
							xs: '1fr',
							md: 'repeat(2, 1fr)',
							gap: '15px',
						},
					}}
				>
					{firstname && (
						<Box>
							<Typography
								style={styles.label}
								component={'label'}
								variant={'body1'}
							>
								First name
							</Typography>
							<TextInput
								fullWidth
								placeholder={'Type in your first name'}
								disabled={true}
								value={firstname}
								onChange={handleChange('firstname')}
							/>
						</Box>
					)}
					{lastname && (
						<Box>
							<Typography
								style={styles.label}
								component={'label'}
								variant={'body1'}
							>
								Last name
							</Typography>
							<TextInput
								fullWidth
								disabled={true}
								placeholder={'Type in your last name'}
								value={lastname}
								onChange={handleChange('lastname')}
							/>
						</Box>
					)}
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Email address
					</Typography>
					<TextInput
						fullWidth
						disabled={true}
						placeholder={'Type in your email address'}
						value={email}
						onChange={handleChange('email')}
					/>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						User name
					</Typography>
					<TextInput
						fullWidth
						disabled={true}
						placeholder={'Type in your preferred user name'}
						value={username}
						onChange={handleChange('username')}
						/* InputProps={{
							endAdornment: (
								<InputAdornment position='start'>
									<Button
										onClick={() =>
											handleEditField({
												field: 'isEditUsername',
												status: !isEditUsername,
											})
										}
										disableRipple
										style={styles.applyBtn}
									>
										{isEditUsername ? 'Cancel' : 'Edit'}
									</Button>
								</InputAdornment>
							),
						}} */
					/>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Phone
					</Typography>
					<TextInput
						fullWidth
						disabled={true}
						placeholder={'Type in your phone number'}
						value={phone}
						onChange={handleChange('phone')}
						/* InputProps={{
							endAdornment: (
								<InputAdornment position='start'>
									<Button
										onClick={() =>
											handleEditField({
												field: 'isEditPhone',
												status: !isEditPhone,
											})
										}
										disableRipple
										style={styles.applyBtn}
									>
										{isEditPhone ? 'Cancel' : 'Edit'}
									</Button>
								</InputAdornment>
							),
						}} */
					/>
				</Box>
				{/* <Button
					type={'submit'}
					size={'large'}
					style={styles.btn}
					onClick={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Save all changes
				</Button> */}
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default ProfileForm;
