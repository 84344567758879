import React, { CSSProperties } from 'react';
import { Box, Typography, useTheme, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { grey } from '@mui/material/colors';
import { useMutation } from 'react-query';
import TextInput from '../form-components/text-input';
import Button from '../button';
import {
	STORAGE_KEYS,
	Storage,
	VerifyEmailPayload,
	ValidationSchema,
	LINKS,
} from 'utilities';
import { useModalAlert, useHandleError, useAlert } from 'hooks';
import { resendVerificationEmail, verifyEmail } from 'api';

type Props = {
	handleVerifyCode?: (code: string) => void;
};

const VerifyEmailForm = ({ handleVerifyCode }: Props) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const alert = useAlert();
	const handleError = useHandleError();
	const navigate = useNavigate();
	const modal = useModalAlert();
	const email = Storage.getItem(STORAGE_KEYS.UserEmail) || '';

	const initialValues: VerifyEmailPayload = {
		code: '',
	};

	const { isLoading: isResendingCode, mutate: mutateResendVerificationCode } =
		useMutation(resendVerificationEmail, {
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					alert({ message: response?.message, type: 'error' });
				}
				if (data && data.success) {
					alert({ message: 'Code sent successfully', type: 'success' });
				}
			},
		});

	const { isLoading: isVerifyingEmail, mutate: mutateVerifyEmail } =
		useMutation(verifyEmail, {
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message)
						alert({ message: response.message, type: 'error' });
				}
				if (data && data.success) {
					// navigate(`${LINKS.Register}?step=bvn_verification`);

					modal({
						title: 'Verify Email',
						message: 'Email verified successfully! log in',
						primaryButtonText: 'Login',
						type: 'success',
						onClickPrimaryButton: function () {
							modal(null);
							navigate(`${LINKS.Login}`);
						},
					});
				}
			},
		});

	const { handleChange, handleSubmit, errors, touched, values } = useFormik({
		initialValues,
		validationSchema: ValidationSchema.VerifyEmail,
		onSubmit: (values) => {
			if (!email) {
				return alert({
					message: 'Something went wrong, try again!!',
					type: 'error',
				});
			}

			mutateVerifyEmail({
				email,
				code: values.code,
			});
		},
	});

	return (
		<Box>
			<Box sx={{ marginBottom: theme.spacing(4) }}>
				<Typography style={styles.title} variant={'h5'}>
					Verify Email
				</Typography>
				<Typography variant={'body1'}>
					Type in the code sent to your email{' '}
					<Box style={styles.emailText} component={'span'}>
						{email}
					</Box>{' '}
					to verify your email.
				</Typography>
			</Box>
			<Box style={styles.form as CSSProperties} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Verification code
					</Typography>
					<TextInput
						fullWidth
						placeholder={'xxxxxx'}
						error={errors && touched.code && errors.code ? true : false}
						helperText={errors && touched.code && errors.code}
						value={values.code}
						onChange={handleChange('code')}
					/>
					<Box
						sx={{
							marginTop: '6px',
							display: 'flex',
							gap: '8px',
							alignItems: 'center',
						}}
					>
						<Typography>
							Didn't get code?
							<Box
								onClick={() => {
									mutateResendVerificationCode({
										email,
									});
								}}
								style={styles.resendLink as CSSProperties}
								component={'span'}
							>
								Resend code
							</Box>
						</Typography>
						{isResendingCode && (
							<CircularProgress
								sx={{ color: theme.palette.secondary.main }}
								size={'16px'}
							/>
						)}
					</Box>
				</Box>
				<Box>
					<Button
						loading={isVerifyingEmail}
						sx={{ marginBottom: '10px' }}
						fullWidth
						size={'large'}
						style={styles.btn}
						onClick={(e: React.FormEvent<HTMLButtonElement>) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						Confirm
					</Button>
					<Button
						sx={{
							color: theme.palette.secondary.main,
							':hover': {
								backgroundColor: theme.palette.secondary.main,
								color: grey[50],
							},
						}}
						fullWidth
						size={'large'}
						style={styles.btnOutline}
						onClick={(e) => {
							e.preventDefault();
							navigate(-1);
						}}
					>
						Go back
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontSize: '14px',
		fontWeight: '600',
	},
	btnOutline: {
		border: `1px solid ${theme.palette.secondary.main}`,
		fontSize: '14px',
		fontWeight: '600',
	},
	emailText: {
		fontWeight: '600',
	},
	resendLink: {
		fontWeight: '600',
		color: theme.palette.secondary.main,
		cursor: 'pointer',
		userSelect: 'none',
		marginLeft: '5px',
	},
});

export default VerifyEmailForm;
