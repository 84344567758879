import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import { useFormik } from 'formik';
import Button from '../button';
import * as yup from 'yup';
import TextInput from '../form-components/text-input';
import { LINKS, EPIN_SERVICES } from 'utilities';
import { useAlert, useHandleError } from 'hooks';
import { validateEPin } from 'api';

const ValidateEPin = () => {
	const theme = useTheme();
	const handleError = useHandleError();
	const styles = useStyles(theme);
	const alert = useAlert();
	const navigate = useNavigate();
	const [isValidatingPin, setValidatingPin] = useState<boolean>(false);

	const validationSchema = yup.object().shape({
		code: yup.string().required('Enter Epin code'),
	});

	const initialValues: { code: string } = {
		code: '',
	};

	const { handleChange, handleSubmit, errors, touched, values } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			handleValidatePin(values.code);
		},
	});

	const { code } = values;

	const navigateToLoadPage = (page: string, used: boolean) => {
		if (used) {
			return alert({ message: 'Epin has been used', type: 'error' });
		}

		navigate(page);
	};

	const handleValidatePin = async (pin: string) => {
		try {
			setValidatingPin(true);
			const data = await validateEPin(pin);
			if (data && data.success) {
				const pin = data.payload.pin;
				const amount = data.payload.amount;
				const service = data.payload.service;
				const used = data.payload.used;

				// Switch Response Service Type
				switch (data.payload.service) {
					case EPIN_SERVICES.AIRTIME:
						return navigateToLoadPage(
							`${LINKS.LoadAirtime}?pin=${pin}&network=${data.payload.pin_data.network}&amount=${amount}`,
							used
						);

					case EPIN_SERVICES.DATA:
						return navigateToLoadPage(
							`${LINKS.LoadData}?pin=${pin}&service=${service}&plan=${data.payload.pin_data.plan}&amount=${amount}`,
							used
						);
					case EPIN_SERVICES.CABLE:
						return navigateToLoadPage(
							`${LINKS.LoadCable}?pin=${pin}&service=${service}&service_type=${data.payload.pin_data.service_type}&product_code=${data.payload.pin_data.product_code}&monthsPaidFor=${data.payload.pin_data.monthsPaidFor}&amount=${amount}`,
							used
						);
					case EPIN_SERVICES.ELECTRICITY:
						return navigateToLoadPage(
							`${LINKS.LoadPower}?pin=${pin}&service_type=${data.payload.pin_data.service_type}&amount=${amount}`,
							used
						);

					case EPIN_SERVICES.EDUCATION:
						return navigateToLoadPage(
							`${LINKS.LoadEducation}?pin=${pin}&service_type=${data.payload.pin_data.service_type}&amount=${amount}`,
							used
						);

					case EPIN_SERVICES.INTERNET:
						return navigateToLoadPage(
							`${LINKS.LoadInternetSubscription}?pin=${pin}&service_type=${data.payload.pin_data.service_type}&amount=${amount}`,
							used
						);

					default:
						alert({ message: 'Something went wrong', type: 'info' });
				}
			}
		} catch (error) {
			setValidatingPin(false);

			const response = handleError({ error });
			if (response?.message) {
				alert({ message: response.message, type: 'error' });
			}
		}
	};

	return (
		<Box>
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						E-pin code
					</Typography>
					<TextInput
						fullWidth
						placeholder={'Type in your e-pin code number here'}
						error={touched.code && errors.code ? true : false}
						helperText={touched.code && errors.code}
						value={code}
						onChange={handleChange('code')}
					/>
				</Box>

				<Button
					loading={isValidatingPin}
					size={'large'}
					style={styles.btn}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Next
				</Button>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
	},
});

export default ValidateEPin;
