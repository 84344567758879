import React from 'react';

type Props = {
	color?: string;
	size?: number;
};

const GiftIcon = ({ size = 33, color }: Props) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 33 33'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g filter='url(#filter0_d_1146_80832)'>
				<path
					d='M25.6658 12.4785V23.9575H7.29883V12.4785'
					stroke={color || '#F38D04'}
					strokeWidth='1.3'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M5.00195 6.74023H27.961V12.4802H5.00195V6.74023Z'
					stroke={color || '#F38D04'}
					strokeWidth='1.3'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M16.4805 23.9592V6.74023'
					stroke={color || '#F38D04'}
					strokeWidth='1.3'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M16.4813 6.74H11.3153C10.5541 6.74 9.82415 6.43763 9.28592 5.8994C8.74769 5.36117 8.44531 4.63117 8.44531 3.87C8.44531 3.10883 8.74769 2.37883 9.28592 1.8406C9.82415 1.30237 10.5541 1 11.3153 1C15.3333 1 16.4813 6.74 16.4813 6.74Z'
					stroke={color || '#F38D04'}
					strokeWidth='1.3'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M16.4805 6.74H21.6465C22.4076 6.74 23.1376 6.43763 23.6759 5.8994C24.2141 5.36117 24.5165 4.63117 24.5165 3.87C24.5165 3.10883 24.2141 2.37883 23.6759 1.8406C23.1376 1.30237 22.4076 1 21.6465 1C17.6285 1 16.4805 6.74 16.4805 6.74Z'
					stroke={color || '#F38D04'}
					strokeWidth='1.3'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
			{/* <defs>
				<filter
					id='filter0_d_1146_80832'
					x='0.00195312'
					y='0'
					width='32.959'
					height='32.959'
					filterUnits='userSpaceOnUse'
					color-interpolation-filters='sRGB'
				>
					<feFlood flood-opacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='4' />
					<feGaussianBlur stdDeviation='0' />
					<feComposite in2='hardAlpha' operator='out' />
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
					/>
					<feBlend
						mode='normal'
						in2='BackgroundImageFix'
						result='effect1_dropShadow_1146_80832'
					/>
					<feBlend
						mode='normal'
						in='SourceGraphic'
						in2='effect1_dropShadow_1146_80832'
						result='shape'
					/>
				</filter>
			</defs> */}
		</svg>
	);
};

export default GiftIcon;
