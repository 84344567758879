import { configureStore } from '@reduxjs/toolkit';
import themeReducer from './theme';
import appReducer from './app';
import authReducer from './auth';
import walletReducer from './wallet';
import transactionReducer from './transaction';
import notificationReducer from './notification';

const store = configureStore({
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
	reducer: {
		theme: themeReducer,
		app: appReducer,
		auth: authReducer,
		walletState: walletReducer,
		transactionState: transactionReducer,
		notification: notificationReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
