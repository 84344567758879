import React from 'react';

type Props = {
	color?: string;
	width?: number;
	height?: number;
	innerColor?: string;
};

const InformationIcon = ({ width, height, color, innerColor }: Props) => {
	return (
		<svg
			width={width || '14'}
			height={height || '14'}
			viewBox='0 0 14 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6.557 2.74485e-06C5.25953 0.000793766 3.99142 0.386262 2.913 1.10767C1.83457 1.82909 0.994255 2.85405 0.498282 4.05299C0.00230873 5.25192 -0.12705 6.571 0.126559 7.84344C0.380168 9.11589 1.00536 10.2846 1.92309 11.2017C2.84082 12.1189 4.00989 12.7434 5.28249 12.9962C6.55509 13.2491 7.87408 13.1189 9.07271 12.6222C10.2713 12.1255 11.2958 11.2845 12.0166 10.2057C12.7373 9.12682 13.122 7.85848 13.122 6.561C13.1223 5.69899 12.9526 4.84538 12.6227 4.04898C12.2929 3.25258 11.8092 2.52902 11.1995 1.91967C10.5898 1.31032 9.86593 0.827137 9.06933 0.497745C8.27273 0.168352 7.41902 -0.000785843 6.557 2.74485e-06ZM7.157 9.589H5.952V5.044H7.152L7.157 9.589ZM6.557 4.549C6.43352 4.54449 6.3141 4.50377 6.21358 4.4319C6.11307 4.36004 6.03591 4.2602 5.9917 4.14481C5.9475 4.02943 5.9382 3.9036 5.96496 3.78297C5.99172 3.66234 6.05337 3.55224 6.14222 3.46638C6.23108 3.38052 6.34322 3.32268 6.4647 3.30007C6.58617 3.27745 6.71162 3.29106 6.82542 3.33919C6.93922 3.38732 7.03636 3.46786 7.10474 3.57077C7.17312 3.67369 7.20972 3.79444 7.21 3.918C7.2098 4.00289 7.19249 4.08686 7.15912 4.16491C7.12575 4.24297 7.077 4.31349 7.01577 4.37229C6.95455 4.43109 6.88211 4.47695 6.80277 4.50714C6.72343 4.53733 6.63883 4.55123 6.554 4.548L6.557 4.549Z'
				fill={color || '#28536B'}
			/>
		</svg>
	);
};

export default InformationIcon;
