import React from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { ContentCopy } from '@mui/icons-material';
import { LINKS, LIGHT_GRAY, REFERRAL_CODE, THEME_MODE } from 'utilities';
import { useAppSelector } from 'store/hooks';

type Props = {
	code: string;
};

const CopyReferralCode = ({ code }: Props) => {
	const styles = useStyles();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const host = typeof window !== 'undefined' && window.location.host;
	const { mode } = useAppSelector((store) => store.theme);

	const referralLink = `${host}${LINKS.Register}?${REFERRAL_CODE}=${code}`;

	return (
		<Box>
			<Box
				sx={{
					backgroundColor:
						THEME_MODE.dark === mode
							? theme.palette.background.paper
							: LIGHT_GRAY,
				}}
				style={styles.referralBox}
			>
				<Typography variant={'body1'}>{code}</Typography>
				<CopyToClipboard
					text={code}
					onCopy={() =>
						enqueueSnackbar(`Code copied successfully!!`, { variant: 'info' })
					}
				>
					<IconButton color={'inherit'}>
						<ContentCopy />
					</IconButton>
				</CopyToClipboard>
			</Box>
			<Box
				sx={{
					backgroundColor:
						THEME_MODE.dark === mode
							? theme.palette.background.paper
							: LIGHT_GRAY,
				}}
				style={styles.referralBox}
			>
				<Typography variant={'body1'}>{referralLink}</Typography>
				<CopyToClipboard
					text={referralLink}
					onCopy={() =>
						enqueueSnackbar(`Referral link copied successfully!!`, {
							variant: 'info',
						})
					}
				>
					<IconButton color={'inherit'}>
						<ContentCopy />
					</IconButton>
				</CopyToClipboard>
			</Box>
			<Typography
				sx={{ color: (theme) => theme.palette.secondary.main }}
				variant={'body1'}
			>
				Copy referral code/link and send to your friend
			</Typography>
		</Box>
	);
};

const useStyles = () => ({
	referralBox: {
		marginBottom: '15px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		// backgroundColor: LIGHT_GRAY,
		padding: '6px 10px 6px 20px',
		borderRadius: '5px',
	},
});

export default CopyReferralCode;
