import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { Check, Close, AccessTime } from '@mui/icons-material';
import TransactionRecordItem from './transaction-record-item';
import {
	PENDING_COLOR,
	DANGER_COLOR,
	SUCCESS_COLOR,
	LINKS,
	TRANSACTION_STATUS,
	SECOUNDARY_COLOR,
	TRANSACTION_SERVICE,
} from 'utilities';
import { setTransactionFilter } from 'store/transaction';

interface IProps {
	sx?: any;
	hasTitle?: boolean;
}

const TransactionRecord: React.FC<IProps> = ({ sx, hasTitle = false }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const handleClickTransaction = (status?: string) => {
		dispatch(
			setTransactionFilter({
				status,
				service: TRANSACTION_SERVICE.DATA_SUBSCRIPTION,
			})
		);
		navigate(LINKS.TransactionHistory);
	};

	return (
		<Box
			sx={{
				display: 'grid',
				gap: (theme) => theme.spacing(2),
				...sx,
			}}
		>
			{hasTitle && (
				<Typography
					variant={'h5'}
					sx={{
						fontWeight: 'bold',
						marginBottom: '15px',
					}}
				>
					Transactions
				</Typography>
			)}
			<TransactionRecordItem
				bgColor={SECOUNDARY_COLOR}
				icon={<AccessTime sx={{ fontSize: '14px' }} />}
				text={'All transactions'}
				onClick={() => handleClickTransaction()}
			/>
			<TransactionRecordItem
				bgColor={PENDING_COLOR}
				icon={<AccessTime sx={{ fontSize: '14px' }} />}
				text={'Pending transactions'}
				onClick={() => handleClickTransaction(TRANSACTION_STATUS.PENDING)}
			/>
			<TransactionRecordItem
				bgColor={SUCCESS_COLOR}
				icon={<Check sx={{ fontSize: '14px' }} />}
				text={'Completed transactions'}
				onClick={() => handleClickTransaction(TRANSACTION_STATUS.SUCCESSFUL)}
			/>
			<TransactionRecordItem
				bgColor={DANGER_COLOR}
				icon={<Close sx={{ fontSize: '14px' }} />}
				text={'Failed transactions'}
				onClick={() => handleClickTransaction(TRANSACTION_STATUS.FAILDED)}
			/>
		</Box>
	);
};

export default TransactionRecord;
