import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ArrowDropDown } from '@mui/icons-material';
import { useAppSelector } from 'store/hooks';
import { THEME_MODE } from 'utilities';

type Props = {
	text: string;
	isSelectable?: boolean;
};

const TextPlaceholder = ({ text, isSelectable = false }: Props) => {
	const theme = useTheme();
	const { mode } = useAppSelector((store) => store.theme);
	return (
		<Box
			sx={{
				position: 'relative',
				display: 'flex',
				alignItems: 'center',
				borderRadius: theme.spacing(1),
				border: `1px solid ${
					mode === THEME_MODE.dark ? grey[50] : theme.palette.primary.main
				}`,
				padding: '16.5px 14px',
				cursor: 'not-allowed',
				userSelect: 'none',
			}}
		>
			<Typography variant={'body1'}>{text}</Typography>
			{isSelectable && (
				<ArrowDropDown sx={{ position: 'absolute', right: '7px' }} />
			)}
		</Box>
	);
};

export default TextPlaceholder;
