import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import WalletOperationItem from './wallet-opertaion-item';
import { THEME_MODE, LINKS, KYC_LEVEL } from 'utilities';
import { useAppSelector } from 'store/hooks';
import { useModal } from 'hooks';

const OPERATIONS = [
	{
		title: 'Fund wallet',
		description: `Add money to your AirtimeFlip wallet from your credit card or bank account.
			`,
		img: require('assets/icons/fund-wallet.png'),
		link: LINKS.WalletTopup,
		type: 'fund-wallet',
	},
	{
		title: 'Withdraw fund',
		description:
			'Send money to your bank account from your airtimeflip wallet.',
		img: require('assets/icons/withdraw.png'),
		link: LINKS.WalletWithdraw,
	},
	{
		title: 'Transfer fund',
		description:
			'Transfer funds from your airtimeflip wallet to other airtimeflip users.',
		img: require('assets/icons/transfer.png'),
		link: LINKS.WalletTransfer,
	},
];

const WalletOpertaions = () => {
	const theme = useTheme();
	const themeMode = useAppSelector((store) => store.theme.mode);
	const styles = useStyles(theme, themeMode);
	const modal = useModal();
	const navigate = useNavigate();

	const kycLevel = useAppSelector((store) => store.auth.user?.kycLevel);

	const handleFundWallet = () => {
		const link =
			kycLevel === 1
				? `${LINKS.LevelTwoVerification}?redirect=${LINKS.WalletTopup}`
				: kycLevel === 2
				? `${LINKS.BvnVerification}?redirect=${LINKS.WalletTopup}`
				: '';

		if (parseInt(`${kycLevel}`) < 2) {
			const nestedMessage =
				kycLevel === 1
					? 'National identity verification'
					: kycLevel === 2
					? 'bank verification number'
					: '';
			modal.display({
				title: 'Verification required',
				message: `Unable to perform action, kindly perform your ${nestedMessage} to continue`,
				primaryButtonText: 'Continue',
				secondaryButtonText: 'Cancel',
				onClickPrimaryButton: () => {
					modal.close();
					// typeof handleChangeTab === 'function' && handleChangeTab(3);
					if (kycLevel === KYC_LEVEL.LevelOne) {
						navigate(
							`${LINKS.LevelTwoVerification}?redirect=${LINKS.WalletTopup}`,
							{
								state: {
									redirect: LINKS.WalletTopup,
								},
							}
						);
					}
				},
				onClickSecondaryButton: modal.close,
			});
			return;
		}

		navigate(LINKS.WalletTopup);
	};

	const handleClick = (value: string) => {
		if (value === 'fund-wallet') {
			handleFundWallet();
		}
	};

	return (
		<Box>
			<Typography style={styles.title} variant={'h5'}>
				Wallet Operations
			</Typography>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: {
						xs: '1fr',
						sm: 'repeat(2, 1fr)',
						md: 'repeat(3, 1fr)',
					},
					gap: '15px',
				}}
			>
				{OPERATIONS.map((data, key) => (
					<WalletOperationItem onClick={handleClick} data={data} key={key} />
				))}
			</Box>
		</Box>
	);
};

const useStyles = (theme: any, mode?: any) => ({
	title: {
		marginBottom: theme.spacing(3),
		fontWeight: '600',
		color: mode === THEME_MODE.dark ? grey[300] : 'inherit',
	},
});

export default WalletOpertaions;
