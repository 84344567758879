import { useState } from 'react';
import { Metadata, ITransaction } from 'utilities';
import {
	dataSubscriptions,
	airtimeTransactions,
	airtimeConverts,
	walletWithdrawal,
	ePins as ePinTransactions,
	walletFunding,
	walletTransfers,
	billTransactions,
	autoAirtimeConverts,
	transactions,
	internationalAirtimeTransactions,
	internationalDataSubscriptions,
	voucherTransactions,
	giftCardTransactions,
	eSimTransactions,
} from 'api';

// Query Transactions
export const useQueryTransactions = (
	callback?: (value: {
		data: any;
		metadata?: Metadata;
		service?: string;
	}) => void
) => {
	const [dataTransactions, setDataTransactions] = useState<
		{ [key: string]: any }[] | null
	>(null);
	const [isLoading, setLoading] = useState<boolean>(false);

	const queryTransactions = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await transactions(params);
			setLoading(false);

			if (response && response.success) {
				setDataTransactions(response.payload);
				typeof callback === 'function' &&
					callback({
						data: response.payload,
						metadata: response.metadata,
						service: params.type,
					});
				return response.payload;
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingTransactions: isLoading,
		transactions: dataTransactions,
		queryTransactions,
	};
};

// Query Convert Airtimes
export const useQueryConvertAirtimes = (
	callback?: (data: any, metadata?: Metadata) => void
) => {
	const [dataConvertAirtimes, setDataConvertAirtime] = useState<
		{ [key: string]: any }[] | null
	>(null);
	const [isLoading, setLoading] = useState<boolean>(false);

	const queryConvertAirtimes = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await airtimeConverts(params);
			setLoading(false);

			if (response && response.success) {
				setDataConvertAirtime(response.payload);
				typeof callback === 'function' &&
					callback(response.payload, response.metadata);
				return response.payload;
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingConvertAirtime: isLoading,
		convertAirtimes: dataConvertAirtimes,
		queryConvertAirtimes,
	};
};

// Auto Convert Airtime
export const useQueryAutoConvertAirtimes = (
	callback?: (data: any, metadata?: Metadata) => void
) => {
	const [dataAutoAirtimeConverts, setDataAutoAirtimeConverts] = useState<
		{ [key: string]: any }[] | null
	>(null);
	const [isLoading, setLoading] = useState<boolean>(false);

	const queryAutoAirtimeConverts = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await autoAirtimeConverts(params);
			setLoading(false);

			if (response && response.success) {
				setDataAutoAirtimeConverts(response.payload);
				typeof callback === 'function' &&
					callback(response.payload, response.metadata);
				return response.payload;
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingAutoAirtimeConverts: isLoading,
		autoAitimeConverts: dataAutoAirtimeConverts,
		queryAutoAirtimeConverts,
	};
};

// Query Data Subscription
export const useQueryDataSubscriptions = (
	callback?: (data: any, metadata?: Metadata) => void
) => {
	const [dataDataSubscriptions, setDataDataSubscriptions] = useState<
		{ [key: string]: any }[] | null
	>(null);
	const [isLoading, setLoading] = useState<boolean>(false);

	const queryDataSubscriptions = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await dataSubscriptions(params);
			setLoading(false);

			if (response && response.success) {
				setDataDataSubscriptions(response.payload);

				typeof callback === 'function' &&
					callback(response.payload, response?.metadata);
				return response.payload;
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingDataSubscriptions: isLoading,
		dataSubscriptions: dataDataSubscriptions,
		queryDataSubscriptions,
	};
};

// Query Airtime Transactiion
export const useQueryAirtimeTransactions = (
	callback?: (data: any, metadata?: Metadata) => void
) => {
	const [dataAirtimeTransactions, setDataAirtimeTransactions] = useState<
		{ [key: string]: any }[] | null
	>(null);

	const [isLoading, setLoading] = useState<boolean>(false);

	const queryAirtimeTransactions = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await airtimeTransactions(params);
			setLoading(false);

			if (response && response.success) {
				setDataAirtimeTransactions(response.payload);
				const metadata = response.metadata;
				typeof callback === 'function' && callback(response.payload, metadata);
				return response.payload;
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingAirtimeTransactions: isLoading,
		dataAirtimeTransactions: dataAirtimeTransactions,
		queryAirtimeTransactions,
	};
};

// Query International Airtime Transactiion
export const useQueryInternationalAirtimeTransactions = (
	callback?: (data: any, metadata?: Metadata) => void
) => {
	const [dataAirtimeTransactions, setDataAirtimeTransactions] = useState<
		{ [key: string]: any }[] | null
	>(null);

	const [isLoading, setLoading] = useState<boolean>(false);

	const queryInterAirtimeTransactions = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await internationalAirtimeTransactions(params);
			setLoading(false);

			if (response && response.success) {
				setDataAirtimeTransactions(response.payload);
				const metadata = response.metadata;
				typeof callback === 'function' && callback(response.payload, metadata);
				return response.payload;
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingInterAirtimeTransactions: isLoading,
		dataInterAirtimeTransactions: dataAirtimeTransactions,
		queryInterAirtimeTransactions,
	};
};

// Query IInternational Data Transaction
export const useQueryInternationalDataTransactions = (
	callback?: (data: any, metadata?: Metadata) => void
) => {
	const [dataResponse, setDataResponse] = useState<
		{ [key: string]: any }[] | null
	>(null);

	const [isLoading, setLoading] = useState<boolean>(false);

	const queryInterDataTransactions = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await internationalDataSubscriptions(params);

			if (response && response.success) {
				setDataResponse(response.payload);
				const metadata = response.metadata;
				typeof callback === 'function' && callback(response.payload, metadata);
				return response.payload;
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return {
		isLoadingInterDataTransactions: isLoading,
		dataInterDataTransactions: dataResponse,
		queryInterDataTransactions,
	};
};

export const useQueryCableTransactions = (
	callback?: (data: any, metadata?: Metadata) => void
) => {
	const [transactions, setTransactions] = useState<
		{ [key: string]: any }[] | null
	>(null);

	const [isLoading, setLoading] = useState<boolean>(false);

	// const query = async (params: Record<string, any>) => {
	const queryCableTransactions = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await billTransactions(params);
			setLoading(false);

			if (response && response.success) {
				setTransactions(response.payload);
				typeof callback === 'function' &&
					callback(response.payload, response?.metadata);
				// return response.payload;
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingCableTransactions: isLoading,
		cableTransactions: transactions,
		queryCableTransactions,
	};
};

export const useQueryBillTransactions = (
	callback?: ({
		data,
		metadata,
		service,
	}: {
		data: any;
		metadata?: Metadata;
		service: string;
	}) => void
) => {
	const [transactions, setTransactions] = useState<
		{ [key: string]: any }[] | null
	>(null);

	const [isLoading, setLoading] = useState<boolean>(false);

	// const query = async (params: Record<string, any>) => {
	const queryBillTransactions = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await billTransactions(params);
			setLoading(false);

			if (response && response.success) {
				setTransactions(response.payload);
				typeof callback === 'function' &&
					callback({
						data: response.payload,
						metadata: response?.metadata,
						service: params.type,
					});
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingBillTransactions: isLoading,
		billTransactions: transactions,
		queryBillTransactions,
	};
};

export const useQueryEducationTransactions = (
	callback?: (data: any, metadata?: Metadata) => void
) => {
	const [transactions, setTransactions] = useState<
		{ [key: string]: any }[] | null
	>(null);

	const [isLoading, setLoading] = useState<boolean>(false);

	// const query = async (params: Record<string, any>) => {
	const queryEducationTransactions = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await billTransactions(params);
			setLoading(false);

			if (response && response.success) {
				setTransactions(response.payload);
				typeof callback === 'function' &&
					callback(response.payload, response?.metadata);
				// return response.payload;
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingEducationTransactions: isLoading,
		educationTransactions: transactions,
		queryEducationTransactions,
	};
};

// Wallet Funding hooks

export const useQueryWalletFundings = (
	callback?: (data: any, metadata?: Metadata) => void
) => {
	const [dataWalletFundings, setDataWalletFundings] = useState<
		ITransaction[] | null
	>(null);

	const [isLoading, setLoading] = useState<boolean>(false);
	const queryWalletFundings = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await walletFunding(params);
			setLoading(false);

			if (response && response.success) {
				setDataWalletFundings(response.payload);
				typeof callback === 'function' &&
					callback(response.payload, response?.metadata);
				// return response.payload;
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingWalletFundings: isLoading,
		walletFundings: dataWalletFundings,
		queryWalletFundings,
	};
};

export const useQueryWalletWithdrawals = (
	callback?: (data: any, metadata?: Metadata) => void
) => {
	const [dataWalletWithdrawals, setDataWalletWithdrawals] = useState<
		ITransaction[] | null
	>(null);

	const [isLoading, setLoading] = useState<boolean>(false);
	const queryWalletWithdrawals = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await walletWithdrawal(params);
			setLoading(false);

			if (response && response.success) {
				setDataWalletWithdrawals(response.payload);
				typeof callback === 'function' &&
					callback(response.payload, response?.metadata);
				// return response.payload;
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingWalletWithdrawals: isLoading,
		walletWithdrawals: dataWalletWithdrawals,
		queryWalletWithdrawals,
	};
};

// Wallet Transfer hooks
export const useQueryWalletTransfers = (
	callback?: (data: any, metadata?: Metadata) => void
) => {
	const [dataWalletTransfers, setDataWalletTransfers] = useState<
		ITransaction[] | null
	>(null);

	const [isLoading, setLoading] = useState<boolean>(false);
	const queryWalletTransfers = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await walletTransfers(params);
			setLoading(false);

			if (response && response.success) {
				setDataWalletTransfers(response.payload);
				typeof callback === 'function' &&
					callback(response.payload, response?.metadata);
				// return response.payload;
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingWalletTransfers: isLoading,
		walletTransfers: dataWalletTransfers,
		queryWalletTransfers,
	};
};

// Epin Transaction Hooks

export const useQueryEPinTransactions = (
	callback?: (data: any, metadata?: Metadata) => void
) => {
	const [dataEPinTransactions, setDataEPinTransactions] = useState<
		ITransaction[] | null
	>(null);

	const [isLoading, setLoading] = useState<boolean>(false);

	// const query = async (params: Record<string, any>) => {
	const queryEPinTransactions = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await ePinTransactions(params);
			setLoading(false);

			if (response && response.success) {
				setDataEPinTransactions(response.payload as ITransaction[]);
				typeof callback === 'function' &&
					callback(response.payload, response?.metadata);
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingEPinTransactions: isLoading,
		ePinTransactions: dataEPinTransactions,
		queryEPinTransactions,
	};
};

export const useQueryVoucherTransactions = (
	callback?: (data: any, metadata?: Metadata) => void
) => {
	const [dataVoucherTransactions, setDataVoucherTransactions] = useState<
		ITransaction[] | null
	>(null);

	const [isLoading, setLoading] = useState<boolean>(false);

	// const query = async (params: Record<string, any>) => {
	const queryVoucherTransactions = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await voucherTransactions(params);
			setLoading(false);

			if (response && response.success) {
				setDataVoucherTransactions(response.payload as ITransaction[]);
				typeof callback === 'function' &&
					callback(response.payload, response?.metadata);
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingVoucherTransactions: isLoading,
		voucherTransactions: dataVoucherTransactions,
		queryVoucherTransactions,
	};
};

export const useQueryGiftCardTransactions = (
	callback?: (data: any, metadata?: Metadata) => void
) => {
	const [dataGiftCardTransactions, setGiftCardTransactions] = useState<
		{ [key: string]: any }[] | null
	>(null);

	const [isLoading, setLoading] = useState<boolean>(false);

	const queryGiftCardTransactions = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await giftCardTransactions(params);
			setLoading(false);

			if (response && response.success) {
				setGiftCardTransactions(response.payload);
				const metadata = response.metadata;
				typeof callback === 'function' && callback(response.payload, metadata);
				return response.payload;
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingGiftCardTransactions: isLoading,
		dataGiftCardTransactions: dataGiftCardTransactions,
		queryGiftCardTransactions,
	};
};

export const useQueryESimTransactions = (
	callback?: (data: any, metadata?: Metadata) => void
) => {
	const [dataESimTransactions, setESimTransactions] = useState<
		{ [key: string]: any }[] | null
	>(null);

	const [isLoading, setLoading] = useState<boolean>(false);

	const queryESimTransactions = async (params: Record<string, any>) => {
		setLoading(true);
		try {
			const response = await eSimTransactions(params);
			setLoading(false);

			if (response && response.success) {
				setESimTransactions(response.payload);
				const metadata = response.metadata;
				typeof callback === 'function' && callback(response.payload, metadata);
				return response.payload;
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		isLoadingESimTransactions: isLoading,
		eSimTransactions: dataESimTransactions,
		queryESimTransactions,
	};
};
