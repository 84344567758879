import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import queryString from 'query-string';
import {
	Layout,
	BillTab,
	TwoColGrid,
	WalletWithBreakdown,
	CablePaymentForm,
	EducationBillForm,
	InternetSubscriptionForm,
	PowerBillForm,
	Seo,
	BackButton,
} from 'components';
import { BILL_TAB, LINKS } from 'utilities';

const Bills = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const { tab } = queryString.parse(location.search);
	const [currentTab, setCurrentTab] = useState<number>(0);

	const title =
		currentTab === 0
			? 'Cable payment'
			: currentTab === 1
			? 'Power bill'
			: currentTab === 2
			? 'Education'
			: currentTab === 3
			? 'Internet subscription'
			: '';

	const handleChangeTab = (e: any, value: number) => {
		switch (value) {
			case 1:
				return navigate(`${LINKS.PayBill}?tab=${BILL_TAB.PowerBill}`);

			case 2:
				return navigate(`${LINKS.PayBill}?tab=${BILL_TAB.Education}`);
			case 3:
				return navigate(
					`${LINKS.PayBill}?tab=${BILL_TAB.InternetSubscription}`
				);

			default:
				navigate(`${LINKS.PayBill}`);
		}
		setCurrentTab(value);
	};

	useEffect(() => {
		switch (tab) {
			case BILL_TAB.PowerBill:
				return setCurrentTab(1);

			case BILL_TAB.Education:
				return setCurrentTab(2);

			case BILL_TAB.InternetSubscription:
				return setCurrentTab(3);

			default:
				setCurrentTab(0);
		}
	}, [tab]);

	return (
		<Layout>
			<Seo title={title} />
			<BackButton name={'Services'} sx={{ marginBottom: ['2rem'] }} />
			<BillTab currentTab={currentTab} handleChange={handleChangeTab} />
			<TwoColGrid
				rightAside={<WalletWithBreakdown isStickySidebar top={'10rem'} />}
			>
				<Box hidden={currentTab !== 0}>
					<CablePaymentForm enableRequest={currentTab === 0} />
				</Box>
				<Box hidden={currentTab !== 1}>
					<PowerBillForm enableRequest={currentTab === 1} />
				</Box>
				<Box hidden={currentTab !== 2}>
					<EducationBillForm enableRequest={currentTab === 2} />
				</Box>
				<Box hidden={currentTab !== 3}>
					<InternetSubscriptionForm enableRequest={currentTab === 3} />
				</Box>
			</TwoColGrid>
		</Layout>
	);
};

export default Bills;
