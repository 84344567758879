import React, { useState } from 'react';
import { Box, Typography, useTheme, CircularProgress } from '@mui/material';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { Layout, Seo, OtpForm } from 'components';
import { useHandleError, useModalAlert, useAlert } from 'hooks';
import { LINKS, QUERY_KEYS } from 'utilities';
import { set2FASettings, twoFAToken } from 'api';
import { useAppSelector } from 'store/hooks';

const ConfirmTwoFactorAuthToken = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const alert = useAlert();
	const navigate = useNavigate();
	const modal = useModalAlert();
	const handleError = useHandleError();
	const { state, search } = useLocation();
	const queryClient = useQueryClient();
	const { user } = useAppSelector((store) => store.auth);

	const parseUrl = queryString.parse(search);

	const [isRequestingToken, setRequestingToken] = useState<boolean>(false);

	const requestToken = async () => {
		setRequestingToken(true);
		try {
			const data = await twoFAToken();
			if (data.success) {
				alert({ message: 'OTP send successfully!', type: 'success' });
			}
		} catch (error) {
			const response = handleError({ error });
			if (response?.message)
				alert({ message: response.message, type: 'error' });
		}
		setRequestingToken(false);
	};

	const handleNavigate = () => {
		if (parseUrl && parseUrl.redirect) {
			return navigate(parseUrl.redirect as string, {
				replace: true,
				state: {},
			});
		}
		if (state && state.redirect) {
			return navigate(state.redirect as string, {
				state: {},
			});
		}

		navigate(LINKS.Dashboard);
	};

	const { mutate, isLoading: isUpdatingSettings } = useMutation(
		set2FASettings,
		{
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response && response.message) {
						modal({
							title: 'Confirm OTP',
							message: response.message,
							type: 'error',
							primaryButtonText: 'Close',
							onClickPrimaryButton: () => modal(null),
						});
					}
				}
				if (data && data.success) {
					queryClient.invalidateQueries(QUERY_KEYS.Me);

					modal({
						title: 'Confirm OTP',
						message: 'Two factor authentication token confirm successfully!',
						type: 'success',
						primaryButtonText: 'Close',
						onClickPrimaryButton: () => {
							modal(null);
							handleNavigate();
						},
					});
				}
			},
		}
	);

	const updateTwoFactorAuth = (token: string) => {
		mutate({
			data: {
				twoFactorAuth: user ? !user.twoFactorAuth : false,
				token: Number(token),
			},
			id: user?.id as string,
		});
	};

	return (
		<Layout>
			<Seo title={'Confirm two factor authentication token'} />
			<Box style={styles.container}>
				<Box>
					<Typography style={styles.title} variant={'h5'}>
						Confirm OTP
					</Typography>
					<Typography variant={'body1'}>
						An OTP has been sent to your email "{user?.email}", enter the OTP to
						confirm update
					</Typography>
				</Box>
				<Box>
					<OtpForm
						isLoading={isUpdatingSettings}
						callback={(token: string) => updateTwoFactorAuth(token)}
					/>
					<Typography
						onClick={requestToken}
						sx={{
							marginTop: '8px',
							display: 'flex',
							alignItems: 'center',
							gap: '6px',
						}}
					>
						Didn't get OTP?{' '}
						<Box style={styles.resendOtp} component={'span'}>
							Resent OTP
						</Box>
						{isRequestingToken && <CircularProgress size={16} />}
					</Typography>
				</Box>
			</Box>
		</Layout>
	);
};

const useStyles = (theme: any) => ({
	container: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '30px',
		maxWidth: '540px',
	},
	title: {
		fontWeight: '600',
		marginBottom: '20px',
	},
	resendOtp: {
		fontWeight: 'bold',
		cursor: 'pointer',
	},
});

export default ConfirmTwoFactorAuthToken;
