import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import './index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import * as ReactDom from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import * as serviceWorker from './serviceWorker';

const container: any = document.getElementById('root');
const root = ReactDom.createRoot(container);

const RootApp = () => {
	const queryClient = new QueryClient();

	return (
		<HelmetProvider>
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<BrowserRouter>
						<SnackbarProvider
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							autoHideDuration={3000}
							maxSnack={3}
						>
							<App />
						</SnackbarProvider>
					</BrowserRouter>
					<ReactQueryDevtools />
				</QueryClientProvider>
			</Provider>
		</HelmetProvider>
	);
};

root.render(
	<React.StrictMode>
		<RootApp />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.register();
reportWebVitals();
