import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
	Layout,
	Divider,
	GenerateEpin,
	ViewGeneratedPin,
	ValidateEpinForm,
	Seo,
	H5Typography,
} from 'components';
import { useAppSelector } from 'store/hooks';
import { THEME_MODE } from 'utilities';

const IEPin = () => {
	const theme = useTheme();
	const { mode } = useAppSelector((store) => store.theme);
	const styles = useStyles(theme, mode);
	return (
		<Layout>
			<Seo title={'Load E-Pin'} />

			<Box>
				<H5Typography name='Load E-Pin' />

				<Box sx={{ maxWidth: '600px' }}>
					<Box>
						<ValidateEpinForm />
					</Box>
				</Box>
				<Divider sx={{ margin: '2rem 0px' }} text={'OR'} />
				<Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: '2rem' }}>
					<GenerateEpin />
					<Divider sx={{ margin: '2rem 0px' }} text={'OR'} />

					<ViewGeneratedPin />
				</Box>
			</Box>
		</Layout>
	);
};

const useStyles = (theme: any, mode: string) => ({
	title: {
		fontWeight: '600',
		marginBottom: '20px',
		color: mode === THEME_MODE.dark ? grey[300] : 'initial',
	},
});

export default IEPin;
