import React from 'react';

type Props = {
	color?: string;
	height?: number;
	width?: number;
};

const PowerBillIcon = ({ width, height, color }: Props) => {
	return (
		<svg
			width='18'
			height='24'
			viewBox='0 0 18 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				opacity='0.5'
				d='M5.88639 4.59323H3.5625V1.82687C3.56227 1.51846 3.68457 1.22261 3.90247 1.00436C4.12038 0.786109 4.41605 0.663344 4.72445 0.663086C5.03286 0.663344 5.32853 0.786109 5.54643 1.00436C5.76433 1.22261 5.88662 1.51846 5.88639 1.82687V4.59323Z'
				fill='#DCE3E7'
				stroke={color || '#28536B'}
				strokeMiterlimit='10'
			/>
			<path
				opacity='0.5'
				d='M12.3649 4.59323H10.041V1.82687C10.0408 1.51846 10.1631 1.22261 10.381 1.00436C10.5989 0.786109 10.8945 0.663344 11.203 0.663086C11.5114 0.663344 11.807 0.786109 12.0249 1.00436C12.2428 1.22261 12.3651 1.51846 12.3649 1.82687V4.59323Z'
				fill='#DCE3E7'
				stroke={color || '#28536B'}
				strokeMiterlimit='10'
			/>
			<path
				opacity='0.5'
				d='M10.4333 14.1414H5.48242V17.021H10.4333V14.1414Z'
				fill='#DCE3E7'
				stroke={color || '#28536B'}
				strokeMiterlimit='10'
			/>
			<path
				d='M8.29289 15.4303C7.42741 15.4722 6.56245 15.3377 5.75056 15.035C4.93867 14.7323 4.1968 14.2676 3.57005 13.6693C2.94329 13.071 2.44472 12.3515 2.10465 11.5545C1.76458 10.7576 1.5901 9.89977 1.59181 9.03328V4.60622C1.59126 4.51814 1.60823 4.43083 1.64172 4.34936C1.67522 4.2679 1.72458 4.19391 1.78693 4.13169C1.84928 4.06947 1.92338 4.02025 2.00491 3.98692C2.08645 3.9536 2.1738 3.93679 2.26188 3.93752C2.43372 3.94101 2.59734 4.01175 2.71763 4.13451C2.83793 4.25727 2.9053 4.42227 2.9053 4.59414V9.03822C2.90101 9.70302 3.02798 10.3621 3.27893 10.9778C3.52987 11.5934 3.89986 12.1534 4.36767 12.6258C4.83549 13.0981 5.39194 13.4735 6.00511 13.7304C6.61829 13.9873 7.27614 14.1206 7.94094 14.1227C8.60575 14.1249 9.26443 13.9958 9.87924 13.7428C10.4941 13.4899 11.0529 13.1181 11.5237 12.6488C11.9946 12.1794 12.3682 11.6218 12.623 11.0078C12.8779 10.3938 13.0091 9.73548 13.0091 9.07067V7.91886C13.0086 7.83079 13.0255 7.7435 13.059 7.66204C13.0925 7.58058 13.1418 7.50658 13.2042 7.44436C13.2665 7.38214 13.3406 7.33293 13.4221 7.2996C13.5037 7.26626 13.591 7.24948 13.6791 7.2502C13.8509 7.25366 14.0146 7.32435 14.1349 7.44711C14.2552 7.56987 14.3226 7.73492 14.3226 7.90681V9.07371C14.3206 10.7032 13.6946 12.2701 12.5731 13.4523C11.4517 14.6346 9.92004 15.3423 8.29289 15.4303Z'
				fill={color || '#28536B'}
			/>
			<path
				d='M14.8163 5.25003H1.11173C1.02488 5.25156 0.938585 5.23582 0.857852 5.20378C0.777118 5.17173 0.703542 5.12401 0.641384 5.06334C0.579226 5.00266 0.52973 4.93025 0.495742 4.85031C0.461754 4.77037 0.443945 4.68448 0.443374 4.59762C0.442802 4.51077 0.459477 4.42466 0.492409 4.34428C0.525342 4.2639 0.573878 4.19087 0.635232 4.12939C0.696585 4.0679 0.769527 4.01919 0.849831 3.98609C0.930136 3.95298 1.01621 3.93612 1.10307 3.9365H14.8076C14.8944 3.93497 14.9807 3.95071 15.0614 3.98276C15.1422 4.0148 15.2157 4.06253 15.2779 4.1232C15.3401 4.18387 15.3896 4.25626 15.4236 4.33619C15.4575 4.41612 15.4753 4.50198 15.4759 4.58884C15.4765 4.67569 15.4598 4.7618 15.4269 4.84218C15.394 4.92256 15.3454 4.99562 15.2841 5.05711C15.2228 5.1186 15.1498 5.16729 15.0695 5.20041C14.9892 5.23352 14.9032 5.2504 14.8163 5.25003Z'
				fill={color || '#28536B'}
			/>
			<path
				d='M5.88231 5.24999H3.55236C3.37738 5.24999 3.20956 5.18047 3.08583 5.05674C2.9621 4.93301 2.89258 4.7652 2.89258 4.59022V1.8239C2.89273 1.58363 2.94033 1.34575 3.03264 1.12392C3.12494 0.902088 3.26013 0.700665 3.43047 0.531208C3.6008 0.361752 3.80293 0.227587 4.02523 0.136433C4.24754 0.045278 4.48565 -0.00107474 4.72592 1.89106e-05C4.96619 0.00111256 5.20388 0.0496301 5.42535 0.142805C5.64682 0.235979 5.8477 0.371963 6.01649 0.542964C6.18527 0.713964 6.31864 0.916638 6.40892 1.1393C6.4992 1.36197 6.54462 1.60026 6.54259 1.84052V4.59026C6.5425 4.7653 6.47289 4.93313 6.34906 5.05685C6.22524 5.18056 6.05735 5.25005 5.88231 5.24999ZM4.21872 3.93646H5.2291V1.83844C5.22942 1.7033 5.17624 1.57353 5.0812 1.47746C4.98616 1.3814 4.85697 1.32683 4.72184 1.3257C4.58671 1.32457 4.45662 1.37695 4.35999 1.47142C4.26335 1.56588 4.20802 1.69478 4.20609 1.8299V3.92383H4.21872V3.93646Z'
				fill={color || '#28536B'}
			/>
			<path
				d='M12.3627 5.24995H10.0328C9.85783 5.24995 9.69001 5.18043 9.56628 5.0567C9.44255 4.93297 9.37305 4.76516 9.37305 4.59018V1.82386C9.37325 1.58362 9.42087 1.34578 9.5132 1.12399C9.60552 0.902207 9.74074 0.700843 9.91107 0.531428C10.0814 0.362013 10.2835 0.227892 10.5058 0.136762C10.7281 0.0456322 10.9661 -0.000708375 11.2064 0.000385121C11.4466 0.00147862 11.6843 0.0499846 11.9057 0.143134C12.1272 0.236284 12.328 0.372225 12.4968 0.543183C12.6656 0.714142 12.799 0.916757 12.8893 1.13938C12.9796 1.36199 13.025 1.60026 13.023 1.84049V4.59022C13.0229 4.76527 12.9533 4.93309 12.8295 5.05681C12.7057 5.18053 12.5378 5.25002 12.3627 5.24995ZM10.6992 3.93642H11.7095V1.83862C11.7105 1.77202 11.6983 1.70588 11.6737 1.64401C11.649 1.58214 11.6123 1.52577 11.5658 1.47812C11.5193 1.43047 11.4638 1.39247 11.4025 1.36635C11.3412 1.34024 11.2754 1.3265 11.2088 1.32592C11.1422 1.32535 11.0761 1.33797 11.0144 1.36302C10.9527 1.38807 10.8965 1.42506 10.8492 1.4719C10.8018 1.51873 10.7642 1.57445 10.7384 1.63588C10.7127 1.69732 10.6993 1.76326 10.6992 1.82987V3.9365V3.93642Z'
				fill={color || '#28536B'}
			/>
			<path
				d='M10.4312 17.6903H5.48594C5.31096 17.6903 5.14313 17.6208 5.0194 17.4971C4.89567 17.3734 4.82617 17.2055 4.82617 17.0306V14.1444C4.82617 13.9694 4.89567 13.8016 5.0194 13.6779C5.14313 13.5541 5.31096 13.4846 5.48594 13.4846H10.4308C10.6057 13.4846 10.7736 13.5541 10.8973 13.6779C11.021 13.8016 11.0905 13.9694 11.0905 14.1444V17.03C11.0906 17.205 11.0212 17.3728 10.8976 17.4967C10.774 17.6205 10.6062 17.6902 10.4312 17.6903ZM6.1523 16.3768H9.77704V14.8107H6.1523V16.3768Z'
				fill={color || '#28536B'}
			/>
			<path
				d='M10.0887 23.9985C9.34051 23.9707 8.63217 23.6541 8.11235 23.1152C7.59254 22.5762 7.30166 21.8569 7.30079 21.1082V17.0395C7.30024 16.9514 7.31722 16.864 7.35071 16.7826C7.3842 16.7011 7.43354 16.6272 7.49587 16.565C7.55821 16.5027 7.63229 16.4535 7.71381 16.4202C7.79533 16.3869 7.88267 16.37 7.97074 16.3707C8.1426 16.3742 8.30625 16.4449 8.42656 16.5677C8.54688 16.6905 8.61429 16.8555 8.6143 17.0274V21.0911C8.61244 21.299 8.65167 21.5052 8.72973 21.6979C8.80779 21.8906 8.92314 22.066 9.06917 22.214C9.2152 22.362 9.38902 22.4797 9.58066 22.5603C9.7723 22.641 9.97798 22.683 10.1859 22.6839C10.3938 22.6848 10.5999 22.6447 10.7922 22.5658C10.9846 22.4868 11.1594 22.3707 11.3068 22.224C11.4541 22.0773 11.5711 21.903 11.6509 21.711C11.7307 21.519 11.7717 21.3132 11.7717 21.1052V20.6356C11.7712 20.5475 11.7881 20.4602 11.8216 20.3787C11.8551 20.2973 11.9045 20.2233 11.9668 20.1611C12.0291 20.0989 12.1032 20.0497 12.1848 20.0163C12.2663 19.983 12.3536 19.9662 12.4417 19.9669C12.6135 19.9704 12.7772 20.0411 12.8975 20.1639C13.0178 20.2866 13.0852 20.4516 13.0852 20.6235V21.0809C13.0875 21.4714 13.011 21.8583 12.8602 22.2185C12.7094 22.5787 12.4875 22.9048 12.2077 23.1772C11.9279 23.4496 11.5961 23.6628 11.232 23.8039C10.8679 23.9451 10.479 24.0112 10.0887 23.9985Z'
				fill={color || '#28536B'}
			/>
			<path
				d='M16.9128 20.8211C16.8247 20.8218 16.7373 20.805 16.6558 20.7717C16.5742 20.7384 16.5001 20.6892 16.4377 20.627C16.3753 20.5648 16.326 20.4908 16.2925 20.4093C16.259 20.3278 16.242 20.2405 16.2425 20.1524V16.6735C16.2444 16.4656 16.2052 16.2594 16.1271 16.0667C16.0491 15.874 15.9337 15.6986 15.7877 15.5506C15.6416 15.4026 15.4678 15.2849 15.2762 15.2042C15.0845 15.1236 14.8789 15.0816 14.6709 15.0807C14.463 15.0797 14.257 15.1199 14.0646 15.1988C13.8723 15.2777 13.6974 15.3938 13.55 15.5405C13.4027 15.6872 13.2858 15.8616 13.206 16.0536C13.1262 16.2455 13.0851 16.4514 13.0851 16.6593V17.4639C13.0857 17.552 13.0687 17.6393 13.0352 17.7207C13.0017 17.8022 12.9524 17.8762 12.89 17.9384C12.8277 18.0006 12.7536 18.0498 12.6721 18.0832C12.5906 18.1165 12.5032 18.1333 12.4152 18.1326C12.2433 18.1291 12.0797 18.0584 11.9593 17.9357C11.839 17.8129 11.7716 17.6478 11.7716 17.476V16.6836C11.768 16.3027 11.8397 15.9248 11.9826 15.5716C12.1255 15.2185 12.3367 14.897 12.6042 14.6257C12.8716 14.3544 13.1901 14.1386 13.5412 13.9908C13.8923 13.8429 14.2691 13.7659 14.6501 13.7641C15.0311 13.7623 15.4086 13.8357 15.7611 13.9803C16.1136 14.1248 16.4341 14.3376 16.7041 14.6064C16.9741 14.8751 17.1884 15.1945 17.3346 15.5463C17.4808 15.8981 17.556 16.2754 17.556 16.6563V20.1645C17.5561 20.3363 17.4887 20.5014 17.3685 20.6241C17.2482 20.7469 17.0846 20.8176 16.9128 20.8211Z'
				fill={color || '#28536B'}
			/>
			<path
				d='M10.4335 7.81384H2.25624C2.16939 7.81536 2.08312 7.79967 2.00238 7.76762C1.92165 7.73557 1.84807 7.68781 1.78592 7.62714C1.72376 7.56647 1.67424 7.49409 1.64025 7.41415C1.60627 7.33422 1.58848 7.24833 1.5879 7.16147C1.58733 7.07461 1.60399 6.9885 1.63692 6.90812C1.66985 6.82775 1.71841 6.75472 1.77976 6.69323C1.84112 6.63174 1.91406 6.58304 1.99436 6.54993C2.07467 6.51682 2.16074 6.49996 2.2476 6.50035H10.4248C10.5116 6.49882 10.5979 6.51452 10.6786 6.54656C10.7594 6.5786 10.8329 6.62637 10.8951 6.68704C10.9573 6.74771 11.0068 6.8201 11.0408 6.90003C11.0748 6.97996 11.0926 7.06582 11.0931 7.15268C11.0937 7.23953 11.0771 7.32565 11.0441 7.40602C11.0112 7.4864 10.9627 7.55946 10.9013 7.62095C10.84 7.68244 10.767 7.73114 10.6867 7.76425C10.6064 7.79736 10.5204 7.81421 10.4335 7.81384Z'
				fill={color || '#28536B'}
			/>
		</svg>
	);
};

export default PowerBillIcon;
