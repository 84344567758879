import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme, Box, Typography } from '@mui/material';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import { Layout, TransactionHistoryTable, Pagination } from 'components';
import { QUERY_KEYS, MAX_RECORDS, LINKS } from 'utilities';
import TransactionTableFilter from 'components/filter/transaction-table-filter';
import { useAppSelector } from 'store/hooks';
import { useAlert, useHandleError, usePageTitle } from 'hooks';
import { transactions } from 'api';

const PendingTransactions = () => {
	usePageTitle('Pending Transaction History');
	const handleError = useHandleError();
	const alert = useAlert();
	const {
		theme: { mode },
		auth: { token },
	} = useAppSelector((store) => store);
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();
	const styles = useStyles(theme, mode);

	const [count, setCount] = useState<number>(1);
	const [page, setPage] = useState<number>(1);
	const [total, setTotal] = useState<number>(0);
	const query = queryString.parse(location.search);

	useEffect(() => {
		if (query && query.page) {
			setPage(parseInt(query.page as string));
		}
	}, [query, query.page]);

	const { isLoading, data } = useQuery(
		[QUERY_KEYS.Transactions, page],
		() =>
			transactions({
				limit: MAX_RECORDS,
				skip: (page - 1) * MAX_RECORDS,
			}),
		{
			enabled: !!token,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				if (data && data.success) {
					const total = data.metadata && data.metadata.total;
					if (total) {
						setTotal(total);
						const count = Math.ceil(total / MAX_RECORDS);
						setCount(count);
					}
				}
				if (error) {
					const res = handleError({
						error,
					});

					alert({ message: res?.message, type: 'error' });
				}
			},
		}
	);

	const filterData = (value: string) => {
		console.log(value);
	};

	const handlePageChange = (page: number) => {
		if (page !== 1) {
			setPage(page);
			navigate(`${LINKS.TransactionHistory}?page=${page}`);
		} else {
			navigate(LINKS.TransactionHistory);
			setPage(page);
		}
	};

	return (
		<Layout>
			<Box style={styles.container}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Typography style={styles.title} variant={'h5'}>
						Pending ITransaction History
					</Typography>
					<TransactionTableFilter />
				</Box>
				<TransactionHistoryTable
					isLoading={isLoading}
					data={data && data.payload}
					filterData={filterData}
				/>

				{total > MAX_RECORDS && (
					<Pagination
						size={'large'}
						variant={'outlined'}
						shape={'rounded'}
						page={page}
						count={count}
						onChange={(e, number) => handlePageChange(number)}
					/>
				)}
			</Box>
		</Layout>
	);
};

const useStyles = (theme: any, mode?: any) => ({
	container: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '10px',
	},
	title: {
		fontWeight: '600',
	},
});

export default PendingTransactions;
