import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { Box, Avatar, useTheme, CircularProgress } from '@mui/material';
import { CameraAlt, Delete } from '@mui/icons-material';
import axios from 'axios';
import Button from '../button';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
	API_ENDPOINTS,
	LIGHT_GRAY,
	DANGER_COLOR,
	Configs,
	DataResponse,
	IUser,
	QUERY_KEYS,
} from 'utilities';
import { useAlert, useHandleError, useModal } from 'hooks';
import UploadImageButton from '../button/upload-image-button';
import Image from 'components/image';
import { grey } from '@mui/material/colors';
import { setUser } from 'store/auth';
import { useMutation, useQueryClient } from 'react-query';
import Loader from 'components/loader';
import { updateSettings } from 'api';

interface PreviewImageProps {
	image: string;
	file: any;
	callback?: () => void;
}

const PreviewImage: React.FC<PreviewImageProps> = ({
	image,
	file,
	callback,
}) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const dispatch = useAppDispatch();
	const alert = useAlert();
	const handleError = useHandleError();
	const { token } = useAppSelector((store) => store.auth);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isSubmittingImage, setSubmittingImage] = useState<boolean>(false);

	useEffect(() => {
		if (image) {
			setLoading(true);

			setTimeout(() => {
				setLoading(false);
			}, 3000);
		}
	}, [image]);

	const handleSubmit = async () => {
		const formData = new FormData();
		formData.append('file-upload', file);

		setSubmittingImage(true);

		try {
			const res = await axios.post<DataResponse<IUser>>(
				`${Configs.BASE_URL}${API_ENDPOINTS.User}/upload-display-picture`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			const data = res.data;
			if (data && data.success) {
				dispatch(setUser(data.payload));
				typeof callback !== 'undefined' && callback();
			}
		} catch (error) {
			setSubmittingImage(false);
			const res = handleError({ error });
			if (res?.message) {
				alert({ message: res.message, type: 'error' });
			}
		}
	};

	return (
		<Box
			style={styles.imagePreviewContainer as CSSProperties}
			sx={{
				width: '100%',
				height: '100%',
				'&:after': {
					position: 'absolute',
					content: '""',
					height: '100%',
					width: '100%',
					top: 0,
					left: 0,
					background: 'rgba(250, 250, 250, 0.5)',
					display: isLoading ? 'block' : 'none',
					// backgroundColor: isLoading ? grey[50] : 'unset',
				},
			}}
		>
			{isLoading && (
				<CircularProgress style={styles.spinner as CSSProperties} />
			)}
			<Image src={image} />
			{!isLoading && (
				<Button
					onClick={handleSubmit}
					loading={isSubmittingImage}
					style={styles.button}
					size={'large'}
				>
					Submit
				</Button>
			)}
		</Box>
	);
};

const UserImageWithAction = () => {
	const theme = useTheme();
	const modal = useModal();
	const queryClient = useQueryClient();
	const styles = useStyles(theme);
	const handleError = useHandleError();
	const alert = useAlert();

	const { user } = useAppSelector((store) => store.auth);

	const handlePreviewImage = useCallback(
		(file: any, image: string) => {
			if (image) {
				modal.display({
					title: 'Preview Image',
					children: (
						<PreviewImage
							callback={() =>
								modal.display({
									title: 'Profile Image',
									message: 'Profile image uploaded successfully!',
									type: 'success',
									primaryButtonText: 'Close',
									onClickPrimaryButton: () => {
										modal.close();
										// handleNavigate();
									},
								})
							}
							file={file}
							image={image}
						/>
					),
					hasCloseButton: true,
					callback: () => {
						modal.close();
					},
				});
			}
		},
		// eslint-disable-next-line
		[]
	);

	const { mutate: mutateRemovePhoto, isLoading: isDeletingPhoto } = useMutation(
		updateSettings,
		{
			onSettled: (data, error) => {
				if (data && data.success) {
					queryClient.invalidateQueries(QUERY_KEYS.Me);
					alert({ message: 'Photo deleted successfully!', type: 'success' });
				}

				if (error) {
					const response = handleError({ error });
					if (response?.message)
						alert({ message: response.message, type: 'error' });
				}
			},
		}
	);

	const onClickDeleteButton = () =>
		mutateRemovePhoto({
			photoUrl: null,
		});

	return (
		<>
			{isDeletingPhoto && <Loader />}
			<Box style={styles.container}>
				<Avatar
					src={user?.photoUrl || ''}
					sx={{
						backgroundImage: `url(${require('../../assets/images/stripBg.png')})`,
						backgroundSize: '400%',
						backgroundPosition: 'center',
						color: theme.palette.primary.main,
					}}
					style={styles.avatar}
				/>
				<UploadImageButton
					setFile={handlePreviewImage}
					style={styles.uploadBtn}
					startIcon={<CameraAlt />}
				>
					Upload Image
				</UploadImageButton>
				{user?.photoUrl && (
					<Button
						onClick={onClickDeleteButton}
						style={styles.deleteBtn}
						startIcon={<Delete />}
					>
						Delete Image
					</Button>
				)}
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	container: {
		display: 'flex',
		// alignItems: 'center',
		gap: theme.spacing(3),
	},
	avatar: {
		borderRadius: '5px',
		backgroundColor: LIGHT_GRAY,
		height: '50px',
		width: '50px',
		border: `1px solid ${theme.palette.primary.main}`,
	},
	uploadBtn: {
		backgroundColor: 'unset',
		border: `1px solid ${theme.palette.secondary.main}`,
		minWidth: '200px',
		fontWeight: '600',
		color: theme.palette.secondary.main,
	},
	deleteBtn: {
		backgroundColor: 'unset',
		border: `1px solid ${DANGER_COLOR}`,
		minWidth: '200px',
		fontWeight: '600',
		color: DANGER_COLOR,
	},
	imagePreviewContainer: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(2),
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
	},
	spinner: {
		position: 'absolute',
		color: grey['50'],
	},
	button: {
		backgroundColor: theme.palette.secondary.main,
		color: grey['50'],
		minWidth: '160px',
	},
});

export default UserImageWithAction;
