import React from 'react';
import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import { LIGHT_GRAY, THEME_MODE } from 'utilities';
import Link from '../../link';
import { useAppSelector } from 'store/hooks';
import { grey } from '@mui/material/colors';

interface Props extends BoxProps {
	data: { img: any; title: string; description: string; link: string };
	isNew?: boolean;
}

const ServicesCard = ({
	data: { title, description, img, link },
	isNew,
	...rest
}: Props) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const { mode } = useAppSelector((store) => store.theme);
	return (
		<Link to={link}>
			<Box
				{...rest}
				sx={{
					position: 'relative',
					background: `url(${require('assets/images/stripBg.png')})`,
					backgroundColor:
						mode === THEME_MODE.dark
							? theme.palette.background.paper
							: LIGHT_GRAY,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					borderRadius: '10px',
					display: 'grid',
					gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: '6fr 4fr' },
					gap: '6px',
					alignItems: 'center',
					overflow: 'hidden',
					cursor: 'pointer',
					height: { xs: '140px', sm: '150px', md: '160px' },
					...rest.sx,
				}}
			>
				{isNew && (
					<Box
						sx={{
							padding: ['4px 10px'],
							borderRadius: '15px',
							backgroundColor: theme.palette.primary.main,
							position: 'absolute',
							top: '10px',
							right: '10px',
						}}
					>
						<Typography
							sx={{ color: 'white', fontWeight: 'bold' }}
							variant={'body2'}
						>
							New
						</Typography>
					</Box>
				)}
				<Box
					sx={{
						// maxWidth: '200px',
						padding: {
							xs: '10px',
							sm: '15px 0px 15px 15px',
							md: '25px 0px 25px 30px',
						},
					}}
				>
					<Typography
						sx={{
							color:
								mode === THEME_MODE.dark
									? grey['100']
									: theme.palette.primary.main,
						}}
						style={styles.title}
						variant={'h6'}
					>
						{title}
					</Typography>
					<Typography
						sx={{
							color:
								mode === THEME_MODE.dark
									? grey['300']
									: theme.palette.primary.main,
							fontSize: '14px',
						}}
						variant={'body1'}
					>
						{description}
					</Typography>
				</Box>
				<Box
					sx={{
						background: `url(${img}) no-repeat`,
						backgroundPosition: 'left',
						backgroundSize: 'cover',
						// height: '150px',
						height: { xs: '140px', sm: '150px', md: '160px' },
					}}
				/>
			</Box>
		</Link>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: '3px',
		fontSize: '16px',
	},
});

export default ServicesCard;
