import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import ServicesCard from '../card/services';
import { THEME_MODE, LINKS } from 'utilities';
import { useAppSelector } from 'store/hooks';

const SERVICES = [
	{
		title: 'Instant Airtime to Cash',
		description: 'Sell airtime to us and get paid in cash to your bank account',

		img: require('../../assets/images/airtime-convert.png'),
		link: LINKS.RequestConvertAirtimeOtp,
	},
	{
		title: 'Manual Airtime to Cash',
		description: 'Sell airtime to us and get paid in cash to your bank account',

		img: require('../../assets/images/airtime-convert.png'),
		link: LINKS.ManualConvertAirtime,
	},
];

const ConvertAirtimeServices = () => {
	const theme = useTheme();
	const themeMode = useAppSelector((store) => store.theme.mode);
	const styles = useStyles(theme, themeMode);
	return (
		<div>
			<Typography style={styles.title} variant={'h5'}>
				Convert Airtime
			</Typography>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: {
						xs: '1fr',
						sm: 'repeat(2, 1fr)',
					},
					gap: '10px',
				}}
			>
				{SERVICES.map((data, key) => (
					<ServicesCard
						sx={{
							gridTemplateColumns: '0.6fr 0.4fr',
						}}
						data={data}
						key={key}
					/>
				))}
			</Box>
		</div>
	);
};

const useStyles = (theme: any, mode?: any) => ({
	title: {
		marginBottom: theme.spacing(3),
		fontWeight: '600',
		color: mode === THEME_MODE.dark ? grey[300] : 'inherit',
	},
});

export default ConvertAirtimeServices;
