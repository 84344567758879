import React from 'react';
import {
	Layout,
	TwoColGrid,
	WalletWithBreakdown,
	Seo,
	FundBettingAccountForm,
	H5Typography,
	BackButton,
} from 'components';

const FundBettingAccount = () => {
	return (
		<Layout>
			<Seo title={'Fund Betting Account'} />
			<TwoColGrid
				rightAside={<WalletWithBreakdown top={'6rem'} isStickySidebar />}
			>
				<BackButton name={'Services'} sx={{ marginBottom: ['2rem'] }} />
				<H5Typography name={'Fund Betting Account'} />
				<FundBettingAccountForm />
			</TwoColGrid>
		</Layout>
	);
};

export default FundBettingAccount;
