import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { red } from '@mui/material/colors';
import OtpInput from 'react-otp-input';
import moduleStyles from './style.module.css';

interface Props {
	text?: string;
	onChange?: (value: string) => void;
	value?: string;
	numInputs?: number;
	error?: string | null | boolean;
	label?: string;
}

const OtpTextInput: React.FC<Props> = ({
	text,
	onChange,
	numInputs = 4,
	error,
	value,
	label,
}) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	return (
		<Box>
			{label && (
				<Typography style={styles.label} component={'label'} variant={'body1'}>
					{label}
				</Typography>
			)}

			<OtpInput
				isInputNum
				shouldAutoFocus
				errorStyle={Boolean(error)}
				focusStyle={styles.focusStyle}
				inputStyle={moduleStyles.otpInputStyle}
				value={value}
				onChange={onChange}
				isInputSecure
				numInputs={numInputs}
				separator={<Box style={styles.separator} />}
			/>
			{error && (
				<Typography style={styles.errorText} variant={'body2'}>
					{error}
				</Typography>
			)}
		</Box>
	);
};

const useStyles = (theme: any) => ({
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
	},

	separator: {
		width: '15px',
	},
	inputStyle: {
		height: '55px',
		width: '55px',
		fontSize: '14px',
		color: theme.palette.primary.main,
		borderRadius: theme.spacing(1),
		border: `1px solid ${theme.palette.primary.main}`,
		backgroundColor: 'unset !important',
	},
	focusStyle: {
		outlineColor: theme.palette.primary.main,
	},
	errorText: {
		color: red[800],
		marginTop: theme.spacing(1),
	},
});

export default OtpTextInput;
