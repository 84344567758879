import React, { ReactNode } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './index';
import { useAppSelector } from 'store/hooks';

type Props = {
	children: ReactNode;
};

const MuiThemeProvider = ({ children }: Props) => {
	// Todo Use Store
	const appTheme: any = useAppSelector((store) => store.theme);

	return (
		<ThemeProvider theme={theme(appTheme.mode === 'dark')}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	);
};

export default MuiThemeProvider;
