import React from 'react';
import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import TextInput from '../form-components/text-input';
import Button from '../button';
import { grey } from '@mui/material/colors';
import Select from '../form-components/select';
import { useAppSelector } from 'store/hooks';
import { QUERY_KEYS, INetwork, EPIN_SERVICES } from 'utilities';
import { ValidationSchema, LINKS, formatNumberToCurrency } from 'utilities';
import { airtimeNetworks, generateEPin } from 'api';
import {
	useAlert,
	useHandleError,
	useModalAlert,
	useVerifyPin,
	useCheckKycLevelTwo,
} from 'hooks';
import CopyItem from 'components/copy-items';

const SELECT_SERVICE_PROVIDER = 'Select service provider';

const GenerateAirtimeForm = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const theme = useTheme();
	const styles = useStyles(theme);
	const alert = useAlert();
	const handleError = useHandleError();
	const modal = useModalAlert();
	const verifyPin = useVerifyPin();
	const checkKycLevel = useCheckKycLevelTwo();

	/* State */
	const { token } = useAppSelector((store) => store.auth);

	// Initial Values
	const initialValues = {
		network: SELECT_SERVICE_PROVIDER,
		amount: '',
	};

	// Generate E-Pin
	const { isLoading: isGeneratingEPin, mutate: mutateGenerateEPin } =
		useMutation(generateEPin, {
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						alert({ message: response.message, type: 'error' });
					}
				}

				if (data && data.success) {
					queryClient.invalidateQueries([QUERY_KEYS.RecentTransactions]);
					const pin = data.payload.pin;
					const amount = Number(data.payload.pin_data.amount);
					resetForm();
					queryClient.invalidateQueries(QUERY_KEYS.UserWallet);
					queryClient.invalidateQueries(QUERY_KEYS.Transactions);
					console.log(data);
					/* 
					!!TODO
						*Display pin: data.payload.pin,


					 */
					modal({
						type: 'success',
						title: 'Generate E-Pin',
						message: `E-Pin of Network for the amount of ${formatNumberToCurrency(
							amount
						)} has been generated successfully!!`,
						hasCloseButton: true,
						primaryButtonText: 'Go to Dashboard',
						secondaryButtonText: 'Load E-Pin',
						children: <CopyItem text={pin} />,
						onClickPrimaryButton: () => {
							modal(null);
							navigate(LINKS.Dashboard);
						},
						onClickSecondaryButton: () => {
							modal(null);
							navigate(LINKS.EPin);
						},
					});
				}
			},
		});

	const { handleChange, handleSubmit, errors, touched, values, resetForm } =
		useFormik({
			initialValues,
			validationSchema: ValidationSchema.GenerateAirtime,
			onSubmit: (values) => {
				const data = {
					service: EPIN_SERVICES.AIRTIME,
					pin_data: {
						amount: parseInt(values.amount),
						network: values.network,
					},
				};

				if (checkKycLevel()) {
					verifyPin({
						title: 'Generate Airtime',
						message: 'Verify transaction pin to continue',
						callback: () => {
							verifyPin(null);
							mutateGenerateEPin(data);
						},
					});
				}
			},
		});

	const { amount, network } = values;

	const { isLoading: isLoadingAirtimeNetwork, data: dataAirtimeNetwork } =
		useQuery(
			[QUERY_KEYS.AirtimeNetwork],
			() => airtimeNetworks({ isActive: true }),
			{
				enabled: !!token,
				refetchOnWindowFocus: false,
				onSettled: (data, error) => {
					if (error) {
						const response = handleError({ error });
						if (response?.message) {
							alert({ type: 'error', message: response.message });
						}
					}
				},
			}
		);

	return (
		<>
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Service Provider
					</Typography>
					<Select
						fullWidth
						error={touched.network && errors.network ? true : false}
						helpertext={touched.network && errors.network}
						value={network}
						onChange={handleChange('network') as never}
					>
						<MenuItem disabled value={SELECT_SERVICE_PROVIDER}>
							{isLoadingAirtimeNetwork
								? 'Loading...'
								: dataAirtimeNetwork && dataAirtimeNetwork.payload.length === 0
								? 'No available network'
								: SELECT_SERVICE_PROVIDER}
						</MenuItem>
						{dataAirtimeNetwork &&
							dataAirtimeNetwork.payload.length > 0 &&
							dataAirtimeNetwork.payload.map((airtime: INetwork) => (
								<MenuItem
									value={airtime.id}
									disabled={!airtime.isActive}
									key={airtime.id}
								>
									{airtime.name} {!airtime.isActive && `(Not available)`}
								</MenuItem>
							))}
					</Select>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Amount
					</Typography>
					<TextInput
						fullWidth
						error={touched.amount && errors.amount ? true : false}
						helperText={touched.amount && errors.amount}
						placeholder={'Enter amount'}
						type={'number'}
						value={amount}
						onChange={handleChange('amount')}
					/>
				</Box>
				<Button
					loading={isGeneratingEPin}
					size={'large'}
					style={styles.btn}
					onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					Generate e-Pin
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default GenerateAirtimeForm;
