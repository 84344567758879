import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
	Layout,
	ResetPasswordStepper,
	ResetPasswordPinForm,
	Seo,
	OtpForm,
} from 'components';
import Alert from 'components/alert';
import { LINKS, QUERY_KEYS } from 'utilities';
import { validateResetPinOrPassword, resetPassword } from 'api';
import { useAlert, useHandleError, useModalAlert } from 'hooks';
import { useAppSelector } from 'store/hooks';

const ResetPassword = () => {
	const theme = useTheme();
	const queryClient = useQueryClient();
	const handleError = useHandleError();
	const modal = useModalAlert();
	const styles = useStyles(theme);
	const navigate = useNavigate();
	const alert = useAlert();
	const { user } = useAppSelector((store) => store.auth);
	const { search } = useLocation();

	const parseQuery = queryString.parse(search);

	const [otp, setOtp] = useState<string>('');
	const [currentStep, setCurrentStep] = useState<number>(1);

	const { isLoading: isValidatingPin, mutate: mutateValidatePin } = useMutation(
		validateResetPinOrPassword,
		{
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						alert({ message: res.message, type: 'error' });
					}
				}

				if (data && data.success) {
					setCurrentStep(2);
					alert({ message: 'OTP validated successfully', type: 'success' });
				}
			},
		}
	);

	const handleConfirmOtp = (token: string) => {
		setOtp(token);
		mutateValidatePin({
			data: {
				type: 'RESET_PASSWORD',
				otp: parseInt(token),
			},
			id: user?.id as string,
		});
	};

	const { isLoading: isResettingPassword, mutate: mutateResetPassword } =
		useMutation(resetPassword, {
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						modal({
							title: 'Reset Password Message',
							message: res.message,
							onClickPrimaryButton: () => modal(null),
							type: 'error',
							primaryButtonText: 'Close',
						});
					}
				}

				if (data && data.success) {
					queryClient.invalidateQueries(QUERY_KEYS.Me);
					setCurrentStep(3);
					setOtp('');
				}
			},
		});

	const handleResetPassword = (value: string) => {
		mutateResetPassword({
			data: {
				newPassword: value,
				otp: parseInt(otp),
			},
			id: user?.id as string,
		});
	};

	const handleNavigate = () => {
		if (parseQuery && parseQuery.redirect) {
			return navigate(parseQuery.redirect as string);
		}

		navigate(LINKS.Dashboard);
	};

	return (
		<Layout>
			<Seo title={'Reset Password'} />
			<Box style={styles.container}>
				{currentStep !== 3 && <ResetPasswordStepper step={currentStep} />}

				<Box hidden={currentStep !== 1}>
					<Box>
						<Box sx={{ marginBottom: '2rem' }}>
							<Typography style={styles.title} variant={'h5'}>
								Verify OTP
							</Typography>
							<Typography variant={'body1'}>
								Enter the OTP sent to your email to reset Password
							</Typography>
						</Box>
						<OtpForm
							isLoading={isValidatingPin}
							callback={(values: string) => handleConfirmOtp(values)}
						/>
					</Box>
				</Box>
				<Box hidden={currentStep !== 2}>
					<ResetPasswordPinForm
						isPasswordForm
						title={'Update password'}
						description={'Please type in your new password.'}
						placeholder={'Type in your new password'}
						label={'New password'}
						btnText={'Update password'}
						onSubmit={(value: string) => handleResetPassword(value)}
						handleGoBack={() => navigate(-1)}
						isLoading={isResettingPassword}
					/>
				</Box>
				<Box hidden={currentStep !== 3}>
					<Alert
						title={'Success'}
						alertType={'success'}
						successType={'primary'}
						handlePress={handleNavigate}
						message={'Your password has been successfully updated'}
						btnText={'Continue'}
					/>
				</Box>
			</Box>
		</Layout>
	);
};

const useStyles = (theme: any) => ({
	container: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '30px',
		maxWidth: '540px',
	},
	title: {
		fontWeight: '600',
		marginBottom: '20px',
	},
});

export default ResetPassword;
