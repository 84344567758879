import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { grey } from '@mui/material/colors';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
	Box,
	IconButton,
	InputAdornment,
	Typography,
	useTheme,
} from '@mui/material';
import TextInput from '../form-components/text-input';
import Button from '../button';
import OtpTextInput from 'components/form-components/otp-text-field';
import { PASSWORD } from 'utilities';

type Props = {
	label: string;
	description: string;
	isPasswordForm?: boolean;
	title: string;
	placeholder: string;
	btnText: string;
	isHideBackBtn?: boolean;
	onSubmit: (values?: any) => void;
	handleGoBack?: () => void;
	setValues?: (value: string) => void;
	isLoading?: boolean;
	numInputs?: number;
};

const ResetPasswordOrPinForm = ({
	label,
	title,
	description,
	placeholder,
	isHideBackBtn,
	isPasswordForm,
	onSubmit,
	handleGoBack,
	setValues,
	isLoading,
	numInputs = 6,
	btnText,
}: Props) => {
	const theme = useTheme();
	const styles = useStyles(theme);

	const [isDisplayPassword, setDisplayPassword] =
		React.useState<boolean>(false);
	const [isDisplayConfirmPassword, setDisplayConfirmPassword] =
		React.useState<boolean>(false);

	const pinRe = numInputs === 6 ? /\d{6}/ : /\d{4}/;

	const validationSchema = yup.object().shape({
		value: yup
			.string()
			.matches(
				isPasswordForm ? PASSWORD : pinRe,
				isPasswordForm
					? 'Password must contain at least 8 characters, one uppercase, one number and a special character(!@#$&)'
					: `Pin must be ${numInputs} digit number`
			)
			.required(`Enter ${isPasswordForm ? 'Password' : 'Pin'}`),
		confirmValue: yup
			.string()
			.oneOf(
				[yup.ref('value')],
				`${isPasswordForm ? 'Password' : 'Pin'} do not match`
			)
			.required(`Enter confirm ${isPasswordForm ? 'password' : 'pin'}`),
	});

	const initialValues = {
		value: '',
		confirmValue: '',
	};

	const { errors, handleChange, handleSubmit, values, touched } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			typeof setValues !== 'undefined' && setValues(values.value);
			onSubmit(values.value);
		},
	});

	const { value, confirmValue } = values;

	return (
		<Box>
			<Box sx={{ marginBottom: theme.spacing(4) }}>
				<Typography style={styles.title} variant={'h5'}>
					{title}
				</Typography>
				<Typography variant={'body1'}>{description}</Typography>
			</Box>
			<Box style={styles.form as any} component={'form'}>
				{isPasswordForm ? (
					<>
						<Box>
							<Typography
								style={styles.label}
								component={'label'}
								variant={'body1'}
							>
								{label}
							</Typography>
							<TextInput
								fullWidth
								type={isDisplayPassword ? 'text' : 'password'}
								placeholder={placeholder}
								error={errors && touched.value && Boolean(errors.value)}
								helperText={errors && touched.value && errors.value}
								value={values.value}
								onChange={handleChange('value')}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												onClick={() => setDisplayPassword(!isDisplayPassword)}
											>
												{isDisplayPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Box>

						<Box>
							<Typography
								style={styles.label}
								component={'label'}
								variant={'body1'}
							>
								Confirm {label}
							</Typography>
							<TextInput
								fullWidth
								type={isDisplayConfirmPassword ? 'text' : 'password'}
								placeholder={placeholder}
								error={
									errors && touched.confirmValue && Boolean(errors.confirmValue)
								}
								helperText={
									errors && touched.confirmValue && errors.confirmValue
								}
								value={values.confirmValue}
								onChange={handleChange('confirmValue')}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												onClick={() =>
													setDisplayConfirmPassword(!isDisplayConfirmPassword)
												}
											>
												{isDisplayConfirmPassword ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Box>
					</>
				) : (
					<>
						<Box>
							<Typography
								style={styles.label}
								component={'label'}
								variant={'body1'}
							>
								Pin
							</Typography>
							<OtpTextInput
								error={touched.value && errors.value}
								value={value}
								onChange={handleChange('value')}
								numInputs={numInputs}
							/>
						</Box>
						<Box>
							<Typography
								style={styles.label}
								component={'label'}
								variant={'body1'}
							>
								Confirm Pin
							</Typography>
							<OtpTextInput
								error={touched.confirmValue && errors.confirmValue}
								value={confirmValue}
								onChange={handleChange('confirmValue')}
								numInputs={numInputs}
							/>
						</Box>
					</>
				)}
				<Box>
					<Button
						loading={isLoading}
						fullWidth
						size={'large'}
						sx={{ marginBottom: '10px' }}
						style={styles.btn}
						onClick={(e: React.FormEvent<HTMLButtonElement>) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						{btnText || 'Save'}
					</Button>
					{!isHideBackBtn && (
						<Button
							onClick={() => {
								typeof handleGoBack !== 'undefined' && handleGoBack();
							}}
							sx={{
								color: theme.palette.secondary.main,
								':hover': {
									backgroundColor: theme.palette.secondary.main,
									color: grey[50],
								},
							}}
							fullWidth
							size={'large'}
							style={styles.btnOutline}
						>
							Go back
						</Button>
					)}
				</Box>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontSize: '14px',
		fontWeight: '600',
	},
	btnOutline: {
		border: `1px solid ${theme.palette.secondary.main}`,
		fontSize: '14px',
		fontWeight: '600',
	},
	emailText: {
		fontWeight: '600',
	},
});

export default ResetPasswordOrPinForm;
