import React from 'react';

type Props = {
	color?: string;
	height?: number;
	width?: number;
};

const PurchaseDataIcon = ({ width, height, color }: Props) => {
	return (
		<svg
			width='32'
			height='24'
			viewBox='0 0 32 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				opacity='0.5'
				d='M16.0004 23.1739L22.3738 16.8004C21.8392 15.5532 20.9503 14.4903 19.8174 13.7433C18.6845 12.9963 17.3574 12.5981 16.0004 12.5981C14.6434 12.5982 13.3162 12.9963 12.1833 13.7433C11.0504 14.4903 10.1616 15.5532 9.62695 16.8005L16.0004 23.1739Z'
				fill='#DCE3E7'
				stroke={color || '#28536B'}
				strokeMiterlimit='10'
			/>
			<path
				d='M1.08332 9.08423C0.929608 9.08409 0.778978 9.04106 0.648372 8.96001C0.517765 8.87896 0.41236 8.76312 0.344005 8.62544C0.275649 8.48776 0.247055 8.33375 0.261438 8.18071C0.27582 8.02767 0.332608 7.88166 0.425418 7.75913C2.22016 5.39492 4.52452 3.46544 7.16732 2.11406C9.90417 0.724324 12.9303 1.0947e-05 15.9998 1.24146e-10C19.0693 -1.09468e-05 22.0955 0.72427 24.8323 2.11399C27.4751 3.46547 29.7795 5.395 31.5743 7.7592C31.7064 7.93387 31.7638 8.15383 31.7338 8.37078C31.7038 8.58772 31.5888 8.78389 31.4143 8.91614C31.2397 9.04839 31.0198 9.10587 30.8028 9.07603C30.5858 9.04618 30.3896 8.93145 30.2572 8.75698C28.5927 6.5501 26.4386 4.75976 23.9646 3.5269C21.4905 2.29405 18.7641 1.65234 15.9999 1.65234C13.2356 1.65234 10.5092 2.29405 8.03511 3.5269C5.56105 4.75976 3.40697 6.5501 1.74248 8.75698C1.66542 8.85881 1.56577 8.94138 1.45139 8.99817C1.33701 9.05496 1.21102 9.0844 1.08332 9.08423Z'
				fill={color || '#28536B'}
			/>
			<path
				d='M15.9996 24C15.8911 24.0001 15.7836 23.9788 15.6834 23.9373C15.5831 23.8958 15.4921 23.8348 15.4154 23.758L3.30643 11.6492C3.16901 11.5118 3.08443 11.3303 3.06757 11.1367C3.05071 10.9431 3.10265 10.7497 3.21425 10.5906C4.65135 8.53228 6.56415 6.85137 8.79009 5.6907C11.016 4.53003 13.4893 3.92395 15.9996 3.92395C18.51 3.92395 20.9833 4.53003 23.2092 5.6907C25.4351 6.85137 27.3479 8.53228 28.785 10.5906C28.9104 10.77 28.9594 10.9918 28.9214 11.2074C28.8834 11.4229 28.7614 11.6145 28.5822 11.7402C28.403 11.8659 28.1812 11.9154 27.9656 11.8777C27.75 11.84 27.5582 11.7183 27.4322 11.5393C26.181 9.74615 24.5256 8.27249 22.5997 7.23735C20.6738 6.20222 18.5313 5.63465 16.3455 5.58054C14.1597 5.52644 11.9918 5.98735 10.017 6.92593C8.04219 7.8645 6.31589 9.25442 4.97756 10.9835L16.5838 22.5895C16.6993 22.7051 16.778 22.8523 16.8099 23.0126C16.8418 23.1728 16.8254 23.339 16.7629 23.4899C16.7004 23.6409 16.5945 23.7699 16.4586 23.8607C16.3227 23.9515 16.163 24 15.9996 24Z'
				fill={color || '#28536B'}
			/>
			<path
				d='M15.9999 24C15.8914 24.0002 15.7839 23.9789 15.6836 23.9373C15.5834 23.8958 15.4923 23.8349 15.4157 23.758L6.13845 14.481C6.00748 14.35 5.92432 14.1787 5.90233 13.9948C5.88034 13.8109 5.92079 13.6249 6.01718 13.4667C7.04598 11.7814 8.4818 10.3817 10.1927 9.39605C11.96 8.38182 13.9622 7.84814 15.9998 7.84814C18.0375 7.84814 20.0396 8.38182 21.8069 9.39605C23.5178 10.3817 24.9536 11.7814 25.9824 13.4667C26.0788 13.6249 26.1193 13.8109 26.0973 13.9948C26.0753 14.1787 25.9921 14.35 25.8612 14.481L16.5841 23.7581C16.5074 23.8349 16.4164 23.8958 16.3161 23.9373C16.2158 23.9788 16.1084 24.0002 15.9999 24ZM7.78073 13.7865L15.9997 22.0055L24.2188 13.7865C23.2951 12.4632 22.0655 11.3825 20.6345 10.6363C19.2036 9.89003 17.6136 9.50034 15.9998 9.50034C14.3859 9.50034 12.796 9.89003 11.365 10.6363C9.93407 11.3825 8.70447 12.4632 7.78073 13.7865Z'
				fill={color || '#28536B'}
			/>
			<path
				d='M16.0004 24C15.8919 24.0001 15.7844 23.9788 15.6842 23.9373C15.5839 23.8958 15.4929 23.8348 15.4162 23.758L9.04273 17.3846C8.92596 17.2678 8.84687 17.1187 8.81567 16.9565C8.78446 16.7943 8.80257 16.6265 8.86765 16.4747C9.466 15.0789 10.4607 13.8893 11.7286 13.0534C12.9965 12.2174 14.4818 11.7719 16.0004 11.7719C17.5191 11.7719 19.0043 12.2174 20.2722 13.0534C21.5401 13.8893 22.5348 15.0789 23.1331 16.4747C23.1982 16.6265 23.2163 16.7943 23.1851 16.9565C23.1539 17.1187 23.0748 17.2678 22.9581 17.3846L16.5846 23.758C16.5079 23.8348 16.4169 23.8958 16.3166 23.9373C16.2164 23.9788 16.1089 24.0001 16.0004 24ZM10.6255 16.6307L16.0004 22.0055L21.3753 16.6307C20.8519 15.6611 20.076 14.8512 19.1297 14.2867C18.1835 13.7222 17.1022 13.4242 16.0004 13.4242C14.8986 13.4242 13.8173 13.7222 12.8711 14.2867C11.9249 14.8512 11.1489 15.6611 10.6255 16.6307Z'
				fill={color || '#28536B'}
			/>
		</svg>
	);
};

export default PurchaseDataIcon;
