import React from 'react';
import { Box, useTheme } from '@mui/material';
import {
	Layout,
	Todo,
	TransactionRecord,
	Seo,
	QuickServices,
	Wallet,
	ToastAlert,
} from 'components';
import { usePageTitle } from 'hooks';

const Dashboard = () => {
	const theme = useTheme();
	usePageTitle('Dashboard');

	return (
		<Layout>
			<Seo title={'Dashboard'} />
			<ToastAlert />

			<Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: '4rem' }}>
				<Box>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: {
								xs: '1fr',
								sm: '5.5fr 4.5fr',
								md: '6fr 4fr',
								lg: '5.5fr 4.5fr',
							},
							gap: theme.spacing(2),
						}}
					>
						<Wallet />
						<TransactionRecord sx={{ display: { xs: 'none', sm: 'grid' } }} />
					</Box>
				</Box>
				<Todo />
				<QuickServices />
				<TransactionRecord
					hasTitle
					sx={{ display: { xs: 'grid', sm: 'none' } }}
				/>

				{/* <TransactionHistory /> */}
			</Box>
		</Layout>
	);
};

export default Dashboard;
