import React, { useState, useEffect } from 'react';
import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';
import TextInput from '../form-components/text-input';
import Button from '../button/custom-button';
import { useFormik } from 'formik';
import { grey } from '@mui/material/colors';
import Select from '../form-components/select';
import {
	QUERY_KEYS,
	calculatePercentageAmount,
	ValidationSchema,
	INetwork,
	formatNumberToCurrency,
	AirtimeConversion,
	PHONE_REX,
} from 'utilities';
import { useAppSelector } from 'store/hooks';
import TextPlaceholder from '../partials/custom-text-input-holder';
import AirtimeConvertModal from '../modal/airtime-conversion-request-modal';
import { useHandleError, useModalAlert, useVerifyPin } from 'hooks';
import { airtimeConvertNetworks, convertAirtime } from 'api';

const SELECT_NETWORK = 'Select network provider';

interface Props {
	selectNetwork?: (network: string) => void;
}

const ConvertAirtimeForm: React.FC<Props> = ({ selectNetwork }) => {
	const theme = useTheme();
	const styles = useStyles(theme);

	const verifyPin = useVerifyPin();
	const modal = useModalAlert();
	const handleError = useHandleError();
	const { state: urlState } = useLocation();
	const { token } = useAppSelector((store) => store.auth);
	const queryClient = useQueryClient();
	const parseQueryString: any = urlState;

	const [isDisplayConvertModal, setDisplayConvertModal] =
		useState<boolean>(false);
	const [selectedNetwork, setSelectedNetwork] = useState<INetwork | null>(null);
	const [receiveAmount, setReceiveAmount] = useState<string>('');

	const initialValues: AirtimeConversion = {
		phone_number: '',
		amount: '',
		network: SELECT_NETWORK,
	};

	const validationSchema = yup.object().shape({
		network: yup
			.string()
			.notOneOf(['Select network provider'], 'Select network provider')
			.required('Select network provider'),
		phone_number: yup
			.string()
			.required('Specify phone number')
			.matches(PHONE_REX, 'Invalid phone number'),
		amount: yup
			.number()
			.positive('Amount must be positive')
			.moreThan(
				499,
				`Amount must be more than ${formatNumberToCurrency('500')}`
			)
			.lessThan(
				50001,
				`Amount must be less than ${formatNumberToCurrency('5000')}`
			)
			.required('Specify amount'),
	});

	const {
		isLoading: isLoadingNetwork,
		data: dataNetworkProviders,
		error: errorDataNetworks,
	} = useQuery(
		QUERY_KEYS.GetConvertNetwork,
		() => airtimeConvertNetworks({ isActive: true }),
		{
			enabled: !!token,
			retry: 1,
			refetchOnWindowFocus: false,
		}
	);

	const { isLoading, mutate: mutateConvertAirtime } = useMutation(
		convertAirtime,
		{
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						modal({
							type: 'error',
							title: 'Convert Airtime to Cash',
							message: response.message,
							primaryButtonText: 'Try again',
							onClickPrimaryButton: () => modal(null),
						});
					}
				}

				if (data && data.success) {
					queryClient.invalidateQueries(QUERY_KEYS.UserWallet);
					queryClient.invalidateQueries(QUERY_KEYS.Transactions);
					queryClient.invalidateQueries([QUERY_KEYS.RecentTransactions]);
					typeof selectNetwork === 'function' && selectNetwork(''); // Clear select network

					setDisplayConvertModal(true);
				}
			},
		}
	);

	const {
		handleSubmit,
		handleChange,
		errors,
		touched,
		values,
		setFieldValue,
		resetForm,
	} = useFormik({
		initialValues: parseQueryString
			? {
					...initialValues,
					amount: (parseQueryString?.amount as string) || '',
					phone_number: (parseQueryString.phone_number as string) || '',
			  }
			: initialValues,
		validationSchema,
		onSubmit: (values) => {
			const handleConvertAirtime = () => {
				verifyPin(null);
				mutateConvertAirtime(values);
			};

			verifyPin({
				title: 'Convert Airtime',
				message: `Verify transaction pin to convert airtime of ${
					selectedNetwork?.name
				} for ${formatNumberToCurrency(values.amount as string)}`,
				callback: handleConvertAirtime,
			});
		},
	});

	const { phone_number, amount, network } = values;

	// Set values from url
	useEffect(
		() => {
			if (parseQueryString && parseQueryString.network) {
				const _network =
					dataNetworkProviders &&
					dataNetworkProviders.payload.find(
						(network: INetwork) => network.id === parseQueryString.network
					);

				if (_network) {
					setSelectedNetwork(_network);
					setFieldValue('network', _network.id);
					const cal_amount = Math.round(
						calculatePercentageAmount({
							amount: Number(amount),
							rate: _network.rate as string,
						})
					);
					setReceiveAmount(cal_amount.toString());
				}
			}
		},
		// eslint-disable-next-line
		[dataNetworkProviders]
	);

	const handleSelectNetwork = (value: string) => {
		setFieldValue('network', value);
		const filterNetwork =
			dataNetworkProviders &&
			dataNetworkProviders.payload?.find(
				(network: INetwork) => network.id === value
			);

		if (filterNetwork) {
			setSelectedNetwork(filterNetwork);
			typeof selectNetwork === 'function' &&
				selectNetwork(filterNetwork.name as string);
		}
	};

	const handleChangeAmount = (amount: string) => {
		setFieldValue('amount', amount);
		if (selectedNetwork) {
			const cal_amount = Math.round(
				calculatePercentageAmount({
					amount,
					rate: selectedNetwork.rate as string,
				})
			);
			setReceiveAmount(cal_amount.toString());
		}
	};

	const handleComplete = () => {
		resetForm();
		setReceiveAmount('');
		setDisplayConvertModal(false);
		setSelectedNetwork(null);
		// navigate(LINKS.Dashboard);
	};

	return (
		<>
			{isDisplayConvertModal && (
				<AirtimeConvertModal
					transferNumber={selectedNetwork ? selectedNetwork.number : ''}
					service={selectedNetwork ? selectedNetwork.name : ''}
					amount={amount as string}
					callback={handleComplete}
				/>
			)}

			<Box
				onSubmit={(e: any) => {
					e.preventDefault();
					handleSubmit();
				}}
				style={styles.form as any}
				component={'form'}
			>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Network provider
					</Typography>
					<Select
						error={touched.network && errors.network ? true : false}
						helpertext={touched.network && errors.network}
						fullWidth
						value={network}
						onChange={(e: any) => handleSelectNetwork(e.target.value)}
					>
						<MenuItem value={SELECT_NETWORK} disabled>
							{isLoadingNetwork
								? 'Loading...'
								: dataNetworkProviders &&
								  dataNetworkProviders.payload.length === 0
								? 'No available network'
								: errorDataNetworks
								? handleError({ error: errorDataNetworks })?.message
								: SELECT_NETWORK}
						</MenuItem>
						{dataNetworkProviders &&
							dataNetworkProviders.payload.length > 0 &&
							dataNetworkProviders.payload.map((network: INetwork) => (
								<MenuItem
									disabled={!network.isActive}
									value={network.id}
									key={network.id}
								>
									{network.name} {!network.isActive ? '(Not available)' : ''}
								</MenuItem>
							))}
					</Select>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Phone number
					</Typography>
					<TextInput
						error={touched.phone_number && Boolean(errors.phone_number)}
						helperText={touched.phone_number && errors.phone_number}
						fullWidth
						placeholder={'Type in phone number here'}
						value={phone_number}
						onChange={handleChange('phone_number')}
					/>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Amount to sale
					</Typography>
					{network && network !== SELECT_NETWORK ? (
						<TextInput
							fullWidth
							type={'number'}
							error={touched.amount && Boolean(errors.amount)}
							helperText={touched.amount && errors.amount}
							placeholder={'Type in amount of airtime for sale'}
							value={amount}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								const amount = e.target.value;

								handleChangeAmount(amount);
							}}
						/>
					) : (
						<TextPlaceholder text={'Select network before entering amount'} />
					)}
				</Box>

				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Rate
					</Typography>
					<TextPlaceholder
						text={
							(selectedNetwork && (selectedNetwork.rate as string)) ||
							'----------'
						}
					/>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Amount to receive
					</Typography>
					<TextPlaceholder
						text={receiveAmount ? receiveAmount.toString() : '----------'}
					/>
				</Box>

				<Button
					loading={isLoading}
					buttonProps={{
						type: 'submit',
						size: 'large',
						style: styles.btn,
					}}
				>
					Proceed
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
});

export default ConvertAirtimeForm;
