import React, { ChangeEvent, useEffect, useState } from 'react';
import {
	Box,
	InputAdornment,
	Typography,
	useTheme,
	// InputAdornment,
} from '@mui/material';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { grey } from '@mui/material/colors';
import TextInput from '../form-components/text-input';
import Button from '../button';
import {
	giftCardProducts,
	purchaseGiftCard,
	giftCardProviders,
	IGiftCardPurchase,
} from 'api';
import {
	QUERY_KEYS,
	Product,
	formatNumberToCurrency,
	IPhoneVerifiedData,
	THEME_MODE,
	LIGHT_GRAY,
	capitalize,
	SOCHITEL_LINKS,
	IProvider,
	calculateDiscountedAmount,
} from 'utilities';
import { setTransactionBreakdown } from 'store/transaction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
	useModalAlert,
	useHandleError,
	useVerifyPin,
	useVerifyCoupon,
	useAlert,
} from 'hooks';
import CopyToClipboard from 'components/copy-to-clipboard';
import CustomSelect from 'components/form-components/custom-select';
import CustomSelectWithImage from 'components/form-components/custom-select-with-image';
import countries from 'utilities/data/countries.json';

const SELECT_PROVIDER = 'Select provider';
const SELECT_PACKAGE = 'Select package';
const SELECT_COUNTRY = 'Select country';

interface ICountry {
	id: number;
	sortname: string;
	name: string;
	phoneCode: number;
}

interface IEVoucherPin {
	pin?: {
		number: string;
		serial: string;
		instructions: string;
	};
}

const EVoucherPin = ({ pin }: IEVoucherPin) => {
	const theme = useTheme();
	const mode = useAppSelector((store) => store.theme.mode);
	const isDark = mode === THEME_MODE.dark;

	const MAX_VALUE_LENGTH = 30;

	if (pin) {
		return (
			<Box
				sx={{
					display: 'grid',
					gap: '4px',
				}}
			>
				<Typography>Gift Card Details</Typography>
				{Object.keys(pin).map((pinKey) => {
					const value = pin[pinKey];
					const hasLargerValueLength = value.length > MAX_VALUE_LENGTH;
					return (
						<Box
							sx={{
								display: 'flex',
								alignItems: hasLargerValueLength ? 'flex-start' : 'center',
								justifyContent: 'space-between',
								gap: hasLargerValueLength ? '6' : '15px',
								backgroundColor: isDark
									? theme.palette.background.paper
									: LIGHT_GRAY,
								padding: '6px 10px',
								flexDirection: hasLargerValueLength ? 'column' : 'row',
							}}
							key={pinKey}
						>
							<Typography>{capitalize(pinKey)}:</Typography>
							<Box
								sx={{
									display: 'flex',
									gap: '4px',
									alignItems: 'center',
								}}
							>
								<Typography>{value || `No available ${pinKey}`}</Typography>
								{pinKey === 'number' && <CopyToClipboard text={value} />}
							</Box>
						</Box>
					);
				})}
			</Box>
		);
	}

	return null;
};

const GiftCardForm = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const handleError = useHandleError();
	const modal = useModalAlert();
	const verifyPin = useVerifyPin();
	const alert = useAlert();
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	const [phoneVerifiedData, setPhoneVerifiedData] =
		useState<null | IPhoneVerifiedData>(null);

	// Product State

	const [isLoadingProducts, setLoadingProducts] = useState<boolean>(false);

	const [products, setProducts] = useState<Product[] | null>(null);

	const [selectedProduct, setSelectedProduct] = useState<null | Product>(null);
	const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);

	const [isLoadingGiftCardProviders, setLoadingGiftCardProviders] =
		useState<boolean>(false);
	const [dataGiftCardProviders, setDataGiftCardProviders] = useState<
		null | IProvider[]
	>(null);

	const isProductPriceRanged = selectedProduct?.priceType === 'range';

	const { coupon, clearCoupon, verifyCoupon, isVerifyingCoupon } =
		useVerifyCoupon();

	useEffect(
		() => {
			if (coupon) {
				dispatch(
					setTransactionBreakdown({
						coupon,
					})
				);
			}
		},
		// eslint-disable-next-line
		[coupon]
	);

	const validationSchema = yup.object().shape({
		operatorId: yup
			.string()
			.notOneOf([SELECT_PROVIDER], SELECT_PROVIDER)
			.required('Network provider is required'),
		productId: yup
			.string()
			.notOneOf([SELECT_PACKAGE], SELECT_PACKAGE)
			.required('Network package is required'),
		country: yup
			.string()
			.notOneOf([SELECT_COUNTRY], SELECT_COUNTRY)
			.required('Country is required'),
		amount: yup
			.number()
			.positive('Amount cannot be negative')
			.required('Amount is required'),
	});

	const initialValues = {
		product: '',
		productId: SELECT_PACKAGE,
		operatorId: SELECT_PROVIDER,
		amount: '',
		discount_code: '',
		country: '',
	};

	const { isLoading: isPurchasingGiftCard, mutate: mutatePurchaseGiftCard } =
		useMutation(purchaseGiftCard, {
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						modal({
							title: 'Gift Card Purchase',
							message: response.message,
							hasCloseButton: true,
							type: 'error',
							primaryButtonText: 'Close',
							onClickPrimaryButton: () => modal(null),
						});
					}
				}
				if (data && data.success) {
					queryClient.invalidateQueries([QUERY_KEYS.UserWallet]);
					dispatch(setTransactionBreakdown(null));

					const payload = data.payload;

					setPhoneVerifiedData(null);
					resetForm();
					modal({
						title: 'Gift Card Purchase',
						message: `${payload?.operator} of ${
							payload?.product
						} for ${formatNumberToCurrency(payload.amount)} is successful!`,
						type: 'success',
						hasCloseButton: true,
						children: <EVoucherPin pin={payload.pin} />,
						primaryButtonText: 'Purchase again',
						secondaryButtonText: 'Close',
						onClickPrimaryButton: () => {
							setSelectedProduct(null);
							modal(null);
						},
						onClickSecondaryButton: () => {
							setSelectedProduct(null);
							modal(null);
						},
					});
				}
			},
		});

	const {
		errors,
		values,
		touched,
		handleSubmit,
		setFieldValue,
		resetForm,
		setFieldError,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			const data: { [key: string]: any } = {
				productId: parseFloat(values.productId),
				operatorId: parseFloat(values.operatorId),
				product: values.product,
				// amount: values.amount,
			};

			let amount = parseFloat(values.amount) || 0;

			const price = selectedProduct?.price;

			const currency = selectedProduct?.currency?.operator;

			if (selectedProduct) {
				if (isProductPriceRanged) {
					const minAmount = parseFloat(`${price?.min.operator}`);
					const maxAmount = parseFloat(`${price?.max.operator}`);

					if (amount < minAmount) {
						setFieldError(
							'amount',
							`Amount must be more than ${formatNumberToCurrency(
								minAmount,
								currency
							)}`
						);
						return;
					}
					if (amount > maxAmount) {
						setFieldError(
							'amount',
							`Amount must be less than ${formatNumberToCurrency(
								maxAmount,
								currency
							)}`
						);
						return;
					}

					data.amount = selectedProduct.conversion_rate * amount;
				} else {
					data.amount = amount;
				}
			}

			if (values.discount_code) {
				if (!coupon) {
					return alert({ message: 'Apply discount code', type: 'info' });
				}

				if (coupon && coupon.code !== values.discount_code) {
					return alert({ message: 'Invalid coupon code', type: 'error' });
				}

				data.discount_code = values.discount_code;
				amount = calculateDiscountedAmount({ coupon, amount });
			}

			const callback = () => {
				verifyPin(null);
				mutatePurchaseGiftCard(data as IGiftCardPurchase);
			};
			verifyPin({
				title: 'Gift Card Purchase',
				message: `Verify transaction pin to confirm the purchase of ${formatNumberToCurrency(
					amount,
					currency
				)} worth of ${data.product} Gift card`,
				callback,
			});

			// if (checkKycLevel()) {
			// }
		},
	});

	const { amount, discount_code, operatorId, productId, country } = values;

	const onChangeCode = (code: string) => {
		clearCoupon();
		setFieldValue('discount_code', code);
	};

	const handleApplyCode = () => {
		if (discount_code) {
			verifyCoupon(discount_code);

			return;
		}

		alert({ message: 'Enter discount code', type: 'info' });
	};

	// Gift Card Provider Qury

	const queryGiftCardProviders = async (country: string) => {
		setLoadingGiftCardProviders(true);
		try {
			const response = await giftCardProviders({
				country,
			});
			if (response && response.success) {
				const data = response.payload;
				setDataGiftCardProviders(data);
			}
		} catch (error) {
			const response = handleError({ error });
			if (response?.message)
				alert({ message: response.message, type: 'error' });
		} finally {
			setLoadingGiftCardProviders(false);
		}
	};

	// Gift products
	const getGiftcardProducts = async (providerId: string) => {
		try {
			setLoadingProducts(true);
			const response = await giftCardProducts(providerId);
			if (response.payload) setProducts(response.payload);
		} catch (error) {
			const response = handleError({ error });
			if (response?.message) {
				alert({
					message: response.message,
					type: 'error',
				});
			}
		} finally {
			setLoadingProducts(false);
		}
	};

	// Handlee Select Provider
	const handleSelectProvider = async (operatorId: string) => {
		if (dataGiftCardProviders && Array.isArray(dataGiftCardProviders)) {
			const provider = dataGiftCardProviders.find(
				(provider) => provider.id === operatorId
			);

			if (provider) {
				setFieldValue('operatorId', operatorId);
			}
		}
		setFieldValue('productId', SELECT_PACKAGE);
		setFieldValue('amount', '');
		dispatch(
			setTransactionBreakdown({
				serviceCost: 0,
			})
		);
		await getGiftcardProducts(operatorId); // Call products
	};

	const handleSelectProduct = (productId: string): void => {
		// Clear state
		dispatch(setTransactionBreakdown(null));
		setFieldValue('amount', '');

		if (products) {
			const foundProduct = products.find((product) => product.id === productId);

			if (foundProduct) {
				setSelectedProduct(foundProduct);
				setFieldValue('productId', foundProduct.id);
				setFieldValue('product', foundProduct.name);

				if (foundProduct.priceType === 'fixed') {
					const amount = foundProduct.price.user;
					setFieldValue('amount', amount);
					dispatch(
						setTransactionBreakdown({
							serviceCost: parseFloat(amount),
							currency: foundProduct.currency.user,
						})
					);
				}
			}
		}
	};

	const handleSelectCountry = (countryId: string) => {
		// Clear State
		setFieldValue('productId', SELECT_PACKAGE);
		setFieldValue('product', '');
		setFieldValue('operatorId', SELECT_PROVIDER);
		setFieldValue('amount', '');
		dispatch(setTransactionBreakdown(null));

		// Find selected country
		const country = countries.find(
			(value) => value.id.toString() === countryId.toString()
		);

		if (country) {
			// setSelectedCountry(country);
			queryGiftCardProviders(country.sortname);
			setFieldValue('country', country.id);
		}
	};

	return (
		<Box style={styles.form as any} component={'form'}>
			<CustomSelect
				labelText={SELECT_COUNTRY}
				isLoading={isLoadingProducts}
				placeholder={SELECT_COUNTRY}
				error={touched.country && Boolean(errors.country)}
				helpertext={touched.country && errors.country}
				options={
					countries
						? [
								{ label: SELECT_COUNTRY, value: '' },
								...countries.map((data) => ({
									label: data.name,
									value: data.id,
								})),
						  ]
						: [{ label: SELECT_COUNTRY, value: '' }]
				}
				value={country}
				onChange={(value) => handleSelectCountry(value)}
			/>
			<CustomSelectWithImage
				isLoading={isLoadingGiftCardProviders}
				disabled={isLoadingGiftCardProviders || country === SELECT_COUNTRY}
				labelText={SELECT_PROVIDER}
				placeholder={SELECT_PROVIDER}
				error={touched.operatorId && Boolean(errors.operatorId)}
				helpertext={touched.operatorId && errors.operatorId}
				logo={SOCHITEL_LINKS.OperatorLogo}
				logoWidth={'60px'}
				options={
					dataGiftCardProviders && Array.isArray(dataGiftCardProviders)
						? [
								...dataGiftCardProviders.map((data) => ({
									label: data.name,
									value: data.id,
									imageRef: data.brandId?.toString(),
								})),
						  ]
						: null
				}
				value={operatorId}
				onChange={(value) => handleSelectProvider(value)}
			/>

			<CustomSelect
				disabled={operatorId === SELECT_PROVIDER || isLoadingProducts}
				labelText={SELECT_PACKAGE}
				isLoading={isLoadingProducts}
				placeholder={SELECT_PACKAGE}
				error={touched.productId && Boolean(errors.productId)}
				helpertext={touched.productId && errors.productId}
				options={
					products
						? [
								{ label: SELECT_PACKAGE, value: '' },
								...products.map((data) => ({
									label: data.name,
									value: data.id,
								})),
						  ]
						: [{ label: SELECT_PROVIDER, value: '' }]
				}
				value={productId}
				onChange={(value) => handleSelectProduct(value)}
			/>

			{isProductPriceRanged && (
				<Box>
					<TextInput
						labelText={
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}
							>
								<Typography
									sx={{
										display: 'inline-block',
										fontWeight: '600',
									}}
								>
									Amount
								</Typography>
								<Typography
									sx={{
										display: ['inline-block', 'inline-block', 'none'],
									}}
								>
									{formatNumberToCurrency(
										parseFloat(amount) *
											parseFloat(`${selectedProduct?.conversion_rate}`),
										selectedProduct?.currency?.user
									)}
								</Typography>
							</Box>
						}
						fullWidth
						placeholder={'Type in amount'}
						error={touched.amount && Boolean(errors.amount)}
						helperText={touched.amount && errors.amount}
						value={amount}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							const amount = e.target.value;
							setFieldValue('amount', amount);
							dispatch(
								setTransactionBreakdown({
									serviceCost:
										parseFloat(amount) *
										parseFloat(`${selectedProduct?.conversion_rate}`),
									currency: selectedProduct?.currency?.user,
								})
							);
						}}
					/>
				</Box>
			)}

			<TextInput
				labelText={'	Discount code [optional]'}
				disabled={isVerifyingCoupon}
				value={discount_code}
				onChange={(e: ChangeEvent<HTMLInputElement>) =>
					onChangeCode(e.target.value)
				}
				fullWidth
				placeholder={'Type in discount code here'}
				InputProps={{
					endAdornment: (
						<InputAdornment position='start'>
							<Button
								loading={isVerifyingCoupon}
								onClick={handleApplyCode}
								disableRipple
								style={styles.applyBtn}
							>
								Apply
							</Button>
						</InputAdornment>
					),
				}}
			/>

			<Button
				loading={isPurchasingGiftCard}
				onClick={(e: React.FormEvent<HTMLButtonElement>) => {
					e.preventDefault();
					handleSubmit();
				}}
				size={'large'}
				style={styles.btn}
			>
				{phoneVerifiedData ? 'Purchase' : 'Continue'}
			</Button>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default GiftCardForm;
