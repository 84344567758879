import React from 'react';
import {
	Box,
	Button as MuiButton,
	useTheme,
	Popper,
	Paper,
	ClickAwayListener,
	Typography,
	styled,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import { Notifications as MuiNotificationIcon } from '@mui/icons-material';
import { useAppSelector } from 'store/hooks';
import { THEME_MODE, LINKS, INotification } from 'utilities';
import NotificationItem from '../notification-item';
import { useModal } from 'hooks';

const NotificationButton = () => {
	const theme = useTheme();
	const modal = useModal();
	const navigate = useNavigate();
	const mode = useAppSelector((store) => store.theme.mode);
	const notifications = useAppSelector(
		(store) => store.notification.notifications
	);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const closePopper = () => {
		setAnchorEl(null);
	};

	const handleClickNotification = (notification: INotification) => {
		const title = notification.subject;
		const message = notification.message;
		closePopper();
		modal.display({
			// message,
			children: (
				<Box>
					<Typography
						sx={{
							fontWeight: '600',
							fontSize: '20px',
							marginBottom: '20px !important',
						}}
						variant={'body1'}
					>
						{title}
					</Typography>
					<div
						style={{
							margin: '0px !important',
							fontSize: '13px',
						}}
						dangerouslySetInnerHTML={{ __html: message }}
					/>
				</Box>
			),
			hasCloseButton: true,
		});
	};

	return (
		<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
			<Box>
				<Button
					onClick={handleClick}
					size={'large'}
					sx={{
						border: `1px solid ${
							mode === THEME_MODE.dark ? grey[300] : theme.palette.primary.main
						}`,
						color:
							mode === THEME_MODE.dark ? grey[300] : theme.palette.primary.main,
					}}
				>
					<MuiNotificationIcon />
				</Button>
				<Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
					<Popper
						sx={{ zIndex: theme.zIndex.appBar + 10 }}
						open={Boolean(anchorEl)}
						anchorEl={anchorEl}
					>
						<Notifications sx={{ bgcolor: 'background.paper' }}>
							{notifications && (
								<>
									{notifications.length > 0 ? (
										<Box
											sx={{
												display: 'grid',
												gap: '8px',
											}}
										>
											{[...notifications].slice(0, 4).map((notification) => (
												<NotificationItem
													key={notification.id}
													notification={notification}
													onClick={handleClickNotification}
												/>
											))}
											<ViewAll
												onClick={() => {
													closePopper();
													navigate(LINKS.Notifications);
												}}
												variant={'body1'}
											>
												View all
											</ViewAll>
										</Box>
									) : (
										<Typography>No available notifications</Typography>
									)}
								</>
							)}
						</Notifications>
					</Popper>
				</Popper>
			</Box>
		</ClickAwayListener>
	);
};

const Button = styled(MuiButton)(({ theme }) => ({
	overflow: 'hidden',
	minWidth: 'unset',
	borderRadius: '5px',
}));

const Notifications = styled(Paper)(({ theme }) => ({
	marginTop: '15px',
	width: '200px',
	padding: '15px',
	display: 'grid',
	gridTemplateColumns: '1fr',
	gap: theme.spacing(2),
}));

const ViewAll = styled(Typography)(({ theme }) => ({
	justifySelf: 'center',
	color: theme.palette.secondary.main,
	cursor: 'pointer',
	marginTop: theme.spacing(2),
}));

export default NotificationButton;
