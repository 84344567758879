import { API_ENDPOINTS, Coupon, DataResponse } from 'utilities';
import { apiRequest } from '../config';

export const verifyCoupon = async (
	code: string
): Promise<DataResponse<Coupon>> =>
	apiRequest({
		url: `${API_ENDPOINTS.Coupon}/validate/${code}`,
		method: 'GET',
	});
