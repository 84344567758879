const PHONE_REX = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{5})$/;
const EMAIL_REX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const TEN_DIGITS = /[0-9]{10}/;
// const PASSWORD =
// 	/^.*(?=.{8,})((?=.*[!@#$&]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
const PASSWORD =
	/^.*(?=.{8,})((?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
const LOWER_CHARACTERS = /^(?=.*[a-z])/;
const UPPER_CHARACTERS = /^(?=.*[A-Z])/;
const NUMBER_CHARACTERS = /^(?=.*\d)/;
const SPECIAL_CHARACTERS = /(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).+$/;

export {
	PHONE_REX,
	EMAIL_REX,
	TEN_DIGITS,
	PASSWORD,
	LOWER_CHARACTERS,
	UPPER_CHARACTERS,
	NUMBER_CHARACTERS,
	SPECIAL_CHARACTERS,
};
