import React from 'react';
import { Tab } from '@mui/material';
import TabWrapper from './tab-wrapper';

type Props = {
	currentTab: string | number;
	handleChange: (e: any, value: number) => void;
};

const SettingsTab = ({ currentTab, handleChange }: Props) => {
	return (
		<TabWrapper currentTab={currentTab} handleChange={handleChange}>
			<Tab label='Profile' />
			<Tab label='Security' />
			<Tab label='Notification' />
			<Tab label='Bank information' />
			<Tab label='Manage limit' />
		</TabWrapper>
	);
};

export default SettingsTab;
