import {
	API_ENDPOINTS,
	Bank,
	DataResponse,
	DeleteBank,
	BankAccount,
	VerifyAccountPayload,
} from 'utilities';
import { apiRequest } from '../config';

export const banks = async (params: {
	[key: string]: any;
}): Promise<DataResponse<Bank[]>> =>
	apiRequest({
		url: API_ENDPOINTS.Institutions,
		method: 'GET',
		params,
	});

export const myBanks = async (): Promise<DataResponse<BankAccount[]>> =>
	apiRequest({
		url: `${API_ENDPOINTS.BankAccount}/me`,
		method: 'GET',
	});

export const createBank = async (
	data: BankAccount
): Promise<DataResponse<BankAccount>> =>
	apiRequest({
		url: `${API_ENDPOINTS.BankAccount}`,
		method: 'POST',
		data,
	});

export const setDefaultBank = async (data: Record<string, any>): Promise<any> =>
	apiRequest({
		url: `${API_ENDPOINTS.User}/set-default-bank`,
		method: 'PUT',
		data,
	});

export const verifyBankAccount = async (
	params: VerifyAccountPayload
): Promise<any> =>
	apiRequest({
		url: `${API_ENDPOINTS.Institutions}/verify`,
		method: 'GET',
		params,
	});

export const deleteBank = async ({
	data,
	id,
}: {
	data: DeleteBank;
	id: string;
}): Promise<any> =>
	apiRequest({
		url: `${API_ENDPOINTS.BankAccount}/${id}`,
		method: 'DELETE',
		data,
	});
