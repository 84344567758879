import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NotificationsNoneOutlined } from '@mui/icons-material';
import {
	INotification,
	LIGHT_PRIMARY_COLOR,
	truncateText,
	removeHtmlTags,
} from 'utilities';

interface Props {
	notification: INotification;
	onClick?: (notification: INotification) => void;
	textCount?: number;
}

const NotificationItem: React.FC<Props> = ({
	notification,
	onClick,
	textCount = 20,
}) => {
	const message = removeHtmlTags(notification.message);
	return (
		<Box
			onClick={() => typeof onClick === 'function' && onClick(notification)}
			sx={{
				display: 'flex',
				gap: '15px',
				alignItems: 'center',
				userSelect: 'none',
				cursor: 'pointer',
			}}
		>
			<IconWrapper>
				<NotificationsNoneOutlined
					sx={{
						fontSize: '16px',
						color: (theme) => theme.palette.secondary.main,
					}}
				/>
			</IconWrapper>
			<Typography variant={'body2'}>
				{truncateText(message, textCount)}
			</Typography>
		</Box>
	);
};

const IconWrapper = styled(Box)(({ theme }) => ({
	borderRadius: '50%',
	color: theme.palette.primary.main,
	backgroundColor: LIGHT_PRIMARY_COLOR,
	padding: theme.spacing(2),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

export default NotificationItem;
