import React, { ReactNode } from 'react';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Add, Minimize } from '@mui/icons-material';
import { useAppSelector } from 'store/hooks';
import { THEME_MODE } from 'utilities';

type Props = {
	summary: ReactNode;
	accordion: string;
	selected: string;
	children: ReactNode;
	handleChange: () => void;
};

const AccordionWrapper = ({
	summary,
	accordion,
	selected,
	children,
	handleChange,
}: Props) => {
	const theme = useTheme();
	const { mode } = useAppSelector((store) => store.theme);
	return (
		<Accordion
			sx={{
				border:
					accordion === selected
						? `1px solid ${
								mode === THEME_MODE.dark
									? grey[500]
									: theme.palette.primary.main
						  }`
						: 'none',
				borderRadius: '5px',
				padding: '8px 5px',
				backgroundColor: 'unset',
			}}
			expanded={accordion === selected}
			onChange={handleChange}
			elevation={0}
		>
			<AccordionSummary
				sx={{
					svg: {
						color:
							mode === THEME_MODE.dark ? grey[500] : theme.palette.primary.main,
					},
				}}
				expandIcon={accordion === selected ? <Minimize /> : <Add />}
			>
				{summary}
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};

export default AccordionWrapper;
